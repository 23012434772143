import React from 'react';
import { useSelector } from "react-redux";
import { Row, Col, Image } from 'react-bootstrap';

import empatKosongEmpat from "../assets/images/system/empatKosongEmpat.png";
import Layout from "../components/Layout";
import { brand, widthLG, widthMD, widthSM, widthXS } from "../utils/globalVar";

const EmpatKosongEmpatGeneral = () => {

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);

    return (
        <React.Fragment>

            <Layout titleTab="404">
                <Row>
                    <Col xs={12} className="mt-5">
                        <div className="text-center">

                            <div className="f50 fontweightBold" style={{ color: '#263E53' }}>
                                404
                            </div>

                            <div className="f14 fontweight600" style={{ color: '#263E53' }}>
                                Halaman Tidak Ditemukan
                            </div>

                            <div className="mt-4">
                                <Image
                                    src={empatKosongEmpat}
                                    style={{ width: reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? '75%' : '50%') : '40%') : '30%') : '30%' }}
                                />
                            </div>

                            <div className="mt-4" style={{ color: '#263E53' }}>
                                <div className="f20 fontweightBold">{brand.nama}</div>
                                <div className="f14 fontweightBold">{brand.tag}</div>
                                <div className="f12 fontweightBold">{brand.keterangan}</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Layout>

        </React.Fragment>
    );
}

export default EmpatKosongEmpatGeneral;
