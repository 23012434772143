import React from 'react';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import 'font-awesome/css/font-awesome.min.css';
import { Row, Col, Card, Image, Form, Modal, Button } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image as ImagePdf } from '@react-pdf/renderer';

import CustomerAvatar from "../components/CustomerAvatar";
import soldering from "../assets/images/system/soldering.png";
import capTtd from "../assets/images/system/cap_ttd.png";
import { ROOT_BASE_URL, BASE_URL, batasFluid, metodeBayar, kategoriServis, reminderMaintenance, widthLG, widthMD, widthSM, widthXS, levelUser } from "../utils/globalVar";
import { isoDateToSqlDate, sqlDateToLongHumanDate, dateTimeToHumanDateTime, formatNumberCanZero, formatNumberCanZeroMinus, deko } from "../utils/globalFunc";
import Layout from "../components/Layout";
import NavbarTopOwner from "../components/NavbarTop";
import NavbarTopTeknisi from "../components/teknisi/NavbarTop";
import ModalAlertWarning from '../components/ModalAlertWarning';
import PlaceHolderElement1 from "../components/PlaceHolderElement1";
import KopDokumen from "../components/KopDokumen";

const DokumenBuktiBayar = () => {

    const parameter = useParams();
    const idTransaksi = parameter.id;

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [srcFoto, setSrcFoto] = React.useState('');
    const [modalZoomImage, setModalZoomImage] = React.useState(false);

    const [loadingDataProses, setLoadingDataProses] = React.useState(false);

    const [resumeBiaya, setResumeBiaya] = React.useState({
        subTotal: '',
        diskon: '',
        total: ''
    });

    const [stateData, setStateData] = React.useState({
        dataPenggunaanStok: {},
        dataProses: null,
        dataKelengkapan: null,
        listFotoTransaksi: null,
        dataProgres: null,
        dataPenggunaanStokInclusion: null,
        dataTDBYR: null,
        dataDiskon: null,
        kategoriServis: '',
        reminderAt: '',
        isLunas: '0',
        tanggalAmbil: '',
        tanggalPelunasan: '',
        catatanSelesai: '',
        dataLabel: null,
        penerima: ''
    });

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [])

    React.useEffect(() => {
        setLoadingDataProses(true);

        axios({
            method: 'post',
            url: BASE_URL + '/proses_ending',
            data: {
                id_transaksi: idTransaksi,
                flag_proses: 5
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            document.body.style.backgroundColor = "#FAF9F9";
            setLoadingDataProses(false);

            if (response.data.data_proses) {
                setStateData(s => ({
                    ...s,
                    dataPenggunaanStok: response.data.data_penggunaan_stok,
                    dataProses: response.data.data_proses,
                    dataKelengkapan: response.data.data_kelengkapan,
                    listFotoTransaksi: response.data.data_foto_transaksi,
                    dataProgres: response.data.data_progres['k1'],
                    dataPenggunaanStokInclusion: response.data.data_penggunaan_stok_inclusion,
                    dataTDBYR: response.data.data_tdbyr,
                    dataDiskon: response.data.data_diskon,
                    kategoriServis: response.data.data_proses[0].kategori_servis ? response.data.data_proses[0].kategori_servis : '',
                    reminderAt: response.data.data_proses[0].reminder_at ? response.data.data_proses[0].reminder_at : '',
                    isLunas: response.data.data_proses[0].is_lunas ? response.data.data_proses[0].is_lunas : '0',
                    tanggalAmbil: response.data.data_proses[0].tanggal_ambil ? response.data.data_proses[0].tanggal_ambil : '',
                    tanggalPelunasan: response.data.data_proses[0].tanggal_pelunasan ? response.data.data_proses[0].tanggal_pelunasan : '',
                    catatanSelesai: response.data.data_proses[0].catatan_selesai ? response.data.data_proses[0].catatan_selesai : '',
                    dataLabel: response.data.data_label,
                    penerima: response.data.penerima
                }));

                handleSubTotalSummary(response.data.data_tdbyr, response.data.data_diskon, response.data.data_proses[0].dp);
            } else {
                setStateData(s => ({ ...s, dataProses: null }));
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingDataProses(false);
            handleWarningModal(error.message);
        })
    }, [idTransaksi]);

    const handleSubTotalSummary = (arrSTS, prmDataDiskon, prmDp) => {

        const resDp = prmDp ? Number(prmDp) : 0;
        let resDiskon = 0;

        if (arrSTS) {
            var st = 0;
            arrSTS.forEach(element => {
                st = st + Number(element.total ? element.total : 0);
            });

            resDiskon = prmDataDiskon ? (prmDataDiskon[0].flag === 'rupiah' ? Number(prmDataDiskon[0].jumlah) : (Number(prmDataDiskon[0].jumlah) / 100 * st).toFixed(0)) : 0;

            setResumeBiaya({
                subTotal: st,
                diskon: resDiskon,
                total: st - resDp - resDiskon
            })
        } else {
            resDiskon = prmDataDiskon ? (prmDataDiskon[0].flag === 'rupiah' ? Number(prmDataDiskon[0].jumlah) : Number(prmDataDiskon[0].jumlah) / 100 * 0) : 0;

            setResumeBiaya({
                subTotal: 0,
                diskon: resDiskon,
                total: 0 - resDp - resDiskon
            })
        }
    }

    const handleUangMuka = (prmUangMuka) => {
        if (!prmUangMuka || prmUangMuka === '0') {
            return '-';
        } else {
            return 'Rp ' + formatNumberCanZero(prmUangMuka);
        }
    }

    const handleMetodeUangMuka = (prmMetode) => {
        if (!prmMetode) {
            return '';
        } else {
            let hasil = '';

            Object.keys(metodeBayar).forEach(element => {
                if (element === prmMetode) {
                    hasil = '(Metode ' + metodeBayar[element] + ')';
                    return
                }
            });

            return hasil;
        }
    }

    const widthQty = '30px';
    const widthNo = '25px';
    const widthHargaSatuan = '100px';
    const widthJumlah = '120px';

    const styles = StyleSheet.create({
        flexRow: {
            display: 'flex',
            flexDirection: "row",
            flexWrap: "nowrap"
        },
        subFlexRow: {
            flexBasis: 'auto',
            flexGrow: '1',
        },
        dataMasterSubSection: {
            display: 'flex',
            flexDirection: "row",
            flexWrap: "nowrap",
            marginBottom: '3px'
        },
        dataMasterCaptionA: {
            marginRight: '8px',
            width: '85px'
        },
        dataMasterCaptionB: {
            marginRight: '8px',
            width: '110px'
        },
        dataMasterContent: {
            flexBasis: 'auto',
            flexGrow: '1',
        },
        containerJudul: {
            display: 'flex',
            flexDirection: "row",
            flexWrap: "nowrap",
            borderBottom: '1px solid #000'
        },
        no: {
            fontWeight: 'bold',
            padding: '4px',
            borderRight: '1px solid #000',
            width: widthNo
        },
        qty: {
            fontWeight: 'bold',
            textAlign: 'center',
            paddingTop: '4px',
            paddingBottom: '4px',
            borderRight: '1px solid #000',
            width: widthQty
        },
        itemJasa: {
            fontWeight: 'bold',
            flexBasis: 'auto',
            flexGrow: '1',
            textAlign: 'center',
            paddingTop: '4px',
            paddingBottom: '4px',
            borderRight: '1px solid #000'
        },
        hargaSatuan: {
            fontWeight: 'bold',
            textAlign: 'center',
            paddingTop: '4px',
            paddingBottom: '4px',
            borderRight: '1px solid #000',
            width: widthHargaSatuan
        },
        jumlah: {
            fontWeight: 'bold',
            textAlign: 'center',
            paddingTop: '4px',
            paddingBottom: '4px',
            width: widthJumlah
        },
        serahTerima: {
            display: 'flex',
            flexDirection: "column",
            flexWrap: "nowrap",
            flexBasis: 'auto',
            flexGrow: '1',
            justifyContent: 'center',
            alignItems: 'center'
        }
    });

    const PdfBuktiBayar = () => {
        return (
            <Document>
                <Page size="a5" orientation="landscape" style={{ padding: '16px 32px 16px 32px' }}>
                    {stateData.dataProses &&
                        <View>
                            <KopDokumen />

                            <View style={{ marginTop: '10px' }}>
                                <Text style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'center' }}>
                                    Bukti Bayar
                                </Text>
                            </View>

                            <View style={{ marginTop: '10px', fontSize: '10px' }}>
                                <View style={styles.flexRow}>
                                    <View style={styles.subFlexRow}>
                                        <View style={styles.dataMasterSubSection}>
                                            <Text style={styles.dataMasterCaptionA}>
                                                No Nota
                                            </Text>

                                            <Text style={styles.dataMasterContent}>
                                                : {stateData.dataProses[0].id_transaksi}
                                            </Text>
                                        </View>

                                        <View style={styles.dataMasterSubSection}>
                                            <Text style={styles.dataMasterCaptionA}>
                                                Customer
                                            </Text>

                                            <Text style={styles.dataMasterContent}>
                                                : {stateData.dataProses[0].nama}
                                            </Text>
                                        </View>
                                    </View>

                                    <View style={styles.subFlexRow}>
                                        <View style={styles.dataMasterSubSection}>
                                            <Text style={styles.dataMasterCaptionB}>
                                                Telepon
                                            </Text>

                                            <Text style={styles.dataMasterContent}>
                                                : {stateData.dataProses[0].no_hp}
                                            </Text>
                                        </View>

                                        <View style={styles.dataMasterSubSection}>
                                            <Text style={styles.dataMasterCaptionB}>
                                                Tanggal Pembayaran
                                            </Text>

                                            <Text style={styles.dataMasterContent}>
                                                : {stateData.dataProses[0].tanggal_pelunasan ? sqlDateToLongHumanDate(stateData.dataProses[0].tanggal_pelunasan) : ' - '}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={{ border: '1px solid #000', marginTop: '6px', fontSize: '10px' }}>
                                <View style={styles.containerJudul}>
                                    <Text style={styles.no}>
                                        No
                                    </Text>

                                    <Text style={styles.itemJasa}>
                                        Item/Jasa
                                    </Text>

                                    <Text style={styles.qty}>
                                        Qty
                                    </Text>

                                    <Text style={styles.hargaSatuan}>
                                        Harga Satuan
                                    </Text>

                                    <Text style={styles.jumlah}>
                                        Jumlah
                                    </Text>
                                </View>

                                {stateData.dataTDBYR ? Object.keys(stateData.dataTDBYR).map((keyPdf) => {
                                    return (
                                        <View
                                            key={keyPdf + 'ps_pdf_7987gh'}
                                            style={{ display: 'flex', flexDirection: "row", flexWrap: "nowrap" }}
                                        >
                                            <Text style={{ width: widthNo, borderRight: '1px solid #000', padding: '4px' }}>
                                                {Number(keyPdf) + 1}
                                            </Text>

                                            <Text style={{ flexBasis: 'auto', flexGrow: '1', borderRight: '1px solid #000', padding: '4px' }}>
                                                {stateData.dataTDBYR[keyPdf].item_jasa}
                                            </Text>

                                            <Text style={{ width: widthQty, textAlign: 'center', borderRight: '1px solid #000', padding: '4px' }}>
                                                {formatNumberCanZero(stateData.dataTDBYR[keyPdf].jumlah)}
                                            </Text>

                                            <Text style={{ width: widthHargaSatuan, textAlign: 'right', borderRight: '1px solid #000', padding: '4px' }}>
                                                Rp {formatNumberCanZero(stateData.dataTDBYR[keyPdf].harga)}
                                            </Text>

                                            <Text style={{ width: widthJumlah, textAlign: 'right', padding: '4px' }}>
                                                Rp {formatNumberCanZero(stateData.dataTDBYR[keyPdf].total)}
                                            </Text>
                                        </View>
                                    )
                                })
                                    :
                                    <View
                                        style={{ display: 'flex', flexDirection: "row", flexWrap: "nowrap" }}
                                    >
                                        <Text style={{ width: widthNo, borderRight: '1px solid #000', padding: '4px' }}>
                                            -
                                        </Text>

                                        <Text style={{ flexBasis: 'auto', flexGrow: '1', borderRight: '1px solid #000', padding: '4px' }}>
                                            -
                                        </Text>

                                        <Text style={{ width: widthQty, textAlign: 'center', borderRight: '1px solid #000', padding: '4px' }}>
                                            -
                                        </Text>

                                        <Text style={{ width: widthHargaSatuan, textAlign: 'right', borderRight: '1px solid #000', padding: '4px' }}>
                                            -
                                        </Text>

                                        <Text style={{ width: widthJumlah, textAlign: 'right', padding: '4px' }}>
                                            -
                                        </Text>
                                    </View>
                                }

                                <View style={{ display: 'flex', flexDirection: "row", flexWrap: "nowrap", fontSize: '10px', borderTop: '1px solid #000' }}>
                                    <Text style={{ flexBasis: 'auto', flexGrow: '1', borderRight: '1px solid #000', padding: '4px', textAlign: 'left' }}>
                                        Sub Total
                                    </Text>

                                    <Text style={{ width: widthJumlah, textAlign: 'right', padding: '4px' }}>
                                        Rp {formatNumberCanZero(resumeBiaya.subTotal)}
                                    </Text>
                                </View>

                                <View style={{ display: 'flex', flexDirection: "row", flexWrap: "nowrap", fontSize: '10px', borderTop: '1px solid #000' }}>
                                    <Text style={{ flexBasis: 'auto', flexGrow: '1', borderRight: '1px solid #000', padding: '4px', textAlign: 'left' }}>
                                        Uang Muka
                                    </Text>

                                    <Text style={{ width: widthJumlah, textAlign: 'right', padding: '4px' }}>
                                        Rp {stateData.dataProses[0].dp ? formatNumberCanZero(stateData.dataProses[0].dp) : 0}
                                    </Text>
                                </View>

                                <View style={{ display: 'flex', flexDirection: "row", flexWrap: "nowrap", fontSize: '10px', borderTop: '1px solid #000' }}>
                                    <Text style={{ flexBasis: 'auto', flexGrow: '1', borderRight: '1px solid #000', padding: '4px', textAlign: 'left' }}>
                                        Diskon {stateData.dataDiskon && stateData.dataDiskon[0].keterangan &&
                                            <Text>
                                                ({stateData.dataDiskon[0].keterangan} sebesar {stateData.dataDiskon[0].flag === 'rupiah' && 'Rp '}{formatNumberCanZero(stateData.dataDiskon[0].jumlah)}{stateData.dataDiskon[0].flag !== 'rupiah' && '%'})
                                            </Text>
                                        }
                                    </Text>

                                    <Text style={{ width: widthJumlah, textAlign: 'right', padding: '4px' }}>
                                        {!resumeBiaya.diskon ? 'Rp 0' : 'Rp ' + formatNumberCanZero(resumeBiaya.diskon)}
                                    </Text>
                                </View>

                                <View style={{ display: 'flex', flexDirection: "row", flexWrap: "nowrap", fontSize: '10px', borderTop: '1px solid #000' }}>
                                    <Text style={{ flexBasis: 'auto', flexGrow: '1', borderRight: '1px solid #000', padding: '4px', textAlign: 'left' }}>
                                        Total
                                    </Text>

                                    <Text style={{ width: widthJumlah, textAlign: 'right', padding: '4px' }}>
                                        Rp {resumeBiaya.total > 0 ? formatNumberCanZeroMinus(resumeBiaya.total) : 0}
                                    </Text>
                                </View>
                            </View>

                            <View break style={{ marginTop: '10px' }}>
                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: "row",
                                        justifyContent: 'space-between',
                                        flexWrap: "nowrap"
                                    }}
                                >
                                    <View
                                        style={{
                                            flexBasis: 'auto',
                                            flexGrow: '0.55',
                                            fontSize: '8px'
                                        }}
                                    >
                                        <View>
                                            <Text>Catatan : </Text>
                                        </View>

                                        <View style={{ marginTop: '6px' }}>
                                            <View style={styles.flexRow}>
                                                <View style={{ width: '4px', marginRight: '2px' }}>
                                                    <Text>-</Text>
                                                </View>

                                                <View style={styles.subFlexRow}>
                                                    <Text>
                                                        Barang dapat diambil apabila sudah menyelesaikan pembayaran
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>

                                        <View style={{ marginTop: '3px' }}>
                                            <View style={styles.flexRow}>
                                                <View style={{ width: '4px', marginRight: '2px' }}>
                                                    <Text>-</Text>
                                                </View>

                                                <View style={styles.subFlexRow}>
                                                    <Text>
                                                        Garansi item yang diperbaiki yaitu satu bulan untuk kerusakan yang sama
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>

                                        <View style={{ marginTop: '3px' }}>
                                            <View style={styles.flexRow}>
                                                <View style={{ width: '4px', marginRight: '2px' }}>
                                                    <Text>-</Text>
                                                </View>

                                                <View style={styles.subFlexRow}>
                                                    <Text>
                                                        Pembayaran dapat dilakukan Tunai/Transfer BCA 8100 877 989 (Hans Adrian)
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>

                                        <View style={{ marginTop: '3px' }}>
                                            <View style={styles.flexRow}>
                                                <View style={{ width: '4px', marginRight: '2px' }}>
                                                    <Text>-</Text>
                                                </View>

                                                <View style={styles.subFlexRow}>
                                                    <Text>
                                                        Tunjukkan Bukti Pembayaran ini untuk garansi sparepart
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                    <View
                                        style={{
                                            display: 'flex',
                                            flexDirection: "row",
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-start',
                                            flexWrap: "nowrap",
                                            fontSize: '10px',
                                            flexBasis: 'auto',
                                            flexGrow: '0.4'
                                        }}
                                    >
                                        <View style={styles.serahTerima}>
                                            <View style={{ paddingBottom: '32px' }}>
                                                <Text>Diterima oleh,</Text>
                                            </View>
                                        </View>

                                        <View style={styles.serahTerima}>
                                            <View style={{ marginBottom: '4px' }}>
                                                <Text>Mengetahui,</Text>
                                            </View>

                                            <View>
                                                <ImagePdf src={capTtd} style={{ width: '75px' }} />
                                            </View>

                                            <View>
                                                <Text>Hans Adrian</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    }
                </Page>
            </Document>
        )
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />


            {deko(reduxLogin.syswebappj) === levelUser.owner &&
                <NavbarTopOwner
                    shadow={true}
                    titleNavTop="Cetak Bukti Bayar"
                    prmBackButton={true}
                />
            }

            {deko(reduxLogin.syswebappj) === levelUser.teknisi &&
                <NavbarTopTeknisi
                    shadow={true}
                    titleNavTop="Cetak Bukti Bayar"
                    prmBackButton={true}
                />
            }

            <Layout titleTab="Cetak Bukti Bayar">
                <Row> <Col xs={12} className="mt-3">
                    {stateData.dataProses && !loadingDataProses && <React.Fragment>
                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3 bgWhite`}>
                            <div className="flex">
                                <div>
                                    <CustomerAvatar
                                        prmSlug={stateData.dataProses[0].slug_ck}
                                        prmGender={stateData.dataProses[0].gender}
                                        ukuran={{ width: '55px', height: '55px' }}
                                    />
                                </div>

                                <div className="ml-3 wordBreakAll" style={{ width: '100%' }}>
                                    <div className="color636363 bold">
                                        {stateData.dataProses[0].nama}
                                    </div>

                                    <div className="color808080 f14">
                                        {stateData.dataProses[0].nama_ktgr_cust}
                                    </div>

                                    <div className="color808080 f14 mt-2">
                                        {stateData.dataProses[0].alamat}
                                    </div>

                                    {deko(reduxLogin.syswebappj) === levelUser.owner &&
                                        <div className="color808080 f14">
                                            {stateData.dataProses[0].no_hp}
                                        </div>
                                    }
                                </div>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600 mb-1">No Nota</div>
                                <div className="color808080">{stateData.dataProses[0].id_transaksi}</div>
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600 mb-1">Tanggal Masuk</div>
                                <div className="color808080">{sqlDateToLongHumanDate(stateData.dataProses[0].tanggal_masuk)}</div>
                            </div>

                            <div className="f14">
                                <div className="color636363 fontweight600 mb-1">Penerima</div>
                                <div className="color808080">{stateData.penerima}</div>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600 mb-1">
                                    Kategori Barang
                                </div>
                                <div className="color808080">{stateData.dataProses[0].nama_barang_kategori}</div>
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600 mb-1">Barang</div>
                                <div className="color808080">{stateData.dataProses[0].nama_barang}</div>
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600">Keluhan</div>
                                <div className="color808080">{stateData.dataProses[0].kerusakan}</div>
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600">Foto Barang</div>

                                {stateData.listFotoTransaksi ? <div className="text-center mt-2">
                                    <Masonry
                                        breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 4 : 5) : 5) : 6) : 6}`}
                                        className="my-masonry-grid"
                                        columnClassName="my-masonry-grid_column"
                                    >
                                        {Object.keys(stateData.listFotoTransaksi).map((key) => {
                                            return (
                                                <img
                                                    key={"lfkp87hjsdk" + key}
                                                    src={ROOT_BASE_URL + stateData.listFotoTransaksi[key].foto}
                                                    alt={'foto'}
                                                    style={{ width: '100%', borderRadius: '6px' }}
                                                    className="cp"
                                                    onClick={() => {
                                                        setSrcFoto(ROOT_BASE_URL + stateData.listFotoTransaksi[key].foto)
                                                        setModalZoomImage(true);
                                                    }}
                                                />
                                            )
                                        })}
                                    </Masonry>
                                </div>
                                    :
                                    <div className="color808080">
                                        Tidak ada
                                    </div>
                                }
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600">Uang Muka</div>
                                <div className="color808080">
                                    {handleUangMuka(stateData.dataProses[0].dp)}
                                    {' '}
                                    {handleMetodeUangMuka(stateData.dataProses[0].metode_dp)}
                                </div>
                            </div>

                            <div className="f14">
                                <div className="color636363 fontweight600">Catatan</div>
                                <div className="color808080">
                                    {stateData.dataProses[0].catatan ? stateData.dataProses[0].catatan : '-'}
                                </div>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                        >
                            <div className="f14 mb-3 colorTheme fontweight600">
                                Kelengkapan Barang
                            </div>

                            <div>
                                <div className="color636363 fontweight600">
                                    Kelengkapan Utama :
                                </div>
                                <div className="color808080 mt-1">{stateData.dataProses[0].nama_barang}</div>
                            </div>

                            <div className="mt-3">
                                <div className="color636363 fontweight600 mb-1">
                                    Kelengkapan Lainnya :
                                </div>

                                {stateData.dataLabel ? Object.keys(stateData.dataLabel).map((keyDL) => {
                                    return (
                                        <span
                                            key={keyDL + 'dl_78'}
                                            className={`color808080`}
                                        >
                                            <span>{stateData.dataLabel[keyDL].label}</span>
                                            <span>{stateData.dataLabel.length === (Number(keyDL) + 1) ? '' : ', '}</span>
                                        </span>
                                    )
                                })
                                    :
                                    <div className="color808080">Tidak ada</div>
                                }
                            </div>
                        </Card>

                        <div className={`mb-3`}>
                            <div className="mb-3 text-center fontweight600">Proses Pengerjaan</div>
                            {stateData.dataProgres ? <React.Fragment>
                                {Object.keys(stateData.dataProgres).map((key) => {
                                    return (
                                        <Card
                                            key={key}
                                            className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} ${stateData.dataProgres && Object.keys(stateData.dataProgres).length === (parseInt(key) + 1) ? '' : 'mb-3'} bgWhite shadow-sm brSoftMedium color636363 wordBreakAll`}
                                        >
                                            <div className="flex pb-2 mb-3 borderBottomEee">
                                                <div
                                                    className="bgTheme colorWhite f10 mr-3 flexJustifyAlignCenter"
                                                    style={{ width: '20px', minWidth: '20px', height: '20px', minHeight: '20px', marginTop: '2px', borderRadius: '50%' }}
                                                >
                                                    {parseInt(key) + 1}
                                                </div>

                                                <div style={{ flex: 1 }}>
                                                    <div className="fontweight600 colorTheme">
                                                        {stateData.dataProgres[key]['nama_user']}
                                                    </div>

                                                    <div className="f13 colorAaa">
                                                        {dateTimeToHumanDateTime(stateData.dataProgres[key]['tanggal_progres'])} WIB
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="color808080">
                                                {stateData.dataProgres[key].flag_urutan === '1' ? 'Project diambil oleh ' + stateData.dataProgres[key].nama_user :
                                                    <div>

                                                        <div className="flex">
                                                            <div className="flex1 whiteSpacePreLine">
                                                                {stateData.dataProgres[key].progres}
                                                            </div>
                                                        </div>

                                                        {stateData.dataProgres[key].id_tdp in stateData.dataPenggunaanStok &&
                                                            <div className="borderTopEee mt-3">
                                                                <div className="colorTheme mt-3">
                                                                    Penggunaan Part Stok Barang
                                                                </div>

                                                                <div className="mt-3">
                                                                    {stateData.dataProgres[key].id_tdp in stateData.dataPenggunaanStok && Object.keys(stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu).map((keyPS) => {
                                                                        return (
                                                                            <div
                                                                                key={keyPS}
                                                                                className={`flex ${Object.keys(stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu).length === (parseInt(keyPS) + 1) ? '' : 'mb-3'}`}
                                                                            >
                                                                                <div className="mr-2">
                                                                                    {(parseInt(keyPS) + 1) + '. '}
                                                                                </div>

                                                                                <div style={{ flex: 1 }}>
                                                                                    <div>
                                                                                        {stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].nama} {' '} {stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].merk} {' '} {stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].tipe} {' '} {stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].serial_number ? 'SN ' + stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].serial_number : 'SN -'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>


                                            {stateData.dataProgres[key]['b'] !== null && <div className="mt-3 pt-3 borderTopEee">
                                                <Masonry
                                                    breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 5 : 5) : 5) : 6) : 6}`}
                                                    className="my-masonry-grid"
                                                    columnClassName="my-masonry-grid_column"
                                                >
                                                    {Object.keys(stateData.dataProgres[key]['b']).map((subKey) => {
                                                        return (
                                                            <img
                                                                key={subKey}
                                                                src={ROOT_BASE_URL + stateData.dataProgres[key]['b'][subKey].foto}
                                                                alt={`foto ${subKey}`}
                                                                className="cp"
                                                                style={{ width: '100%', borderRadius: '6px' }}

                                                                onClick={() => {
                                                                    setSrcFoto(ROOT_BASE_URL + stateData.dataProgres[key]['b'][subKey].foto)
                                                                    setModalZoomImage(true);
                                                                }}
                                                            />
                                                        )
                                                    })}
                                                </Masonry>
                                            </div>
                                            }
                                        </Card>
                                    )
                                })}
                            </React.Fragment>
                                :
                                <div className="color808080 mt-3">
                                    Belum ada progres pengerjaan
                                </div>
                            }
                        </div>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="f14">
                                <div className="colorTheme fontweight600 mb-3">
                                    Form Q.C. Barang
                                </div>

                                <div className="mb-4 color808080">
                                    Kategori Form : {stateData.dataKelengkapan ? stateData.dataKelengkapan[0].nama_kategori : 'Tidak Ada'}
                                </div>

                                <div className="color808080">
                                    {stateData.dataKelengkapan && Object.keys(stateData.dataKelengkapan).map((key) => {
                                        return (<React.Fragment key={key + 'kkl7js'}>

                                            {key === '0' &&
                                                <div className="color636363 mb-3 pb-3 borderBottomEee">
                                                    <span className="mr-3">Q.C.</span>
                                                    <span>Item</span>
                                                </div>
                                            }

                                            <Form.Row className="mb-2">
                                                <Form.Group
                                                    as={Col}
                                                    xs="10"
                                                    controlId={`fg_01_pqc_${key}`}
                                                    className="mb-0"
                                                >
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={`fgfc_pqc_${key}`}
                                                        custom
                                                    >
                                                        <Form.Check.Input
                                                            type="checkbox"
                                                            readOnly
                                                            disabled

                                                            defaultChecked={parseInt(stateData.dataKelengkapan[key].check_qc)}

                                                            value={parseInt(stateData.dataKelengkapan[key].check_qc)}
                                                        />

                                                        <Form.Check.Label
                                                            className="ml-1"
                                                            style={{ paddingTop: '2px' }}
                                                        >
                                                            <div className="flexAlignCenter ml-3">
                                                                {
                                                                    parseInt(stateData.dataKelengkapan[key].check) ?
                                                                        <span
                                                                            className="fa fa-check f10 mr-1"
                                                                            style={{ color: '#007BFF' }}
                                                                        />
                                                                        :
                                                                        <span
                                                                            className="fa fa-close f10 mr-1"
                                                                            style={{ color: '#ccc' }}
                                                                        />
                                                                }

                                                                <span>
                                                                    {stateData.dataKelengkapan[key].nama_tdk}
                                                                </span>
                                                            </div>
                                                        </Form.Check.Label>

                                                        <div className="mt-2 color808080 f14 ml-4">
                                                            {stateData.dataKelengkapan[key].keterangan}
                                                        </div>

                                                        <div
                                                            className="mt-2 color808080 f14 ml-4"
                                                        >
                                                            {stateData.dataKelengkapan[key].keterangan_qc &&
                                                                <div className="borderTopEee pt-2">
                                                                    <div className="mb-0">
                                                                        Keterangan Q.C. :
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div id={`ketCapt_so7_pqc_${key}`}>
                                                                {stateData.dataKelengkapan[key].keterangan_qc}
                                                            </div>
                                                        </div>
                                                    </Form.Check>
                                                </Form.Group>
                                            </Form.Row>

                                            {(stateData.dataKelengkapan.length - 1) !== parseInt(key) && <hr />}
                                        </React.Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="colorTheme fontweight600 mb-2">Rincian Pengambilan</div>

                            <div className="color636363 mb-3">
                                <div className="mb-1">
                                    Kategori Servis
                                </div>

                                <div className="color808080">
                                    {Object.keys(kategoriServis).includes(stateData.kategoriServis) ? kategoriServis[stateData.kategoriServis] : 'Tidak ada'}
                                </div>
                            </div>

                            {stateData.kategoriServis && stateData.kategoriServis === reminderMaintenance &&
                                <div className="color636363 mb-3">
                                    <div className="mb-1">
                                        Reminder Untuk Maintenance
                                    </div>

                                    <div className="color808080">
                                        {stateData.reminderAt ? (stateData.reminderAt + ' Bulan Dari Tanggal Pengambilan') : 'Tidak ada reminder / Sudah diremove'}
                                    </div>
                                </div>
                            }

                            <div className="color636363">
                                <div className="mb-1">
                                    Tanggal Ambil
                                </div>

                                <div className="color808080">
                                    {stateData.tanggalAmbil ? sqlDateToLongHumanDate(isoDateToSqlDate(stateData.tanggalAmbil)) : '-'}
                                </div>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="colorTheme fontweight600 mb-2">Catatan</div>

                            <div className="color636363">
                                {stateData.catatanSelesai ? stateData.catatanSelesai : '-'}
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="colorTheme fontweight600 mb-2">
                                Stok item yang digunakan
                            </div>

                            <div className="color636363">
                                {stateData.dataPenggunaanStokInclusion &&
                                    <div className="color808080">
                                        {Object.keys(stateData.dataPenggunaanStokInclusion).map((key) => {
                                            return (
                                                <div key={key + 'inclsn_ps_879j'} className={`borderBottomEeeChild ${(Number(key) + 1) === stateData.dataPenggunaanStokInclusion.length ? 'pt-2' : 'py-2'}`}>
                                                    {stateData.dataPenggunaanStokInclusion[key].nama + ' ' + stateData.dataPenggunaanStokInclusion[key].merk + ' ' + stateData.dataPenggunaanStokInclusion[key].tipe + ' SN ' + (stateData.dataPenggunaanStokInclusion[key].serial_number ? stateData.dataPenggunaanStokInclusion[key].serial_number : '-')}
                                                </div>
                                            )
                                        })}
                                    </div>
                                }

                                {!stateData.dataPenggunaanStokInclusion &&
                                    <div>Tidak ada</div>
                                }
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="colorTheme fontweight600 mb-2">Rincian Biaya</div>

                            <div>
                                {stateData.dataTDBYR ? Object.keys(stateData.dataTDBYR).map((key) => {
                                    return (
                                        <div
                                            key={key + 'ps_7987gh'}
                                            className={`mt-2 ${(Number(key) + 1) === stateData.dataTDBYR.length ? '' : 'pb-2'} borderBottomEeeChild color636363`}
                                        >
                                            <div className="mb-1">
                                                {stateData.dataTDBYR[key].item_jasa}
                                            </div>

                                            <div>
                                                <Row>
                                                    <Col>
                                                        Rp {formatNumberCanZero(stateData.dataTDBYR[key].harga)}
                                                    </Col>

                                                    <Col>
                                                        Qty {formatNumberCanZero(stateData.dataTDBYR[key].jumlah)}
                                                    </Col>

                                                    <Col>
                                                        Rp {formatNumberCanZero(stateData.dataTDBYR[key].total)}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    )
                                })
                                    :
                                    <div className="color636363">Tidak ada</div>
                                }
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium`}>
                            <div>
                                <div className="flexAlignCenter mb-3">
                                    <div className="flex1">Sub Total</div>
                                    <div className="flex2 color636363">
                                        Rp {formatNumberCanZero(resumeBiaya.subTotal)}
                                    </div>
                                </div>

                                <div className="flexAlignCenter mb-3">
                                    <div className="flex1">Uang Muka</div>
                                    <div className="flex2 color636363">
                                        Rp {stateData.dataProses[0].dp ? formatNumberCanZero(stateData.dataProses[0].dp) : '0'}
                                    </div>
                                </div>

                                <div className="flexAlignTop mb-3">
                                    <div className="flex1">Diskon</div>
                                    <div className="flex2 color636363">
                                        <div className="colorTheme">
                                            {stateData.dataDiskon && stateData.dataDiskon[0].keterangan &&
                                                <div className="f13 color808080 mb-2">
                                                    <div className="flexJustifyBeetwenAlignCenter">
                                                        <div>
                                                            Diskon{' '}
                                                            {stateData.dataDiskon[0].flag === 'rupiah' && 'Rp '}
                                                            {formatNumberCanZero(stateData.dataDiskon[0].jumlah)}
                                                            {stateData.dataDiskon[0].flag !== 'rupiah' && '%'}
                                                        </div>
                                                    </div>

                                                    <div>{stateData.dataDiskon[0].keterangan}</div>
                                                </div>
                                            }
                                        </div>

                                        <div>
                                            {!resumeBiaya.diskon ? 'Tidak ada' : 'Rp ' + formatNumberCanZero(resumeBiaya.diskon)}
                                        </div>
                                    </div>
                                </div>

                                <div className="flexAlignCenter mb-3">
                                    <div className="flex1">Total</div>
                                    <div className="flex2 color636363">
                                        Rp {formatNumberCanZeroMinus(resumeBiaya.total)}
                                    </div>
                                </div>

                                <div className="flexAlignCenter mb-3">
                                    <div className="flex1">
                                        Lunas
                                    </div>
                                    <div className="flex2">
                                        <span className="color636363">Sudah Lunas</span>
                                    </div>
                                </div>

                                <div className="flexAlignCenter mb-3">
                                    <div className="flex1">
                                        Metode
                                    </div>

                                    <div className="flex2">
                                        <span className="color636363">
                                            {stateData.dataProses[0].metode_pelunasan ? metodeBayar[stateData.dataProses[0].metode_pelunasan] : 'Tidak ada'}
                                        </span>
                                    </div>
                                </div>

                                <div className="flexAlignCenter">
                                    <div className="flex1">
                                        Tanggal Lunas
                                    </div>

                                    <div className="flex2">
                                        <span className="color636363">
                                            {stateData.tanggalPelunasan ? sqlDateToLongHumanDate(stateData.tanggalPelunasan) : ''}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Card>

                        <div className="mt-4">
                            <PDFDownloadLink document={<PdfBuktiBayar />} fileName={`bukti_bayar_${idTransaksi}.pdf`}>
                                {({ blob, url, loading, error }) =>
                                    <Button
                                        disabled={loading}
                                        variant='info'
                                        size="sm"
                                        className="btnXsOverride width100"
                                    >
                                        <span>Download Bukti Bayar</span>
                                    </Button>
                                }
                            </PDFDownloadLink>
                        </div>
                    </React.Fragment>
                    }

                    {
                        loadingDataProses &&
                        <PlaceHolderElement1 prmMT="" prmPX="" />
                    }

                    {!stateData.dataProses && !loadingDataProses &&
                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-5' : 'p-4'} shadow-sm brSoftMedium text-center f13`}>
                            <div className="f14 mb-3 color808080">
                                Tidak ada data
                            </div>

                            <div className="flexJustifyAlignCenter">
                                <Image
                                    src={soldering}
                                    style={{ width: '75%' }}
                                />
                            </div>
                        </Card>
                    }
                </Col>
                </Row>
            </Layout>

            <Modal
                size="lg"
                show={modalZoomImage}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body className="p-0">
                    <div className="flexJustifyAlignCenter">
                        <span
                            onClick={() => setModalZoomImage(false)}
                            className="fa fa-close f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                            style={{
                                borderRadius: '50%',
                                width: '35px',
                                height: '35px',
                                position: 'absolute',
                                top: '15px',
                                right: '17px'
                            }}
                        />

                        <img src={srcFoto} alt='foto' style={{ width: '100%' }} />
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment>
    );
}

export default DokumenBuktiBayar;
