import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import "../../styles/dotMenu.scss";

const BackButtonNavTop = ({ prmColor }) => {
    return (
        <span className={`fa fa-chevron-left f15 ${prmColor ? prmColor : ''}`} />
    )
}

export default BackButtonNavTop