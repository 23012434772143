import React from 'react';
import { Col, Nav } from 'react-bootstrap';
import ScrollContainer from 'react-indiana-drag-scroll';

const PlaceHolderProses = ({ prmHeight }) => {

    const NavItem = () => {
        return (
            <Nav.Item className="mr-1">
                <Nav.Link>
                    <div className="flex px-2">
                        <div
                            className="mr-2"
                            style={{ width: '60px', backgroundColor: '#eee', borderRadius: '16px' }}
                        >
                            &nbsp;
                        </div>

                        <div
                            className="flexJustifyAlignCenter"
                            style={{
                                borderRadius: '50%',
                                padding: '4px',
                                fontSize: '9px',
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#eee'
                            }}
                        >&nbsp;</div>
                    </div>
                    <div><hr className="hrCaption" /></div>
                </Nav.Link>
            </Nav.Item>
        )
    }

    return (
        <Col
            xs={12}
            className={`px-3 pt-1 navProsesScrollHeight`}
            style={{
                position: 'sticky',
                top: `${prmHeight}px`,
                zIndex: '3',
                backgroundColor: '#fff',
                borderBottom: '1px solid #eee'
            }}
        >
            <ScrollContainer className="navProsesScroll">
                <Nav className={`navRelContainer`}>
                    <NavItem />
                    <NavItem />
                    <NavItem />
                    <NavItem />
                    <NavItem />
                    <NavItem />
                </Nav>

            </ScrollContainer>
        </Col>
    );
}

export default PlaceHolderProses;
