import React from 'react';
import axios from "axios";
import { Row, Col, Card, Spinner } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Lottie from "lottie-react";
import 'font-awesome/css/font-awesome.min.css';
import ScrollContainer from 'react-indiana-drag-scroll';

import CustomerAvatar from "../../components/CustomerAvatar";
import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import { brand, widthLG, widthMD, widthSM, widthXS, BASE_URL, BASE_URL_DUA, prefixUrl } from "../../utils/globalVar";
import { deko } from "../../utils/globalFunc";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ModalConfirmation from "../../components/ModalConfirmation";
import lottiePaus from "../../assets/lottie/paus.json";

const Home = () => {

    const history = useHistory();

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [countProsesNota, setCountProsesNota] = React.useState(null);
    const [countProsesSatu, setCountProsesSatu] = React.useState(null);
    const [countProsesDua, setCountProsesDua] = React.useState(null);
    const [countProsesTiga, setCountProsesTiga] = React.useState(null);
    const [countProsesAnda, setCountProsesAnda] = React.useState(null);
    const [countProsesRecommendedTodo, setCountProsesRecommendedTodo] = React.useState(null);

    const [detilProses, setDetilProses] = React.useState(null);
    const [flagProses, setFlagProses] = React.useState(null);
    const [loadingDetilProses, setLoadingDetilProses] = React.useState(false);

    const [loadingCounterProses, setLoadingCounterProses] = React.useState(false);
    const [closeDetilProses, setCloseDetilProses] = React.useState(false);

    const [loadingReminder, setLoadingReminder] = React.useState(false);
    const [dataReminder, setDataReminder] = React.useState(null);
    const [dataForAction, setDataForAction] = React.useState({
        id: ''
    });
    const [modalRemoveReminder, setModalRemoveReminder] = React.useState(false);
    const [loadingRemoveReminder, setLoadingRemoveReminder] = React.useState(false);

    const refDivSatu = React.useRef();
    const refDivDua = React.useRef();

    const mountedRef = React.useRef(true);
    const mountedRefDetilCounter = React.useRef(true);

    React.useEffect(() => {
        refDivDua.current = refDivSatu.current.clientHeight + 'px';
    }, [reduxWindowWidth])

    React.useEffect(() => {
        setLoadingCounterProses(true);

        axios({
            method: 'post',
            url: BASE_URL + '/all_proses',
            data: {
                id_user: deko(reduxLogin.syswebappi),
                by_teknisi_id: 'by_teknisi_id',
                user_recommended_todo: 'user_recommended_todo'
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingCounterProses(false);

            setCountProsesNota(response.data.proses_nota);
            setCountProsesSatu(response.data.proses_antri);
            setCountProsesDua(response.data.proses_pengerjaan);
            setCountProsesTiga(response.data.proses_qc);
            setCountProsesAnda(response.data.proses_by_teknisi_id);
            setCountProsesRecommendedTodo(response.data.antri_recommended_todo);
        }).catch(error => {
            setLoadingCounterProses(false);
            handleWarningModal('Warning : ' + error.message)
        })

        // batas ---

        setLoadingReminder(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/get_reminder',
            data: {
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingReminder(false);

            setDataReminder(response.data.reminder);
        }).catch(error => {
            setLoadingReminder(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }, [reduxLogin.syswebappi]);

    const handleDetilProses = (prmFlag) => {
        !closeDetilProses && setCloseDetilProses(true);
        setFlagProses(prmFlag);
        setLoadingDetilProses(true);

        setTimeout(() => {
            axios({
                method: 'post',
                url: BASE_URL + '/home_detil_proses_flag',
                data: {
                    flag_proses: prmFlag,
                    id_user: deko(reduxLogin.syswebappi)
                }
            }).then((response) => {
                if (!mountedRefDetilCounter.current) return null;

                setLoadingDetilProses(false);
                setDetilProses(response.data.data_detil);

                prmFlag === 0 && setCountProsesNota(response.data.data_detil ? response.data.data_detil.length : 0);

                prmFlag === 1 && setCountProsesSatu(response.data.data_detil ? response.data.data_detil.length : 0);

                prmFlag === 2 && setCountProsesDua(response.data.data_detil ? response.data.data_detil.length : 0);

                prmFlag === 3 && setCountProsesTiga(response.data.data_detil ? response.data.data_detil.length : 0);

                prmFlag === 'by_teknisi_id' && setCountProsesAnda(response.data.data_detil ? response.data.data_detil.length : 0);

                prmFlag === 'user_recommended_todo' && setCountProsesRecommendedTodo(response.data.data_detil ? response.data.data_detil.length : 0);
            }).catch(error => {
                setLoadingDetilProses(false);
                handleWarningModal('Warning : ' + error.message)
            })
        }, 250);
    }

    React.useEffect(() => {
        return () => {
            mountedRef.current = false;
            mountedRefDetilCounter.current = false;
        }
    }, []);

    const PlaceHolderCounterProses = () => {
        return (
            <Card
                className="bgSoftMedium brSoft mt-2 shadow-sm flexRowJustifyAlignCenter"
                style={{ height: '75px', minHeight: '75px' }}
            >
                <div
                    className="bgSoft rounded-lg"
                    style={{ width: '60%', height: '20%' }}
                />

                <div
                    className="bgSoft rounded-lg mt-2"
                    style={{ width: '20%', height: '20%' }}
                />
            </Card>
        )
    }

    const PlaceHolderDetilProses = () => {
        return (
            <Card
                className="brSoft shadow-sm bgSoftMedium px-1 pb-2"
                style={{
                    overflow: 'hidden',
                    minHeight: refDivDua.current,
                    maxHeight: refDivDua.current
                }}
            >
                <Card className="py-2 px-2 mt-2 bgSoft brSoft shadow-sm">
                    <div className="flexAlignCenter">
                        <div className="ml-2">
                            <div
                                style={{
                                    width: '30px',
                                    height: '30px',
                                    backgroundColor: '#eee',
                                    borderRadius: '50%'
                                }}
                            >
                                &nbsp;
                            </div>
                        </div>

                        <div className="ml-3 f13 width100">
                            <div
                                className="bgSoftMedium rounded-lg"
                                style={{ width: '30%' }}
                            >
                                &nbsp;
                            </div>

                            <div
                                className="bgSoftMedium rounded-lg mt-2"
                                style={{ width: '50%' }}
                            >
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </Card>

                <Card className="py-2 px-2 mt-2 bgSoft brSoft shadow-sm">
                    <div className="flexAlignCenter">
                        <div className="ml-2">
                            <div
                                style={{
                                    width: '30px',
                                    height: '30px',
                                    backgroundColor: '#eee',
                                    borderRadius: '50%'
                                }}
                            >
                                &nbsp;
                            </div>
                        </div>

                        <div className="ml-3 f13 width100">
                            <div
                                className="bgSoftMedium rounded-lg"
                                style={{ width: '30%' }}
                            >
                                &nbsp;
                            </div>

                            <div
                                className="bgSoftMedium rounded-lg mt-2"
                                style={{ width: '50%' }}
                            >
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </Card>
            </Card>
        )
    }

    const handleLinkToProses = (prmIdTransaksi) => {
        flagProses === 0 && history.push('/transaksi/' + prmIdTransaksi + '/home');
        flagProses === 1 && history.push('/confirmdoproses/' + prmIdTransaksi + '/home');
        flagProses === 2 && history.push('/working/' + prmIdTransaksi + '/home');
        flagProses === 3 && history.push('/' + prefixUrl.owner + '/qc/' + prmIdTransaksi + '/home');
        flagProses === 'by_teknisi_id' && history.push('/working/' + prmIdTransaksi + '/home');
        flagProses === 'user_recommended_todo' && history.push('/confirmdoproses/' + prmIdTransaksi + '/home');
    }

    const styleGarisBiru = {
        width: '55%',
        height: '3px',
        marginTop: '8px',
        marginBottom: '3px'
    }

    const styleTinggiCard = {
        height: '100px',
        minHeight: '100px'
    }

    const handleRemoveReminder = () => {
        setModalRemoveReminder(false);
        setLoadingRemoveReminder(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/remove_reminder',
            data: {
                id_user: deko(reduxLogin.syswebappi),
                id_transaksi: dataForAction.id
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingRemoveReminder(false);

            if (response.data.flag_status === true) {
                setDataReminder(response.data.reminder);
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.pesan)
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingRemoveReminder(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }

    const handleGoTransaksi = (prm) => {
        history.push('/' + prefixUrl.owner + '/sudahdiambil/' + prm);
    }



    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop shadow={false} titleNavTop="" />

            <Layout titleTab="Home">
                <Row>
                    <Col xs={12} className="mt-2 px-3">
                        <div className="f25 fontweight500">{brand.nama}</div>
                        <div className="f14 fontweight500 color636363">{brand.tag}</div>
                        <div className="f14 fontweight500 color636363">{brand.keterangan}</div>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col xs={12}>
                        <div className="color636363 fontweight500 mb-3">
                            Reminder Maintenance
                        </div>

                        {dataReminder && !loadingReminder && !loadingRemoveReminder &&
                            <Masonry
                                breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 1 : 2) : 2) : 2) : 2}`}
                                className="my-masonry-grid-12"
                                columnClassName="my-masonry-grid_column-12"
                            >
                                {Object.keys(dataReminder).map((key) => {
                                    return (
                                        <div key={key + 'r_745'} className="f13">
                                            <div className="alert alert-info mb-0 px-3">
                                                <div className="flex">
                                                    <div className="">
                                                        <CustomerAvatar
                                                            prmSlug={dataReminder[key].slug_ck}
                                                            prmGender={dataReminder[key].gender}
                                                            ukuran={{ width: '30px', height: '30px' }}
                                                        />
                                                    </div>

                                                    <div className="ml-3 flex1">
                                                        <div>{dataReminder[key].nama}</div>
                                                        <div>{dataReminder[key].alamat} {dataReminder[key].no_hp}</div>
                                                    </div>

                                                    <div className="ml-3 flexJustifyAlignCenter">
                                                        <span
                                                            className="fa fa-close cp"
                                                            onClick={() => {
                                                                setDataForAction({ ...dataForAction, id: dataReminder[key].id_transaksi });
                                                                setModalRemoveReminder(true);
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <hr className="my-2" />

                                                <div>Barang {dataReminder[key].nama_barang}</div>
                                                <div>Kategori {dataReminder[key].nama_barang_kategori}</div>

                                                <div className="flexJustifyBeetwenAlignCenter">
                                                    <div>Direminder {dataReminder[key].reminder_at} bulan lalu</div>
                                                    <div>
                                                        <span
                                                            className="underLine"
                                                            onClick={() => handleGoTransaksi(dataReminder[key].id_transaksi)}
                                                        >
                                                            Lihat Transaksi
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Masonry>
                        }

                        {!dataReminder && !loadingReminder && !loadingRemoveReminder &&
                            <div className="bgAlert p-3 rounded-sm color808080">Belum ada reminder</div>
                        }

                        {loadingReminder &&
                            <div className="bgAlert p-3 rounded-sm color808080">
                                <span>Checking reminder</span>
                                <Spinner
                                    as="span"
                                    className="ml-2"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </div>
                        }

                        {loadingRemoveReminder &&
                            <div className="bgAlert p-3 rounded-sm color808080">
                                <span>Removing reminder</span>
                                <Spinner
                                    as="span"
                                    className="ml-2"
                                    variant="info"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </div>
                        }
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col xs={12} className="mb-3">
                        <div className="color636363 fontweight500">
                            Project
                        </div>
                    </Col>

                    <Col xs={12} md={6} className="colSatu mb-3">
                        <Card className="divSatu pb-2 px-1 bgAlert brSoftMedium shadow-sm" ref={refDivSatu}>
                            <Masonry
                                breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 3 : 4) : 3) : 4) : 4}`}
                                className="my-masonry-grid"
                                columnClassName="my-masonry-grid_column"
                            >
                                {!loadingCounterProses ?
                                    <Card
                                        className="bgWhite f13 brSoft shadow-sm mt-2 py-2 px-2 cp"
                                        style={styleTinggiCard}
                                        onClick={() => handleDetilProses(0)}
                                    >
                                        <div className="flexRowJustifyAlignCenter f12 flex1">
                                            <div>Semua Nota</div>
                                        </div>

                                        <div className="flexRowJustifyAlignCenter">
                                            <div>{countProsesNota}</div>
                                            <div
                                                className={`${flagProses === 0 ? 'bgTheme' : 'bgSoftMedium'} rounded-sm`}
                                                style={styleGarisBiru}
                                            />
                                        </div>
                                    </Card>
                                    :
                                    <PlaceHolderCounterProses />
                                }

                                {!loadingCounterProses ?
                                    <Card
                                        className="bgWhite f13 brSoft shadow-sm mt-2 py-2 px-2 cp"
                                        style={styleTinggiCard}
                                        onClick={() => handleDetilProses(1)}
                                    >
                                        <div className="flexRowJustifyAlignCenter f12 flex1">
                                            <div>Semua Antrian</div>
                                        </div>

                                        <div className="flexRowJustifyAlignCenter">
                                            <div>{countProsesSatu}</div>
                                            <div
                                                className={`${flagProses === 1 ? 'bgTheme' : 'bgSoftMedium'} rounded-sm`}
                                                style={styleGarisBiru}
                                            />
                                        </div>
                                    </Card>
                                    :
                                    <PlaceHolderCounterProses />
                                }

                                {!loadingCounterProses ?
                                    <Card
                                        className="bgWhite f13 brSoft shadow-sm mt-2 py-2 px-2 cp"
                                        style={styleTinggiCard}
                                        onClick={() => handleDetilProses(2)}
                                    >
                                        <div className="flexRowJustifyAlignCenter f12 flex1">
                                            <div>Semua Proses</div>
                                        </div>

                                        <div className="flexRowJustifyAlignCenter">
                                            <div>{countProsesDua}</div>
                                            <div
                                                className={`${flagProses === 2 ? 'bgTheme' : 'bgSoftMedium'} rounded-sm`}
                                                style={styleGarisBiru}
                                            />
                                        </div>
                                    </Card>
                                    :
                                    <PlaceHolderCounterProses />
                                }

                                {!loadingCounterProses ?
                                    <Card
                                        className="bgWhite f13 brSoft shadow-sm mt-2 py-2 px-2 cp"
                                        style={styleTinggiCard}
                                        onClick={() => handleDetilProses(3)}
                                    >
                                        <div className="flexRowJustifyAlignCenter f12 flex1">
                                            <div>Semua Q.C.</div>
                                        </div>

                                        <div className="flexRowJustifyAlignCenter">
                                            <div>{countProsesTiga}</div>
                                            <div
                                                className={`${flagProses === 3 ? 'bgTheme' : 'bgSoftMedium'} rounded-sm`}
                                                style={styleGarisBiru}
                                            />
                                        </div>
                                    </Card>
                                    :
                                    <PlaceHolderCounterProses />
                                }

                                {!loadingCounterProses ?
                                    <Card
                                        className="bgWhite f13 brSoft shadow-sm mt-2 py-2 px-2 cp"
                                        style={styleTinggiCard}
                                        onClick={() => handleDetilProses('user_recommended_todo')}
                                    >
                                        <div className="flexRowJustifyAlignCenter f12 flex1">
                                            <div className="text-center">Antrian Untuk Anda</div>
                                        </div>

                                        <div className="flexRowJustifyAlignCenter">
                                            <div>{countProsesRecommendedTodo}</div>
                                            <div
                                                className={`${flagProses === 'user_recommended_todo' ? 'bgTheme' : 'bgSoftMedium'} rounded-sm`}
                                                style={styleGarisBiru}
                                            />
                                        </div>
                                    </Card>
                                    :
                                    <PlaceHolderCounterProses />
                                }

                                {!loadingCounterProses ?
                                    <Card
                                        className="bgWhite f13 brSoft shadow-sm mt-2 py-2 px-2 cp"
                                        style={styleTinggiCard}
                                        onClick={() => handleDetilProses('by_teknisi_id')}
                                    >
                                        <div className="flexRowJustifyAlignCenter f12 flex1">
                                            <div className="text-center">Sedang Anda Kerjakan</div>
                                        </div>

                                        <div className="flexRowJustifyAlignCenter">
                                            <div>{countProsesAnda}</div>
                                            <div
                                                className={`${flagProses === 'by_teknisi_id' ? 'bgTheme' : 'bgSoftMedium'} rounded-sm`}
                                                style={styleGarisBiru}
                                            />
                                        </div>
                                    </Card>
                                    :
                                    <PlaceHolderCounterProses />
                                }
                            </Masonry>
                        </Card>
                    </Col>

                    <Col xs={12} md={6} className="colDua mb-3">
                        {!loadingDetilProses && flagProses !== null && closeDetilProses &&
                            <div
                                className={`${detilProses ? 'bgAlert' : 'bgSoft'} brSoftMedium shadow-sm borderRadiusCard`}
                            >
                                <Card
                                    className={`${detilProses ? 'bgAlert' : 'bgSoft flexJustifyAlignCenter'} px-1 pb-2 hideScrollBar border-0`}
                                    style={{
                                        overflowY: 'scroll',
                                        minHeight: refDivDua.current,
                                        maxHeight: refDivDua.current
                                    }}
                                >
                                    <ScrollContainer>
                                        {detilProses ?
                                            Object.keys(detilProses).map((key) => {
                                                return (
                                                    <div key={key}>
                                                        <Card
                                                            className={`py-2 px-2 mt-2 bgWhite brSoft shadow-sm cp`}
                                                            onClick={() => handleLinkToProses(detilProses[key].id_transaksi)}
                                                        >
                                                            <div className="flexAlignCenter">
                                                                <div className="ml-2">
                                                                    <CustomerAvatar
                                                                        prmSlug={detilProses[key].slug_ck}
                                                                        prmGender={detilProses[key].gender}
                                                                        ukuran={{ width: '30px', height: '30px' }}
                                                                    />
                                                                </div>

                                                                <div className="ml-3 f13 color808080">
                                                                    <div className="fontweight600 color636363">{detilProses[key].nama}</div>
                                                                    <div>{detilProses[key].nama_barang ? detilProses[key].nama_barang : ''}</div>
                                                                    <div>{detilProses[key].id_transaksi}</div>

                                                                    {(flagProses === 1 || flagProses === 'user_recommended_todo') &&
                                                                        <div className="mt-1">
                                                                            Disarankan untuk {detilProses[key].nama_user_recommended_todo ? detilProses[key].nama_user_recommended_todo : <span className="mx-2">-</span>}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Card>

                                                        {
                                                            (parseInt(key) + 1) === detilProses.length &&
                                                            <div className="flexJustifyAlignCenter mt-3 mb-3 color808080 f13"
                                                            >
                                                                End of Data
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="flexJustifyAlignCenter mt-3">
                                                <div className="flexRowJustifyAlignCenter">
                                                    <div className="color808080 f12">Tidak Ada Data</div>
                                                    <Lottie
                                                        animationData={lottiePaus}
                                                        style={{
                                                            width: reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? '30%' : '30%') : '30%') : '30%') : '30%'
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </ScrollContainer>

                                    <div
                                        onClick={() => setCloseDetilProses(false)}
                                        className="flexJustifyAlignCenter mt-1 cp"
                                    >
                                        <span className="fa fa-chevron-up f18 colorBbb" />
                                    </div>
                                </Card>
                            </div>
                        }

                        {loadingDetilProses && <PlaceHolderDetilProses />}
                    </Col>

                </Row>
            </Layout>

            <ModalConfirmation
                showModal={modalRemoveReminder}
                hideModal={() => setModalRemoveReminder(false)}
                title="Konfirmasi"
                bodyCaption={<React.Fragment><div>Reminder yang telah diremove tidak akan ditampilkan kembali.<br /><br />Remove reminder ?</div></React.Fragment>}
                btnCloseClass="px-3"
                btnCloseCaption="Close"
                bLPrm={false}
                bLBtnClass="ml-3"
                bLOnClick={handleRemoveReminder}
                bLCaption="Remove"
            />

        </React.Fragment>
    );
}

export default Home;
