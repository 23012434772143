import React from 'react';
import axios from "axios";
import { useSelector } from "react-redux";
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import { BASE_URL_DUA } from "../../utils/globalVar";
import { deko } from "../../utils/globalFunc";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ModalConfirmation from "../../components/ModalConfirmation";
import ButtonLoading from "../../components/ButtonLoading";
import LottiePausAstronot from "../../components/LottiePausAstronot";
import PlaceHolderElement2 from "../../components/PlaceHolderElement2";

const Diskon = () => {

    const reduxLogin = useSelector(data => data.lumoslored);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [dataInit, setDataInit] = React.useState(null);
    const [loadingInit, setLoadingInit] = React.useState(false);

    const [modalTambah, setModalTambah] = React.useState(false);
    const [btnLoadingTambah, setBtnLoadingTambah] = React.useState(false);
    const refNama = React.useRef();
    const [dataForAction, setDataForAction] = React.useState({
        id: '',
        nama: ''
    });

    const [modalEdit, setModalEdit] = React.useState(false);
    const [btnLoadingEdit, setBtnLoadingEdit] = React.useState(false);
    const refNamaEdit = React.useRef();

    const [modalHapus, setModalHapus] = React.useState(false);
    const [btnLoadingHapus, setBtnLoadingHapus] = React.useState(false);

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        setLoadingInit(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/init_pengeluaran_kategori',
            data: {
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);

            setDataInit(response.data.data);
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);
            handleWarningModal('Warning : ' + error.message)
        });

        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [reduxLogin.syswebappi]);

    const handleTambah = () => {
        setBtnLoadingTambah(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/tambah_pengeluaran_kategori',
            data: {
                id_user: deko(reduxLogin.syswebappi),
                nama: refNama.current.value
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingTambah(false);
            setModalTambah(false);

            if (response.data.flag_status === true) {
                setDataInit(response.data.data);
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.error)
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingTambah(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }

    const handleEdit = () => {
        setBtnLoadingEdit(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/edit_pengeluaran_kategori',
            data: {
                id_user: deko(reduxLogin.syswebappi),
                nama: refNamaEdit.current.value,
                id: dataForAction.id
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingEdit(false);
            setModalEdit(false);

            if (response.data.flag_status === true) {
                setDataInit(response.data.data);
                setDataForAction({ id: '', nama: '' });
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.error)
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingEdit(false);
            setModalEdit(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }

    const handleHapus = () => {
        setBtnLoadingHapus(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/hapus_pengeluaran_kategori',
            data: {
                id_user: deko(reduxLogin.syswebappi),
                id: dataForAction.id
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapus(false);
            setModalHapus(false);

            if (response.data.flag_status === true) {
                setDataInit(response.data.data);
                setDataForAction({ id: '', nama: '' });
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.error)
            }

            if (response.data.flag_status === 'cannot-delete') {
                handleWarningModal('Kategori ini tidak dapat dihapus karena digunakan pada data pengeluaran.')
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapus(false);
            setModalHapus(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop
                shadow={true}
                titleNavTop="Kategori Pengeluaran"
                prmBackButton={true}
            />

            <Layout titleTab="Kategori Pengeluaran">

                <Row>
                    {!loadingInit &&
                        <Col xs={12} className="mt-4 mb-2">
                            <div className="text-right">
                                <div>
                                    <Button
                                        variant="info"
                                        size="sm"
                                        className="btnXsOverride"
                                        onClick={() => setModalTambah(true)}
                                    >
                                        <span className="fa fa-plus-circle mr-2" />
                                        <span>Tambah Kategori</span>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    }

                    {!loadingInit && dataInit &&
                        <Col xs={12} className="mt-3">
                            <div>
                                {Object.keys(dataInit).map((key) => {
                                    return (
                                        <div
                                            key={key + 'pk_898'}
                                            className="borderBottomEeeChild py-3 flexJustifyBeetwenAlignCenter"
                                        >
                                            <div>{dataInit[key].nama}</div>

                                            <div className="ml-3">
                                                <span
                                                    className="colorTheme mr-3 cp"
                                                    onClick={() => {
                                                        setDataForAction({
                                                            ...dataForAction,
                                                            id: dataInit[key].id,
                                                            nama: dataInit[key].nama
                                                        });

                                                        setModalEdit(true);
                                                    }}
                                                >
                                                    Edit
                                                </span>

                                                <span
                                                    className="colorTheme cp"
                                                    onClick={() => {
                                                        setDataForAction({
                                                            ...dataForAction,
                                                            id: dataInit[key].id,
                                                            nama: dataInit[key].nama
                                                        });

                                                        setModalHapus(true);
                                                    }}
                                                >
                                                    Hapus
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Col>
                    }

                    {!loadingInit && !dataInit &&
                        <Col xs={12} className="mt-3">
                            <div className="flexRowJustifyAlignCenter">
                                <LottiePausAstronot />
                            </div>
                        </Col>
                    }

                    {loadingInit &&
                        <Col xs={12} className="mt-3">
                            <div>
                                <PlaceHolderElement2 />
                            </div>
                        </Col>
                    }
                </Row>
            </Layout>

            <Modal
                show={modalTambah}
                onShow={() => refNama.current.focus()}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="fontweight600 pb-3 borderBottomEee">
                        Tambah Kategori Untuk Pengeluaran
                    </div>

                    <div className="mt-3">
                        <Form.Control
                            ref={refNama}
                            size="sm"
                            type="text"
                            placeholder="Nama Kategori"
                        />

                        <div className="mt-4 text-center">
                            <Button
                                variant="light"
                                size="sm"
                                className="ml-2"
                                onClick={() => setModalTambah(false)}
                            >
                                Kembali
                            </Button>

                            <ButtonLoading
                                prmLoading={btnLoadingTambah}
                                btnClass="px-3 ml-3"
                                btnVariant="info"
                                btnOnClick={handleTambah}
                                caption="Simpan"
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={modalEdit}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="fontweight600 pb-3 borderBottomEee">
                        Edit Kategori
                    </div>

                    <div className="mt-3">
                        <Form.Control
                            ref={refNamaEdit}
                            defaultValue={dataForAction.nama}
                            size="sm"
                            type="text"
                            placeholder="Nama Kategori"
                        />

                        <div className="mt-4 text-center">
                            <Button
                                variant="light"
                                size="sm"
                                className="ml-2"
                                onClick={() => setModalEdit(false)}
                            >
                                Kembali
                            </Button>

                            <ButtonLoading
                                prmLoading={btnLoadingEdit}
                                btnClass="px-3 ml-3"
                                btnVariant="info"
                                btnOnClick={handleEdit}
                                caption="Simpan"
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <ModalConfirmation
                showModal={modalHapus}
                hideModal={() => setModalHapus(false)}
                title="Konfirmasi"
                bodyCaption={`Hapus kategori ${dataForAction.nama} ?`}
                btnCloseClass="px-4"
                btnCloseCaption="Close"
                bLPrm={btnLoadingHapus}
                bLBtnClass="ml-3 px-3"
                bLOnClick={handleHapus}
                bLCaption="Hapus"
            />

        </React.Fragment >
    );
}

export default Diskon;
