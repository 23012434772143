import React from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { is_login } from "../../redux/reduxLogin/action";
import axios from "axios";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'font-awesome/css/font-awesome.min.css';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { Row, Col, Card, Form, Modal, Button, Dropdown, Spinner, InputGroup } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import CustomerAvatar from "../../components/CustomerAvatar";
import NoData from "../../components/NoData";
import { ROOT_BASE_URL, BASE_URL, BASE_URL_DUA, batasFluid, prefixUrl, menuCaptionIcon, metodeBayar, levelUser, widthLG, widthMD, widthSM, widthXS } from "../../utils/globalVar";
import { isoDateToSqlDate, sqlDateToLongHumanDate, cutStringWidthWindowThreeDots, formatNumberCanZero, formatNumber, VFCannotNull, VFNotNumber, handleCallBack, deko } from "../../utils/globalFunc";
import Layout from "../../components/Layout";
import NavbarTopOwner from "../../components/NavbarTop";
import NavbarTopTeknisi from "../../components/teknisi/NavbarTop";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ButtonLoading from "../../components/ButtonLoading";
import ModalConfirmation from "../../components/ModalConfirmation";
import PlaceHolderElement1 from "../../components/PlaceHolderElement1";
import '../../styles/transaksi.scss';

const Transaksi = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [flagDataCustomer, setFlagDataCustomer] = React.useState(null);

    // const [src, setSrc] = React.useState('');

    const [srcFile, setSrcFile] = React.useState('');
    const [showFile, setShowFile] = React.useState(false);

    const [srcCam, setSrcCam] = React.useState('');
    const [showCam, setShowCam] = React.useState(false);
    const [prmFacingMode, setPrmFacingMode] = React.useState('environment');

    const [idKelengkapanKategori, setIdKelengkapanKategori] = React.useState(null);

    const [namaBarang, setNamaBarang] = React.useState('');
    const [namaBarangModal, setNamaBarangModal] = React.useState('');
    const [modalNamaBarang, setModalNamaBarang] = React.useState(false);

    const [kerusakanBarang, setKerusakanBarang] = React.useState('');
    const [kerusakanBarangModal, setKerusakanBarangModal] = React.useState('');
    const [modalKerusakanBarang, setModalKerusakanBarang] = React.useState(false);

    const [catatanTransaksi, setCatatanTransaksi] = React.useState('');
    const [catatanTransaksiModal, setCatatanTransaksiModal] = React.useState('');
    const [modalCatatan, setModalCatatan] = React.useState(false);

    const [kelengkapanKategoriActive, setKelengkapanKategoriActive] = React.useState(null);
    const [kelengkapanKategori, setKelengkapanKategori] = React.useState(null);

    const [kelengkapanKategoriList, setKelengkapanKategoriList] = React.useState(null);

    const [barangKategorList, setBarangKategorList] = React.useState(null);
    const [barangKategorActive, setBarangKategorActive] = React.useState('0');

    const [uangMuka, setUangMuka] = React.useState('');
    const [uangMukaModal, setUangMukaModal] = React.useState('');
    const [modalUangMuka, setModalUangMuka] = React.useState(false);
    const [btnLoadingUangMuka, setBtnLoadingUangMuka] = React.useState(false);
    const refUangMuka = React.useRef();

    const [metodeDp, setMetodeDp] = React.useState('');
    const [loadingMetodeDp, setLoadingMetodeDp] = React.useState(false);

    const [dataCustomer, setDataCustomer] = React.useState(null);
    const [loadingDataCustomer, setLoadingDataCustomer] = React.useState(false);

    const parameter = useParams();
    const idTransaksi = parameter.id;
    const callbackUrl = parameter.callbackUrl;

    const [valueTanggalMasuk, setValueTanggalMasuk] = React.useState(isoDateToSqlDate(new Date()));
    const [showModalCalendar, setShowModalCalendar] = React.useState(false);

    const [paramSubmitModal, setParamSubmitModal] = React.useState(false);
    const [paramSubmit, setParamSubmit] = React.useState(null);
    const [paramSubmitError, setParamSubmitError] = React.useState('');

    const [modalZoomImage, setModalZoomImage] = React.useState(false);
    const [fotoKelola, setFotoKelola] = React.useState({
        foto: '',
        id: ''
    });
    const [listFotoTransaksi, setListFotoTransaksi] = React.useState(null);

    const [btnLoadUpdateKetKel, setBtnLoadUpdateKetKel] = React.useState(false);
    const [loadUpdateFormKel, setLoadUpdateFormKel] = React.useState(false);
    const [prmLoadSetTglMasuk, setLoadSetTglMasuk] = React.useState(false);
    const [btnLoadSimpanNamaBrg, setBtnLoadSimpanNamaBrg] = React.useState(false);
    const [btnLoadSimpanKeluhan, setBtnLoadSimpanKeluhan] = React.useState(false);
    const [btnLoadCatatan, setBtnLoadCatatan] = React.useState(false);
    const [prmLoadUbahFormKel, setPrmLoadUbahFormKel] = React.useState(false);
    const [btnLoadSimpanFoto, setBtnLoadSimpanFoto] = React.useState(false);
    const [btnLoadUploadFile, setBtnLoadUploadFile] = React.useState(false);
    const [prmLoadHapusFoto, setPrmLoadHapusFoto] = React.useState(false);
    const [prmLoadKtgrBrg, setPrmLoadKtgrBrg] = React.useState(false);
    const [btnLoadSubmit, setBtnLoadSubmit] = React.useState(false);

    const [modalBatalkanNota, setModalBatalkanNota] = React.useState(false);
    const [btnLoadingBatalkanNota, setBtnLoadingBatalkanNota] = React.useState(false);

    const [dataLabel, setDataLabel] = React.useState(null);
    const [modalAddLabel, setModalAddLabel] = React.useState(false);
    const refAddLabel = React.useRef();
    const [btnLoadingAddLabel, setBtnLoadingAddLabel] = React.useState(false);

    const [labelAction, setLabelAction] = React.useState({ id: '', nama: '', key: '' });
    const [modalDeleteLabel, setModalDeleteLabel] = React.useState(false);

    const [modalSubmit, setModalSubmit] = React.useState(false);

    const mountedRef = React.useRef(true);

    const flipCamera = () => {
        if (prmFacingMode === 'environment') { setPrmFacingMode('user') }
        else { setPrmFacingMode('environment') }
    }

    const handleTakePhoto = (dataUri) => {
        setTimeout(() => { setSrcCam(dataUri) }, 1000);
        setTimeout(() => { window.navigator.vibrate(200) }, 1100);
    }

    const showCloseFoto = () => {
        document.querySelector("#closeFlag").classList.remove("d-none");
        document.querySelector("#closeFlag").classList.add("closeFlagBlock");
    }

    const hideCloseFoto = () => {
        document.querySelector("#closeFlag").classList.add("d-none");
        document.querySelector("#closeFlag").classList.remove("closeFlagBlock");
    }

    React.useEffect(() => {
        srcCam && setShowCam(false)
    }, [srcCam])

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [])

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleSetValueTanggalMasuk = (prm) => {
        setLoadSetTglMasuk(true);

        axios({
            method: 'post',
            url: BASE_URL + '/set_tanggal_masuk_transaksi',
            data: {
                tanggal_masuk: isoDateToSqlDate(prm),
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadSetTglMasuk(false);

            response.data.status_hasil === true && setValueTanggalMasuk(response.data.tanggal_masuk);

            response.data.status_hasil === false && handleWarningModal('Error. Silahkan ulangi proses.');

            if (response.data.status_hasil === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadSetTglMasuk(false);
            handleWarningModal('Error : ' + error.message)
        })
    }

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleUbahKelengkapan = React.useCallback((prm, caption) => {
        if (caption !== kelengkapanKategoriActive) {

            setPrmLoadUbahFormKel(true);

            axios({
                method: 'post',
                url: BASE_URL + '/ubah_kelengkapan_trx',
                data: {
                    id_kelengkapan: prm,
                    id_transaksi: idTransaksi,
                    id_user: deko(reduxLogin.syswebappi)
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setPrmLoadUbahFormKel(false);

                if (response.data.status_perubahan === true) {
                    setIdKelengkapanKategori(prm);
                    setKelengkapanKategoriList(response.data.pesan);
                    setKelengkapanKategoriActive(caption);

                    var boxKetKlngkpnAll = document.querySelectorAll('.boxKetKlngkpn');
                    for (var i = 0; i < boxKetKlngkpnAll.length; i++) {
                        boxKetKlngkpnAll[i].style.display = 'none';
                    }
                }

                if (response.data.status_perubahan === false) {
                    handleWarningModal('Warning : ' + response.data.pesan);
                }

                if (response.data.status_perubahan === 'not-found') {
                    handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setPrmLoadUbahFormKel(false);
                handleWarningModal('Warning : ' + error.message);
            })
        }
    }, [idTransaksi, kelengkapanKategoriActive, reduxLogin.syswebappi])

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleUpdateFormKelengkapan = React.useCallback(() => {
        setLoadUpdateFormKel(true);

        axios({
            url: BASE_URL + '/updateFormKelengkapanTransaksi',
            method: 'POST',
            data: {
                id_transaksi: idTransaksi,
                id_kelengkapan_kategori: idKelengkapanKategori,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadUpdateFormKel(false);

            if (response.data.flag_status === true) {
                setKelengkapanKategoriList(response.data.hasil);

                var boxKetKlngkpnAll = document.querySelectorAll('.boxKetKlngkpn');
                for (var i = 0; i < boxKetKlngkpnAll.length; i++) {
                    boxKetKlngkpnAll[i].style.display = 'none';
                }
            }

            if (response.data.flag_status === false) {
                handleUbahKelengkapan(null, 'Tidak Ada');
            }

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadUpdateFormKel(false);
            handleWarningModal('Error : ' + error.message)
        });
    }, [handleUbahKelengkapan, idTransaksi, idKelengkapanKategori, reduxLogin.syswebappi])

    // info mountRef : ok
    React.useEffect(() => {
        !dataCustomer && setLoadingDataCustomer(true);

        !dataCustomer && axios({
            method: 'post',
            url: BASE_URL + '/transaksi',
            data: {
                id_transaksi: idTransaksi,
                id_user_login: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (response.data.cek_user === 'not-found-user') {
                dispatch(is_login(null));
            }

            if (!mountedRef.current) return null;

            setLoadingDataCustomer(false)

            if (response.data.flag_hasil === true) {
                setDataCustomer(response.data.data_transaksi);

                setIdKelengkapanKategori(response.data.data_transaksi[0].id_kelengkapan_kategori);
                setValueTanggalMasuk(response.data.data_transaksi[0].tanggal_masuk);
                setNamaBarang(response.data.data_transaksi[0].nama_barang ? response.data.data_transaksi[0].nama_barang : '');
                setKerusakanBarang(response.data.data_transaksi[0].kerusakan);
                setCatatanTransaksi(response.data.data_transaksi[0].catatan);
                setUangMuka(response.data.data_transaksi[0].dp ? (parseInt(response.data.data_transaksi[0].dp) === 0 ? '' : response.data.data_transaksi[0].dp) : '');
                setMetodeDp(response.data.data_transaksi[0].metode_dp ? response.data.data_transaksi[0].metode_dp : '');
                setKelengkapanKategoriActive(response.data.data_transaksi[0].nama_kelengkapan);
                setBarangKategorActive(response.data.data_transaksi[0].id_barang_kategori ? response.data.data_transaksi[0].id_barang_kategori : 'pilih');

                setBarangKategorList(response.data.data_barang_kategori);
                setKelengkapanKategoriList(response.data.data_kelengkapan_list);
                setKelengkapanKategori(response.data.data_kelengkapan_kategori);
                setListFotoTransaksi(response.data.data_foto_transaksi);
                setFlagDataCustomer(true);
                response.data.data_transaksi[0].nama_kelengkapan && handleUpdateFormKelengkapan();
                setDataLabel(response.data.data_label);
            }

            if (response.data.flag_hasil === false) {
                setFlagDataCustomer(false);
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingDataCustomer(false)
            handleWarningModal('Transaksi Warning : ' + error.message)
        })
    }, [idTransaksi, reduxLogin.syswebappi, handleUpdateFormKelengkapan, dataCustomer, dispatch])

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleSimpanNamaBarang = () => {
        if (VFCannotNull(namaBarangModal)) {
            document.querySelector("#fgnb987").style.border = '1px solid red';
            document.querySelector("#fgnb987").focus();
            return false;
        }

        setBtnLoadSimpanNamaBrg(true);

        axios({
            method: 'post',
            url: BASE_URL + '/simpan_nama_barang_trx',
            data: {
                nama_barang: namaBarangModal,
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadSimpanNamaBrg(false);
            setModalNamaBarang(false);

            if (response.data.flag_status === true) {
                setNamaBarang(namaBarangModal);
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.error);
            }

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadSimpanNamaBrg(false);
            setModalNamaBarang(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleSimpanKerusakanBarang = () => {
        if (VFCannotNull(kerusakanBarangModal)) {
            document.querySelector("#fgkb0hl").style.border = '1px solid red';
            document.querySelector("#fgkb0hl").focus();
            return false;
        }

        setBtnLoadSimpanKeluhan(true);

        axios({
            method: 'post',
            url: BASE_URL + '/simpan_kerusakan_barang_trx',
            data: {
                kerusakan_barang: kerusakanBarangModal,
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadSimpanKeluhan(false);
            setModalKerusakanBarang(false);

            if (response.data.flag_status === true) {
                setKerusakanBarang(kerusakanBarangModal);
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.error);
            }

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadSimpanKeluhan(false);
            setModalKerusakanBarang(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleSimpanCatatanTransaksi = () => {
        setBtnLoadCatatan(true);

        axios({
            method: 'post',
            url: BASE_URL + '/simpan_catatan_trx',
            data: {
                catatan_trx: catatanTransaksiModal,
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadCatatan(false);
            setModalCatatan(false);

            if (response.data.status_hasil === true) {
                setCatatanTransaksi(catatanTransaksiModal);
            }

            if (response.data.status_hasil === false) {
                handleWarningModal('Error : ' + response.data.pesan_error);
            }

            if (response.data.status_hasil === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadCatatan(false);
            setModalCatatan(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleOnChangeCbKelengkapan = (e, prm_id_tdk, key) => {
        // info : API 'update_keterangan_kelengkapan_by_id' dipakai juga di file ProsesQC.js namun parameter field nya berbeda

        if (document.querySelector(".loadCb" + key)) {
            document.querySelector(".loadCb" + key).classList.remove("d-none");
        }

        axios({
            method: 'post',
            url: BASE_URL + '/update_each_cb_kelengkapan',
            data: {
                id_tdk: prm_id_tdk,
                prm_checked: e.target.checked,
                id_transaksi: idTransaksi,
                field: 'check',
                flag_proses: 0,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            if (document.querySelector(".loadCb" + key)) {
                document.querySelector(".loadCb" + key).classList.add("d-none");
            }

            if (response.data.status_flag === true) {
                setKelengkapanKategoriList(response.data.hasil);
            }

            if (response.data.status_flag === false) {
                handleWarningModal('Error : ' + response.data.hasil);
            }

            if (response.data.status_flag === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            if (document.querySelector(".loadCb" + key)) {
                document.querySelector(".loadCb" + key).classList.add("d-none");
            }

            handleWarningModal('Warning : ' + error.message)
        })
    }

    const toggleFormEditSubKlngkpn = (prm) => {
        let boxKlngkpn = document.querySelector("#boxKetKlngkpn_" + prm);
        boxKlngkpn.style.display = boxKlngkpn.style.display === 'block' ? 'none' : 'block';

        let captShow = document.querySelector("#ketCapt_so7_kkl_" + prm);
        captShow.style.display = boxKlngkpn.style.display === 'block' ? 'none' : 'block';

        let faBtn = document.querySelector("#yuyayu_" + prm);
        faBtn.classList.toggle("fa-pencil");
        faBtn.classList.toggle("fa-close");

        document.querySelector("#fgtak0jh_kkl_" + prm).value = captShow.innerHTML;
        document.querySelector("#fgtak0jh_kkl_" + prm).focus();
    }

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleSimpanKetKlngkpn = (idTdk, key) => {
        // info : API 'update_keterangan_kelengkapan_by_id' dipakai juga di file ProsesQC.js namun parameter field nya berbeda

        setBtnLoadUpdateKetKel(true);
        let valKet = document.querySelector("#fgtak0jh_kkl_" + key).value;
        let faBtn = document.querySelector("#yuyayu_" + key);

        axios({
            method: 'post',
            url: BASE_URL + '/update_keterangan_kelengkapan_by_id',
            data: {
                id_tdk: idTdk,
                value_ket: valKet,
                id_transaksi: idTransaksi,
                field: 'keterangan',
                flag_proses: 0,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadUpdateKetKel(false);

            if (response.data.status_flag === true) {
                setKelengkapanKategoriList(response.data.hasil);

                document.querySelector("#boxKetKlngkpn_" + key).style.display = 'none';
                document.querySelector("#ketCapt_so7_kkl_" + key).style.display = 'block';

                faBtn.classList.remove("fa-pencil");
                faBtn.classList.remove("fa-close");
                faBtn.classList.add("fa-pencil");
            }

            if (response.data.status_flag === false) {
                handleWarningModal('Error : ' + response.data.hasil);
            }

            if (response.data.status_flag === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadUpdateKetKel(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }

    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleSimpanFoto = (prmSrc) => {
        setBtnLoadSimpanFoto(true);

        let formdata = new FormData();
        formdata.append('id_transaksi', idTransaksi);
        formdata.append('id_user', deko(reduxLogin.syswebappi));
        formdata.append('foto', dataURLtoFile(prmSrc, `${Math.random(10)}.jpg`));

        axios({
            url: BASE_URL + '/simpan_foto_trx',
            method: 'POST',
            data: formdata,
            headers: {
                Accept: 'application/json', 'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadSimpanFoto(false);
            setSrcCam('');

            if (response.data.status_hasil === true) {
                setListFotoTransaksi(response.data.pesan);
            }

            if (response.data.status_hasil === false) {
                handleWarningModal('Error : ' + response.data.pesan);
            }

            if (response.data.status_hasil === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;
            setBtnLoadSimpanFoto(false);
            setSrcCam('');
            handleWarningModal('Warning : ' + error.message)
        });
    }

    // info cek id transaksi : ok
    // info mountRef : ok
    // info cek id foto : ok
    const handleHapusFoto = () => {
        setPrmLoadHapusFoto(true);

        axios({
            url: BASE_URL + '/hapus_foto_trx',
            method: 'POST',
            data: {
                id_tdf: fotoKelola.id,
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setPrmLoadHapusFoto(false);
            setModalZoomImage(false);

            if (response.data.status_hasil === true) {
                setListFotoTransaksi(response.data.pesan);
            }

            if (response.data.status_hasil === false) {
                handleWarningModal('Error : ' + response.data);
            }

            if (response.data.status_hasil === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }

            if (response.data.status_hasil === 'not-found-foto') {
                handleWarningModal('Foto ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setPrmLoadHapusFoto(false);
            setModalZoomImage(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }

    const changeNameFileLabel = (selector, target) => {
        const choosen_fie = document.querySelector(selector);
        let dot = (choosen_fie.files[0].name).length < 30 ? '' : '..';
        document.querySelector(target).innerHTML = (choosen_fie.files[0].name).substr(0, 30) + dot;
    }

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleUploadFileFoto = () => {
        if (!srcFile) {
            handleWarningModal('File foto belum dipilih');
            return false;
        }

        setBtnLoadUploadFile(true);

        let formdata = new FormData();
        formdata.append('id_user', deko(reduxLogin.syswebappi));
        formdata.append('id_transaksi', idTransaksi);
        formdata.append('foto', srcFile);

        axios({
            url: BASE_URL + '/simpan_foto_trx',
            method: 'POST',
            data: formdata,
            headers: {
                Accept: 'application/json', 'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadUploadFile(false);
            document.querySelector("#label-foto").innerHTML = "Foto";
            setSrcFile('');
            setShowFile(false);

            if (response.data.status_hasil === true) {
                setListFotoTransaksi(response.data.pesan);
            }

            if (response.data.status_hasil === false) {
                handleWarningModal('Silahkan ulangi proses. Error : ' + response.data);
            }

            if (response.data.status_hasil === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadUploadFile(false);
            document.querySelector("#label-foto").innerHTML = "Foto";
            setSrcFile('');
            setShowFile(false);

            handleWarningModal('Warning : ' + error.message)
        });
    }

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleLanjutProsesBarang = () => {
        if (VFCannotNull(namaBarang)) {
            setModalSubmit(false);
            handleWarningModal("Isi Barang Terlebih Dahulu.");
        } else if (VFCannotNull(kerusakanBarang)) {
            setModalSubmit(false);
            handleWarningModal("Isi Data Kerusakan Barang Terlebih Dahulu.");
        } else if (barangKategorActive === 'pilih') {
            setModalSubmit(false);
            handleWarningModal("Isi Data Kategori Barang Terlebih Dahulu.");
        } else if (uangMuka && !metodeDp) {
            setModalSubmit(false);
            handleWarningModal("Pilih Metode Pembayaran Uang Muka Terlebih Dahulu.");
        } else {
            setBtnLoadSubmit(true);

            axios({
                url: BASE_URL + '/submit_transaksi',
                method: 'POST',
                data: {
                    id_transaksi: idTransaksi,
                    id_user: deko(reduxLogin.syswebappi)
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadSubmit(false);
                setModalSubmit(false);

                if (response.data.status_flag === true) {
                    setParamSubmit(true);
                    setParamSubmitModal(true);
                }

                if (response.data.status_flag === false) {
                    setParamSubmit(false);
                    setParamSubmitError(response.data.error);
                    setParamSubmitModal(true);
                }

                if (response.data.status_flag === 'not-found') {
                    handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadSubmit(false);
                setModalSubmit(false);
                handleWarningModal('Error : ' + error.message)
            });
        }
    }

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleUpdateBarangKategori = (prm) => {
        setPrmLoadKtgrBrg(true);

        axios({
            url: BASE_URL + '/update_barang_kategori',
            method: 'POST',
            data: {
                id_transaksi: idTransaksi,
                id_barang_kategori: prm,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setPrmLoadKtgrBrg(false);

            if (response.data.flag_status === true) {
                setBarangKategorActive(prm);
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Failed update kategori barang : ' + response.data.error);
            }

            if (response.data.flag_status === 'no-data') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setPrmLoadKtgrBrg(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleBatalkanNota = () => {
        setBtnLoadingBatalkanNota(true);

        axios({
            method: 'post',
            url: BASE_URL + '/batalkan_nota',
            data: {
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingBatalkanNota(false);
            setModalBatalkanNota(false);

            response.data.flag_status === 'masih-ada-foto' && handleWarningModal('Hapus Foto Barang terlebih dahulu untuk dapat membatalkan nota.');

            if (response.data.flag_status === true) {
                deko(reduxLogin.syswebappj) === levelUser.owner && history.push('/' + prefixUrl.owner + '/proses/prosesNota');

                deko(reduxLogin.syswebappj) === levelUser.teknisi && history.push('/' + prefixUrl.teknisi + '/proses/prosesNota');
            }

            response.data.flag_status === false && handleWarningModal('Error : ' + response.data.error);

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingBatalkanNota(true);
            setModalBatalkanNota(false);
            handleWarningModal('Error : ' + error.message)
        })
    }

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleSimpanUangMuka = () => {
        if (uangMukaModal) {
            if (VFNotNumber(uangMukaModal.replaceAll(',', ''))) {
                refUangMuka.current.style.border = '1px solid red';
                refUangMuka.current.focus();
                return false;
            }
        }

        setBtnLoadingUangMuka(true);

        axios({
            method: 'post',
            url: BASE_URL + '/simpan_uang_muka',
            data: {
                uang_muka: uangMukaModal.replaceAll(',', '') === '0' ? '' : uangMukaModal.replaceAll(',', ''),
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingUangMuka(false);
            setModalUangMuka(false);

            if (response.data.status_hasil === true) {
                setUangMuka(response.data.dp ? (parseInt(response.data.dp) === 0 ? '' : response.data.dp) : '');
            }

            if (response.data.status_hasil === false) {
                handleWarningModal('Error : ' + response.data.pesan_error);
            }

            if (response.data.status_hasil === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingUangMuka(false);
            setModalUangMuka(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }

    // info cek id transaksi : ok
    // info mountRef : ok
    const handleChangeMetodeUangMuka = (prmVal) => {
        setLoadingMetodeDp(true);

        axios({
            method: 'post',
            url: BASE_URL + '/simpan_metode_uang_muka',
            data: {
                metode: prmVal,
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingMetodeDp(false);

            if (response.data.status_hasil === true) {
                setMetodeDp(response.data.metode_dp ? response.data.metode_dp : '');
            }

            if (response.data.status_hasil === false) {
                handleWarningModal('Error : ' + response.data.pesan_error);
            }

            if (response.data.status_hasil === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingMetodeDp(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }

    const handleAddLabel = () => {
        refAddLabel.current.style.border = 'unset';

        if (VFCannotNull(refAddLabel.current.value)) {
            refAddLabel.current.style.border = '1px solid red';
            refAddLabel.current.focus();
        } else {
            if (!mountedRef.current) return null;

            setBtnLoadingAddLabel(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/add_label',
                data: {
                    id_transaksi: idTransaksi,
                    label: refAddLabel.current.value,
                    id_user: deko(reduxLogin.syswebappi)
                }
            }).then((response) => {
                setBtnLoadingAddLabel(false);
                setModalAddLabel(false);

                if (response.data.status_flag === true) {
                    setDataLabel(response.data.result);
                }

                if (response.data.status_flag === false) {
                    handleWarningModal(response.data.result)
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingAddLabel(false);
                setModalAddLabel(false);
                handleWarningModal('Warning : ' + error.message)
            })
        }
    }

    const handleDeleteLabel = () => {
        setModalDeleteLabel(false);

        if (document.querySelector(".loadingLbl" + labelAction.key)) {
            document.querySelector(".loadingLbl" + labelAction.key).classList.remove("d-none");
        }

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/delete_label',
            data: {
                id_tdl: labelAction.id,
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            if (document.querySelector(".loadingLbl" + labelAction.key)) {
                document.querySelector(".loadingLbl" + labelAction.key).classList.add("d-none");
            }

            if (response.data.status_flag === true) {
                setDataLabel(response.data.result);
            }

            if (response.data.status_flag === false) {
                handleWarningModal('Error : ' + response.data.result)
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            if (document.querySelector(".loadingLbl" + labelAction.key)) {
                document.querySelector(".loadingLbl" + labelAction.key).classList.add("d-none");
            }

            setModalDeleteLabel(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }

    return (
        <React.Fragment>

            <Modal
                show={paramSubmitModal}
                onHide={() => setParamSubmitModal(false)}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="text-center f18 mb-3 mt-3">
                        <div>Simpan Data {paramSubmit === true ? 'Berhasil' : 'Gagal'}</div>
                        {paramSubmit === false && <div className="mt-2 f14">{paramSubmitError}</div>}
                    </div>

                    <div className="text-center">
                        {paramSubmit === true ?
                            <span className="fa fa-check-circle colorTheme f50" style={{ color: '#69ceb8' }} />
                            :
                            <span className="fa fa-close colorTheme f50" style={{ color: '#f18790' }} />
                        }
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {paramSubmit === true ?
                        <div className="flexJustifyBeetwenAlignCenter flex1">
                            <div>
                                <Button
                                    variant="light"
                                    size="sm"
                                    className="px-4"
                                    onClick={() => {
                                        deko(reduxLogin.syswebappj) === levelUser.owner && history.push('/' + prefixUrl.owner + '/proses');

                                        deko(reduxLogin.syswebappj) === levelUser.teknisi && history.push('/' + prefixUrl.teknisi + '/proses');

                                    }}
                                >
                                    Close
                                </Button>
                            </div>

                            <div>
                                <Button
                                    variant="info"
                                    size="sm"
                                    className="px-2"
                                    onClick={() => history.push('/cetaklabel/' + idTransaksi)}
                                >
                                    Cetak Label
                                </Button>

                                <Button
                                    variant="info"
                                    size="sm"
                                    className="px-2 ml-2"
                                    onClick={() => history.push('/tandaterima/' + idTransaksi)}
                                >
                                    Tanda Terima
                                </Button>
                            </div>
                        </div>
                        :
                        <div className="flexJustifyAlignCenter flex1">
                            <Button
                                variant="light"
                                size="sm"
                                className="px-4"
                                onClick={() => setParamSubmitModal(false)}
                            >
                                Close
                            </Button>
                        </div>
                    }
                </Modal.Footer>
            </Modal>

            {deko(reduxLogin.syswebappj) === levelUser.owner ?
                <NavbarTopOwner
                    shadow={true}
                    titleNavTop="Transaksi"
                    prmBackButton={true}
                    prmOnBack={`${callbackUrl ? '/' + handleCallBack(deko(reduxLogin.syswebappj), levelUser, callbackUrl, prefixUrl) : '/' + prefixUrl.owner + '/proses/prosesNota'}`}
                />
                :
                <NavbarTopTeknisi
                    shadow={true}
                    titleNavTop="Transaksi"
                    prmBackButton={true}
                    prmOnBack={`${callbackUrl ? '/' + handleCallBack(deko(reduxLogin.syswebappj), levelUser, callbackUrl, prefixUrl) : '/' + prefixUrl.teknisi + '/proses/prosesNota'}`}
                />
            }

            <Layout titleTab="Transaksi">

                <Row>
                    <Col xs={12} className="mt-3">
                        {
                            dataCustomer && !loadingDataCustomer &&
                            <div>
                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium`}>
                                    <div className="flex">
                                        <div>
                                            <CustomerAvatar
                                                prmSlug={dataCustomer[0].slug_ck}
                                                prmGender={dataCustomer[0].gender}
                                                ukuran={{ width: '45px', height: '45px' }}
                                            />
                                        </div>

                                        <div className="ml-3 color636363">
                                            <div className="bold">{dataCustomer[0].nama}</div>
                                            <div className="f13">{dataCustomer[0].nama_ktgr_cust}</div>
                                            <div className="f13 mt-2">{dataCustomer[0].alamat}</div>
                                            {deko(reduxLogin.syswebappj) === levelUser.owner &&
                                                <div className="f13">{dataCustomer[0].no_hp}</div>
                                            }
                                        </div>
                                    </div>
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm mt-3 color636363 brSoftMedium`}>
                                    <div className="bold mb-1 colorTheme">Nomor Nota</div>
                                    <div className="flexJustifyBeetwenAlignCenter">
                                        <div>{dataCustomer[0].id_transaksi}</div>
                                        <div>
                                            <Button
                                                variant="info"
                                                size="sm"
                                                className="btnXsOverride"
                                                onClick={() => setModalBatalkanNota(true)}
                                            >
                                                Batalkan Nota
                                            </Button>
                                        </div>
                                    </div>
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} color636363 shadow-sm mt-3 brSoftMedium`}>
                                    <div className="bold mb-2 colorTheme">
                                        <span>Tanggal Masuk</span>

                                        {prmLoadSetTglMasuk &&
                                            <Spinner
                                                as="span"
                                                className="ml-1"
                                                animation="border"
                                                variant="info"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        }
                                    </div>
                                    <div className="flexJustifyBeetwenAlignCenter">
                                        <div>
                                            {valueTanggalMasuk ? sqlDateToLongHumanDate(valueTanggalMasuk) : ''}
                                        </div>

                                        <div className="cp">
                                            <span
                                                onClick={() => { setShowModalCalendar(true) }}
                                                className="fa fa-calendar colorTheme f20"
                                            />
                                        </div>
                                    </div>
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} color636363 shadow-sm mt-3 brSoftMedium`}>
                                    <div className="bold mb-3 colorTheme">Barang Masuk</div>

                                    <Form.Group controlId="barangKategoriList">
                                        <Form.Label>
                                            <span>Kategori Barang</span>

                                            {prmLoadKtgrBrg &&
                                                <Spinner
                                                    as="span"
                                                    className="ml-1"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            }
                                        </Form.Label>

                                        <Form.Control
                                            custom
                                            name="barangKategoriList"
                                            as="select"
                                            size="sm"
                                            value={barangKategorActive}
                                            onChange={(e) => handleUpdateBarangKategori(e.target.value)}
                                        >
                                            <option value="pilih">Pilih Kategori Barang</option>
                                            {
                                                barangKategorList &&
                                                Object.keys(barangKategorList).map((key) => {
                                                    return (
                                                        <option
                                                            key={'bkljhad8a' + key}
                                                            value={barangKategorList[key].id}>
                                                            {barangKategorList[key].nama}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </Form.Control>

                                        {!barangKategorList &&
                                            <div className="alert alert-warning mt-3 mb-2 f12">
                                                <span className="fa fa-exclamation-circle" /> Daftar Kategori Barang masih kosong.<br />Tambahkan kategori barang terlebih dahulu pada menu <span className={`${menuCaptionIcon.menuKategoriBarang.icon} ml-1`} /> <span className="fontweight600">{menuCaptionIcon.menuKategoriBarang.caption}</span>.
                                            </div>
                                        }
                                    </Form.Group>

                                    <Form.Group controlId="inputTrxNamaBarang">
                                        <Form.Label>
                                            Barang
                                        </Form.Label>

                                        <Form.Control
                                            readOnly
                                            name="barang"
                                            size="sm"
                                            type="text"
                                            placeholder="Nama Barang"
                                            onClick={() => setModalNamaBarang(true)}
                                            className="inputOverride"
                                            value={namaBarang}
                                            onChange={(e) => setNamaBarang(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="inputTrxKerusakanBarang" className="mb-0">
                                        <Form.Label>
                                            Keluhan
                                        </Form.Label>

                                        <Form.Control
                                            readOnly
                                            as="textarea"
                                            size="sm"
                                            rows={4}
                                            placeholder="Kerusakan/Keluhan Barang"
                                            onClick={() => setModalKerusakanBarang(true)}
                                            className="inputOverride"
                                            value={kerusakanBarang === null ? '' : kerusakanBarang}
                                            onChange={(e) => setKerusakanBarang(e.target.value)}
                                        />

                                    </Form.Group>

                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} color636363 shadow-sm mt-3 brSoftMedium`}>

                                    <div className="bold mb-3 colorTheme">
                                        Form Cek Barang Terima
                                    </div>

                                    <div className="flexJustifyBeetwenAlignCenter">
                                        <div className="flexAlignCenter">
                                            <div className="fontweight500">
                                                Kategori :
                                            </div>

                                            <div>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="link"
                                                        id="dropdown-basicerr"
                                                        size="sm"
                                                        className="pl-3 pr-2 color636363"
                                                        style={{
                                                            borderWidth: '0',
                                                            textDecoration: 'none',
                                                            boxShadow: '0 0 0 0rem rgb(0 123 255 / 25%)'
                                                        }}
                                                    >
                                                        {
                                                            kelengkapanKategoriActive === null ? 'Tidak Ada' : cutStringWidthWindowThreeDots(reduxWindowWidth, batasFluid, kelengkapanKategoriActive, 15)
                                                        } {' '}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                handleUbahKelengkapan(null, 'Tidak Ada')
                                                            }}>
                                                            Tidak Ada
                                                        </Dropdown.Item>

                                                        {
                                                            kelengkapanKategori && Object.keys(kelengkapanKategori).map((key) => {
                                                                return (
                                                                    <React.Fragment key={key}>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item
                                                                            onClick={() => {
                                                                                handleUbahKelengkapan(kelengkapanKategori[key].id, kelengkapanKategori[key].nama)
                                                                            }}
                                                                        >
                                                                            {cutStringWidthWindowThreeDots(reduxWindowWidth, batasFluid, kelengkapanKategori[key].nama, 30)}
                                                                        </Dropdown.Item>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                            {prmLoadUbahFormKel &&
                                                <div>
                                                    <Spinner
                                                        as="span"
                                                        className="ml-0"
                                                        animation="border"
                                                        variant="info"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            }
                                        </div>

                                        {kelengkapanKategoriList &&
                                            <div>
                                                <ButtonLoading
                                                    prmLoading={loadUpdateFormKel}
                                                    btnClass="colorTheme"
                                                    btnVariant="link"
                                                    btnOnClick={handleUpdateFormKelengkapan}
                                                    caption="Update"
                                                />
                                            </div>
                                        }
                                    </div>

                                    {!kelengkapanKategori &&
                                        <div className="alert alert-warning mt-2 mb-2 f12">
                                            <span className="fa fa-exclamation-circle" /> Form Cek Barang masih kosong.<br />Tambahkan form cek barang terlebih dahulu pada menu <span className={`${menuCaptionIcon.menu9.icon} ml-1`} /> <span className="fontweight600">{menuCaptionIcon.menu9.caption}</span>.
                                        </div>
                                    }

                                    <div className={`${kelengkapanKategoriList && 'mt-4'}`}>
                                        {kelengkapanKategoriList !== null && Object.keys(kelengkapanKategoriList).map((key) => {
                                            return (
                                                <React.Fragment key={key + 'kkl7js'}>
                                                    <Form.Row className="mb-3">
                                                        <Form.Group
                                                            as={Col}
                                                            xs="10"
                                                            controlId={`fg_01_kkl_${key}`}
                                                            className="mb-0"
                                                        >

                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`fgfc_kkl_${key}`}
                                                                custom
                                                            >
                                                                <Form.Check.Input
                                                                    type="checkbox"
                                                                    onChange={(e) => {
                                                                        handleOnChangeCbKelengkapan(e, kelengkapanKategoriList[key].id_tdk, key)
                                                                    }}

                                                                    checked={parseInt(kelengkapanKategoriList[key].check)}

                                                                    value={parseInt(kelengkapanKategoriList[key].check)}
                                                                />

                                                                <Form.Check.Label className="cp">
                                                                    <span>
                                                                        {kelengkapanKategoriList[key].nama}
                                                                    </span>
                                                                    <Spinner
                                                                        as="span"
                                                                        className={`ml-1 d-none loadCb${key}`}
                                                                        animation="border"
                                                                        variant="info"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />
                                                                </Form.Check.Label>

                                                                <div
                                                                    className="mt-1 color808080 f13"
                                                                    id={`ketCapt_so7_kkl_${key}`}
                                                                >
                                                                    {kelengkapanKategoriList[key].keterangan}
                                                                </div>
                                                            </Form.Check>

                                                            <div
                                                                className={`boxKetKlngkpn mt-2`}
                                                                id={`boxKetKlngkpn_${key}`}
                                                            >
                                                                <Form.Group controlId={`fgtak0jh_kkl_${key}`}>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        size="sm"
                                                                        rows={3}
                                                                        placeholder="Kondisi / Catatan"
                                                                        className="mt-1"
                                                                    />
                                                                </Form.Group>

                                                                <div className="mt-2">
                                                                    <ButtonLoading
                                                                        prmLoading={btnLoadUpdateKetKel}
                                                                        btnClass="btnXsOverride"
                                                                        btnVariant="info"
                                                                        btnOnClick={() => {
                                                                            handleSimpanKetKlngkpn(kelengkapanKategoriList[key].id_tdk, key)
                                                                        }}
                                                                        caption="Simpan"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Form.Group>

                                                        <Form.Group
                                                            as={Col}
                                                            xs="2"
                                                            controlId={`fg_trig_edt_ket_kkl_${key}`}
                                                            className="mb-0 cp text-right flexJustifyAlignCenter"
                                                            onClick={() => toggleFormEditSubKlngkpn(key)}
                                                        >
                                                            <span id={`yuyayu_${key}`} className="fa fa-pencil color808080 f14" />
                                                        </Form.Group>
                                                    </Form.Row>

                                                    {(kelengkapanKategoriList.length - 1) !== parseInt(key) && <hr />}
                                                </React.Fragment>
                                            )
                                        })}

                                        {kelengkapanKategoriList &&
                                            <div
                                                className="colorTheme mt-4 f13 cp"
                                                onClick={() => history.push('/kelolaformkelengkapan/' + idKelengkapanKategori)}
                                            >
                                                <span className="fa fa-plus-circle" /> Tambah Poin Check Pada Form {kelengkapanKategoriActive}
                                            </div>
                                        }

                                        {
                                            kelengkapanKategoriList &&
                                            <div className="mt-3 color808080 f12">Note : Checklist bila item tersebut ada, dan beri catatan jika diperlukan.</div>
                                        }
                                    </div>
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} color636363 shadow-sm mt-3 brSoftMedium`}>
                                    <div className="bold mb-3 colorTheme flexJustifyBeetwenAlignCenter">
                                        <div>Kelengkapan Barang</div>

                                        <div>
                                            <span
                                                className="fa fa-plus-circle f22 cp"
                                                onClick={() => setModalAddLabel(true)}
                                            />
                                        </div>
                                    </div>


                                    <div>
                                        <div className="color636363 fontweight600">
                                            Kelengkapan Utama :
                                        </div>

                                        {namaBarang ?
                                            <div className="color808080 mt-1">{namaBarang}</div>
                                            :
                                            <div className="color808080 mt-1">
                                                Akan otomatis terisi setelah mengisi barang masuk.
                                            </div>
                                        }
                                    </div>

                                    <div className="mt-3">
                                        <div className="color636363 fontweight600">
                                            Kelengkapan Lainnya :
                                        </div>

                                        {dataLabel ? Object.keys(dataLabel).map((keyDL) => {
                                            return (
                                                <div
                                                    key={keyDL + 'dl_78'}
                                                    className={`color808080 flexJustifyBeetwenAlignCenter ${dataLabel.length === 1 ? 'py-1' : 'py-3'} borderBottomEeeChild`}
                                                >
                                                    <div>
                                                        <span>{dataLabel[keyDL].label}</span>

                                                        <Spinner
                                                            as="span"
                                                            className={`ml-1 d-none loadingLbl${keyDL}`}
                                                            animation="border"
                                                            variant="info"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                    <div>
                                                        <span
                                                            className="fa fa-close colorTheme cp"
                                                            onClick={() => {
                                                                setLabelAction({
                                                                    ...labelAction,
                                                                    id: dataLabel[keyDL].id,
                                                                    key: keyDL,
                                                                    nama: dataLabel[keyDL].label
                                                                });

                                                                setModalDeleteLabel(true);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                            :
                                            <div className="color808080 mt-1">
                                                Tidak ada
                                            </div>
                                        }
                                    </div>

                                    <div className="colorAaa f11 mt-3">
                                        <div>
                                            Kelengkapan Lainnya adalah item-item yang biasanya terbawa pada barang utama, misalnya Tas Laptop, Kabel Charger, dsb.
                                        </div>
                                        <div>
                                            Kelengkapan barang ini yang akan menjadi label barang.
                                        </div>
                                    </div>
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} color636363 shadow-sm mt-3 brSoftMedium`} id="card_fb_8ks">
                                    <div className="flexJustifyBeetwenAlignCenter">
                                        <div className="bold colorTheme">Foto Barang</div>

                                        <div>
                                            <span
                                                onClick={() => {
                                                    setSrcCam('');
                                                    setSrcFile('');
                                                    setShowCam(true);
                                                    setShowFile(false);
                                                }}
                                                className="fa fa-camera colorTheme f20 mr-4 cp"
                                            />

                                            <span
                                                onClick={() => {
                                                    setSrcCam('');
                                                    setSrcFile('');
                                                    setShowFile(true);
                                                    setShowCam(false);
                                                }}
                                                className="fa fa-image colorTheme f20 cp"
                                            />
                                        </div>
                                    </div>

                                    {
                                        showFile &&
                                        <div className="mt-3">
                                            <Form.File id="foto-file" custom>
                                                <Form.File.Input
                                                    onChange={(e) => {
                                                        changeNameFileLabel("#foto-file", "#label-foto");
                                                        setSrcFile(e.target.files[0])
                                                    }}
                                                />
                                                <Form.File.Label data-browse="Cari Foto" id="label-foto">
                                                    Foto
                                                </Form.File.Label>
                                            </Form.File>

                                            <div>
                                                <Button
                                                    variant="light"
                                                    size="sm"
                                                    className="btnXsOverride mt-3 mr-3"
                                                    onClick={() => {
                                                        setSrcFile('');
                                                        setShowFile(false);
                                                    }}
                                                >
                                                    Close
                                                </Button>

                                                <ButtonLoading
                                                    prmLoading={btnLoadUploadFile}
                                                    btnClass="btnXsOverride mt-3"
                                                    btnVariant="info"
                                                    btnOnClick={handleUploadFileFoto}
                                                    caption="Upload"
                                                />
                                            </div>

                                            <div><hr className="mb-0" /></div>
                                        </div>
                                    }

                                    {listFotoTransaksi &&
                                        <div className="mt-3 text-center">
                                            <Masonry
                                                breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 4 : 5) : 5) : 6) : 6}`}
                                                className="my-masonry-grid"
                                                columnClassName="my-masonry-grid_column"
                                            >
                                                {Object.keys(listFotoTransaksi).map((key) => {
                                                    return (
                                                        <img
                                                            key={"lftasdh8" + key}
                                                            src={ROOT_BASE_URL + listFotoTransaksi[key].foto}
                                                            alt={'foto'}
                                                            className="rounded-lg cp"
                                                            style={{ width: '100%' }}
                                                            onClick={() => {
                                                                setFotoKelola({
                                                                    foto: ROOT_BASE_URL + listFotoTransaksi[key].foto,
                                                                    id: listFotoTransaksi[key].id_tdf
                                                                })
                                                                setModalZoomImage(true);
                                                            }}
                                                        />
                                                    )
                                                })}
                                            </Masonry>
                                        </div>
                                    }
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} color636363 shadow-sm mt-3 brSoftMedium`}>
                                    <div className="bold mb-3 colorTheme">Uang Muka</div>

                                    <div>
                                        <InputGroup size="sm">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>Rp</InputGroup.Text>
                                            </InputGroup.Prepend>

                                            <Form.Control
                                                readOnly
                                                className="bgInputImportant"
                                                size="sm"
                                                type="text"
                                                placeholder="Uang Muka"

                                                onClick={() => {
                                                    setUangMukaModal(uangMuka);
                                                    setModalUangMuka(true);
                                                }}

                                                value={uangMuka ? formatNumberCanZero(uangMuka) : ''}
                                                onChange={(e) => setUangMuka(formatNumberCanZero(e.target.value))}
                                            />
                                        </InputGroup>
                                    </div>

                                    {uangMuka &&
                                        <div className="mt-3">
                                            <Form.Group controlId="metodeDP">
                                                <Form.Label>
                                                    <span>Metode</span>

                                                    {loadingMetodeDp &&
                                                        <Spinner
                                                            as="span"
                                                            className="ml-1"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    }
                                                </Form.Label>

                                                <Form.Control
                                                    custom
                                                    as="select"
                                                    size="sm"
                                                    value={metodeDp}
                                                    onChange={e => handleChangeMetodeUangMuka(e.target.value)}
                                                >
                                                    <option value="">Metode Pembayaran Uang Muka</option>
                                                    {Object.keys(metodeBayar).map((key) => {
                                                        return (
                                                            <option
                                                                key={key + 'mbT9889j'}
                                                                value={key}
                                                            >
                                                                {metodeBayar[key]}
                                                            </option>
                                                        )
                                                    })}
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                    }
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} color636363 shadow-sm mt-3 brSoftMedium`}>
                                    <div className="bold mb-3 colorTheme">Catatan</div>

                                    <div>
                                        <Form.Group controlId="inputTrxCatatan" className="mb-0">
                                            <Form.Control
                                                readOnly
                                                as="textarea"
                                                size="sm"
                                                rows={3}
                                                placeholder="Catatan"

                                                onClick={() => setModalCatatan(true)}
                                                className="inputOverride"
                                                value={catatanTransaksi === null ? '' : catatanTransaksi}
                                                onChange={(e) => setCatatanTransaksi(e.target.value)}
                                            />
                                        </Form.Group>
                                    </div>
                                </Card>

                                <div className="flexJustifyAlignCenter">
                                    <Button
                                        variant="info"
                                        size="sm"
                                        className="mt-4 py-2 width100"
                                        onClick={() => setModalSubmit(true)}
                                    >
                                        Simpan dan Lanjut Pengerjaan
                                    </Button>
                                </div>

                            </div>
                        }

                        {
                            loadingDataCustomer &&
                            <PlaceHolderElement1 prmMT="" prmPX="" />
                        }

                        {!dataCustomer && flagDataCustomer === false && !loadingDataCustomer && <NoData />}
                    </Col>
                </Row>

            </Layout>



            <Modal
                show={showCam}
                className="modalNoBorderRadius"
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >

                <Modal.Body className="p-0">
                    <div className="d-none" id="closeFlag">
                        <span
                            onClick={() => {
                                setShowCam(false);
                                setSrcCam('');
                                if (document.querySelector("#closeFlag")) {
                                    hideCloseFoto()
                                }
                            }}

                            className="fa fa-close f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                            style={{
                                borderRadius: '50%',
                                width: '35px',
                                height: '35px',
                                position: 'absolute',
                                top: '15px',
                                right: '17px',
                                zIndex: '1'
                            }}
                        />

                        <span
                            onClick={flipCamera}
                            className="fa fa-retweet f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                            style={{
                                borderRadius: '50%',
                                width: '35px',
                                height: '35px',
                                position: 'absolute',
                                top: '15px',
                                left: '17px',
                                zIndex: '1'
                            }}
                        />
                    </div>

                    <Camera
                        imageType="jpg"
                        onCameraError={(error) => { handleWarningModal(error); }}
                        isDisplayStartCameraError={false}
                        idealFacingMode={prmFacingMode}
                        isImageMirror={false}
                        idealResolution={{ width: 1920, height: 1440 }}
                        onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                        onCameraStart={() => {
                            // for (let ctr = 0; ctr < 2; ctr++) {
                            //     console.log('start' + ctr);
                            //     // if (ctr === 1 && document.querySelector("#closeFlag")) { showCloseFoto() }
                            //     document.querySelector("#closeFlag") && showCloseFoto()
                            // }
                            document.querySelector("#closeFlag") && showCloseFoto()
                            console.log('start camera');
                        }}
                        onCameraStop={() => { console.log('stop camera'); }}
                        imageCompression={0.3}
                        style={{ width: '100%' }}
                    />
                </Modal.Body>
            </Modal>



            {/* Show Src Cam */}
            <Modal
                show={srcCam ? true : false}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body className="p-0">
                    <div className="flexJustifyAlignCenter">
                        <span
                            onClick={() => setSrcCam('')}
                            className="fa fa-close f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                            style={{
                                borderRadius: '50%',
                                width: '35px',
                                height: '35px',
                                position: 'absolute',
                                top: '15px',
                                right: '17px'
                            }}
                        />

                        <img
                            src={srcCam}
                            alt={srcCam}
                            style={{ width: '100%' }}
                        />
                    </div>

                    <div className="py-3 px-3">
                        <Button
                            variant="light"
                            size="sm"
                            className="btnXsOverride mr-2"
                            onClick={() => {
                                setSrcCam('');
                                setShowCam(true);
                            }}
                        >
                            Foto Ulang
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadSimpanFoto}
                            btnClass="btnXsOverride px-3"
                            btnVariant="info"
                            btnOnClick={() => handleSimpanFoto(srcCam)}
                            caption="Simpan"
                        />
                    </div>
                </Modal.Body>
            </Modal>



            <Modal
                size="lg"
                show={modalZoomImage}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body className="p-0">
                    <span
                        onClick={() => setModalZoomImage(false)}
                        className="fa fa-close cp f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                        style={{
                            borderRadius: '50%',
                            width: '35px',
                            height: '35px',
                            position: 'absolute',
                            top: '15px',
                            right: '17px'
                        }}
                    />

                    <span
                        onClick={() => handleHapusFoto()}
                        className="fa fa-trash f20 cp bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                        style={{
                            borderRadius: '50%',
                            width: '35px',
                            height: '35px',
                            position: 'absolute',
                            top: '15px',
                            left: '17px',
                            zIndex: '1'
                        }}
                    />

                    {prmLoadHapusFoto &&
                        <span
                            className="f12 colorBlack bgWhite px-2 py-1 rounded-sm"
                            style={{
                                position: 'absolute',
                                top: '20px',
                                left: '60px',
                                zIndex: '1'
                            }}
                        >
                            Loading..
                        </span>
                    }

                    <img
                        src={fotoKelola.foto}
                        alt='foto'
                        style={{ width: '100%' }}
                    />
                </Modal.Body>
            </Modal>


            <Modal
                show={showModalCalendar}
                onHide={() => setShowModalCalendar(false)}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tanggal Masuk</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flexJustifyAlignCenter pt-1 pb-3">
                        <Calendar
                            defaultView="month"
                            onChange={(value) => {
                                handleSetValueTanggalMasuk(value);
                                setShowModalCalendar(false);
                            }}
                            value={new Date(valueTanggalMasuk)}
                        />
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={modalNamaBarang}
                onShow={() => {
                    setNamaBarangModal(namaBarang);
                    document.querySelector("#fgnb987").focus();
                }}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f16 color636363 fontweight600 mb-3">Nama Barang</div>

                    <div>
                        <Form.Group controlId="fgnb987" className="mb-0">
                            <Form.Control
                                name="fgnb987"
                                size="sm"
                                type="text"
                                placeholder="Nama Barang"
                                value={namaBarangModal}
                                onChange={(e) => setNamaBarangModal(e.target.value)}
                            />
                        </Form.Group>
                    </div>

                    <div className="mt-4 flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="px-3"
                            onClick={() => setModalNamaBarang(false)}
                        >
                            Close
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadSimpanNamaBrg}
                            btnClass="ml-3"
                            btnVariant="info"
                            btnOnClick={handleSimpanNamaBarang}
                            caption="Simpan"
                        />
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={modalKerusakanBarang}
                onShow={() => {
                    setKerusakanBarangModal(kerusakanBarang);
                    document.querySelector("#fgkb0hl").focus();
                }}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f16 color636363 fontweight600 mb-3">Keluhan</div>

                    <div>
                        <Form.Group controlId="fgkb0hl" className="mb-0">
                            <Form.Control
                                as="textarea"
                                rows={4}
                                name="fgkb0hl"
                                size="sm"
                                placeholder="Kerusakan/Keluhan Barang"
                                value={kerusakanBarangModal === null ? '' : kerusakanBarangModal}
                                onChange={(e) => setKerusakanBarangModal(e.target.value)}
                            />
                        </Form.Group>
                    </div>

                    <div className="mt-4 flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="px-3"
                            onClick={() => setModalKerusakanBarang(false)}
                        >
                            Close
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadSimpanKeluhan}
                            btnClass="ml-3"
                            btnVariant="info"
                            btnOnClick={handleSimpanKerusakanBarang}
                            caption="Simpan"
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={modalCatatan}
                onShow={() => {
                    setCatatanTransaksiModal(catatanTransaksi);
                    document.querySelector("#fgctlt7").focus();
                }}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f16 color636363 fontweight600 mb-3">Catatan</div>

                    <div>
                        <Form.Group controlId="fgctlt7" className="mb-0">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="fgctlt7"
                                size="sm"
                                placeholder="Catatan"
                                value={catatanTransaksiModal === null ? '' : catatanTransaksiModal}
                                onChange={(e) => setCatatanTransaksiModal(e.target.value)}
                            />
                        </Form.Group>
                    </div>

                    <div className="mt-4 flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="px-3"
                            onClick={() => setModalCatatan(false)}
                        >
                            Close
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadCatatan}
                            btnClass="ml-3"
                            btnVariant="info"
                            btnOnClick={handleSimpanCatatanTransaksi}
                            caption="Simpan"
                        />
                    </div>
                </Modal.Body>
            </Modal>



            <Modal
                show={modalUangMuka}
                onShow={() => {
                    refUangMuka.current.focus();
                }}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f16 color636363 fontweight600 mb-3">Uang Muka</div>

                    <div>
                        <InputGroup size="sm" className="mb-0">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Rp</InputGroup.Text>
                            </InputGroup.Prepend>

                            <Form.Control
                                type="text"
                                ref={refUangMuka}
                                size="sm"
                                placeholder="Uang Muka"
                                value={uangMukaModal ? formatNumberCanZero(uangMukaModal) : ''}
                                onChange={(e) => setUangMukaModal(formatNumber(e.target.value))}
                            />
                        </InputGroup>
                    </div>

                    <div className="mt-4 flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="px-3"
                            onClick={() => setModalUangMuka(false)}
                        >
                            Close
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadingUangMuka}
                            btnClass="ml-3"
                            btnVariant="info"
                            btnOnClick={handleSimpanUangMuka}
                            caption="Simpan"
                        />
                    </div>
                </Modal.Body>
            </Modal>


            <ModalConfirmation
                showModal={modalSubmit}
                hideModal={() => setModalSubmit(false)}
                title="Konfirmasi"
                bodyCaption="Lanjut ke proses pengerjaan ?"
                btnCloseClass="px-4"
                btnCloseCaption="Close"
                bLPrm={btnLoadSubmit}
                bLBtnClass="ml-3"
                bLOnClick={handleLanjutProsesBarang}
                bLCaption="Ya, Lanjutkan"
            />


            <ModalConfirmation
                showModal={modalBatalkanNota}
                hideModal={() => setModalBatalkanNota(false)}
                title="Konfirmasi"
                bodyCaption="Nota transaksi akan dibatalkan. Lanjut Batalkan Nota ?"
                btnCloseClass="px-4"
                btnCloseCaption="Close"
                bLPrm={btnLoadingBatalkanNota}
                bLBtnClass="ml-3"
                bLOnClick={handleBatalkanNota}
                bLCaption="Ya, Batalkan"
            />

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <Modal
                show={modalAddLabel}
                onShow={() => refAddLabel.current.focus()}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f16 color636363 fontweight600 mb-3">Kelengkapan Lainnya</div>

                    <div>
                        <Form.Control
                            size="sm"
                            type="text"
                            ref={refAddLabel}
                            placeholder="Item"
                        />
                    </div>

                    <div className="mt-4 flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="px-3"
                            onClick={() => setModalAddLabel(false)}
                        >
                            Close
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadingAddLabel}
                            btnClass="ml-3"
                            btnVariant="info"
                            btnOnClick={handleAddLabel}
                            caption="Simpan"
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <ModalConfirmation
                showModal={modalDeleteLabel}
                hideModal={() => setModalDeleteLabel(false)}
                title="Konfirmasi"
                bodyCaption={`Hapus item ${labelAction.nama} ?`}
                btnCloseClass="px-3"
                btnCloseCaption="Close"
                bLPrm={false}
                bLBtnClass="ml-3"
                bLOnClick={handleDeleteLabel}
                bLCaption="Hapus"
            />

        </React.Fragment >
    )
}

export default Transaksi;
