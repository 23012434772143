import React from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Button, Card, Modal, Form } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

import Layout from "../../components/Layout";
import NavbarTopOwner from "../../components/NavbarTop";
import NavbarTopTeknisi from "../../components/teknisi/NavbarTop";
import { BASE_URL, batasFluid, levelUser } from "../../utils/globalVar";
import { VFCannotNull, deko } from "../../utils/globalFunc";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ButtonLoading from "../../components/ButtonLoading";
import ModalConfirmation from "../../components/ModalConfirmation";
import LottiePausAstronot from "../../components/LottiePausAstronot";
import ModalInfoHint from "../../components/ModalInfoHint";
import PlaceHolderElement2 from "../../components/PlaceHolderElement2";

const FormKelengkapan = () => {

    const history = useHistory();

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    const [dataKelengkapan, setDataKelengkapan] = React.useState(null);
    const [loadingInit, setLoadingInit] = React.useState(false);

    const [modalTambahKategori, setModalTambahKategori] = React.useState(false);
    const [inputanNamaKategori, setInputanNamaKategori] = React.useState('');
    const refNamaKategori = React.useRef();

    const [modalEditNamaKategori, setModalEditNamaKategori] = React.useState(false);
    const [inputanEditNamaKategori, setInputanEditNamaKategori] = React.useState('');
    const refEditNamaKategori = React.useRef();
    const refPrmIdKategori = React.useRef();

    const [btnLoadingTambahKel, setBtnLoadingTambahKel] = React.useState(false);
    const [btnLoadingEditKel, setBtnLoadingEditKel] = React.useState(false);

    const [modalHapusFormKel, setModalHapusFormKel] = React.useState(false);
    const [btnLoadingHapusFormKel, setBtnLoadingHapusFormKel] = React.useState(false);
    const [prmHapusFormKel, setPrmHapusFormKel] = React.useState({
        id: '',
        nama: ''
    });

    const [modalInfoHint, setModalInfoHint] = React.useState(false);

    const mountedRef = React.useRef(true);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    React.useEffect(() => {
        setLoadingInit(true);

        axios({
            method: 'post',
            url: BASE_URL + '/get_all_kelengkapan',
            data: {
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);

            if (response.data.data) {
                setDataKelengkapan(response.data.data['k1']);
            }
        }).catch(error => {
            setLoadingInit(false);
            handleWarningModal(error.message)
        })

        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [reduxLogin.syswebappi])

    const modalEditKategori = (prm) => {
        axios({
            method: 'post',
            url: BASE_URL + '/get_kelengkapan_kategori_by_id',
            data: {
                id: prm,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            if (response.data.data) {
                setInputanEditNamaKategori(response.data.data);
                refPrmIdKategori.current = prm;
                setModalEditNamaKategori(true);
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            handleWarningModal(error.message)
        })
    }

    const handleDoEditKategori = () => {
        if (VFCannotNull(refEditNamaKategori.current.value)) {
            refEditNamaKategori.current.style.border = '1px solid red';
            refEditNamaKategori.current.focus();
            return false;
        }

        setBtnLoadingEditKel(true);

        axios({
            method: 'post',
            url: BASE_URL + '/do_edit_nama_kelengkapan_kategori',
            data: {
                id: refPrmIdKategori.current.value,
                nama: inputanEditNamaKategori,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingEditKel(false);
            setModalEditNamaKategori(false);

            if (response.data.flag_status === true) {
                setDataKelengkapan(response.data.data_kk['k1']);
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.error)
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingEditKel(false);
            setModalEditNamaKategori(false);
            handleWarningModal(error.message)
        })
    }

    const handleKelolaChecklist = (prm) => {
        history.push('/kelolaformkelengkapan/' + prm);
    }

    const handleHapusKategori = () => {
        setBtnLoadingHapusFormKel(true);

        axios({
            method: 'post',
            url: BASE_URL + '/cek_kelengkapan_if_delete',
            data: {
                id_kategori: prmHapusFormKel.id,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusFormKel(false);
            setModalHapusFormKel(false);

            if (response.data.hasil === 'tdk-bs-dihapus') {
                handleWarningModal('Form cek barang ' + prmHapusFormKel.nama + ' tidak dapat dihapus. Terdapat transaksi yang menggunakan form tersebut. Pilih opsi Hidden untuk menon-aktifkan form ini.');
            }

            if (response.data.hasil === true) {
                setDataKelengkapan(response.data.data_kk['k1']);
            }

            if (response.data.hasil === false) {
                handleWarningModal('Error : ' + response.data.error);
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusFormKel(false);
            setModalHapusFormKel(false);
            handleWarningModal(error.message)
        })
    }

    const toggleActiveKategori = (statusCheck, prm) => {
        axios({
            method: 'post',
            url: BASE_URL + '/toggle_show_kategori_kelengkapan',
            data: {
                id_kategori: prm,
                status_show: statusCheck,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            if (response.data.flag_status === true) {
                setDataKelengkapan(response.data.data_kk['k1']);
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.error);
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            handleWarningModal(error.message)
        })
    }

    const handleTambahKategori = () => {
        if (VFCannotNull(inputanNamaKategori)) {
            refNamaKategori.current.style.border = '1px solid red';
            refNamaKategori.current.focus();
            return false;
        }

        setBtnLoadingTambahKel(true);

        axios({
            method: 'post',
            url: BASE_URL + '/tambah_kategori_kelengkapan',
            data: {
                nama: inputanNamaKategori,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingTambahKel(false);
            setModalTambahKategori(false);

            if (response.data.flag_status === true) {
                setInputanNamaKategori('');
                setDataKelengkapan(response.data.data_kk['k1']);
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Warning : ' + response.data.error)
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingTambahKel(false);
            setModalTambahKategori(false);
            handleWarningModal(error.message)
        })
    }

    const InfoHint = () => {
        return (
            <React.Fragment>
                <div className="alert alert-info py-3 px-3">
                    <div className="fontweight500">
                        Pengertian
                        <hr className="my-2" />
                    </div>

                    <div>
                        Form cek barang adalah form yang digunakan untuk melakukan cek kelengkapan / kondisi barang pada saat penerimaan barang (transaksi) dan form Q.C. barang pada saat proses Q.C.
                    </div>

                    <div className="mt-3">
                        Contoh Form cek barang misalnya <span className="fontweight500">Laptop</span>.
                    </div>

                    <div className="mt-3">
                        Setelah membuat form cek barang (misalkan Laptop), lalu buatlah poin-poin checklist seperti <span className="fontweight500">Port USB Kanan, Port USB Kiri, Keyboard, Engsel, Bluetooth, dst</span> melalui tombol <span className="underLine">Kelola</span>.
                    </div>
                </div>

                <div className="mt-3 alert alert-info py-3 px-3">
                    <div className="fontweight500">
                        Active/Hidden Form Cek Barang
                        <hr className="my-2" />
                    </div>

                    <div>
                        Jika status Active, maka form tersebut akan tampil pada daftar pilihan Form Cek Barang pada saat memilih Form Cek Barang (pada proses transaksi).
                        <br /><br />
                        Jika status Hidden, maka form tersebut tidak akan ditampilkan.
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            {
                deko(reduxLogin.syswebappj) === levelUser.owner && <NavbarTopOwner shadow={true} titleNavTop="Form Cek Barang" />
            }

            {
                deko(reduxLogin.syswebappj) === levelUser.teknisi && <NavbarTopTeknisi shadow={true} titleNavTop="Form Cek Barang" />
            }

            <Layout titleTab="Form Cek Barang">
                <Row>
                    <Col xs={12} className="mt-4">

                        {
                            loadingInit && <PlaceHolderElement2 prmMT="" prmPX="" />
                        }

                        {
                            !loadingInit &&
                            <Card
                                className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-5`}
                            >

                                <div
                                    className="flexJustifyBeetwenAlignCenter mb-4 pb-3"
                                    style={{ borderBottom: '1px solid #ddd' }}
                                >
                                    <div className="fontweightBold colorTheme">
                                        List Form Cek Barang
                                    </div>

                                    <div>
                                        <Button
                                            variant="info"
                                            size="sm"
                                            className="btnXsOverride"
                                            onClick={() => setModalTambahKategori(true)}
                                        >
                                            <span className="fa fa-plus-circle colorWhite f14 mr-1" />
                                            <span className="colorWhite">Tambah</span>
                                        </Button>
                                    </div>
                                </div>

                                {
                                    dataKelengkapan && !loadingInit &&
                                    Object.keys(dataKelengkapan).map((key) => {
                                        return (
                                            <div
                                                key={'dataKelengkapanajhd98ash' + key}
                                                className="f13"
                                            >
                                                <Row>
                                                    <Col xs={7}>
                                                        <div
                                                            className="wordBreakAll cp"
                                                            onClick={() => modalEditKategori(dataKelengkapan[key].id_kategori)}
                                                        >
                                                            <span className="mr-2 fontweight600 color636363">
                                                                {dataKelengkapan[key].nama_kategori}
                                                            </span>

                                                            <span className="fa fa-pencil mr-1 color808080 f13" />
                                                        </div>

                                                        <div className="wordBreakAll mt-3 color808080">
                                                            <span className="mr-2">
                                                                {dataKelengkapan[key]['b'] !== null ? Object.keys(dataKelengkapan[key]['b']).length : '0'} checklist
                                                            </span>
                                                            <span
                                                                className="f12 underLine cp"
                                                                onClick={() => handleKelolaChecklist(dataKelengkapan[key].id_kategori)}
                                                            >
                                                                Kelola
                                                            </span>
                                                        </div>
                                                    </Col>

                                                    <Col xs={5} className="pl-0 pr-3 color808080 flexJustifyEndAlignCenter">
                                                        <div
                                                            className="flexRowJustifyAlignCenter pr-3"
                                                            style={{ borderRight: '1px solid #eee' }}
                                                        >
                                                            <div>
                                                                <Form.Check
                                                                    custom
                                                                    type="switch"
                                                                    id={`statusAktif-${key}`}
                                                                    className="cp f13"
                                                                    style={{
                                                                        marginLeft: '8px'
                                                                    }}

                                                                    defaultChecked={parseInt(dataKelengkapan[key].show_kategori)}

                                                                    defaultValue={parseInt(dataKelengkapan[key].show_kategori)}

                                                                    onChange={(e) => toggleActiveKategori(e.target.checked, dataKelengkapan[key].id_kategori)}
                                                                />
                                                            </div>

                                                            <div className="f11">
                                                                {parseInt(dataKelengkapan[key].show_kategori) === 1 ? 'Active' : 'Hidden'}
                                                            </div>
                                                        </div>

                                                        <div className="pl-3 text-center">
                                                            <div
                                                                style={{
                                                                    lineHeight: '0',
                                                                    paddingTop: '3px',
                                                                    paddingBottom: '4px'
                                                                }}
                                                            >
                                                                <span
                                                                    className="fa fa-trash cp color636363 f16"
                                                                    onClick={() => {
                                                                        setPrmHapusFormKel({
                                                                            id: dataKelengkapan[key].id_kategori,
                                                                            nama: dataKelengkapan[key].nama_kategori
                                                                        })

                                                                        setModalHapusFormKel(true);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="f11">Hapus</div>
                                                        </div>
                                                    </Col>

                                                    {
                                                        (dataKelengkapan.length - 1) !== parseInt(key) &&
                                                        <Col xs={12}>
                                                            <hr style={{ borderColor: '#eee' }} />
                                                        </Col>
                                                    }
                                                </Row>
                                            </div>
                                        )
                                    })
                                }

                                {
                                    !dataKelengkapan && !loadingInit &&
                                    <div className="flexRowJustifyAlignCenter">
                                        <LottiePausAstronot caption={`Belum ada data.
                                Klik tombol Tambah untuk menambah form cek barang.`} />
                                    </div>
                                }
                            </Card>
                        }

                    </Col>
                </Row>
            </Layout>


            <Modal
                show={modalTambahKategori}
                onShow={() => refNamaKategori.current.focus()}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f15 fontweight600 mb-4">Tambah Form Cek Barang</div>

                    <Form.Group controlId="fnm89laksj43" className="mb-4">
                        <Form.Control
                            ref={refNamaKategori}
                            size="sm"
                            type="text"
                            placeholder="Nama Barang. Misal : Laptop"
                            value={inputanNamaKategori}
                            onChange={(e) => setInputanNamaKategori(e.target.value)}
                        />
                    </Form.Group>

                    <div className="flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="mr-3 px-3"
                            onClick={() => setModalTambahKategori(false)}
                        >
                            Close
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadingTambahKel}
                            btnClass=""
                            btnVariant="info"
                            btnOnClick={handleTambahKategori}
                            caption="Simpan"
                        />
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={modalEditNamaKategori}
                onShow={() => refEditNamaKategori.current.focus()}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f15 fontweight600 mb-4">Edit Nama Form</div>

                    <Form.Group controlId="fenk96asdg" className="mb-4">
                        <Form.Control
                            ref={refEditNamaKategori}
                            size="sm"
                            type="text"
                            placeholder="Nama Barang"
                            value={inputanEditNamaKategori}
                            onChange={(e) => setInputanEditNamaKategori(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Control
                        hidden
                        ref={refPrmIdKategori}
                        defaultValue={refPrmIdKategori.current}
                        size="sm"
                        type="text"
                    />

                    <div className="flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="mr-3 px-3"
                            onClick={() => setModalEditNamaKategori(false)}
                        >
                            Close
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadingEditKel}
                            btnClass=""
                            btnVariant="info"
                            btnOnClick={handleDoEditKategori}
                            caption="Simpan"
                        />
                    </div>
                </Modal.Body>
            </Modal>


            <ModalConfirmation
                showModal={modalHapusFormKel}
                hideModal={() => setModalHapusFormKel(false)}
                title="Konfirmasi"
                bodyCaption={`Hapus Form Cek Barang ${prmHapusFormKel.nama} ?`}
                btnCloseClass="px-3"
                btnCloseCaption="Close"
                bLPrm={btnLoadingHapusFormKel}
                bLBtnClass="ml-3 px-3"
                bLOnClick={handleHapusKategori}
                bLCaption="Hapus"
            />

            {
                !loadingInit &&
                <span
                    className="alert alert-secondary cp py-2 px-3"
                    style={{ position: 'fixed', bottom: '0', right: '10px' }}
                    onClick={() => setModalInfoHint(true)}
                >
                    <span className="fa fa-info-circle" /> Info
                </span>
            }

            <ModalInfoHint
                showWarningModal={modalInfoHint}
                hideWarningModal={() => setModalInfoHint(false)}
                paramText={InfoHint()}
            />


        </React.Fragment >
    );
}

export default FormKelengkapan;
