import React from 'react';
import Lottie from "lottie-react";
import lottiePaus from "../assets/lottie/paus.json";
import lottieAstronot from "../assets/lottie/astronot.json";
import { useSelector } from "react-redux";
import { widthXS, widthSM, widthMD, widthLG } from "../utils/globalVar";

const LottiePausAstronot = ({ caption }) => {

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const [toggleLottie, setToggleLottie] = React.useState(true);

    const handleToggleLottie = () => {
        setTimeout(() => {
            setToggleLottie(!toggleLottie)
        }, 250);
    }

    return (
        <React.Fragment>
            <div className="mt-3 color808080 text-center whiteSpacePreLine">
                {!caption ? 'Tidak ada data' : caption}
            </div>

            {toggleLottie &&
                <div
                    onClick={handleToggleLottie}
                    className="cp animateUpDownSlow"
                    style={{ width: reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? '60%' : '40%') : '40%') : '25%') : '25%' }}
                >
                    <Lottie animationData={lottiePaus} />
                </div>
            }

            {!toggleLottie &&
                <div
                    onClick={handleToggleLottie}
                    className="mt-3 py-3 cp"
                    style={{ width: reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? '85%' : '60%') : '50%') : '30%') : '30%' }}
                >
                    <Lottie animationData={lottieAstronot} />
                </div>
            }
        </React.Fragment>
    );
}

export default LottiePausAstronot;
