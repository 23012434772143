import React from 'react';
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Card, Button } from 'react-bootstrap';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import { BASE_URL_DUA, prefixUrl, menuCaptionIcon } from "../../utils/globalVar";
import { formatNumber } from "../../utils/globalFunc";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ModalConfirmation from "../../components/ModalConfirmation";
import LottiePausAstronot from "../../components/LottiePausAstronot";

const CariDiskon = () => {

    const parameter = useParams();
    const idTransaksi = parameter.id;

    const history = useHistory();

    const [dataDiskon, setDataDiskon] = React.useState(null);
    const [loadingInit, setLoadingInit] = React.useState(false);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const mountedRef = React.useRef(true);

    const [btnLoadingGunakan, setBtnLoadingGunakan] = React.useState(false);
    const [modalGunakan, setModalGunakan] = React.useState({
        modal: false,
        id: ''
    });

    React.useEffect(() => {
        return () => mountedRef.current = false;
    }, []);

    React.useEffect(() => {
        setLoadingInit(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/get_all_diskon',
            data: {
                dummy: 'sjkdfh87'
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);
            setDataDiskon(response.data.data_diskon);
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }, []);

    const handleGunakanDiskon = () => {
        setBtnLoadingGunakan(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/gunakan_diskon',
            data: {
                id_transaksi: idTransaksi,
                id_diskon: modalGunakan.id
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingGunakan(false);
            setModalGunakan({ modal: false, id: '' });

            response.data.flag_status === true && history.push('/' + prefixUrl.owner + '/selesai/' + idTransaksi, { goBottom: true });

            response.data.flag_status === false && handleWarningModal('Error : ' + response.data.pesan)

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi sudah tidak available. Kembali ke halaman sebelumnya untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingGunakan(false);
            handleWarningModal('Warning : ' + error.message);
        })
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop
                shadow={true}
                titleNavTop="Diskon"
                prmBackButton={true}
                prmOnBack={`/${prefixUrl.owner}/selesai/${idTransaksi}`}
                prmLocationState={{ goBottom: true }}
            />

            <Layout titleTab="Diskon">

                <Row>
                    {dataDiskon && !loadingInit &&
                        Object.keys(dataDiskon).map((key) => {
                            return (
                                <Col
                                    key={key + 'dd7348jh'}
                                    xs={12}
                                    md={6}
                                    className="mt-3"
                                >
                                    <Card className="shadow-sm brSoftMedium">
                                        <div className="flex">
                                            <div
                                                className="py-2 px-2 mr-3 flexJustifyAlignCenter borderRadiusLeftCard colorWhite bgTheme"
                                            >
                                                <span
                                                    className="flexJustifyAlignCenter"
                                                    style={{ width: '30px', height: '30px' }}
                                                >
                                                    {dataDiskon[key].flag === 'rupiah' ? 'Rp' : '%'}
                                                </span>
                                            </div>

                                            <div
                                                className="py-2 mr-2"
                                                style={{ flex: 1 }}
                                            >
                                                <div className="fontweight500">
                                                    Diskon{' '}
                                                    {dataDiskon[key].flag === 'rupiah' && 'Rp '}
                                                    {formatNumber(dataDiskon[key].jumlah)}
                                                    {dataDiskon[key].flag === 'persen' && ' %'}
                                                </div>

                                                <div className="color808080 f13">
                                                    {dataDiskon[key].nama}
                                                </div>
                                            </div>

                                            <div className="flexJustifyAlignCenter mr-2">
                                                <Button
                                                    variant="outline-info"
                                                    size="sm"
                                                    className="btnXsOverride"
                                                    onClick={() => setModalGunakan({
                                                        modal: true,
                                                        id: dataDiskon[key].id
                                                    })}
                                                >
                                                    Gunakan
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })
                    }

                    {
                        loadingInit &&
                        <Col xs={12} className="mt-3 text-center">
                            Loading..
                        </Col>
                    }

                    {
                        !loadingInit && !dataDiskon &&
                        <Col xs={12} className="mt-4 text-center flexRowJustifyAlignCenter">
                            <div className="color808080 px-4">
                                <div className="alert alert-info">
                                    Daftar diskon masih kosong.
                                    <br /><br />
                                    Tambahkan diskon terlebih dahulu pada menu <span className={`${menuCaptionIcon.menuDiskon.icon} ml-2`} /> {menuCaptionIcon.menuDiskon.caption}
                                </div>
                            </div>

                            <LottiePausAstronot caption=" " />
                        </Col>
                    }
                </Row>

            </Layout>

            <ModalConfirmation
                showModal={modalGunakan.modal}
                hideModal={() => setModalGunakan({ ...modalGunakan, modal: false })}
                title="Konfirmasi"
                bodyCaption="Gunakan diskon ini ?"
                btnCloseClass="px-3"
                btnCloseCaption="Close"
                bLPrm={btnLoadingGunakan}
                bLBtnClass="ml-3 px-1"
                bLOnClick={handleGunakanDiskon}
                bLCaption="Gunakan"
            />

        </React.Fragment>
    );
}

export default CariDiskon;
