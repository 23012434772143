import React from 'react';
import { useSelector } from "react-redux";
import { Row, Col, Card } from 'react-bootstrap';

import { batasFluid } from "../utils/globalVar";

const PlaceHolderElement2 = ({ prmMT, prmPX }) => {

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);

    const bgCard = '#f6f6f6';
    const bgLabel = '#f0f0f0';
    const borderColorCard = '1px solid #f0f0f0';

    const LabelPH = ({ prmWidth, prmClass }) => {
        return (
            <div
                className={`rounded-lg ${prmClass}`}
                style={{ width: prmWidth, backgroundColor: bgLabel }}
            >
                &nbsp;
            </div>
        )
    }

    const CardPH = ({ children }) => {
        return (
            <Card
                className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} mb-3 shadow-sm`}
                style={{
                    backgroundColor: bgCard,
                    border: borderColorCard
                }}
            >
                {children}
            </Card>
        )
    }

    return (
        <Row className={prmPX}>
            <Col xs={12} className={prmMT}>

                <CardPH>
                    <div className="width100">
                        <LabelPH prmWidth="40%" />

                        <LabelPH prmWidth="60%" prmClass="mt-1" />

                        <LabelPH prmWidth="40%" prmClass="mt-3" />

                        <LabelPH prmWidth="60%" prmClass="mt-1" />
                    </div>
                </CardPH>

                <CardPH>
                    <div className="width100">
                        <LabelPH prmWidth="40%" />

                        <LabelPH prmWidth="60%" prmClass="mt-1" />

                        <LabelPH prmWidth="40%" prmClass="mt-3" />

                        <LabelPH prmWidth="60%" prmClass="mt-1" />
                    </div>
                </CardPH>

                <CardPH>
                    <div className="width100">
                        <LabelPH prmWidth="60%" />
                        <LabelPH prmWidth="80%" prmClass="mt-1" />

                        <LabelPH prmWidth="60%" prmClass="mt-3" />
                        <LabelPH prmWidth="80%" prmClass="mt-1" />

                        <LabelPH prmWidth="60%" prmClass="mt-3" />
                        <LabelPH prmWidth="80%" prmClass="mt-1" />

                        <LabelPH prmWidth="60%" prmClass="mt-3" />
                        <LabelPH prmWidth="80%" prmClass="mt-1" />
                    </div>
                </CardPH>

            </Col>
        </Row>
    );
}

export default PlaceHolderElement2;
