// ! : ROOT_BASE_URL dan BASE_URL jangan menggunakan slash di akhir URL !
export const ROOT_BASE_URL = 'https://poslumos.com/lumos_back_end';
export const BASE_URL = ROOT_BASE_URL + '/lumos';
export const BASE_URL_DUA = ROOT_BASE_URL + '/lumoscontrollerdua';
// ! : ROOT_BASE_URL dan BASE_URL jangan menggunakan slash di akhir URL !

export const prefixUrl = {
    owner: "o",
    teknisi: "t"
}

export const levelUser = {
    owner: 'owner',
    teknisi: 'teknisi'
}

export const levelUserNama = {
    owner: 'Owner',
    teknisi: 'Teknisi'
}

export const otspc = 'sakldfhskldfhlskdhfsdf78f74hukhfkjdfkjlkhk_uhdfsdhf$hadjfdskfj*kldfsh#@jlsjdk0923844758jkhdsfjdksfjhfjdskfj';

export const metodeBayar = {
    cash: 'Cash',
    transfer: 'Transfer'
}

export const kategoriServis = {
    maintenance: 'Maintenance',
    service: 'Service'
}

export const reminderMaintenance = 'maintenance';

export const batasFluid = 767.98;

/* Extra small devices (portrait phones, less than 576px) */
export const widthXS = 575;

/* Small devices (landscape phones, 576px and up) */
export const widthSM = 767;

/* Medium devices (tablets, 768px and up) */
export const widthMD = 991;

/* Large devices (desktops, 992px and up) */
export const widthLG = 1199;

/* Extra large devices (large desktops, 1200px and up) */
export const widthXL = 1200;

export const brand = {
    nama: 'Lumos',
    tag: 'IT, Network & Electronic Solution',
    keterangan: 'Since 2014'
};

export const avatar = {
    defaultAvatar: 'defaultAvatar.png',
    perorangan: { slug: 'perorangan' },
    rekananTeknisi: { slug: 'rekanan_teknisi' },
    perusahaan: { slug: 'perusahaan' }
}

export const menuCaptionIcon = {
    menu1: {
        caption: 'Home',
        icon: 'fa fa-home'
    },
    menu2: {
        caption: 'Transaksi Baru',
        icon: 'fa fa-plus-square'
    },
    menu3: {
        caption: 'Customer',
        icon: 'fa fa-user'
    },
    menu4: {
        caption: 'Kelola Barang',
        icon: 'fa fa-wrench'
    },
    menu5: {
        caption: 'Scan QR',
        icon: 'fa fa-list-alt'
    },
    menu6: {
        caption: 'Proses',
        icon: 'fa fa-wrench'
    },
    menu7: {
        caption: 'Cetak Label',
        icon: 'fa fa-tag'
    },
    menu8: {
        caption: 'Sign Out',
        icon: 'fa fa-sign-out'
    },
    menu9: {
        caption: 'Form Cek Barang',
        icon: 'fa fa-list-alt'
    },
    menu10: {
        caption: 'Stok Barang',
        icon: 'fa fa-server'
    },
    menuTesting: {
        caption: 'Testing',
        icon: 'fa fa-stethoscope'
    },
    menuAbout: {
        caption: 'Developer Contact',
        icon: 'fa fa-code'
    },
    menuDiskon: {
        caption: 'Diskon',
        icon: 'fa fa-percent'
    },
    menuKategoriBarang: {
        caption: 'Kategori Barang',
        icon: 'fa fa-laptop'
    },
    menuAnggaranBelanjaBarang: {
        caption: 'Pengeluaran',
        icon: 'fa fa-shopping-cart'
    },
    menuPenilaianKaryawan: {
        caption: 'Penilaian Karyawan',
        icon: 'fa fa-bar-chart'
    },
    menuProjectCancel: {
        caption: 'List Project Cancel',
        icon: 'fa fa-list-ul'
    },
    menuKelolaAkunUser: {
        caption: 'Kelola Akun User',
        icon: 'fa fa-vcard-o'
    },
    menuAkunKaryawan: {
        caption: 'Akun',
        icon: 'fa fa-vcard-o'
    },
    menuCetakDokumen: {
        caption: 'Cetak Dokumen',
        icon: 'fa fa-print'
    },
    menuCekSerialNumber: {
        caption: 'Cek Penggunaan Part',
        icon: 'fa fa-microchip'
    },
    menuPendapatan: {
        caption: 'Pendapatan',
        icon: 'fa fa-usd'
    },
    menuPencarianCepat: {
        caption: 'Pencarian Cepat',
        icon: 'fa fa-search'
    },
    menuReport: {
        caption: 'Report',
        icon: 'fa fa-file-text-o'
    }
}