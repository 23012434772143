import React from 'react';
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Col, Card, InputGroup, FormControl, Button, Modal, Form } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

import { BASE_URL, batasFluid, levelUser } from "../../utils/globalVar";
import { VFCannotNull, cutStringWidthWindowThreeDots, deko } from "../../utils/globalFunc";
import Layout from "../../components/Layout";
import NavbarTopOwner from "../../components/NavbarTop";
import NavbarTopTeknisi from "../../components/teknisi/NavbarTop";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ModalConfirmation from '../../components/ModalConfirmation';
import ButtonLoading from '../../components/ButtonLoading';
import NoData from '../../components/NoData';
import PlaceHolderElement2 from "../../components/PlaceHolderElement2";

const KelolaSubKelengkapan = () => {

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    const parameter = useParams();
    const idTransaksi = parameter.id;

    const [dataKelengkapan, setDataKelengkapan] = React.useState(null);
    const [inputKelengkapan, setInputKelengkapan] = React.useState('');
    const refInputKelengkapan = React.useRef();

    const [modalEditKelengkapan, setModalEditKelengkapan] = React.useState(false);
    const [inputanEditKelengkapan, setInputanEditKelengkapan] = React.useState('');
    const refEditKelengkapan = React.useRef();
    const refPrmIdKelengkapan = React.useRef();

    const [modalHapusKel, setModalHapusKel] = React.useState(false);
    const [btnLoadingHapusKel, setBtnLoadingHapusKel] = React.useState(false);
    const [prmHapus, setPrmHapus] = React.useState({
        idKtgr: '', idKel: '', namaKel: '', namaKtgr: ''
    });

    const [btnLoadingEdit, setBtnLoadingEdit] = React.useState(false);
    const [loadingInit, setLoadingInit] = React.useState(false);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        return () => {
            mountedRef.current = false;
        }
    }, []);

    const initKelengkapan = React.useCallback(() => {
        axios({
            method: 'post',
            url: BASE_URL + '/get_kelengkapan_by_id',
            data: {
                id: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);

            if (response.data) {
                setDataKelengkapan(response.data['k1']);
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);
            handleWarningModal(error.message)
        })
    }, [idTransaksi, reduxLogin.syswebappi])

    React.useEffect(() => {
        setLoadingInit(true);
        initKelengkapan();
    }, [initKelengkapan]);

    const handleTambahKelengkapan = () => {
        if (VFCannotNull(inputKelengkapan)) {
            refInputKelengkapan.current.style.border = '1px solid red';
            refInputKelengkapan.current.focus();
            return false;
        }

        axios({
            method: 'post',
            url: BASE_URL + '/insert_kelengkapan_by_id',
            data: {
                id: idTransaksi,
                nama: inputKelengkapan,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            if (response.data && response.data.flag_status === true) {
                refInputKelengkapan.current.style.border = '0px solid #eee';
                setInputKelengkapan('');

                initKelengkapan();
            }

            if (response.data && response.data.flag_status === false) {
                handleWarningModal(response.data.error)
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            handleWarningModal(error.message)
        })
    }

    const handleModalEditKeleng = (prm) => {
        refPrmIdKelengkapan.current = prm;

        axios({
            method: 'post',
            url: BASE_URL + '/get_sub_kelengkapan_by_id',
            data: {
                id: prm,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            response.data.hasil && setInputanEditKelengkapan(response.data.hasil);
            setModalEditKelengkapan(true);
        }).catch(error => {
            if (!mountedRef.current) return null;

            handleWarningModal(error.message)
        })
    }

    const handleDoEditKelengkapan = () => {
        if (VFCannotNull(inputanEditKelengkapan)) {
            refEditKelengkapan.current.style.border = '1px solid red';
            refEditKelengkapan.current.focus();
            return false;
        }

        setBtnLoadingEdit(true);

        axios({
            method: 'post',
            url: BASE_URL + '/update_kelengkapan_by_id',
            data: {
                id: refPrmIdKelengkapan.current.value,
                nama: inputanEditKelengkapan,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingEdit(false);

            if (response.data && response.data.flag_status === true) {
                refEditKelengkapan.current.style.border = '0px solid #eee';
                setInputanEditKelengkapan('');
                setModalEditKelengkapan(false);

                initKelengkapan();
            }

            if (response.data && response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.error)
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingEdit(false);
            handleWarningModal(error.message)
        })
    }

    const toggleActiveKelengkapan = (statusCheck, prm) => {
        axios({
            method: 'post',
            url: BASE_URL + '/toggle_show_kelengkapan',
            data: {
                id_kategori: prm,
                status_show: statusCheck,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            if (response.data && response.data.flag_status === true) {
                initKelengkapan();
            }

            if (response.data && response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.error);
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            handleWarningModal(error.message)
        })
    }

    const handleHapusKelengkapan = () => {
        setBtnLoadingHapusKel(true);

        axios({
            method: 'post',
            url: BASE_URL + '/cek_sub_kelengkapan_if_delete',
            data: {
                id_kategori: prmHapus.idKtgr,
                id_kelengkapan: prmHapus.idKel,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusKel(false);
            setModalHapusKel(false);

            if (response.data && response.data.hasil === 'tdk-bs-dihapus') {
                handleWarningModal(`'${prmHapus.namaKel}' tidak dapat dihapus. Terdapat transaksi yang menggunakan form '${prmHapus.namaKtgr}'. Pilih opsi Hidden untuk menon-aktifkan '${prmHapus.namaKel}'.`);
            } else if (response.data && response.data.hasil === true) {
                initKelengkapan();
            } else if (response.data && response.data.hasil === false) {
                handleWarningModal('Error : ' + response.data.error);
            }
        }).catch(error => {
            if (!mountedRef.current) return null;
            setBtnLoadingHapusKel(false);
            setModalHapusKel(false);
            handleWarningModal(error.message)
        })
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <Modal
                show={modalEditKelengkapan}
                onShow={() => refEditKelengkapan.current.focus()}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f15 fontweight600 mb-4">Edit Item</div>

                    <Form.Group controlId="fekajsdi8y" className="mb-0">
                        <Form.Control
                            ref={refEditKelengkapan}
                            size="sm"
                            type="text"
                            placeholder="Nama Item"
                            value={inputanEditKelengkapan}
                            onChange={(e) => setInputanEditKelengkapan(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Control
                        hidden
                        ref={refPrmIdKelengkapan}
                        defaultValue={refPrmIdKelengkapan.current}
                        size="sm"
                        type="text"
                    />

                    <div className="mt-4 flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="mr-3 px-3"
                            onClick={() => { setModalEditKelengkapan(false); setInputanEditKelengkapan(''); }}
                        >
                            Close
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadingEdit}
                            btnClass="px-3"
                            btnVariant="info"
                            btnOnClick={handleDoEditKelengkapan}
                            caption="Simpan"
                        />
                    </div>
                </Modal.Body>
            </Modal>
            {deko(reduxLogin.syswebappj) === levelUser.owner ?
                <NavbarTopOwner shadow={true} titleNavTop={dataKelengkapan ? cutStringWidthWindowThreeDots(reduxWindowWidth, batasFluid, dataKelengkapan[0].nama_kategori, 20) : 'Item Form'} prmBackButton={true} />
                :
                <NavbarTopTeknisi shadow={true} titleNavTop={dataKelengkapan ? cutStringWidthWindowThreeDots(reduxWindowWidth, batasFluid, dataKelengkapan[0].nama_kategori, 20) : 'Item Form'} prmBackButton={true} />
            }
            <Layout titleTab={dataKelengkapan ? 'Form ' + dataKelengkapan[0].nama_kategori : 'Form Item'}>
                <Row>
                    {
                        loadingInit &&
                        <Col xs={12} className="mt-3">
                            <PlaceHolderElement2 />
                        </Col>
                    }


                    {
                        !loadingInit &&
                        <Col xs={12} className="mt-3">
                            <Card
                                className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                            >
                                {dataKelengkapan && !loadingInit &&
                                    Object.keys(dataKelengkapan).map((key) => {
                                        return (
                                            <div key={'dataSubKelengkapan93eklf' + key} className="f13">

                                                <div className="mb-3 pb-3">
                                                    <div className="fontweightBold colorTheme mb-3">
                                                        Kelola Form Cek Barang {dataKelengkapan[key].nama_kategori}
                                                    </div>

                                                    <InputGroup size="sm">
                                                        <FormControl
                                                            ref={refInputKelengkapan}
                                                            value={inputKelengkapan}
                                                            onChange={(e) => setInputKelengkapan(e.target.value)}
                                                            placeholder="Item. Misal : Port USB Kiri"
                                                        />
                                                        <InputGroup.Append>
                                                            <Button
                                                                variant="info"
                                                                className="px-3"
                                                                onClick={handleTambahKelengkapan}
                                                            >
                                                                <span className="fa fa-plus colorWhite" />
                                                            </Button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </div>

                                                <div>
                                                    {dataKelengkapan[key]['b'] ?
                                                        Object.keys(dataKelengkapan[key]['b']).map((subKey) => {
                                                            return (
                                                                <div
                                                                    key={'dataSubKelengkapanajhd98ash' + subKey}
                                                                    className="f13"
                                                                >
                                                                    <Row>
                                                                        <Col xs={7}>
                                                                            <div
                                                                                onClick={() => handleModalEditKeleng(dataKelengkapan[key]['b'][subKey].id_kelengkapan)}
                                                                                className="wordBreakAll color636363 cp"
                                                                            >
                                                                                <span className="mr-2">
                                                                                    {dataKelengkapan[key]['b'][subKey].nama_kelengkapan}
                                                                                </span>

                                                                                <span
                                                                                    className="fa fa-pencil color808080 f12"
                                                                                />
                                                                            </div>
                                                                        </Col>

                                                                        <Col xs={5} className="pl-0 pr-3 color808080 flexJustifyEndAlignCenter">
                                                                            <div
                                                                                className="flexRowJustifyAlignCenter pr-3"
                                                                                style={{ borderRight: '1px solid #eee' }}
                                                                            >
                                                                                <div>
                                                                                    <Form.Check
                                                                                        custom
                                                                                        type="switch"
                                                                                        id={`statusAktif-${subKey}`}
                                                                                        className="cp f13"
                                                                                        style={{
                                                                                            marginLeft: '8px'
                                                                                        }}

                                                                                        defaultChecked={parseInt(dataKelengkapan[key]['b'][subKey].show_kelengkapan)}

                                                                                        defaultValue={parseInt(dataKelengkapan[key]['b'][subKey].show_kelengkapan)}

                                                                                        onChange={(e) => toggleActiveKelengkapan(e.target.checked, dataKelengkapan[key]['b'][subKey].id_kelengkapan)}
                                                                                    />
                                                                                </div>

                                                                                <div className="f11">
                                                                                    {parseInt(dataKelengkapan[key]['b'][subKey].show_kelengkapan) === 1 ? 'Active' : 'Hidden'}
                                                                                </div>
                                                                            </div>

                                                                            <div className="pl-3 text-center">
                                                                                <div
                                                                                    style={{
                                                                                        lineHeight: '0',
                                                                                        paddingTop: '3px',
                                                                                        paddingBottom: '4px'
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        className="fa fa-trash cp color636363 f16"
                                                                                        onClick={() => {
                                                                                            setPrmHapus({
                                                                                                idKtgr: dataKelengkapan[key].id_kategori,

                                                                                                idKel: dataKelengkapan[key]['b'][subKey].id_kelengkapan,

                                                                                                namaKel: dataKelengkapan[key]['b'][subKey].nama_kelengkapan,

                                                                                                namaKtgr: dataKelengkapan[key].nama_kategori
                                                                                            });

                                                                                            setModalHapusKel(true);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="f11">Hapus</div>
                                                                            </div>
                                                                        </Col>

                                                                        {
                                                                            (dataKelengkapan[key]['b'].length - 1) !== parseInt(subKey) &&
                                                                            <Col xs={12}>
                                                                                <hr
                                                                                    style={{ borderColor: '#eee' }}
                                                                                />
                                                                            </Col>
                                                                        }
                                                                    </Row>

                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div className="color808080">Belum Ada items. Tambah Item Terlebih Dahulu.</div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                {!dataKelengkapan && !loadingInit &&
                                    <NoData />
                                }
                            </Card>
                        </Col>
                    }
                </Row>

            </Layout>

            <ModalConfirmation
                showModal={modalHapusKel}
                hideModal={() => setModalHapusKel(false)}
                title="Konfirmasi"
                bodyCaption={`Hapus '${prmHapus.namaKel}' ?`}
                btnCloseClass="px-3"
                btnCloseCaption="Close"
                bLPrm={btnLoadingHapusKel}
                bLBtnClass="ml-3 px-3"
                bLOnClick={handleHapusKelengkapan}
                bLCaption="Hapus"
            />

        </React.Fragment>
    );
}

export default KelolaSubKelengkapan;
