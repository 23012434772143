import React from 'react';
import axios from "axios";
import NumberFormat from 'react-number-format';
import Masonry from 'react-masonry-css';
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import { BASE_URL, batasFluid, prefixUrl, widthLG, widthMD, widthSM, widthXS } from "../../utils/globalVar";
import { sqlDateToHumanDate } from "../../utils/globalFunc";
import ButtonLoading from "../../components/ButtonLoading";
import ModalConfirmation from "../../components/ModalConfirmation";
import NoData from "../../components/NoData";
import LottiePausAstronot from "../../components/LottiePausAstronot";
import PlaceHolderElement2 from "../../components/PlaceHolderElement2";

const StokBarangTambah = () => {

    const history = useHistory();

    const parameter = useParams();
    const idSK = parameter.id;

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [loadingDataStok, setLoadingDataStok] = React.useState(false);
    const [dataStok, setDataStok] = React.useState(null);
    const [dataSumJumlah, setDataSumJumlah] = React.useState(0);

    const mountedRef = React.useRef(true);

    const get_data = React.useCallback(() => {
        setLoadingDataStok(true);

        axios({
            method: 'post',
            url: BASE_URL + '/kelola_stok',
            data: {
                id_sk: idSK
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingDataStok(false);

            response.data.data_stok && setDataStok(response.data.data_stok['k1']);
            setDataSumJumlah(response.data.data_sum_jumlah[0].sum_jumlah ? response.data.data_sum_jumlah[0].sum_jumlah : 0);
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingDataStok(false);
            handleWarningModal(error.message)
        })
    }, [idSK])

    React.useEffect(() => {
        get_data();
    }, [get_data])

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [])

    const handleEditSK = (prmIdSK) => {
        history.push('/' + prefixUrl.owner + '/editstokbarangkategori/' + prmIdSK);
    }

    const handleTambahStok = () => {
        history.push('/' + prefixUrl.owner + '/stoktambah/' + idSK);
    }

    const refHapusPrmIdS = React.useRef('');
    const refHapusPrmNamaS = React.useRef('');
    const [modalHapusStok, setModalHapusStok] = React.useState(false);
    const [prmBtnLoading, setPrmBtnLoading] = React.useState(false);

    const [modalHapusSK, setModalHapusSK] = React.useState(false);
    const [btnLoadingHapusSK, setBtnLoadingHapusSK] = React.useState(false);
    const refIdSK = React.useRef('');

    const handleHapusStok = (prmIdS, prmNamaS) => {
        refHapusPrmIdS.current = prmIdS;
        refHapusPrmNamaS.current = prmNamaS;

        setModalHapusStok(true);
    }

    const doHapusStok = () => {
        setPrmBtnLoading(true);

        axios({
            method: 'post',
            url: BASE_URL + '/hapus_stok',
            data: {
                id_s: refHapusPrmIdS.current
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setPrmBtnLoading(false);

            response.data.flag_status === true && get_data();

            response.data.flag_status === false && handleWarningModal('Error : ' + response.data.pesan);

            setModalHapusStok(false);
        }).catch(error => {
            if (!mountedRef.current) return null;

            setPrmBtnLoading(false);
            handleWarningModal(error.message)
        })
    }

    const handleHapusSK = () => {
        setBtnLoadingHapusSK(true);

        axios({
            method: 'post',
            url: BASE_URL + '/hapus_stok_kategori',
            data: {
                id_sk: refIdSK.current
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusSK(false);
            setModalHapusSK(false);

            if (response.data.flag_status === true) {
                history.push('/' + prefixUrl.owner + '/stokbarang');
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.pesan)
            }

            if (response.data.flag_status === 'cannot-delete') {
                handleWarningModal('Kategori ini masih memiliki stok. Hapus stok tersebut terlebih dahulu untuk dapat menghapus kategori.')
            }

        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusSK(false);
            handleWarningModal(error.message)
        })
    }

    return (
        <React.Fragment>

            <Modal
                show={modalHapusStok}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="mb-3 fontweight600 f16">Konfirmasi</div>

                    <div>
                        Hapus {refHapusPrmNamaS.current} ?
                    </div>

                    <div className="flexJustifyAlignCenter mt-4">
                        <div>
                            <Button
                                variant="light"
                                size="sm"
                                className="px-3 mr-3"
                                onClick={() => setModalHapusStok(false)}
                            >
                                Kembali
                            </Button>
                        </div>

                        <div>
                            <ButtonLoading
                                prmLoading={prmBtnLoading}
                                btnClass="px-2"
                                btnVariant="info"
                                btnOnClick={doHapusStok}
                                caption="Ya, Hapus"
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop
                shadow={true}
                titleNavTop="Kelola Stok"
                prmBackButton={true}
                prmOnBack={`/${prefixUrl.owner}/stokbarang`}
            />

            <Layout titleTab="Kelola Stok">
                {/* alias tambahstokbarang/id_sk */}
                <Row>

                    {
                        loadingDataStok &&
                        <Col xs={12} className="mt-3">
                            <PlaceHolderElement2 prmMT="" prmPX="" />
                        </Col>
                    }

                    {!loadingDataStok && dataStok &&
                        <Col xs={12} className="mt-3">

                            {parseInt(dataSumJumlah) < parseInt(dataStok[0].stok_minimal) &&
                                <div className="mt-2 f13 alert alert-warning text-center">
                                    <span className="fa fa-exclamation-circle" /> Stok kurang dari batas minimal stok.
                                </div>
                            }

                            {Object.keys(dataStok).map((key) => {
                                return (
                                    <div key={key}>
                                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium`}>
                                            <div className="fontweight600 color636363 mb-2">
                                                {dataStok[key].nama_sk}
                                            </div>

                                            <div className="color636363">
                                                <div>
                                                    <span className="f13 fontweight500 mr-1">
                                                        {dataSumJumlah}
                                                    </span>

                                                    <span className="f13">Stok</span>

                                                    <span className="mx-3">&#8901;</span>

                                                    <span>
                                                        Minimal Stok {dataStok[key].stok_minimal}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="flex colorTheme mt-3">
                                                <Button
                                                    variant="light"
                                                    size="sm"
                                                    className="btnXsOverride mr-3 px-3"
                                                    onClick={() => handleEditSK(dataStok[key].id_sk)}
                                                >
                                                    Edit
                                                </Button>

                                                <Button
                                                    variant="light"
                                                    size="sm"
                                                    className="btnXsOverride px-3"
                                                    onClick={() => {
                                                        refIdSK.current = dataStok[key].id_sk;
                                                        setModalHapusSK(true);
                                                    }}
                                                >
                                                    Hapus
                                                </Button>
                                            </div>
                                        </Card>

                                        <div className="mt-4 text-right">
                                            <div>
                                                <Button
                                                    variant="info"
                                                    size="sm"
                                                    className="btnXsOverride"
                                                    onClick={handleTambahStok}
                                                >
                                                    <span className="fa fa-plus-circle" /> Tambah Stok
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            {dataStok[key]['b'] ?
                                                <Masonry
                                                    breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 2 : 3) : 4) : 5) : 5}`}
                                                    className="my-masonry-grid-12"
                                                    columnClassName="my-masonry-grid_column-12"
                                                >
                                                    {Object.keys(dataStok[key]['b']).map((subKey) => {
                                                        return (
                                                            <div
                                                                key={subKey}
                                                                className="shadow-sm flexRowJustifyAlignCenter brSoftMedium wordBreakAll"
                                                                style={{ borderRadius: '6px' }}
                                                            >
                                                                <div
                                                                    className="pt-3 pb-2 px-3"
                                                                    style={{
                                                                        flex: 1,
                                                                        backgroundColor: '#EFEFEF',
                                                                        width: '100%',
                                                                        borderRadius: '6px 6px 0 0',
                                                                        borderBottom: '1px solid #eee'
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="f13 color808080"
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <div className="colorTheme fontweight600">
                                                                            {dataStok[key]['b'][subKey].nama_s}
                                                                        </div>

                                                                        <div className="f13">
                                                                            {dataStok[key]['b'][subKey].merk} {dataStok[key]['b'][subKey].tipe}
                                                                        </div>

                                                                        <div className="f13">
                                                                            SN {dataStok[key]['b'][subKey].serial_number}
                                                                        </div>

                                                                        <div className="f13 mt-2">
                                                                            {dataStok[key]['b'][subKey].supplier}
                                                                        </div>

                                                                        <div className="f13">
                                                                            Beli {sqlDateToHumanDate(dataStok[key]['b'][subKey].tanggal_beli)}
                                                                        </div>

                                                                        <div className="f13">
                                                                            Garansi {dataStok[key]['b'][subKey].masa_garansi} Bulan
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="py-2 px-3 f13 color808080 bgWhite"
                                                                    style={{ width: '100%', borderRadius: '0 0 6px 6px' }}
                                                                >
                                                                    <div>
                                                                        <NumberFormat
                                                                            value={dataStok[key]['b'][subKey].harga_beli}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            prefix={'Rp '}
                                                                        />
                                                                    </div>

                                                                    <div className="flexJustifyBeetwenAlignTop">
                                                                        <div>
                                                                            Stok {dataStok[key]['b'][subKey].jumlah}
                                                                        </div>
                                                                        <div>
                                                                            {Number(dataStok[key]['b'][subKey].is_original) ? 'Ori' : 'Non Ori'}
                                                                        </div>
                                                                    </div>

                                                                    <div className="flexJustifyBeetwenAlignCenter mt-1">
                                                                        <div
                                                                            onClick={() => {
                                                                                history.push('/' + prefixUrl.owner + '/stokedit/' + idSK + '/' + dataStok[key]['b'][subKey].id_s);
                                                                            }}
                                                                            className="colorTheme cp"
                                                                        >
                                                                            Edit
                                                                        </div>

                                                                        <div
                                                                            onClick={() => handleHapusStok(dataStok[key]['b'][subKey].id_s, dataStok[key]['b'][subKey].nama_s)}
                                                                            className="colorTheme cp"
                                                                        >
                                                                            Hapus
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </Masonry>
                                                :
                                                <div className="flexRowJustifyAlignCenter">
                                                    <LottiePausAstronot caption="Belum ada stok item." />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </Col>
                    }

                    {!loadingDataStok && !dataStok &&
                        <Col xs={12} className="mt-3">
                            <NoData />
                        </Col>
                    }
                </Row>
            </Layout>

            <ModalConfirmation
                showModal={modalHapusSK}
                hideModal={() => setModalHapusSK(false)}
                title="Konfirmasi"
                bodyCaption="Hapus Kategori Ini ?"
                btnCloseClass="px-3"
                btnCloseCaption="Close"
                bLPrm={btnLoadingHapusSK}
                bLBtnClass="ml-3 px-3"
                bLOnClick={handleHapusSK}
                bLCaption="Hapus"
            />

        </React.Fragment >
    );
}

export default StokBarangTambah;
