import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingStaticCenter = () => {

    return (
        <div className="flexJustifyAlignCenter">
            <span className="alert alert-warning py-2 px-3 rounded-lg">
                <Spinner animation="border" role="status" size="sm">
                    <span className="sr-only">Loading...</span>
                </Spinner>
                <span className="f14 ml-2">Loading...</span>
            </span>
        </div>
    );
}

export default LoadingStaticCenter;
