import React from 'react';
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Col, Nav } from 'react-bootstrap';
import ScrollContainer from 'react-indiana-drag-scroll';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/teknisi/NavbarTop";
import ProsesNota from "../../components/ProsesNota";
import ProsesSatu from "../../components/ProsesSatu";
import ProsesDua from "../../components/ProsesDua";
import ProsesTiga from "../../components/teknisi/ProsesTiga";
import { BASE_URL, batasFluid } from "../../utils/globalVar";
import { deko } from "../../utils/globalFunc";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import PlaceHolderProses from '../../components/PlaceHolderProses';
import '../../styles/proses.scss';

const Proses = () => {
    // ! Teknisi

    // 0 = proses buat nota
    // 1 = proses antri, belum start pengerjaan oleh teknisi
    // 2 = proses pengerjaan oleh teknisi
    // 3 = selesai pengerjaan oleh teknisi, proses qc
    // 4 = selesai qc, siap ambil
    // 5 = sudah ambil

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    const parameter = useParams();
    const [prmNav, setPrmNav] = React.useState(parameter.nav);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [prmLoadInit, setPrmLoadInit] = React.useState(false);

    const [heightNavTop, setHeightNavTop] = React.useState(0);
    const [heightNavProsesScroll, setHeightNavProsesScroll] = React.useState(0);

    const [compProsesNota, setCompProsesNota] = React.useState(prmNav ? prmNav === 'prosesNota' ? true : false : true);
    const [countProsesNota, setCountProsesNota] = React.useState(0);

    const [compProsesSatu, setCompProsesSatu] = React.useState(prmNav ? prmNav === 'prosesSatu' ? true : false : false);
    const [countProsesSatu, setCountProsesSatu] = React.useState(0);

    const [compProsesDua, setCompProsesDua] = React.useState(prmNav ? prmNav === 'prosesDua' ? true : false : false);
    const [countProsesDua, setCountProsesDua] = React.useState(0);

    const [compProsesTiga, setCompProsesTiga] = React.useState(prmNav ? prmNav === 'prosesTiga' ? true : false : false);
    const [countProsesTiga, setCountProsesTiga] = React.useState(0);

    const mountedRef = React.useRef(true);

    const handleCompProses = (prm) => {
        setPrmNav(prm);

        if (prm === 'prosesSatu') {
            setCompProsesSatu(true);
            setCompProsesDua(false);
            setCompProsesNota(false);
            setCompProsesTiga(false);
        } else if (prm === 'prosesDua') {
            setCompProsesSatu(false);
            setCompProsesDua(true);
            setCompProsesNota(false);
            setCompProsesTiga(false);
        } else if (prm === 'prosesNota') {
            setCompProsesSatu(false);
            setCompProsesDua(false);
            setCompProsesNota(true);
            setCompProsesTiga(false);
        } else if (prm === 'prosesTiga') {
            setCompProsesSatu(false);
            setCompProsesDua(false);
            setCompProsesNota(false);
            setCompProsesTiga(true);
        }
    }

    React.useEffect(() => {
        setPrmLoadInit(true);

        axios({
            method: 'post',
            url: BASE_URL + '/all_proses',
            data: {
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setPrmLoadInit(false);

            setCountProsesNota(response.data.proses_nota);
            setCountProsesSatu(response.data.proses_antri);
            setCountProsesDua(response.data.proses_pengerjaan);
            setCountProsesTiga(response.data.proses_qc);
        }).catch(error => {
            if (!mountedRef.current) return null;

            setPrmLoadInit(false);
            handleWarningModal('Warning : ' + error.message)
        })

        document.querySelector(".containerLuarTopNavbar") && mountedRef.current && setHeightNavTop(document.querySelector(".containerLuarTopNavbar").clientHeight);

        document.querySelector(".navProsesScrollHeight") && mountedRef.current && setHeightNavProsesScroll(document.querySelector(".navProsesScrollHeight").clientHeight);

        document.body.style.backgroundColor = "#FAF9F9";

        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [reduxLogin.syswebappi]);

    React.useEffect(() => {
        if (!prmLoadInit && prmNav) {
            const x = document.querySelector('.' + prmNav) ? document.querySelector('.' + prmNav).offsetLeft - 90 : 0;

            if (document.querySelector(".navProsesScroll")) {
                document.querySelector(".navProsesScroll").scrollTo({ left: x, behavior: 'smooth' });
            }
        }
    }, [prmLoadInit, prmNav]);

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop shadow={false} titleNavTop="Proses" />

            <Layout titleTab="Proses">
                <Row>

                    {!prmLoadInit &&
                        <Col
                            xs={12}
                            className={`px-3 pt-3 navProsesScrollHeight`}
                            style={{
                                position: 'sticky',
                                top: `${heightNavTop}px`,
                                zIndex: '3',
                                backgroundColor: '#fff',
                                borderBottom: '1px solid #eee'
                            }}
                        >
                            <ScrollContainer className="navProsesScroll">
                                <Nav
                                    defaultActiveKey={`${prmNav ? prmNav : 'prosesNota'}`}
                                    onSelect={(selectedKey) => handleCompProses(selectedKey)}
                                    className={`navRelContainer ${reduxWindowWidth > batasFluid ? '' : (prmNav ? '' : 'animateGeserKiriKanan')}`}
                                >
                                    <Nav.Item className="mr-1 prosesNota">
                                        <Nav.Link eventKey="prosesNota" className="mr-2">
                                            <div className="flex px-2">
                                                <div className="mr-2">Nota</div>
                                                <div
                                                    className="flexJustifyAlignCenter dotCount"
                                                    style={{
                                                        borderRadius: '50%',
                                                        padding: '4px',
                                                        fontSize: '9px',
                                                        width: '20px',
                                                        height: '20px'
                                                    }}
                                                >{countProsesNota}</div>
                                            </div>
                                            <div><hr className="hrCaption" /></div>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className="mr-1 prosesSatu">
                                        <Nav.Link eventKey="prosesSatu" className="mr-2">
                                            <div className="flex px-2">
                                                <div className="mr-2">Antrian</div>
                                                <div
                                                    className="flexJustifyAlignCenter dotCount"
                                                    style={{
                                                        borderRadius: '50%',
                                                        padding: '4px',
                                                        fontSize: '9px',
                                                        width: '20px',
                                                        height: '20px'
                                                    }}
                                                >{countProsesSatu}</div>
                                            </div>
                                            <div><hr className="hrCaption" /></div>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className="mr-1 prosesDua">
                                        <Nav.Link eventKey="prosesDua" className="mr-2">
                                            <div className="flex px-2">
                                                <div className="mr-2">Pengerjaan</div>
                                                <div
                                                    className="flexJustifyAlignCenter dotCount"
                                                    style={{
                                                        borderRadius: '50%',
                                                        padding: '4px',
                                                        fontSize: '9px',
                                                        width: '20px',
                                                        height: '20px'
                                                    }}
                                                >
                                                    {countProsesDua}
                                                </div>
                                            </div>
                                            <div><hr className="hrCaption" /></div>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className="mr-1 prosesTiga">
                                        <Nav.Link eventKey="prosesTiga" className="mr-2">
                                            <div className="flex px-2">
                                                <div className="mr-2">Menunggu QC</div>
                                                <div
                                                    className="flexJustifyAlignCenter dotCount"
                                                    style={{
                                                        borderRadius: '50%',
                                                        padding: '4px',
                                                        fontSize: '9px',
                                                        width: '20px',
                                                        height: '20px'
                                                    }}
                                                >
                                                    {countProsesTiga}
                                                </div>
                                            </div>
                                            <div><hr className="hrCaption" /></div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>

                            </ScrollContainer>
                        </Col>
                    }

                    {
                        prmLoadInit && <PlaceHolderProses prmHeight={heightNavTop} />
                    }

                    {
                        compProsesNota && !prmLoadInit &&
                        <ProsesNota heightNavTop={heightNavTop} heightNavProsesScroll={heightNavProsesScroll} prmLoadingProses={prmLoadInit} />
                    }

                    {
                        compProsesSatu && !prmLoadInit &&
                        <ProsesSatu heightNavTop={heightNavTop} heightNavProsesScroll={heightNavProsesScroll} />
                    }

                    {
                        compProsesDua && !prmLoadInit &&
                        <ProsesDua heightNavTop={heightNavTop} heightNavProsesScroll={heightNavProsesScroll} />
                    }

                    {
                        compProsesTiga && !prmLoadInit &&
                        <ProsesTiga heightNavTop={heightNavTop} heightNavProsesScroll={heightNavProsesScroll} />
                    }
                </Row>
            </Layout>

        </React.Fragment >
    );
}

export default Proses;
