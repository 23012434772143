import React from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import 'font-awesome/css/font-awesome.min.css';
import { Container, Row, Col, Image, Card } from 'react-bootstrap';

import { deko } from "../utils/globalFunc";
import ppDummy from "../assets/images/system/defaultAvatar.png";
import { is_login } from "../redux/reduxLogin/action";
import DotMenu from "./DotMenu";
import { brand, menuCaptionIcon, prefixUrl, widthLG, widthMD, widthSM, widthXS, levelUser } from '../utils/globalVar';
import '../styles/sideMenu.scss';

const SideMenuPackage = ({ sideMenuState, closeSideMenu, clearStateSideMenu }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    React.useEffect(() => {
        sideMenuState === 'hide-menu' && setTimeout(clearStateSideMenu, 300);
    }, [sideMenuState, clearStateSideMenu])

    return (
        <React.Fragment>

            <div
                onClick={closeSideMenu}
                className={`${sideMenuState === 'show-menu' ? 'showModalSideMenu' : 'hideModalSideMenu'} modalSideMenu`}
            />

            <div
                className={`boxMenu ${sideMenuState}`}
                style={{ minWidth: reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? '80%' : '50%') : '40%') : '30%') : '30%' }}
            >
                <div className={`boxMenu2 bgWhite`}>
                    <Container>
                        <Row>
                            <Col xs={12} className="px-0">

                                <div className="flexAlignCenter py-2 mb-3 pr-3 ">
                                    <div className="mr-2 cp" onClick={closeSideMenu}>
                                        <DotMenu bgDot="bgSoftDark" />
                                    </div>

                                    <div className="colorSoftDark bold f18 wordBreakAll">{brand.nama}</div>
                                </div>

                                <Card className="bgSoft color636363 brSoftMedium p-2 mx-3 mb-4">
                                    <div className="flexAlignCenter">
                                        <div className="mr-3">
                                            <Image
                                                src={ppDummy}
                                                roundedCircle
                                                className="fotoSideBar"
                                            />
                                        </div>

                                        <div>
                                            <div className="pr-3 bold wordBreakAll">
                                                {reduxLogin.syswebappn ? deko(reduxLogin.syswebappn) : 'No Name'}
                                            </div>

                                            {/* // ! Perlu login ulang untuk update nama */}

                                            <div className="f13 wordBreakAll color808080">
                                                {reduxLogin.syswebappj ? (deko(reduxLogin.syswebappj) === levelUser.owner ? 'Super User' : '') : ''}
                                            </div>
                                        </div>
                                    </div>
                                </Card>

                                <div className="pb-5">
                                    <NavLink
                                        to="/"
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menu1.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menu1.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/pretransaksi`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menu2.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menu2.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/pencariancepat`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menuPencarianCepat.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menuPencarianCepat.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/${prefixUrl.owner}/proses`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menu6.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menu6.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/precetakdokumen`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menuCetakDokumen.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menuCetakDokumen.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/${prefixUrl.owner}/precustomer`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menu3.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menu3.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/kategoribarang`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menuKategoriBarang.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menuKategoriBarang.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/formkelengkapan`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menu9.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menu9.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/${prefixUrl.owner}/stokbarang`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span
                                                    className={`${menuCaptionIcon.menu10.icon} iconSizeSideBar`}
                                                />
                                            </div>
                                            <div className="captionMenuSideBar">
                                                {menuCaptionIcon.menu10.caption}
                                            </div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/${prefixUrl.owner}/diskon`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menuDiskon.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menuDiskon.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/${prefixUrl.owner}/cekserialnumber`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menuCekSerialNumber.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menuCekSerialNumber.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/${prefixUrl.owner}/anggaranbelanjabarang`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menuAnggaranBelanjaBarang.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menuAnggaranBelanjaBarang.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/${prefixUrl.owner}/pendapatan`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menuPendapatan.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menuPendapatan.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/${prefixUrl.owner}/penilaiankaryawan`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menuPenilaianKaryawan.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menuPenilaianKaryawan.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/${prefixUrl.owner}/projectcancel`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menuProjectCancel.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menuProjectCancel.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/${prefixUrl.owner}/kelolaakunuser`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menuKelolaAkunUser.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menuKelolaAkunUser.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/${prefixUrl.owner}/report`}
                                        onClick={closeSideMenu}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menuReport.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">{menuCaptionIcon.menuReport.caption}</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to="/signout"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(is_login(null));
                                            history.push('/login');
                                        }}
                                        activeClassName="linkMenuSideBarActive"
                                        className="cleanLink linkMenuSideBar"
                                        exact
                                    >
                                        <div className="menuItemsSideBar">
                                            <div className="circleIconSideBar">
                                                <span className={`${menuCaptionIcon.menu8.icon} iconSizeSideBar`} />
                                            </div>
                                            <div className="captionMenuSideBar">
                                                {menuCaptionIcon.menu8.caption}
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SideMenuPackage