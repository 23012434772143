import React from 'react';
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Form, Modal, Button } from 'react-bootstrap';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import { BASE_URL, prefixUrl } from "../../utils/globalVar";
import { VFCannotNull, VFNoHpFormat, removeStrip } from "../../utils/globalFunc";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ButtonLoading from "../../components/ButtonLoading";
import NoData from "../../components/NoData";
import ModalConfirmation from "../../components/ModalConfirmation";
import PlaceHolderElement2 from "../../components/PlaceHolderElement2";

const Customer = () => {

    const parameter = useParams();
    const idCustomer = parameter.id;

    const history = useHistory();

    const [listKategoriCustomer, setListKategoriCustomer] = React.useState(null);
    const [warningKategoriCustomer, setWarningKategoriCustomer] = React.useState('');
    const [paramSuccessModal, setParamSuccessModal] = React.useState(false);
    const [btnLoadSubmit, setBtnLoadSubmit] = React.useState(false);

    const [modalHapusCust, setModalHapusCust] = React.useState(false);
    const [btnLoadigHapusCust, setBtnLoadigHapusCust] = React.useState(false);

    const initDataForm = {
        prm: true,
        nama: '',
        kategori: '0',
        noHp: '',
        alamat: '',
        gender: '1'
    }

    const [stateInputan, setStateInputan] = React.useState(initDataForm);
    const [loadingDataInit, setLoadingDataInit] = React.useState(false);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const mountedRef = React.useRef(true);

    const getKategoriCustomer = React.useCallback(() => {
        setWarningKategoriCustomer('')

        axios({
            method: 'post',
            url: BASE_URL + '/get_kategori_customer'
        }).then((response) => {
            if (!mountedRef.current) return null;

            if (response.data === null) {
                setWarningKategoriCustomer('(Data Kategori Customer tidak ada)')
            }
            else {
                setListKategoriCustomer(response.data)
            }

        }).catch(error => {
            if (!mountedRef.current) return null;

            handleWarningModal(error.message)
        })
    }, [])

    React.useEffect(() => {
        setLoadingDataInit(true);

        getKategoriCustomer();

        axios({
            method: 'post',
            url: BASE_URL + '/get_data_customer',
            data: {
                id_customer: idCustomer
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingDataInit(false);

            if (response.data) {
                const setDataCust = {
                    prm: true,
                    nama: response.data[0].nama,
                    kategori: response.data[0].id_kategori_customer,
                    noHp: response.data[0].no_hp,
                    alamat: response.data[0].alamat,
                    gender: response.data[0].gender
                }

                setStateInputan(setDataCust);

                if (document.getElementById("inputKategoriCust").selectedOptions[0].getAttribute("rel") === 'perusahaan') {
                    document.getElementById('inputGenderCustBaru').disabled = true;
                }
            } else {
                setStateInputan(s => ({ ...s, prm: false }));
            }

        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingDataInit(false);
            handleWarningModal(error.message)
        })
    }, [idCustomer, getKategoriCustomer])

    const handleSetState = (event) => {
        let nam = event.target.name;
        let val = event.target.value;

        let relSelected = '';

        if (nam === 'kategori') {
            relSelected = event.target.selectedOptions[0].getAttribute("rel");
        }

        if (nam === 'kategori' && relSelected === 'perusahaan') {
            setStateInputan({ ...stateInputan, [nam]: val, gender: '3' });
            document.getElementById('inputGenderCustBaru').disabled = true;
        } else {
            setStateInputan({ ...stateInputan, [nam]: val });
            document.getElementById('inputGenderCustBaru').disabled = false;
        }
    }

    const handleSubmitForm = () => {
        if (VFCannotNull(stateInputan.nama)) {
            document.querySelector("#inputNamaCustBaru").focus();
            handleWarningModal('Nama belum diisi.')
        } else if (VFNoHpFormat(removeStrip(stateInputan.noHp))) {
            document.querySelector("#inputHPCustBaru").focus();
            handleWarningModal('Format No HP (+62, 62, 08) minimum 10 - 13 digit yang terdiri dari angka dan atau tanda plus.')
        } else if (VFCannotNull(stateInputan.noHp)) {
            document.querySelector("#inputHPCustBaru").focus();
            handleWarningModal('No HP belum diisi.')
        } else if (stateInputan.kategori === '0') {
            document.querySelector("#inputKategoriCust").focus();
            handleWarningModal('Kategori belum dipilih.')
        } else if (VFCannotNull(stateInputan.alamat)) {
            document.querySelector("#inputAlamatCustBaru").focus();
            handleWarningModal('Alamat belum diisi.')
        } else {
            setBtnLoadSubmit(true);

            axios({
                method: 'post',
                url: BASE_URL + '/update_data_customer',
                data: {
                    id_customer: idCustomer,
                    nama: stateInputan.nama,
                    kategori: stateInputan.kategori,
                    no_hp: removeStrip(stateInputan.noHp),
                    alamat: stateInputan.alamat,
                    gender: stateInputan.gender
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadSubmit(false);

                if (response.data && response.data.flag_status === true) {
                    const setDataCust = {
                        prm: true,
                        nama: response.data.result[0].nama,
                        kategori: response.data.result[0].id_kategori_customer,
                        noHp: response.data.result[0].no_hp,
                        alamat: response.data.result[0].alamat,
                        gender: response.data.result[0].gender
                    }

                    setStateInputan(setDataCust);
                    setParamSuccessModal(true);

                    if (document.getElementById("inputKategoriCust").selectedOptions[0].getAttribute("rel") === 'perusahaan') {
                        document.getElementById('inputGenderCustBaru').disabled = true;
                    }
                }

                if (response.data && response.data.flag_status === false) {
                    handleWarningModal('Error : ' + response.data.result)
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadSubmit(false);
                handleWarningModal(error.message)
            })
        }
    }

    const handleHapusCust = () => {
        setBtnLoadigHapusCust(true);

        axios({
            method: 'post',
            url: BASE_URL + '/hapus_customer',
            data: {
                id_customer: idCustomer
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadigHapusCust(false);
            setModalHapusCust(false);

            if (response.data.flag_status === 'cannot-delete') {
                handleWarningModal('Customer tidak dapat dihapus karena customer tersebut memiliki data transaksi.');
            }

            if (response.data.flag_status === true) {
                history.push('/' + prefixUrl.owner + '/precustomer');
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.error)
            }

        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadigHapusCust(false);
            handleWarningModal(error.message)
        })
    }

    React.useEffect(() => {
        return () => mountedRef.current = false;
    }, []);

    return (
        <React.Fragment>

            <Modal
                show={paramSuccessModal}
                size="sm"
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="text-center f16 mb-4 mt-1">
                        <div>Berhasil Update Data</div>
                    </div>

                    <div className="text-center mb-4">
                        <span className="fa fa-check-circle colorTheme f50" style={{ color: '#69ceb8' }} />
                    </div>

                    <div className="text-center">
                        <Button
                            variant="light"
                            size="sm"
                            onClick={() => setParamSuccessModal(false)}
                        >
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            <NavbarTop
                shadow={true}
                titleNavTop="Kelola Customer"
                prmBackButton={true}
                prmOnBack={`/${prefixUrl.owner}/precustomer`}
            />

            <Layout titleTab="Kelola Customer">

                <Row>

                    {loadingDataInit &&
                        <Col xs={12} className="mt-4">
                            <PlaceHolderElement2 prmMT="" prmPX="" />
                        </Col>
                    }

                    {!loadingDataInit && stateInputan.prm &&
                        <React.Fragment>
                            <Col xs={12} className="mt-4">

                                <ModalAlertWarning
                                    showWarningModal={warningModal}
                                    hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                                    paramText={warningText}
                                />

                                <Form>
                                    <Form.Group controlId="inputNamaCustBaru">
                                        <Form.Label>
                                            Nama Customer
                                        </Form.Label>
                                        <Form.Control
                                            name="nama"
                                            size="sm"
                                            type="text"
                                            placeholder="Nama"
                                            value={stateInputan.nama}
                                            onChange={handleSetState}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="inputHPCustBaru">
                                        <Form.Label>
                                            <span>No Handphone</span>
                                        </Form.Label>
                                        <Form.Control
                                            name="noHp"
                                            size="sm"
                                            type="text"
                                            placeholder="081234567890"
                                            value={stateInputan.noHp}
                                            onChange={handleSetState}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="inputKategoriCust">
                                        <Form.Label>
                                            Kategori
                                            {warningKategoriCustomer !== '' &&
                                                <span
                                                    onClick={() => handleWarningModal('Pastikan data Kategori Customer sudah dibuat !. Atau muat ulang halaman.')
                                                    }>
                                                    <span className="colorSoftRed f12"> {warningKategoriCustomer} &nbsp;&nbsp;</span>
                                                    <span className="fa fa-question-circle f16" />
                                                </span>
                                            }
                                        </Form.Label>
                                        <Form.Control
                                            custom
                                            name="kategori"
                                            as="select"
                                            size="sm"
                                            value={stateInputan.kategori}
                                            onChange={handleSetState}
                                        >
                                            <option value="0">Kategori Customer</option>
                                            {
                                                listKategoriCustomer !== null &&
                                                Object.keys(listKategoriCustomer).map((key) => {
                                                    return (
                                                        <option
                                                            key={'kcfcb21631728' + key}
                                                            value={listKategoriCustomer[key].id}
                                                            rel={listKategoriCustomer[key].slug}
                                                        >
                                                            {listKategoriCustomer[key].nama}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="inputGenderCustBaru">
                                        <Form.Label>Gender</Form.Label>
                                        <Form.Control
                                            custom
                                            name="gender"
                                            as="select"
                                            size="sm"
                                            value={stateInputan.gender}
                                            onChange={handleSetState}
                                        >
                                            <option value="1">Pria</option>
                                            <option value="0">Wanita</option>
                                            <option value="3">Tanpa Gender</option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="inputAlamatCustBaru">
                                        <Form.Label>
                                            Alamat
                                        </Form.Label>
                                        <Form.Control
                                            name="alamat"
                                            size="sm"
                                            type="text"
                                            placeholder="Alamat"
                                            value={stateInputan.alamat}
                                            onChange={handleSetState}
                                        />
                                    </Form.Group>

                                    <div className="flexJustifyAlignCenter">
                                        <ButtonLoading
                                            prmLoading={btnLoadSubmit}
                                            btnClass="mt-3 px-3"
                                            btnVariant="info"
                                            btnOnClick={handleSubmitForm}
                                            caption="Update Data"
                                        />
                                    </div>
                                </Form>
                            </Col>

                            <Col xs={12} className="mt-4">
                                <div className="fontweight600 f15 borderTopEee pt-4 colorSoftRed">
                                    Hapus customer
                                </div>

                                <div className="mt-2">
                                    Hapus customer atas nama <span className="fontweightBold">{stateInputan.nama}</span> ?
                                </div>

                                <div className="mt-3">
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        className="btnXsOverride px-3"
                                        onClick={() => setModalHapusCust(true)}
                                    >
                                        Ya, Hapus Permanen
                                    </Button>
                                </div>
                            </Col>
                        </React.Fragment>
                    }

                    {
                        !loadingDataInit && !stateInputan.prm &&
                        <Col xs={12} className="mt-4">
                            <NoData />
                        </Col>
                    }
                </Row>
            </Layout>

            <ModalConfirmation
                showModal={modalHapusCust}
                hideModal={() => setModalHapusCust(false)}
                title="Konfirmasi"
                bodyCaption="Hapus permanen data customer ?"
                btnCloseClass="px-3"
                btnCloseCaption="Close"
                bLPrm={btnLoadigHapusCust}
                bLBtnClass="ml-3"
                bLOnClick={handleHapusCust}
                bLCaption="Ya, Hapus"
            />

        </React.Fragment>
    );
}

export default Customer;
