import { createStore, combineReducers } from "redux";
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

import { reducer as reduxLogin } from "./reduxLogin/reducer";
import { reducer as reduxWindowWidth } from "./reduxWindowWidth/reducer";

const allReducers = combineReducers({
    lumoslored: reduxLogin,
    lumoswinwid: reduxWindowWidth
});

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['lumoslored'],
    blacklist: ['lumoswinwid']
};

const persistedReducer = persistReducer(persistConfig, allReducers);

export const storeState = createStore(persistedReducer)
export const persistor = persistStore(storeState);