import React from 'react';
import { useSelector } from "react-redux";
import { Row, Col, Card } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import { widthLG, widthMD, widthSM, widthXS, batasFluid } from "../utils/globalVar";

const PlaceHolderElement5Flex = ({ prmMT, prmPX }) => {

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);

    const bgCard = '#f6f6f6';
    const bgLabel = '#f0f0f0';
    const borderColorCard = '1px solid #f0f0f0';

    const LabelPH = ({ prmWidth, prmClass }) => {
        return (
            <div
                className={`rounded-lg ${prmClass}`}
                style={{ width: prmWidth, backgroundColor: bgLabel }}
            >
                &nbsp;
            </div>
        )
    }

    const CardPH = ({ children }) => {
        return (
            <Card
                className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} mb-3 shadow-sm`}
                style={{
                    backgroundColor: bgCard,
                    border: borderColorCard
                }}
            >
                {children}
            </Card>
        )
    }

    const elemen = () => {
        let yu = [];

        const len = reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 2 : 2) : 4) : 4) : 4;

        for (let index = 0; index < len; index++) {
            yu[index] = <CardPH key={index + 'phe5f'}>
                <div className="width100">
                    <LabelPH prmWidth="40%" />

                    <LabelPH prmWidth="60%" prmClass="mt-1" />

                    <LabelPH prmWidth="40%" prmClass="mt-3" />

                    <LabelPH prmWidth="60%" prmClass="mt-1" />
                </div>
            </CardPH>
        }

        return yu;
    }

    return (
        <Row className={`${prmPX} mx-0`}>
            <Col xs={12} className={prmMT}>

                <div className="mb-3 colorAaa text-center">Mengambil Data..</div>

                <Masonry
                    breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 2 : 2) : 4) : 4) : 4}`}
                    className="my-masonry-grid-12"
                    columnClassName="my-masonry-grid_column-12"
                >
                    {elemen()}
                </Masonry>
            </Col>
        </Row>
    );
}

export default PlaceHolderElement5Flex;
