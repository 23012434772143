import React from "react";
import { Modal, Button } from 'react-bootstrap';
import ButtonLoading from "../components/ButtonLoading";

const ModalConfirmation = (
    {
        showModal,
        hideModal,
        title,
        bodyCaption,
        btnCloseClass,
        btnCloseCaption,
        bLPrm,
        bLBtnClass,
        bLOnClick,
        bLCaption,
        blVariant
    }
) => {
    return (
        <Modal
            show={showModal}
            onHide={hideModal}
            backdrop="static"
            keyboard={false}
            animation={false}
            centered
        >
            <Modal.Body>
                <div className="f16 fontweightBold mb-3">{title}</div>

                <div className="color636363" style={{ marginBottom: '2rem' }}>
                    {bodyCaption}
                </div>

                <div className="flexJustifyAlignCenter">
                    <Button
                        variant="light"
                        size="sm"
                        className={btnCloseClass}
                        onClick={hideModal}
                    >
                        {btnCloseCaption}
                    </Button>

                    <ButtonLoading
                        prmLoading={bLPrm}
                        btnClass={bLBtnClass}
                        btnVariant={blVariant ? blVariant : 'info'}
                        btnOnClick={bLOnClick}
                        caption={bLCaption}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalConfirmation;