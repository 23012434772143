import React from 'react';
import { brand } from "../utils/globalVar";
import { Container } from 'react-bootstrap';
import { useSelector } from "react-redux";

import { batasFluid } from '../utils/globalVar';

const Layout = ({ children, titleTab }) => {

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);

    React.useEffect(() => {
        document.title = `${titleTab} \u00B7 ${brand.nama} ${brand.tag}`;
    }, [titleTab])

    return (
        <React.Fragment>

            <Container fluid={reduxWindowWidth <= batasFluid ? true : false} className="pb-5">
                {children}
            </Container>

        </React.Fragment>
    );
}

export default Layout;
