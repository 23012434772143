import React from 'react';
import axios from "axios";
import { useSelector } from "react-redux";
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

import Layout from "../../components/Layout";
import NavbarTopOwner from "../../components/NavbarTop";
import NavbarTopTeknisi from "../../components/teknisi/NavbarTop";
import { BASE_URL, levelUser } from "../../utils/globalVar";
import { deko } from "../../utils/globalFunc";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ModalConfirmation from "../../components/ModalConfirmation";
import ButtonLoading from "../../components/ButtonLoading";
import LottiePausAstronot from "../../components/LottiePausAstronot";
import ModalInfoHint from "../../components/ModalInfoHint";
import PlaceHolderElement2 from "../../components/PlaceHolderElement2";

const KategoriBarang = () => {

    const reduxLogin = useSelector(data => data.lumoslored);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [loadingInit, setLoadingInit] = React.useState(false);
    const [dataKategoriBarang, setDataKategoriBarang] = React.useState(null);
    const [modalTambahKB, setModalTambahKB] = React.useState(false);
    const [btnLoadingTambahKB, setBtnLoadingTambahKB] = React.useState(false);

    const refNamaKB = React.useRef('');

    const [modalEditKB, setModalEditKB] = React.useState(false);
    const [btnLoadingEditKB, setBtnLoadingEditKB] = React.useState(false);
    const refNamaKBEdit = React.useRef('');
    const refIdKB = React.useRef('');

    const [modalHapusKB, setModalHapusKB] = React.useState(false);
    const [btnLoadingHapusKB, setBtnLoadingHapusKB] = React.useState(false);

    const [modalInfoHint, setModalInfoHint] = React.useState(false);

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        return () => {
            mountedRef.current = false;
        }
    }, []);

    React.useEffect(() => {
        setLoadingInit(true);

        axios({
            method: 'post',
            url: BASE_URL + '/get_kategori_barang',
            data: {
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);

            setDataKategoriBarang(response.data.data);

        }).catch(error => {
            if (!mountedRef.current) return null;
            setLoadingInit(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }, [reduxLogin.syswebappi]);

    const handleTambahKB = () => {
        refNamaKB.current.style.border = 'unset';

        if (!refNamaKB.current.value) {
            refNamaKB.current.style.border = '1px solid red';
            refNamaKB.current.focus();
        } else {
            setBtnLoadingTambahKB(true);

            axios({
                method: 'post',
                url: BASE_URL + '/tambah_kategori_barang',
                data: {
                    nama: refNamaKB.current.value,
                    id_user: deko(reduxLogin.syswebappi)
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingTambahKB(false);
                setModalTambahKB(false);

                if (response.data.flag_status === true) {
                    setDataKategoriBarang(response.data.pesan);
                }

                if (response.data.flag_status === false) {
                    handleWarningModal('Error : ' + response.data.pesan)
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingTambahKB(false);
                handleWarningModal('Warning : ' + error.message)
            });
        }
    }

    const handleEditKB = () => {
        refNamaKBEdit.current.style.border = 'unset';

        if (!refNamaKBEdit.current.value) {
            refNamaKBEdit.current.style.border = '1px solid red';
            refNamaKBEdit.current.focus();
        } else {
            setBtnLoadingEditKB(true);

            axios({
                method: 'post',
                url: BASE_URL + '/edit_kategori_barang',
                data: {
                    nama: refNamaKBEdit.current.value,
                    id: refIdKB.current,
                    id_user: deko(reduxLogin.syswebappi)
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingEditKB(false);
                setModalEditKB(false);

                if (response.data.flag_status === true) {
                    setDataKategoriBarang(response.data.pesan);
                }

                if (response.data.flag_status === false) {
                    handleWarningModal('Error : ' + response.data.pesan)
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingEditKB(false);
                handleWarningModal('Warning : ' + error.message)
            });
        }
    }

    const handleHapusKB = () => {
        setBtnLoadingHapusKB(true);

        axios({
            method: 'post',
            url: BASE_URL + '/hapus_kategori_barang',
            data: {
                id: refIdKB.current,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusKB(false);
            setModalHapusKB(false);

            if (response.data.flag_status === true) {
                setDataKategoriBarang(response.data.pesan);
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.pesan)
            }

            if (response.data.flag_status === 'cannot-delete') {
                handleWarningModal('Kategori tidak dapat dihapus. Terdapat transaksi yang menggunakan kategori barang ini.')
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusKB(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }

    const InfoHint = () => {
        return (
            <React.Fragment>
                <div className="alert alert-info py-3 px-3">
                    <div className="fontweight500">
                        Pengertian
                        <hr className="my-2" />
                    </div>

                    <div>
                        Kategori barang adalah barang-barang yang biasanya dibawa oleh customer untuk diperbaiki.
                    </div>

                    <div className="mt-3">
                        Contoh kategori barang misalnya Laptop, UPS, Televisi, PC Komputer, dll.
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>

            {deko(reduxLogin.syswebappj) === levelUser.owner && <NavbarTopOwner shadow={true} titleNavTop="Kategori Barang" />}
            {deko(reduxLogin.syswebappj) === levelUser.teknisi && <NavbarTopTeknisi shadow={true} titleNavTop="Kategori Barang" />}

            <Layout titleTab="Kategori Barang">
                <Row>
                    {
                        loadingInit &&
                        <Col xs={12} className="my-3">
                            <PlaceHolderElement2 prmMT="" prmPX="" />
                        </Col>
                    }

                    {
                        !loadingInit &&
                        <Col xs={12} className="mt-4 mb-3 text-right">
                            <Button
                                variant="info"
                                size="sm"
                                className="btnXsOverride"
                                onClick={() => setModalTambahKB(true)}
                            >
                                Tambah Kategori
                            </Button>
                        </Col>
                    }

                    {
                        !loadingInit && dataKategoriBarang &&
                        <Col xs={12} className="mb-5">
                            <div className="fontweight600 mb-1 color636363">Daftar Kategori Barang</div>
                            {Object.keys(dataKategoriBarang).map((key) => {
                                return (
                                    <div
                                        key={key + 'kb09809'}
                                        className="flexJustifyBeetwenAlignCenter py-3 borderBottomEee color808080"
                                    >
                                        <div className="flex1">
                                            <div>{dataKategoriBarang[key].nama}</div>

                                            <div className="mt-2">
                                                <span
                                                    className="colorTheme cp"
                                                    onClick={() => {
                                                        refNamaKBEdit.current = dataKategoriBarang[key].nama;
                                                        refIdKB.current = dataKategoriBarang[key].id;

                                                        setModalEditKB(true);
                                                    }}
                                                >
                                                    Edit
                                                </span>

                                                <span
                                                    className="colorTheme ml-4 cp"
                                                    onClick={() => {
                                                        refIdKB.current = dataKategoriBarang[key].id;
                                                        setModalHapusKB(true);
                                                    }}
                                                >
                                                    Hapus
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Col>
                    }

                    {
                        !loadingInit && !dataKategoriBarang &&
                        <Col xs={12} className="mb-3">
                            <div className="flexRowJustifyAlignCenter">
                                <LottiePausAstronot caption="Belum ada kategori barang. Klik tombol Tambah Kategori untuk menambahkan data kategori barang." />
                            </div>
                        </Col>
                    }
                </Row>
            </Layout>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <Modal
                show={modalTambahKB}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="fontweight600 pb-3">
                        Buat Kategori Barang
                    </div>

                    <div className="mt-3">
                        <Form.Control
                            ref={refNamaKB}
                            size="sm"
                            type="text"
                            placeholder="Nama Kategori Barang"
                        />

                        <div className="mt-5 text-center">
                            <Button
                                variant="light"
                                size="sm"
                                className="ml-2 px-3"
                                onClick={() => setModalTambahKB(false)}
                            >
                                Close
                            </Button>

                            <ButtonLoading
                                prmLoading={btnLoadingTambahKB}
                                btnClass="px-3 ml-3"
                                btnVariant="info"
                                btnOnClick={handleTambahKB}
                                caption="Simpan"
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={modalEditKB}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="fontweight600 pb-3">
                        Edit Kategori Barang
                    </div>

                    <div className="mt-3">
                        <Form.Control
                            ref={refNamaKBEdit}
                            size="sm"
                            type="text"
                            placeholder="Nama Kategori Barang"
                            defaultValue={refNamaKBEdit.current}
                        />

                        <div className="mt-5 text-center">
                            <Button
                                variant="light"
                                size="sm"
                                className="ml-2 px-3"
                                onClick={() => setModalEditKB(false)}
                            >
                                Close
                            </Button>

                            <ButtonLoading
                                prmLoading={btnLoadingEditKB}
                                btnClass="px-3 ml-3"
                                btnVariant="info"
                                btnOnClick={handleEditKB}
                                caption="Simpan"
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <ModalConfirmation
                showModal={modalHapusKB}
                hideModal={() => setModalHapusKB(false)}
                title="Konfirmasi"
                bodyCaption="Hapus kategori ini ?"
                btnCloseClass="px-3"
                btnCloseCaption="Close"
                bLPrm={btnLoadingHapusKB}
                bLBtnClass="ml-3 px-3"
                bLOnClick={handleHapusKB}
                bLCaption="Hapus"
            />

            {
                !loadingInit &&
                <span
                    className="alert alert-secondary cp py-2 px-3"
                    style={{ position: 'fixed', bottom: '0', right: '10px' }}
                    onClick={() => setModalInfoHint(true)}
                >
                    <span className="fa fa-info-circle" /> Info
                </span>
            }

            <ModalInfoHint
                showWarningModal={modalInfoHint}
                hideWarningModal={() => setModalInfoHint(false)}
                paramText={InfoHint()}
            />

        </React.Fragment >
    );
}

export default KategoriBarang;
