import React from 'react';
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import 'font-awesome/css/font-awesome.min.css';

import Layout from "../components/Layout";
import NavbarTop from "../components/NavbarTop";
import CustomerAvatar from "../components/CustomerAvatar";
import { sqlDateToLongHumanDate, deko } from "../utils/globalFunc";
import { widthLG, widthMD, widthSM, widthXS, BASE_URL_DUA, batasFluid, prefixUrl } from "../utils/globalVar";

import ModalAlertWarning from '../components/ModalAlertWarning';
import ButtonLoading from '../components/ButtonLoading';
import LottiePausAstronot from '../components/LottiePausAstronot';
import BarcodeScanner from '../components/BarcodeScanner';

const CekSerialNumber = () => {

    const history = useHistory();
    const reduxLogin = useSelector(data => data.lumoslored);
    const reduxWindowWidth = useSelector(data => data.lumoswinwid);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [heightNavTop, setHeightNavTop] = React.useState(0);

    const [data, setData] = React.useState(null);
    const limitData = 1;
    const refOffsetData = React.useRef(0);
    const [inputSearch, setInputSearch] = React.useState('');
    const [hasMore, setHasMore] = React.useState(false);
    const [btnLoadingMore, setBtnLoadingMore] = React.useState(false);
    const [loadingTyping, setLoadingTyping] = React.useState(false);
    const [tidakAdaHasil, setTidakAdaHasil] = React.useState(false);

    const [modalBarcodeScanner, setModalBarcodeScanner] = React.useState(false);
    const handleScanBarcode = (err, result) => {
        if (result) {
            setInputSearch(result.text);
            setModalBarcodeScanner(false);
        }
    }

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";

        document.querySelector(".containerLuarTopNavbar") && mountedRef.current && setHeightNavTop(document.querySelector(".containerLuarTopNavbar").clientHeight);

        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, []);

    const moreData = () => {
        setBtnLoadingMore(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/cek_serial_number',
            data: {
                inputan: inputSearch,
                limit_data: limitData,
                offset_data: refOffsetData.current,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);

            data && response.data.data_where && [...data, ...response.data.data_where].length < response.data.data_where_all.length ? setHasMore(true) : setHasMore(false);

            data && response.data.data_where && setData([...data, ...response.data.data_where]);

            if (response.data.data_where) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);
            handleWarningModal('Warning : ' + error.message);
        })
    };


    // baas
    const getData = React.useCallback((dt) => {
        axios({
            method: 'post',
            url: BASE_URL_DUA + '/cek_serial_number',
            data: dt
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingTyping(false);

            response.data.data_where && response.data.data_where.length < response.data.data_where_all.length ? setHasMore(true) : setHasMore(false);

            if (response.data.data_where) {
                setData(response.data.data_where);
                setTidakAdaHasil(false);
            } else {
                setData(null);
                setTidakAdaHasil(true);
            }

            if (response.data.data_where) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;
            setLoadingTyping(false);
            setTidakAdaHasil(false);
            handleWarningModal('Warning : ' + error.message);
        })
    }, []);

    React.useEffect(() => {
        refOffsetData.current = 0;
        let handler = null;

        if (inputSearch) {
            inputSearch && setLoadingTyping(true);

            handler = setTimeout(() => {
                const dt = {
                    inputan: inputSearch,
                    limit_data: limitData,
                    offset_data: refOffsetData.current,
                    id_user: deko(reduxLogin.syswebappi)
                }

                getData(dt)
            }, 1000);
        } else {
            setLoadingTyping(false);
            setData(null);
            setTidakAdaHasil(false);
        }

        return () => clearTimeout(handler);
    }, [inputSearch, getData, reduxLogin.syswebappi]);
    // baas

    const handleDetil = (prmId, flag) => {
        if (flag === '2') {
            history.push('/working/' + prmId + '/cekserialnumbern');
        } else if (flag === '3') {
            history.push('/' + prefixUrl.owner + '/qc/' + prmId + '/cekserialnumbery');
        } else if (flag === '4') {
            history.push('/' + prefixUrl.owner + '/selesai/' + prmId + '/cekserialnumbery');
        } else if (flag === '5') {
            history.push('/' + prefixUrl.owner + '/sudahdiambil/' + prmId + '/cekserialnumbery');
        }
    }

    return (
        <React.Fragment>

            <BarcodeScanner
                modalBarcodeScanner={modalBarcodeScanner}
                onSken={(satu, dua) => handleScanBarcode(satu, dua)}
                closeModalScanBarcode={() => setModalBarcodeScanner(false)}
            />

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop shadow={false} titleNavTop="Cek Penggunaan Part" />

            <Layout titleTab="Cek Penggunaan Part">
                <Row>
                    <Col
                        xs={12}
                        className="pt-2 pb-3"
                        style={{
                            position: 'sticky',
                            top: `${heightNavTop}px`,
                            zIndex: '3',
                            backgroundColor: '#fff',
                            borderBottom: '1px solid #eee'
                        }}
                    >

                        <InputGroup>
                            <FormControl
                                size="sm"
                                type="text"
                                placeholder="Ketik Serial Number / Part / Merk"
                                value={inputSearch}
                                onChange={(e) => {
                                    setInputSearch(e.target.value);
                                }}
                                className="removeShadowOnFocus"
                                style={{ borderRight: '0 solid #eee' }}
                            />

                            <InputGroup.Append>
                                <InputGroup.Text id="ad-cbd-9890" className="bgColorForm">
                                    {loadingTyping &&
                                        <Spinner animation="border" size="sm" variant="info" />
                                    }

                                    <span className="fa fa-barcode ml-2" onClick={() => setModalBarcodeScanner(true)} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>

                    {!data && !loadingTyping && tidakAdaHasil &&
                        <Col xs={12}>
                            <div className="flexRowJustifyAlignCenter">
                                <LottiePausAstronot />
                            </div>
                        </Col>
                    }

                    {!loadingTyping && data &&
                        <Col xs={12} className="mt-3">
                            <Masonry
                                breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 1 : 2) : 2) : 3) : 3}`}
                                className="my-masonry-grid-12"
                                columnClassName="my-masonry-grid_column-12"
                            >
                                {
                                    Object.keys(data).map((key) => {
                                        return (
                                            <Card
                                                key={'pc_98798' + key}
                                                className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3 cp`}
                                                onClick={() => handleDetil(data[key].id_transaksi, data[key].flag_proses)}
                                            >
                                                <div style={{ width: '100%' }}>

                                                    <div className="flex">
                                                        <div className="mr-3">
                                                            <CustomerAvatar
                                                                prmSlug={data[key].slug_ck}
                                                                prmGender={data[key].gender}
                                                                ukuran={{ width: '40px', height: '40px' }}
                                                            />
                                                        </div>

                                                        <div>
                                                            <div className="bold color636363">
                                                                {data[key].nama}
                                                            </div>
                                                            <div className="mb-1 color808080">
                                                                {data[key].nama_cust_ktgr}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div><hr className="mt-2 mb-3" /></div>

                                                    <div className="f13">
                                                        <span className="fontweight500 mr-2 color636363">Serial Number</span>
                                                        <span className="color808080">{data[key].serial_number}</span>
                                                    </div>

                                                    <div className="f13">
                                                        <span className="fontweight500 mr-2 color636363">
                                                            Item
                                                        </span>
                                                        <span className="color808080">
                                                            {data[key].nama_item} {data[key].merk ? data[key].merk : ' '} {data[key].tipe ? data[key].tipe : ' '}
                                                        </span>
                                                    </div>

                                                    <div className="f13">
                                                        <span className="fontweight500 mr-2 color636363">
                                                            Masa Garansi
                                                        </span>
                                                        <span className="color808080">
                                                            {data[key].masa_garansi ? data[key].masa_garansi : '0'} Bulan
                                                        </span>
                                                    </div>

                                                    <div className="f13 mt-2">
                                                        <span className="fontweight500 mr-2 color636363">
                                                            No Nota
                                                        </span>
                                                        <span className="color808080">
                                                            {data[key].id_transaksi}
                                                        </span>
                                                    </div>

                                                    <div className="f13">
                                                        <span className="fontweight500 mr-2 color636363">
                                                            Tanggal Masuk
                                                        </span>
                                                        <span className="color808080">
                                                            {sqlDateToLongHumanDate(data[key].tanggal_masuk)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Card>
                                        )
                                    })
                                }
                            </Masonry>

                            <div className="mt-1">
                                {hasMore ?
                                    <div className="text-center">
                                        <ButtonLoading
                                            prmLoading={btnLoadingMore}
                                            btnClass="btnXsOverride"
                                            btnVariant="info"
                                            btnOnClick={moreData}
                                            caption="Load More"
                                        />
                                    </div>
                                    :
                                    <div className="text-center colorAaa">
                                        No More Data
                                    </div>
                                }
                            </div>
                        </Col>
                    }
                </Row>
            </Layout>

        </React.Fragment >
    );
}

export default CekSerialNumber;
