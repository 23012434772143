import React from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Calendar from 'react-calendar';
import 'font-awesome/css/font-awesome.min.css';
import { Row, Col, Card, Image, Form, Modal, Button } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import CustomerAvatar from "../../components/CustomerAvatar";
import soldering from "../../assets/images/system/soldering.png";
import { ROOT_BASE_URL, BASE_URL, BASE_URL_DUA, batasFluid, prefixUrl, metodeBayar, kategoriServis, reminderMaintenance, widthLG, widthMD, widthSM, widthXS, levelUser } from "../../utils/globalVar";
import { isoDateToSqlDate, sqlDateToLongHumanDate, dateTimeToHumanDateTime, formatNumberCanZero, formatNumberCanZeroMinus, handleCallBack, deko } from "../../utils/globalFunc";
import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ModalConfirmation from "../../components/ModalConfirmation";
import PlaceHolderElement1 from "../../components/PlaceHolderElement1";

const ProsesSudahDiambil = () => {

    const parameter = useParams();
    const idTransaksi = parameter.id;
    const callbackUrl = parameter.callbackUrl;

    const history = useHistory();

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [srcFoto, setSrcFoto] = React.useState('');
    const [modalZoomImage, setModalZoomImage] = React.useState(false);

    const [loadingDataProses, setLoadingDataProses] = React.useState(false);

    const [modalCalendarTglPelunasan, setModalCalendarTglPelunasan] = React.useState(false);

    const [modalPelunasan, setModalPelunasan] = React.useState(false);
    const [btnLoadingAmbil, setBtnLoadingAmbil] = React.useState(false);
    const [modalBerhasilPelunasan, setModalBerhasilPelunasan] = React.useState(false);

    const refMetodePelunasan = React.useRef('');
    const refIsLunas = React.useRef('0');

    const [resumeBiaya, setResumeBiaya] = React.useState({
        subTotal: '',
        diskon: '',
        total: ''
    });

    const [stateData, setStateData] = React.useState({
        dataPenggunaanStok: {},
        dataProses: null,
        dataKelengkapan: null,
        listFotoTransaksi: null,
        dataProgres: null,
        dataPenggunaanStokInclusion: null,
        dataTDBYR: null,
        dataDiskon: null,
        kategoriServis: '',
        reminderAt: '',
        isLunas: '0',
        tanggalAmbil: '',
        tanggalPelunasan: '',
        catatanSelesai: '',
        dataLabel: null,
        penerima: ''
    });

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [])

    React.useEffect(() => {
        setLoadingDataProses(true);

        axios({
            method: 'post',
            url: BASE_URL + '/proses_ending',
            data: {
                id_transaksi: idTransaksi,
                flag_proses: 5
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            document.body.style.backgroundColor = "#FAF9F9";
            setLoadingDataProses(false);

            if (response.data.data_proses) {
                setStateData(s => ({
                    ...s,
                    dataPenggunaanStok: response.data.data_penggunaan_stok,
                    dataProses: response.data.data_proses,
                    dataKelengkapan: response.data.data_kelengkapan,
                    listFotoTransaksi: response.data.data_foto_transaksi,
                    dataProgres: response.data.data_progres['k1'],
                    dataPenggunaanStokInclusion: response.data.data_penggunaan_stok_inclusion,
                    dataTDBYR: response.data.data_tdbyr,
                    dataDiskon: response.data.data_diskon,
                    kategoriServis: response.data.data_proses[0].kategori_servis ? response.data.data_proses[0].kategori_servis : '',
                    reminderAt: response.data.data_proses[0].reminder_at ? response.data.data_proses[0].reminder_at : '',
                    isLunas: response.data.data_proses[0].is_lunas ? response.data.data_proses[0].is_lunas : '0',
                    tanggalAmbil: response.data.data_proses[0].tanggal_ambil ? response.data.data_proses[0].tanggal_ambil : '',
                    tanggalPelunasan: response.data.data_proses[0].tanggal_pelunasan ? response.data.data_proses[0].tanggal_pelunasan : '',
                    catatanSelesai: response.data.data_proses[0].catatan_selesai ? response.data.data_proses[0].catatan_selesai : '',
                    dataLabel: response.data.data_label,
                    penerima: response.data.penerima
                }));

                handleSubTotalSummary(response.data.data_tdbyr, response.data.data_diskon, response.data.data_proses[0].dp);
            } else {
                setStateData(s => ({ ...s, dataProses: null }));
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingDataProses(false);
            handleWarningModal(error.message);
        })
    }, [idTransaksi]);

    const handleSubTotalSummary = (arrSTS, prmDataDiskon, prmDp) => {

        const resDp = prmDp ? Number(prmDp) : 0;
        let resDiskon = 0;

        if (arrSTS) {
            var st = 0;
            arrSTS.forEach(element => {
                st = st + Number(element.total ? element.total : 0);
            });

            resDiskon = prmDataDiskon ? (prmDataDiskon[0].flag === 'rupiah' ? Number(prmDataDiskon[0].jumlah) : (Number(prmDataDiskon[0].jumlah) / 100 * st).toFixed(0)) : 0;

            setResumeBiaya({
                subTotal: st,
                diskon: resDiskon,
                total: st - resDp - resDiskon
            })
        } else {
            resDiskon = prmDataDiskon ? (prmDataDiskon[0].flag === 'rupiah' ? Number(prmDataDiskon[0].jumlah) : Number(prmDataDiskon[0].jumlah) / 100 * 0) : 0;

            setResumeBiaya({
                subTotal: 0,
                diskon: resDiskon,
                total: 0 - resDp - resDiskon
            })
        }
    }

    const handleUangMuka = (prmUangMuka) => {
        if (!prmUangMuka || prmUangMuka === '0') {
            return '-';
        } else {
            return 'Rp ' + formatNumberCanZero(prmUangMuka);
        }
    }

    const handleMetodeUangMuka = (prmMetode) => {
        if (!prmMetode) {
            return '';
        } else {
            let hasil = '';

            Object.keys(metodeBayar).forEach(element => {
                if (element === prmMetode) {
                    hasil = '(Metode ' + metodeBayar[element] + ')';
                    return
                }
            });

            return hasil;
        }
    }

    const handleLunas = () => {
        if (refIsLunas.current.value !== '1') {
            setModalPelunasan(false);
            handleWarningModal('Update pelunasan dapat dilakukan jika status pembayaran telah lunas.');
        } else if (!refMetodePelunasan.current.value) {
            setModalPelunasan(false);
            handleWarningModal('Pastikan metode pelunasan telah diisi.');
        } else if (!stateData.tanggalPelunasan) {
            setModalPelunasan(false);
            handleWarningModal('Pastikan tanggal pelunasan telah diisi.');
        } else {
            setBtnLoadingAmbil(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/pelunasan',
                data: {
                    id_transaksi: idTransaksi,
                    prm_lunas: refIsLunas.current.value,
                    tgl_pelunasan: isoDateToSqlDate(stateData.tanggalPelunasan),
                    metode_pelunasan: refMetodePelunasan.current.value
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingAmbil(false);
                setModalPelunasan(false);

                if (response.data.flag_status === true) {
                    setModalBerhasilPelunasan(true);
                }

                if (response.data.flag_status === false) {
                    handleWarningModal('Error : ' + response.data.error);
                }

                if (response.data.flag_status === 'not-found') {
                    handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingAmbil(false);
                setModalPelunasan(false);
                handleWarningModal(error.message);
            })
        }
    }

    const handleAfterBerhasilPelunasan = () => {
        setModalBerhasilPelunasan(false);
        history.push('/' + prefixUrl.owner + '/proses/prosesLima');
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop
                shadow={true}
                titleNavTop="Sudah Diambil"
                prmBackButton={true}
                prmOnBack={`${callbackUrl ? '/' + handleCallBack(deko(reduxLogin.syswebappj), levelUser, callbackUrl, prefixUrl) : '/' + prefixUrl.owner + '/proses/prosesLima'}`}
            />

            <Layout titleTab="Sudah Diambil">
                <Row> <Col xs={12} className="mt-3">
                    {stateData.dataProses && !loadingDataProses && <React.Fragment>
                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3 bgWhite`}>
                            <div className="flex">
                                <div>
                                    <CustomerAvatar
                                        prmSlug={stateData.dataProses[0].slug_ck}
                                        prmGender={stateData.dataProses[0].gender}
                                        ukuran={{ width: '55px', height: '55px' }}
                                    />
                                </div>

                                <div className="ml-3 wordBreakAll" style={{ width: '100%' }}>
                                    <div className="color636363 bold">
                                        {stateData.dataProses[0].nama}
                                    </div>

                                    <div className="color808080 f14">
                                        {stateData.dataProses[0].nama_ktgr_cust}
                                    </div>

                                    <div className="color808080 f14 mt-2">
                                        {stateData.dataProses[0].alamat}
                                    </div>

                                    <div className="color808080 f14">
                                        {stateData.dataProses[0].no_hp}
                                    </div>
                                </div>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600 mb-1">No Nota</div>
                                <div className="color808080">{stateData.dataProses[0].id_transaksi}</div>
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600 mb-1">Tanggal Masuk</div>
                                <div className="color808080">{stateData.dataProses[0].tanggal_masuk ? sqlDateToLongHumanDate(stateData.dataProses[0].tanggal_masuk) : ''}</div>
                            </div>

                            <div className="f14">
                                <div className="color636363 fontweight600 mb-1">Penerima</div>
                                <div className="color808080">{stateData.penerima}</div>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600 mb-1">
                                    Kategori Barang
                                </div>
                                <div className="color808080">{stateData.dataProses[0].nama_barang_kategori}</div>
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600 mb-1">Barang</div>
                                <div className="color808080">{stateData.dataProses[0].nama_barang}</div>
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600">Keluhan</div>
                                <div className="color808080">{stateData.dataProses[0].kerusakan}</div>
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600">Foto Barang</div>

                                {stateData.listFotoTransaksi ? <div className="text-center mt-2">
                                    <Masonry
                                        breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 4 : 5) : 5) : 6) : 6}`}
                                        className="my-masonry-grid"
                                        columnClassName="my-masonry-grid_column"
                                    >
                                        {Object.keys(stateData.listFotoTransaksi).map((key) => {
                                            return (
                                                <img
                                                    key={"lfkp87hjsdk" + key}
                                                    src={ROOT_BASE_URL + stateData.listFotoTransaksi[key].foto}
                                                    alt={'foto'}
                                                    style={{ width: '100%', borderRadius: '6px' }}
                                                    className="cp"
                                                    onClick={() => {
                                                        setSrcFoto(ROOT_BASE_URL + stateData.listFotoTransaksi[key].foto)
                                                        setModalZoomImage(true);
                                                    }}
                                                />
                                            )
                                        })}
                                    </Masonry>
                                </div>
                                    :
                                    <div className="color808080">
                                        Tidak ada
                                    </div>
                                }
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600">Uang Muka</div>
                                <div className="color808080">
                                    {handleUangMuka(stateData.dataProses[0].dp)}
                                    {' '}
                                    {handleMetodeUangMuka(stateData.dataProses[0].metode_dp)}
                                </div>
                            </div>

                            <div className="f14">
                                <div className="color636363 fontweight600">Catatan</div>
                                <div className="color808080 whiteSpacePreLine">
                                    {stateData.dataProses[0].catatan ? stateData.dataProses[0].catatan : '-'}
                                </div>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                        >
                            <div className="f14 mb-3 colorTheme fontweight600">
                                Kelengkapan Barang
                            </div>

                            <div>
                                <div className="color636363 fontweight600">
                                    Kelengkapan Utama :
                                </div>
                                <div className="color808080 mt-1">{stateData.dataProses[0].nama_barang}</div>
                            </div>

                            <div className="mt-3">
                                <div className="color636363 fontweight600 mb-1">
                                    Kelengkapan Lainnya :
                                </div>

                                {stateData.dataLabel ? Object.keys(stateData.dataLabel).map((keyDL) => {
                                    return (
                                        <span
                                            key={keyDL + 'dl_78'}
                                            className={`color808080`}
                                        >
                                            <span>{stateData.dataLabel[keyDL].label}</span>
                                            <span>{stateData.dataLabel.length === (Number(keyDL) + 1) ? '' : ', '}</span>
                                        </span>
                                    )
                                })
                                    :
                                    <div className="color808080">Tidak ada</div>
                                }
                            </div>
                        </Card>

                        <div className={`mb-3`}>
                            <div className="mb-3 text-center fontweight600">Proses Pengerjaan</div>
                            {stateData.dataProgres ? <React.Fragment>
                                {Object.keys(stateData.dataProgres).map((key) => {
                                    return (
                                        <Card
                                            key={key}
                                            className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} ${stateData.dataProgres && Object.keys(stateData.dataProgres).length === (parseInt(key) + 1) ? '' : 'mb-3'} bgWhite shadow-sm brSoftMedium color636363 wordBreakAll`}
                                        >
                                            <div className="flex pb-2 mb-3 borderBottomEee">
                                                <div
                                                    className="bgTheme colorWhite f10 mr-3 flexJustifyAlignCenter"
                                                    style={{ width: '20px', minWidth: '20px', height: '20px', minHeight: '20px', marginTop: '2px', borderRadius: '50%' }}
                                                >
                                                    {parseInt(key) + 1}
                                                </div>

                                                <div style={{ flex: 1 }}>
                                                    <div className="fontweight600 colorTheme">
                                                        {stateData.dataProgres[key]['nama_user']}
                                                    </div>

                                                    <div className="f13 colorAaa">
                                                        {stateData.dataProgres[key]['tanggal_progres'] ? dateTimeToHumanDateTime(stateData.dataProgres[key]['tanggal_progres']) : ''} WIB
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="color808080">
                                                {stateData.dataProgres[key].flag_urutan === '1' ? 'Project diambil oleh ' + stateData.dataProgres[key].nama_user :
                                                    <div>

                                                        <div className="flex">
                                                            <div className="flex1 whiteSpacePreLine">
                                                                {stateData.dataProgres[key].progres}
                                                            </div>
                                                        </div>

                                                        {stateData.dataProgres[key].id_tdp in stateData.dataPenggunaanStok &&
                                                            <div className="borderTopEee mt-3">
                                                                <div className="colorTheme mt-3">
                                                                    Penggunaan Part Stok Barang
                                                                </div>

                                                                <div className="mt-3">
                                                                    {stateData.dataProgres[key].id_tdp in stateData.dataPenggunaanStok && Object.keys(stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu).map((keyPS) => {
                                                                        return (
                                                                            <div
                                                                                key={keyPS}
                                                                                className={`flex ${Object.keys(stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu).length === (parseInt(keyPS) + 1) ? '' : 'mb-3'}`}
                                                                            >
                                                                                <div className="mr-2">
                                                                                    {(parseInt(keyPS) + 1) + '. '}
                                                                                </div>

                                                                                <div style={{ flex: 1 }}>
                                                                                    <div>
                                                                                        {stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].nama} {' '} {stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].merk} {' '} {stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].tipe} {' '} {stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].serial_number ? 'SN ' + stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].serial_number : 'SN -'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>


                                            {stateData.dataProgres[key]['b'] !== null && <div className="mt-3 pt-3 borderTopEee">
                                                <Masonry
                                                    breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 5 : 5) : 5) : 6) : 6}`}
                                                    className="my-masonry-grid"
                                                    columnClassName="my-masonry-grid_column"
                                                >
                                                    {Object.keys(stateData.dataProgres[key]['b']).map((subKey) => {
                                                        return (
                                                            <img
                                                                key={subKey}
                                                                src={ROOT_BASE_URL + stateData.dataProgres[key]['b'][subKey].foto}
                                                                alt={`foto ${subKey}`}
                                                                className="cp"
                                                                style={{ width: '100%', borderRadius: '6px' }}

                                                                onClick={() => {
                                                                    setSrcFoto(ROOT_BASE_URL + stateData.dataProgres[key]['b'][subKey].foto)
                                                                    setModalZoomImage(true);
                                                                }}
                                                            />
                                                        )
                                                    })}
                                                </Masonry>
                                            </div>
                                            }
                                        </Card>
                                    )
                                })}
                            </React.Fragment>
                                :
                                <div className="color808080 mt-3">
                                    Belum ada progres pengerjaan
                                </div>
                            }
                        </div>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="f14">
                                <div className="colorTheme fontweight600 mb-3">
                                    Form Q.C. Barang
                                </div>

                                <div className="mb-4 color808080">
                                    Kategori Form : {stateData.dataKelengkapan ? stateData.dataKelengkapan[0].nama_kategori : 'Tidak Ada'}
                                </div>

                                <div className="color808080">
                                    {stateData.dataKelengkapan && Object.keys(stateData.dataKelengkapan).map((key) => {
                                        return (<React.Fragment key={key + 'kkl7js'}>

                                            {key === '0' &&
                                                <div className="color636363 mb-3 pb-3 borderBottomEee">
                                                    <span className="mr-3">Q.C.</span>
                                                    <span>Item</span>
                                                </div>
                                            }

                                            <Form.Row className="mb-2">
                                                <Form.Group
                                                    as={Col}
                                                    xs="10"
                                                    controlId={`fg_01_pqc_${key}`}
                                                    className="mb-0"
                                                >
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={`fgfc_pqc_${key}`}
                                                        custom
                                                    >
                                                        <Form.Check.Input
                                                            type="checkbox"
                                                            readOnly
                                                            disabled

                                                            defaultChecked={parseInt(stateData.dataKelengkapan[key].check_qc)}

                                                            value={parseInt(stateData.dataKelengkapan[key].check_qc)}
                                                        />

                                                        <Form.Check.Label
                                                            className="ml-1"
                                                            style={{ paddingTop: '2px' }}
                                                        >
                                                            <div className="flexAlignCenter ml-3">
                                                                {
                                                                    parseInt(stateData.dataKelengkapan[key].check) ?
                                                                        <span
                                                                            className="fa fa-check f10 mr-1"
                                                                            style={{ color: '#007BFF' }}
                                                                        />
                                                                        :
                                                                        <span
                                                                            className="fa fa-close f10 mr-1"
                                                                            style={{ color: '#ccc' }}
                                                                        />
                                                                }

                                                                <span>
                                                                    {stateData.dataKelengkapan[key].nama_tdk}
                                                                </span>
                                                            </div>
                                                        </Form.Check.Label>

                                                        <div className="mt-2 color808080 f14 ml-4">
                                                            {stateData.dataKelengkapan[key].keterangan}
                                                        </div>

                                                        <div
                                                            className="mt-2 color808080 f14 ml-4"
                                                        >
                                                            {stateData.dataKelengkapan[key].keterangan_qc &&
                                                                <div className="borderTopEee pt-2">
                                                                    <div className="mb-0">
                                                                        Keterangan Q.C. :
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div id={`ketCapt_so7_pqc_${key}`}>
                                                                {stateData.dataKelengkapan[key].keterangan_qc}
                                                            </div>
                                                        </div>
                                                    </Form.Check>
                                                </Form.Group>
                                            </Form.Row>

                                            {(stateData.dataKelengkapan.length - 1) !== parseInt(key) && <hr />}
                                        </React.Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="colorTheme fontweight600 mb-2">Rincian Pengambilan</div>

                            <div className="color636363 mb-3">
                                <div className="mb-1">
                                    Kategori Servis
                                </div>

                                <div className="color808080">
                                    {Object.keys(kategoriServis).includes(stateData.kategoriServis) ? kategoriServis[stateData.kategoriServis] : 'Tidak ada'}
                                </div>
                            </div>

                            {stateData.kategoriServis && stateData.kategoriServis === reminderMaintenance &&
                                <div className="color636363 mb-3">
                                    <div className="mb-1">
                                        Reminder Untuk Maintenance
                                    </div>

                                    <div className="color808080">
                                        {stateData.reminderAt ? (stateData.reminderAt + ' Bulan Dari Tanggal Pengambilan') : 'Tidak ada reminder / Sudah diremove'}
                                    </div>
                                </div>
                            }

                            <div className="color636363">
                                <div className="mb-1">
                                    Tanggal Ambil
                                </div>

                                <div className="color808080">
                                    {stateData.tanggalAmbil ? sqlDateToLongHumanDate(isoDateToSqlDate(stateData.tanggalAmbil)) : '-'}
                                </div>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="colorTheme fontweight600 mb-2">Catatan</div>

                            <div className="color636363 whiteSpacePreLine">
                                {stateData.catatanSelesai ? stateData.catatanSelesai : '-'}
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="colorTheme fontweight600 mb-2">
                                Stok item yang digunakan
                            </div>

                            <div className="color636363">
                                {stateData.dataPenggunaanStokInclusion &&
                                    <div className="color808080">
                                        {Object.keys(stateData.dataPenggunaanStokInclusion).map((key) => {
                                            return (
                                                <Card key={key + 'inclsn_ps_8sd79j'} className="p-2 mb-2">
                                                    <div>
                                                        {stateData.dataPenggunaanStokInclusion[key].nama + ' ' + stateData.dataPenggunaanStokInclusion[key].merk + ' ' + stateData.dataPenggunaanStokInclusion[key].tipe + ' SN ' + (stateData.dataPenggunaanStokInclusion[key].serial_number ? stateData.dataPenggunaanStokInclusion[key].serial_number : '-')}
                                                    </div>

                                                    <div>Supplier : {stateData.dataPenggunaanStokInclusion[key].supplier ? stateData.dataPenggunaanStokInclusion[key].supplier : ' - '}</div>

                                                    <div>Masa Garansi : {stateData.dataPenggunaanStokInclusion[key].masa_garansi ? stateData.dataPenggunaanStokInclusion[key].masa_garansi : ' - '} Bulan</div>

                                                    <div>Tanggal Pembelian : {stateData.dataPenggunaanStokInclusion[key].tanggal_beli ? sqlDateToLongHumanDate(stateData.dataPenggunaanStokInclusion[key].tanggal_beli) : ' - '}</div>

                                                    <div>Harga Beli : Rp {formatNumberCanZero(stateData.dataPenggunaanStokInclusion[key].harga_beli)}</div>

                                                    <div>Original : {stateData.dataPenggunaanStokInclusion[key].is_original ? 'Ya' : 'Tidak'}</div>
                                                </Card>
                                            )
                                        })}
                                    </div>
                                }

                                {!stateData.dataPenggunaanStokInclusion &&
                                    <div>Tidak ada</div>
                                }
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="colorTheme fontweight600 mb-2">Rincian Biaya</div>

                            <div>
                                {stateData.dataTDBYR ? Object.keys(stateData.dataTDBYR).map((key) => {
                                    return (
                                        <div
                                            key={key + 'ps_7987gh'}
                                            className={`mt-2 ${(Number(key) + 1) === stateData.dataTDBYR.length ? '' : 'pb-2'} borderBottomEeeChild color636363`}
                                        >
                                            <div className="mb-1">
                                                {stateData.dataTDBYR[key].item_jasa}
                                            </div>

                                            <div>
                                                <Row>
                                                    <Col>
                                                        Rp {formatNumberCanZero(stateData.dataTDBYR[key].harga)}
                                                    </Col>

                                                    <Col>
                                                        Qty {formatNumberCanZero(stateData.dataTDBYR[key].jumlah)}
                                                    </Col>

                                                    <Col>
                                                        Rp {formatNumberCanZero(stateData.dataTDBYR[key].total)}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    )
                                })
                                    :
                                    <div className="color636363">Tidak ada</div>
                                }
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium`}>
                            <div>
                                <div className="flexAlignCenter mb-3">
                                    <div className="flex1">Sub Total</div>
                                    <div className="flex2 color636363">
                                        Rp {formatNumberCanZero(resumeBiaya.subTotal)}
                                    </div>
                                </div>

                                <div className="flexAlignCenter mb-3">
                                    <div className="flex1">Uang Muka</div>
                                    <div className="flex2 color636363">
                                        Rp {stateData.dataProses[0].dp ? formatNumberCanZero(stateData.dataProses[0].dp) : '0'}
                                    </div>
                                </div>

                                <div className="flexAlignTop mb-3">
                                    <div className="flex1">Diskon</div>
                                    <div className="flex2 color636363">
                                        <div className="colorTheme">
                                            {stateData.dataDiskon && stateData.dataDiskon[0].keterangan &&
                                                <div className="f13 color808080 mb-2">
                                                    <div className="flexJustifyBeetwenAlignCenter">
                                                        <div>
                                                            Diskon{' '}
                                                            {stateData.dataDiskon[0].flag === 'rupiah' && 'Rp '}
                                                            {formatNumberCanZero(stateData.dataDiskon[0].jumlah)}
                                                            {stateData.dataDiskon[0].flag !== 'rupiah' && '%'}
                                                        </div>
                                                    </div>

                                                    <div>{stateData.dataDiskon[0].keterangan}</div>
                                                </div>
                                            }
                                        </div>

                                        <div>
                                            {!resumeBiaya.diskon ? 'Tidak ada' : 'Rp ' + formatNumberCanZero(resumeBiaya.diskon)}
                                        </div>
                                    </div>
                                </div>

                                <div className="flexAlignCenter mb-3">
                                    <div className="flex1">Total</div>
                                    <div className="flex2 color636363">
                                        Rp {formatNumberCanZeroMinus(resumeBiaya.total)}
                                    </div>
                                </div>

                                <div className="flexAlignCenter mb-3">
                                    <div className="flex1">
                                        Lunas
                                    </div>
                                    <div className="flex2">
                                        {stateData.isLunas === '1' ?
                                            <span className="color636363">Sudah Lunas</span>
                                            :
                                            <Form.Control
                                                custom
                                                as="select"
                                                size="sm"
                                                ref={refIsLunas}
                                                defaultValue={stateData.isLunas}
                                            >
                                                <option value="0">Belum Lunas</option>
                                                <option value="1">Lunas</option>
                                            </Form.Control>
                                        }
                                    </div>
                                </div>

                                <div className="flexAlignCenter mb-3">
                                    <div className="flex1">
                                        Metode
                                    </div>

                                    <div className="flex2">
                                        {stateData.isLunas === '1' ?
                                            <span className="color636363">
                                                {stateData.dataProses[0].metode_pelunasan ? metodeBayar[stateData.dataProses[0].metode_pelunasan] : 'Tidak ada'}
                                            </span>
                                            :
                                            <Form.Control
                                                custom
                                                as="select"
                                                size="sm"
                                                ref={refMetodePelunasan}
                                            >
                                                <option value="">Metode Pembayaran</option>
                                                {Object.keys(metodeBayar).map((keyMP) => {
                                                    return (
                                                        <option
                                                            key={keyMP + 'mbT9889j'}
                                                            value={keyMP}
                                                        >
                                                            {metodeBayar[keyMP]}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Control>
                                        }
                                    </div>
                                </div>

                                <div className="flexAlignCenter">
                                    <div className="flex1">
                                        Tanggal Lunas
                                    </div>

                                    <div className="flex2">
                                        {stateData.isLunas === '1' ?
                                            <span className="color636363">
                                                {stateData.tanggalPelunasan ? sqlDateToLongHumanDate(stateData.tanggalPelunasan) : ''}
                                            </span>
                                            :
                                            <Form.Control
                                                readOnly
                                                className="bgInputImportant"
                                                size="sm"
                                                type="text"
                                                placeholder="Tanggal Lunas"
                                                value={stateData.tanggalPelunasan ? sqlDateToLongHumanDate(isoDateToSqlDate(stateData.tanggalPelunasan)) : ''}
                                                onClick={() => setModalCalendarTglPelunasan(true)}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </Card>

                        {stateData.isLunas !== '1' &&
                            <div className="flexJustifyAlignCenter">
                                <Button
                                    variant="info"
                                    size="sm"
                                    className="mt-4 py-2 width100"
                                    onClick={() => setModalPelunasan(true)}
                                >
                                    Update Pelunasan
                                </Button>
                            </div>
                        }

                    </React.Fragment>
                    }

                    {
                        loadingDataProses &&
                        <PlaceHolderElement1 prmMT="" prmPX="" />
                    }

                    {!stateData.dataProses && !loadingDataProses &&
                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-5' : 'p-4'} shadow-sm brSoftMedium text-center f13`}>
                            <div className="f14 mb-3 color808080">
                                Tidak ada data
                            </div>

                            <div className="flexJustifyAlignCenter">
                                <Image
                                    src={soldering}
                                    style={{ width: '75%' }}
                                />
                            </div>
                        </Card>
                    }
                </Col>
                </Row>
            </Layout>

            <Modal
                size="lg"
                show={modalZoomImage}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body className="p-0">
                    <div className="flexJustifyAlignCenter">
                        <span
                            onClick={() => setModalZoomImage(false)}
                            className="fa fa-close f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                            style={{
                                borderRadius: '50%',
                                width: '35px',
                                height: '35px',
                                position: 'absolute',
                                top: '15px',
                                right: '17px'
                            }}
                        />

                        <img src={srcFoto} alt='foto' style={{ width: '100%' }} />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={modalCalendarTglPelunasan}
                onHide={() => setModalCalendarTglPelunasan(false)}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tanggal Lunas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flexJustifyAlignCenter pt-1 pb-3">
                        <Calendar
                            defaultView="month"
                            onChange={(value) => {
                                setStateData(s => ({ ...s, tanggalPelunasan: value }))
                                setModalCalendarTglPelunasan(false);
                            }}
                            value={stateData.tanggalPelunasan ? new Date(stateData.tanggalPelunasan) : new Date()}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <ModalConfirmation
                showModal={modalPelunasan}
                hideModal={() => setModalPelunasan(false)}
                title="Konfirmasi"
                bodyCaption="Update pembayaran menjadi lunas ?"
                btnCloseClass="px-3"
                btnCloseCaption="Close"
                bLPrm={btnLoadingAmbil}
                bLBtnClass="ml-3"
                bLOnClick={handleLunas}
                bLCaption="Ya, Update"
            />

            <Modal
                show={modalBerhasilPelunasan}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="text-center f16 mb-3 mt-3">
                        <div>Pelunasan Berhasil Diupdate</div>
                    </div>

                    <div className="text-center">
                        <span className="fa fa-check-circle colorTheme f50" style={{ color: '#69ceb8' }} />
                    </div>

                    <div className="mt-4 flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="px-4"
                            onClick={handleAfterBerhasilPelunasan}
                        >
                            Oke
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment>
    );
}

export default ProsesSudahDiambil;
