import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

const ModalInfoHint = ({ showWarningModal, hideWarningModal, paramText }) => {
    return (
        <Modal centered animation={false} show={showWarningModal} onHide={hideWarningModal}>
            <Modal.Body>
                <div>

                    <div className="mb-3">
                        <div className="f16 py-1 fontweight500">
                            <span className="fa fa-info-circle" /> Informasi
                        </div>
                    </div>

                    <div className="f14 mb-4 color636363">{paramText}</div>

                    <div className="text-center">
                        <Button
                            variant="light"
                            size="sm"
                            onClick={hideWarningModal}
                            className="px-4"
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    );
}

export default ModalInfoHint