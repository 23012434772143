import React from 'react';
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Row, Col, Form, Card, Modal, Container } from 'react-bootstrap';
import { useSelector } from "react-redux";
import Calendar from 'react-calendar';
import 'font-awesome/css/font-awesome.min.css';
import Masonry from 'react-masonry-css';

import CustomerAvatar from "../../components/CustomerAvatar";
import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ButtonLoading from "../../components/ButtonLoading";
import PlaceHolderElement2 from "../../components/PlaceHolderElement2";
import { batasFluid, BASE_URL_DUA, levelUserNama, prefixUrl, widthLG, widthMD, widthSM, widthXS } from "../../utils/globalVar";
import { isoDateToSqlDate, isoDateToLongHumanDate, deko } from "../../utils/globalFunc";

const PenilaianKaryawan = () => {

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    const history = useHistory();

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const mountedRef = React.useRef(true);

    const [loadinginit, setLoadinginit] = React.useState(false);
    const [btnLoadingFilter, setBtnLoadingFilter] = React.useState(false);
    const [dataTeknisi, setDataTeknisi] = React.useState(null);
    const [dataKategoriBarang, setDataKategoriBarang] = React.useState(null);
    const [modalTanggalAwal, setModalTanggalAwal] = React.useState(false);
    const [modalTanggalAkhir, setModalTanggalAkhir] = React.useState(false);
    const [dataPenilaian, setDataPenilaian] = React.useState({
        data: null,
        nama: '',
        j: '',
        prmHasil: true
    });

    const [resumePenilaian, setResumePenilaian] = React.useState({
        project: 0,
        progres: 0,
        perFlag: {
            pengerjaan: 0,
            qc: 0,
            selesai: 0,
            sudahDiambil: 0
        }
    });

    const refTeknisi = React.useRef();
    const refKategoriBarang = React.useRef();
    const refTanggalAwal = React.useRef();
    const refTanggalAkhir = React.useRef();

    const [stateFilter, setStateFilter] = React.useState({
        teknisi: '',
        teknisiNama: '',
        teknisiLevel: '',
        kategoriBarang: 'all',
        tanggalAwal: new Date(),
        tanggalAkhir: new Date(),
        status: 'all'
    });

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";

        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, []);

    React.useEffect(() => {
        setLoadinginit(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/init_penilaian',
            data: {
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadinginit(false);

            setDataTeknisi(response.data.data_teknisi);
            setDataKategoriBarang(response.data.data_kategori_barang);
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadinginit(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }, [reduxLogin.syswebappi]);

    const handleFilter = () => {
        refTeknisi.current.style.border = 'unset';

        if (!refTeknisi.current.value) {
            refTeknisi.current.style.border = '1px solid red';
        } else {
            const yt = document.querySelector("#el_teknisi");

            setBtnLoadingFilter(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/get_penilaian_teknisi',
                data: {
                    id_user: deko(reduxLogin.syswebappi),
                    id_teknisi: stateFilter.teknisi,
                    kategori_barang: stateFilter.kategoriBarang,
                    tanggal_awal: String(isoDateToSqlDate(stateFilter.tanggalAwal) + ' 00:00:00'),
                    tanggal_akhir: String(isoDateToSqlDate(stateFilter.tanggalAkhir) + ' 23:59:59'),
                    status: stateFilter.status
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingFilter(false);

                const lv_teknisi = yt.options[yt.selectedIndex].getAttribute('lv_teknisi');
                const nm_teknisi = yt.options[yt.selectedIndex].getAttribute('nama_teknisi');

                let pengerjaan = 0;
                let qc = 0;
                let selesai = 0;
                let sudahDiambil = 0;

                if (response.data) {
                    setDataPenilaian({ data: response.data['k1'], nama: nm_teknisi, j: lv_teknisi, prmHasil: true });

                    let jProgres = 0;

                    response.data['k1'].forEach((element) => {
                        jProgres = jProgres + element['b'].length;
                    });

                    response.data['k1'].forEach((element) => {
                        if (element.flag_proses === '2') { pengerjaan = pengerjaan + 1 }
                        if (element.flag_proses === '3') { qc = qc + 1 }
                        if (element.flag_proses === '4') { selesai = selesai + 1 }
                        if (element.flag_proses === '5') { sudahDiambil = sudahDiambil + 1 }
                    });

                    setResumePenilaian({
                        ...resumePenilaian,
                        project: response.data['k1'].length,
                        progres: jProgres,
                        perFlag: {
                            pengerjaan: pengerjaan,
                            qc: qc,
                            selesai: selesai,
                            sudahDiambil: sudahDiambil
                        }
                    });
                } else {
                    setDataPenilaian({ data: null, nama: '', j: '', prmHasil: false });
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingFilter(false);
                handleWarningModal('Warning : ' + error.message)
            })
        }
    }

    const statusProject = (prm) => {
        let hasil = '';

        if (prm === '0') {
            hasil = 'Nota';
        } else if (prm === '1') {
            hasil = 'Antri';
        } else if (prm === '2') {
            hasil = 'Pengerjaan';
        } else if (prm === '3') {
            hasil = 'Q.C.';
        } else if (prm === '4') {
            hasil = 'Selesai';
        } else if (prm === '5') {
            hasil = 'Sudah Diambil';
        }

        return hasil;
    }

    const handleGoProject = (prm, id) => {
        if (prm === '0') {
            history.push('/transaksi/' + id + '/penilaiankaryawan');
        } else if (prm === '1') {
            history.push('/confirmdoproses/' + id + '/penilaiankaryawan');
        } else if (prm === '2') {
            history.push('/working/' + id + '/penilaiankaryawan');
        } else if (prm === '3') {
            history.push('/' + prefixUrl.owner + '/qc/' + id + '/penilaiankaryawan');
        } else if (prm === '4') {
            history.push('/' + prefixUrl.owner + '/selesai/' + id + '/penilaiankaryawan');
        } else if (prm === '5') {
            history.push('/' + prefixUrl.owner + '/sudahdiambil/' + id + '/penilaiankaryawan');
        }
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop
                shadow={true}
                titleNavTop="Penilaian Karyawan"
            />

            <Layout titleTab="Penilaian Karyawan">

                <Row>
                    {!loadinginit &&
                        <Col xs={12} className="mt-3">
                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium`}>
                                <Container className="p-0">
                                    <Row>
                                        <Col xs={12} className="pr-2 mb-3 fontweight600">Filter Penilaian</Col>

                                        <Col xs={6} className="pr-2 mb-3">
                                            <Form.Control
                                                custom
                                                as="select"
                                                size="sm"
                                                id="el_teknisi"
                                                ref={refTeknisi}
                                                value={stateFilter.teknisi}
                                                onChange={(e) => setStateFilter({ ...stateFilter, teknisi: e.target.value })}
                                            >
                                                <option value="" lv_teknisi="" nama_teknisi="">
                                                    Pilih Teknisi
                                                </option>
                                                {
                                                    dataTeknisi &&
                                                    Object.keys(dataTeknisi).map((key) => {
                                                        return (
                                                            <option
                                                                key={'dt_98347' + key}
                                                                value={dataTeknisi[key].id}
                                                                lv_teknisi={dataTeknisi[key].level}
                                                                nama_teknisi={dataTeknisi[key].nama}
                                                            >
                                                                {dataTeknisi[key].nama}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Col>

                                        <Col xs={6} className="pl-2 mb-3">
                                            <Form.Control
                                                custom
                                                as="select"
                                                size="sm"
                                                ref={refKategoriBarang}
                                                value={stateFilter.kategoriBarang}
                                                onChange={(e) => setStateFilter({ ...stateFilter, kategoriBarang: e.target.value })}
                                            >
                                                <option value="all">Semua Kategori Barang</option>
                                                {
                                                    dataKategoriBarang &&
                                                    Object.keys(dataKategoriBarang).map((key) => {
                                                        return (
                                                            <option
                                                                key={'dkb_79847' + key}
                                                                value={dataKategoriBarang[key].id}
                                                            >
                                                                {dataKategoriBarang[key].nama}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Col>

                                        <Col xs={6} className="pr-2">
                                            <Form.Control
                                                readOnly
                                                className="bgInputImportant"
                                                size="sm"
                                                ref={refTanggalAwal}
                                                type="text"
                                                placeholder="Tanggal Awal"
                                                value={isoDateToLongHumanDate(stateFilter.tanggalAwal)}
                                                onClick={() => setModalTanggalAwal(true)}
                                            />
                                        </Col>

                                        <Col xs={6} className="pl-2">
                                            <Form.Control
                                                readOnly
                                                className="bgInputImportant"
                                                size="sm"
                                                ref={refTanggalAkhir}
                                                type="text"
                                                placeholder="Tanggal Akhir"
                                                value={isoDateToLongHumanDate(stateFilter.tanggalAkhir)}
                                                onClick={() => setModalTanggalAkhir(true)}
                                            />
                                        </Col>

                                        <Col xs={6} className="pr-2 mt-3">
                                            <Form.Control
                                                custom
                                                as="select"
                                                size="sm"
                                                value={stateFilter.status}
                                                onChange={(e) => setStateFilter({ ...stateFilter, status: e.target.value })}
                                            >
                                                <option value="all">Semua Status</option>
                                                <option value="2">Pengerjaan</option>
                                                <option value="3">Q.C.</option>
                                                <option value="4">Selesai</option>
                                                <option value="5">Sudah Diambil</option>
                                            </Form.Control>
                                        </Col>

                                        <Col xs={12} className="mt-4">
                                            <div className="flexJustifyBeetwenAlignCenter">
                                                <div className="colorAaa f12">
                                                    Filter tanggal berdasarkan tanggal progres
                                                </div>

                                                <div>
                                                    <ButtonLoading
                                                        prmLoading={btnLoadingFilter}
                                                        btnClass="btnXsOverride px-4"
                                                        btnVariant="info"
                                                        btnOnClick={handleFilter}
                                                        caption="Filter"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card>
                        </Col>
                    }

                    {loadinginit && !dataPenilaian.data &&
                        <Col xs={12} className="mt-3">
                            <PlaceHolderElement2 />
                        </Col>
                    }

                    {dataPenilaian.data &&
                        <Col xs={12} className="mt-3">
                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium`}>
                                <div className="alert alert-info mb-3">
                                    <div>
                                        <div className="bold">{dataPenilaian.nama}</div>
                                        <div className="f13">{levelUserNama[dataPenilaian.j]}</div>
                                    </div>

                                    <hr className="my-2" />

                                    <div className="fontweight500">
                                        <span className="f13">{resumePenilaian.project} Project</span>

                                        <span className="ml-2 f13">({resumePenilaian.progres} Progres)</span>
                                    </div>

                                    <div className="flexJustifyBeetwenAlignCenter mt-2 f13">
                                        <div>
                                            <div>{resumePenilaian.perFlag.pengerjaan} Pengerjaan</div>

                                            <div>{resumePenilaian.perFlag.qc} QC</div>
                                        </div>

                                        <div>
                                            <div>{resumePenilaian.perFlag.selesai} Selesai</div>

                                            <div>{resumePenilaian.perFlag.sudahDiambil} Sudah Diambil</div>
                                        </div>
                                    </div>
                                </div>

                                <Masonry
                                    breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 1 : 2) : 2) : 3) : 3}`}
                                    className="my-masonry-grid-12"
                                    columnClassName="my-masonry-grid_column-12"
                                >
                                    {Object.keys(dataPenilaian.data).map((key) => {
                                        return (
                                            <Card
                                                key={key + 'pk_47'}
                                                className="py-3 px-3 alert-light color808080 brSoftMedium rounded-sm mb-3"
                                            >
                                                <div className="flex borderBottomEee pb-2 mb-2">
                                                    <div>
                                                        <CustomerAvatar
                                                            prmSlug={dataPenilaian.data[key].slug_ck}
                                                            prmGender={dataPenilaian.data[key].gender}
                                                            ukuran={{ width: '30px', height: '30px' }}
                                                        />
                                                    </div>

                                                    <div className="ml-3 wordBreakAll" style={{ width: '100%' }}>
                                                        <div className="color636363 bold">
                                                            {dataPenilaian.data[key].nama_customer}
                                                        </div>

                                                        <div className="color808080 f14">
                                                            {dataPenilaian.data[key].nama_ktgr_cust}
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="flexJustifyBeetwenAlignCenter">
                                                    <div className="color808080">
                                                        {dataPenilaian.data[key].id_transaksi}
                                                    </div>

                                                    <div className="color808080">
                                                        {statusProject(dataPenilaian.data[key].flag_proses)}
                                                    </div>
                                                </div>


                                                <div>
                                                    <span className="color808080 mr-1">Kategori</span>

                                                    <span className="color808080">
                                                        {dataPenilaian.data[key].kategori_barang}
                                                    </span>
                                                </div>

                                                <div>
                                                    <span className="color808080 mr-1">Barang</span>

                                                    <span className="color808080">
                                                        {dataPenilaian.data[key].nama_barang}
                                                    </span>
                                                </div>

                                                <div>
                                                    <span className="color808080">
                                                        {dataPenilaian.data[key]['b'] ? dataPenilaian.data[key]['b'].length : 0}
                                                    </span>

                                                    <span className="color808080 ml-1">Progres</span>
                                                </div>

                                                <div className="mt-1">
                                                    <span
                                                        className="colorTheme"
                                                        onClick={() => handleGoProject(dataPenilaian.data[key].flag_proses, dataPenilaian.data[key].id_transaksi)}
                                                    >
                                                        Go to project
                                                    </span>
                                                </div>
                                            </Card>
                                        )
                                    })}
                                </Masonry>
                            </Card>
                        </Col>
                    }

                    {!dataPenilaian.data && !dataPenilaian.prmHasil &&
                        <Col xs={12} className="mt-3">
                            <div className="color808080 text-center">Tidak ada hasil</div>
                        </Col>
                    }
                </Row>
            </Layout>

            <Modal
                show={modalTanggalAwal}
                onHide={() => {
                    setModalTanggalAwal(false);
                }}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tanggal Awal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flexJustifyAlignCenter pt-1 pb-3">
                        <Calendar
                            defaultView="month"
                            onChange={(value) => {
                                setStateFilter({ ...stateFilter, tanggalAwal: value })
                                setModalTanggalAwal(false);
                            }}
                            value={stateFilter.tanggalAwal}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={modalTanggalAkhir}
                onHide={() => {
                    setModalTanggalAkhir(false);
                }}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tanggal Akhir</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flexJustifyAlignCenter pt-1 pb-3">
                        <Calendar
                            defaultView="month"
                            onChange={(value) => {
                                setStateFilter({ ...stateFilter, tanggalAkhir: value })
                                setModalTanggalAkhir(false);
                            }}
                            value={stateFilter.tanggalAkhir}
                        />
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment >
    );
}

export default PenilaianKaryawan;
