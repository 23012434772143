import React from 'react';
import { Text, View, Image as ImagePdf, StyleSheet } from '@react-pdf/renderer';

import brandImg from "../assets/images/system/lumos_brand_crop_dua.jpg";
import gmail_32 from "../assets/images/system/gmail_32.png";
import ig_32 from "../assets/images/system/ig_32.png";
import wa_32 from "../assets/images/system/wa_32.png";
import web_32 from "../assets/images/system/web_32.png";
import { brand } from "../utils/globalVar";

const KopDokumen = () => {

    const styles = StyleSheet.create({
        kopSurat: {
            display: 'flex',
            flexDirection: "row",
            justifyContent: 'space-between',
            flexWrap: "nowrap",
            paddingBottom: '10px',
            borderBottom: '1px solid #000'
        },
        kopSuratKanan: {
            flexBasis: "auto",
            flexGrow: '1',
            flexShrink: '1',
            fontSize: '10px',
            padding: '0 8px 0 16px',
            marginBottom: '0px'
        },
        kopSuratKontak: {
            display: 'flex',
            flexDirection: "row",
            flexWrap: "nowrap",
            fontSize: '10px'
        },
        kopSuratFitur: {
            flexBasis: "auto",
            flexGrow: '1',
            flexShrink: '1',
            fontSize: '10px'
        },
        kopSuratFiturItem: {
            display: 'flex',
            flexDirection: "row",
            justifyContent: 'space-between',
            flexWrap: "nowrap",
            flexBasis: 'auto',
            flexGrow: '1'
        },
        containerIconKontak: {
            marginRight: '16px',
            display: 'flex',
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: 'center'
        }
    });

    return (
        <View style={styles.kopSurat}>
            <View>
                <ImagePdf src={brandImg} style={{ width: '110px' }} />
            </View>

            <View style={styles.kopSuratKanan}>
                <View style={{ marginBottom: '6px' }}>
                    <View style={{ marginBottom: '4px', fontSize: '10px' }}>
                        <Text>{brand.nama + ' ' + brand.tag} Jl. Kembar Tengah 2 No 12 Bandung</Text>
                    </View>

                    <View>
                        <View style={styles.kopSuratKontak}>
                            <View style={styles.containerIconKontak}>
                                <View style={{ marginRight: '4px' }}>
                                    <ImagePdf src={wa_32} style={{ width: '8px' }} />
                                </View>

                                <View><Text>0895618753345</Text></View>
                            </View>

                            <View style={styles.containerIconKontak}>
                                <View style={{ marginRight: '4px' }}>
                                    <ImagePdf src={gmail_32} style={{ width: '8px' }} />
                                </View>

                                <View><Text>admin@lumos.id</Text></View>
                            </View>

                            <View style={styles.containerIconKontak}>
                                <View style={{ marginRight: '4px' }}>
                                    <ImagePdf src={ig_32} style={{ width: '8px' }} />
                                </View>

                                <View><Text>lumos_solution</Text></View>
                            </View>

                            <View style={styles.containerIconKontak}>
                                <View style={{ marginRight: '4px' }}>
                                    <ImagePdf src={web_32} style={{ width: '8px' }} />
                                </View>

                                <View><Text>www.lumos.id</Text></View>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.kopSuratFitur}>
                    <View style={{ display: 'flex', flexDirection: "row", flexWrap: "nowrap" }}>
                        <View style={{ marginRight: '16px' }}>
                            <Text>
                                Instalasi &amp; Perbaikan :
                            </Text>
                        </View>

                        <View style={styles.kopSuratFiturItem}>
                            <View>
                                <Text>PC &amp; Laptop</Text>
                                <Text>Reparasi Elektronik</Text>
                            </View>

                            <View>
                                <Text>Server</Text>
                                <Text>CCTV</Text>
                            </View>

                            <View>
                                <Text>Network &amp; Wifi</Text>
                                <Text>Pembuatan Software</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default KopDokumen;
