import React from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Image } from 'react-bootstrap';

import empatKosongEmpat from "../assets/images/system/empatKosongEmpat.png";
import Layout from "../components/Layout";
import { brand } from "../utils/globalVar";

const EmpatKosongEmpat = () => {

    const history = useHistory();

    return (
        <React.Fragment>

            <Layout titleTab="404">
                <Row>
                    <Col xs={12} className="mt-5">
                        <div className="text-center">

                            <div className="f50 fontweightBold" style={{ color: '#263E53' }}>
                                404
                            </div>

                            <div className="f14 fontweight600" style={{ color: '#263E53' }}>
                                Halaman Tidak Ditemukan
                            </div>

                            <div className="mt-4">
                                <Image
                                    src={empatKosongEmpat}
                                    style={{ width: '75%' }}
                                />
                            </div>

                            <div className="mt-4" style={{ color: '#263E53' }}>
                                <div className="f20 fontweightBold">{brand.nama}</div>
                                <div className="f14 fontweightBold">{brand.tag}</div>
                                <div className="f12 fontweightBold">{brand.keterangan}</div>
                            </div>

                            <div className="mt-3">
                                <span
                                    className="px-2"
                                    onClick={() => history.push('/')}
                                    style={{
                                        textDecoration: 'underline'
                                    }}
                                >
                                    Home
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Layout>

        </React.Fragment>
    );
}

export default EmpatKosongEmpat;
