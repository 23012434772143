import React from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import 'font-awesome/css/font-awesome.min.css';
import { Row, Col, Card, Image, Form, Button, Modal, Spinner } from 'react-bootstrap';
import CustomerAvatar from "../../components/CustomerAvatar";
import soldering from "../../assets/images/system/soldering.png";
import { ROOT_BASE_URL, BASE_URL, BASE_URL_DUA, batasFluid, prefixUrl, levelUser, metodeBayar, widthLG, widthMD, widthSM, widthXS } from "../../utils/globalVar";
import { sqlDateToLongHumanDate, formatNumberCanZero, handleCallBack, deko } from "../../utils/globalFunc";
import Layout from "../../components/Layout";
import NavbarTopOwner from "../../components/NavbarTop";
import NavbarTopTeknisi from "../../components/teknisi/NavbarTop";
import Masonry from 'react-masonry-css';
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ButtonLoading from "../../components/ButtonLoading";
import PlaceHolderElement1 from "../../components/PlaceHolderElement1";

const ConfirmDoProsesSatu = () => {

    const history = useHistory();

    const parameter = useParams();
    const idTransaksi = parameter.id;
    const callbackUrl = parameter.callbackUrl;

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [dataProses, setDataProses] = React.useState(null);
    const [dataKelengkapan, setDataKelengkapan] = React.useState(null);
    const [dataUserSuggestion, setDataUserSuggestion] = React.useState(null);
    const [dataLabel, setDataLabel] = React.useState(null);

    const [penerima, setPenerima] = React.useState('');

    const [loadingDataProses, setLoadingDataProses] = React.useState(false);
    const [listFotoTransaksi, setListFotoTransaksi] = React.useState(null);
    const [modalZoomImage, setModalZoomImage] = React.useState(false);
    const [srcFoto, setSrcFoto] = React.useState('');

    const [modalKonfirmasiPengerjaan, setModalKonfirmasiPengerjaan] = React.useState(false);
    const [btnLoadSubmit, setBtnLoadSubmit] = React.useState(false);

    const [loadingRecommendedTodo, setLoadingRecommendedTodo] = React.useState(false);
    const [userRecommendedTodo, setUserRecommendedTodo] = React.useState('');

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [])

    React.useEffect(() => {
        setLoadingDataProses(true)

        axios({
            method: 'post',
            url: BASE_URL + '/confirm_do_proses',
            data: {
                id_transaksi: idTransaksi,
                id_user_login: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            if (response.data && response.data.data_proses) {
                setDataProses(response.data.data_proses);
                setDataKelengkapan(response.data.data_kelengkapan);
                setListFotoTransaksi(response.data.data_foto_transaksi);
                setDataUserSuggestion(response.data.dt_user_can_do_this);
                setUserRecommendedTodo(response.data.data_proses[0].id_user_recommended_todo ? response.data.data_proses[0].id_user_recommended_todo : '');
                setDataLabel(response.data.data_label);
                setPenerima(response.data.penerima);
            } else {
                setDataProses(null);
            }

            setLoadingDataProses(false)
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingDataProses(false)
            handleWarningModal('Warning : ' + error.message)
        })
    }, [idTransaksi, reduxLogin.syswebappi])

    const handleDoKerjakan = () => {
        setBtnLoadSubmit(true);

        axios({
            method: 'post',
            url: BASE_URL + '/update_to_proses',
            data: {
                id_transaksi: idTransaksi,
                id_user_login: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadSubmit(false);
            setModalKonfirmasiPengerjaan(false);

            if (response.data.status_flag === 'not-available') {
                handleWarningModal('Project sudah tidak available.')
            }

            if (response.data.status_flag === true) {
                if (deko(reduxLogin.syswebappj) === levelUser.owner || deko(reduxLogin.syswebappj) === levelUser.teknisi) {
                    history.push('/working/' + idTransaksi);
                }
            }

            if (response.data.status_flag === false) {
                handleWarningModal('Error : ' + response.data.error)
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadSubmit(false);
            setModalKonfirmasiPengerjaan(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }

    const handleUangMuka = (prmUangMuka) => {
        if (!prmUangMuka || prmUangMuka === '0') {
            return '-';
        } else {
            return 'Rp ' + formatNumberCanZero(prmUangMuka);
        }
    }

    const handleMetodeUangMuka = (prmMetode) => {
        if (!prmMetode) {
            return '';
        } else {
            let hasil = '';

            Object.keys(metodeBayar).forEach(element => {
                if (element === prmMetode) {
                    hasil = '(Metode ' + metodeBayar[element] + ')';
                    return
                }
            });

            return hasil;
        }
    }

    const handleUserRecommendedTodo = (prm) => {
        setLoadingRecommendedTodo(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/set_user_recommended_todo',
            data: {
                id_transaksi: idTransaksi,
                id_user_login: deko(reduxLogin.syswebappi),
                id_user_recommended: prm
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingRecommendedTodo(false);

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Project sudah tidak available.')
            }

            if (response.data.flag_status === 'not-found-user') {
                handleWarningModal('Anda tidak memiliki akses.')
            }

            if (response.data.flag_status === 'cannot-access') {
                handleWarningModal('Anda tidak memiliki akses !')
            }

            if (response.data.flag_status === true) {
                setUserRecommendedTodo(prm);
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.error)
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingRecommendedTodo(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            {deko(reduxLogin.syswebappj) === levelUser.owner ?
                <NavbarTopOwner
                    shadow={true}
                    titleNavTop="Konfirmasi Pengerjaan"
                    prmBackButton={true}
                    prmOnBack={`${callbackUrl ? '/' + handleCallBack(deko(reduxLogin.syswebappj), levelUser, callbackUrl, prefixUrl) : '/' + prefixUrl.owner + '/proses/prosesSatu'}`}
                />
                :
                <NavbarTopTeknisi
                    shadow={true}
                    titleNavTop="Konfirmasi Pengerjaan"
                    prmBackButton={true}
                    prmOnBack={`${callbackUrl ? '/' + handleCallBack(deko(reduxLogin.syswebappj), levelUser, callbackUrl, prefixUrl) : '/' + prefixUrl.teknisi + '/proses/prosesSatu'}`}

                />
            }

            <Layout titleTab="Konfirmasi Pengerjaan">
                <Row>
                    <Col xs={12} className="mt-3">
                        {
                            dataProses && !loadingDataProses &&
                            <React.Fragment>
                                <Card
                                    className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                >
                                    <div className="flex">
                                        <div>
                                            <CustomerAvatar
                                                prmSlug={dataProses[0].slug_ck}
                                                prmGender={dataProses[0].gender}
                                                ukuran={{ width: '55px', height: '55px' }}
                                            />
                                        </div>

                                        <div className="ml-3 wordBreakAll" style={{ width: '100%' }}>
                                            <div className="color636363 bold">
                                                {dataProses[0].nama}
                                            </div>

                                            <div className="color808080 f14">
                                                {dataProses[0].nama_ktgr_cust}
                                            </div>

                                            <div className="color808080 f14 mt-2">
                                                {dataProses[0].alamat}
                                            </div>

                                            {deko(reduxLogin.syswebappj) === levelUser.owner &&
                                                <div className="color808080 f14">
                                                    {dataProses[0].no_hp}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Card>

                                <Card
                                    className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                >
                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">No Nota</div>
                                        <div className="color808080">{dataProses[0].id_transaksi}</div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">Tanggal Masuk</div>
                                        <div className="color808080">{sqlDateToLongHumanDate(dataProses[0].tanggal_masuk)}</div>
                                    </div>

                                    <div className="f14">
                                        <div className="color636363 fontweight600 mb-1">Penerima</div>
                                        <div className="color808080">{penerima}</div>
                                    </div>
                                </Card>

                                <Card
                                    className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                >
                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">
                                            Kategori Barang
                                        </div>
                                        <div className="color808080">{dataProses[0].nama_barang_kategori}</div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">Barang</div>
                                        <div className="color808080">{dataProses[0].nama_barang}</div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600">Keluhan</div>
                                        <div className="color808080">{dataProses[0].kerusakan}</div>
                                    </div>

                                    <div className="f14 mb-3">
                                        <div className="color636363 fontweight600">Foto Barang</div>

                                        {listFotoTransaksi ?
                                            <div className="text-center mt-2">
                                                <Masonry
                                                    breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 4 : 5) : 5) : 6) : 6}`}
                                                    className="my-masonry-grid"
                                                    columnClassName="my-masonry-grid_column"
                                                >
                                                    {Object.keys(listFotoTransaksi).map((key) => {
                                                        return (
                                                            <img
                                                                key={"lfkp87hjsdk" + key}
                                                                className="rounded-lg width100 cp"
                                                                src={ROOT_BASE_URL + listFotoTransaksi[key].foto}
                                                                alt={'foto'}
                                                                onClick={() => {
                                                                    setSrcFoto(ROOT_BASE_URL + listFotoTransaksi[key].foto)
                                                                    setModalZoomImage(true);
                                                                }}
                                                            />
                                                        )
                                                    })}
                                                </Masonry>
                                            </div>
                                            :
                                            <div className="color808080">
                                                Tidak ada data foto
                                            </div>
                                        }
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-2">
                                            Form Cek Barang {dataKelengkapan && dataKelengkapan[0].nama_kategori}
                                        </div>
                                        <div className="color808080">
                                            {
                                                dataKelengkapan &&
                                                Object.keys(dataKelengkapan).map((key) => {
                                                    return (
                                                        <React.Fragment key={key + 'kkl7js'}>
                                                            <Form.Row className="mb-2">
                                                                <Form.Group
                                                                    as={Col}
                                                                    xs="12"
                                                                    className="mb-0"
                                                                >

                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        custom
                                                                    >
                                                                        <Form.Check.Input
                                                                            readOnly
                                                                            disabled
                                                                            type="checkbox"
                                                                            defaultChecked={parseInt(dataKelengkapan[key].check)}
                                                                        />

                                                                        <Form.Check.Label
                                                                            style={{ paddingTop: '2px' }}
                                                                        >
                                                                            {dataKelengkapan[key].nama_tdk}
                                                                        </Form.Check.Label>

                                                                        <div className="mt-1 color808080 f14">
                                                                            {dataKelengkapan[key].keterangan}
                                                                        </div>
                                                                    </Form.Check>
                                                                </Form.Group>

                                                            </Form.Row>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600">Uang Muka</div>
                                        <div className="color808080">
                                            {handleUangMuka(dataProses[0].dp)}
                                            {' '}
                                            {handleMetodeUangMuka(dataProses[0].metode_dp)}
                                        </div>
                                    </div>

                                    <div className="f14">
                                        <div className="color636363 fontweight600">Catatan</div>
                                        <div className="color808080 whiteSpacePreLine">
                                            {dataProses[0].catatan ? dataProses[0].catatan : '-'}
                                        </div>
                                    </div>
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                >
                                    <div className="f14 mb-3 colorTheme fontweight600">
                                        Kelengkapan Barang
                                    </div>

                                    <div>
                                        <div className="color636363 fontweight600">
                                            Kelengkapan Utama :
                                        </div>
                                        <div className="color808080 mt-1">{dataProses[0].nama_barang}</div>
                                    </div>

                                    <div className="mt-3">
                                        <div className="color636363 fontweight600 mb-1">
                                            Kelengkapan Lainnya :
                                        </div>

                                        {dataLabel ? Object.keys(dataLabel).map((keyDL) => {
                                            return (
                                                <span
                                                    key={keyDL + 'dl_78'}
                                                    className={`color808080`}
                                                >
                                                    <span>{dataLabel[keyDL].label}</span>
                                                    <span>{dataLabel.length === (Number(keyDL) + 1) ? '' : ', '}</span>
                                                </span>
                                            )
                                        })
                                            :
                                            <div className="color808080">Tidak ada</div>
                                        }
                                    </div>
                                </Card>

                                {deko(reduxLogin.syswebappj) === levelUser.teknisi &&
                                    <Card
                                        className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                    >
                                        <div className="f14">
                                            <div className="f14 mb-3 colorTheme fontweight600">
                                                Saran Pengerjaan
                                            </div>

                                            <div className="color636363 mb-0">
                                                Disarankan untuk dikerjakan oleh {dataProses[0].nama_user_recommended_todo ? dataProses[0].nama_user_recommended_todo : ' (Tidak ada) '}
                                            </div>
                                        </div>
                                    </Card>
                                }

                                {deko(reduxLogin.syswebappj) === levelUser.owner &&
                                    <Card
                                        className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                    >
                                        <div className="f14">
                                            <div className="f14 mb-3 colorTheme fontweight600">
                                                Saran Pengerjaan

                                                {loadingRecommendedTodo &&
                                                    <Spinner
                                                        as="span"
                                                        className="ml-1"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                }
                                            </div>

                                            <div className="color636363 mb-0">
                                                <Form.Control
                                                    custom
                                                    as="select"
                                                    size="sm"
                                                    value={userRecommendedTodo}
                                                    onChange={(e) => handleUserRecommendedTodo(e.target.value)}
                                                >
                                                    <option value="">Pilih Teknisi</option>
                                                    {
                                                        dataUserSuggestion &&
                                                        Object.keys(dataUserSuggestion).map((key) => {
                                                            return (
                                                                <option
                                                                    key={'dus_98798' + key}
                                                                    value={dataUserSuggestion[key].id}
                                                                >
                                                                    {dataUserSuggestion[key].nama}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </Form.Control>
                                            </div>
                                        </div>
                                    </Card>
                                }

                                <Card
                                    className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                >
                                    <div className="f14">
                                        <div
                                            className="f14 mb-3 colorTheme fontweight600"
                                        >
                                            Konfirmasi Pengerjaan
                                        </div>

                                        <div><hr /></div>

                                        <div className="color636363 mb-4">
                                            <div>
                                                Hai, <span className="fontweight600 color636363">{deko(reduxLogin.syswebappn)}</span>
                                            </div>
                                            <div>Siap Mulai Mengerjakan Barang Ini ?</div>
                                        </div>

                                        <div className="flex">
                                            <Button
                                                variant="light"
                                                size="sm"
                                                className="mr-3 px-3"

                                                onClick={() => {
                                                    deko(reduxLogin.syswebappj) === levelUser.owner && history.push('/' + prefixUrl.owner + '/proses/prosesSatu');

                                                    deko(reduxLogin.syswebappj) === levelUser.teknisi && history.push('/' + prefixUrl.teknisi + '/proses/prosesSatu');

                                                }}
                                            >
                                                Kembali
                                            </Button>

                                            <Button
                                                variant="info"
                                                size="sm"
                                                onClick={() => setModalKonfirmasiPengerjaan(true)}
                                            >
                                                Ya, Mulai Kerjakan
                                            </Button>
                                        </div>
                                    </div>
                                </Card>
                            </React.Fragment>
                        }

                        {
                            !dataProses && loadingDataProses &&
                            <PlaceHolderElement1 prmMT="" prmPX="" />
                        }

                        {
                            !dataProses && !loadingDataProses &&
                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-5' : 'p-4'} shadow-sm brSoftMedium text-center f13`}>
                                <div className="f14 mb-3 color808080">
                                    Tidak ada data
                                </div>

                                <div className="flexJustifyAlignCenter">
                                    <Image
                                        src={soldering}
                                        style={{
                                            width: '75%'
                                        }}
                                    />
                                </div>
                            </Card>
                        }

                    </Col>
                </Row>
            </Layout>

            <Modal
                size="lg"
                show={modalZoomImage}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body className="p-0">
                    <div className="flexJustifyAlignCenter">
                        <span
                            onClick={() => setModalZoomImage(false)}
                            className="fa fa-close cp f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                            style={{
                                borderRadius: '50%',
                                width: '35px',
                                height: '35px',
                                position: 'absolute',
                                top: '15px',
                                right: '17px'
                            }}
                        />

                        <img
                            src={srcFoto}
                            alt='foto'
                            style={{ width: '100%' }}
                        />
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={modalKonfirmasiPengerjaan}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body className="p-3">

                    <div className="f16 fontweight600 mb-4">Konfirmasi</div>

                    <div className="mb-4">
                        Konfirmasi pengerjaan project ?
                    </div>

                    <div className="flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="mr-4"
                            onClick={() => setModalKonfirmasiPengerjaan(false)}
                        >
                            Kembali
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadSubmit}
                            btnClass=""
                            btnVariant="info"
                            btnOnClick={handleDoKerjakan}
                            caption="Ya, Kerjakan"
                        />
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment>
    );
}

export default ConfirmDoProsesSatu;
