import React from 'react';
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, FormControl, Card, Image, InputGroup, Spinner } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import CustomerAvatar from "../../components/CustomerAvatar";
import { widthLG, widthMD, widthSM, widthXS, batasFluid, BASE_URL, prefixUrl } from "../../utils/globalVar";

import ModalAlertWarning from '../../components/ModalAlertWarning';
import ButtonLoading from "../../components/ButtonLoading";
import PlaceHolderElement1 from "../../components/PlaceHolderElement1";

import manAvatar from "../../assets/images/system/manAvatar.png";
import womanAvatar from "../../assets/images/system/womanAvatar.png";
import rekananTeknisiAvatar from "../../assets/images/system/rekananTeknisiAvatar.png";
import perusahaanAvatar from "../../assets/images/system/perusahaanAvatar.jpg";
import LottiePausAstronot from "../../components/LottiePausAstronot";
import PlaceHolderElement5Flex from "../../components/PlaceHolderElement5Flex";

let handler = null;

const PreCustomer = () => {

    const history = useHistory();
    const reduxWindowWidth = useSelector(data => data.lumoswinwid);

    const [heightNavTop, setHeightNavTop] = React.useState(0);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const refInputan = React.useRef('');
    const refKtgrCust = React.useRef('semua');
    const refGenderCust = React.useRef('semua');

    const [count_all, setCount_all] = React.useState(0);
    const [counterDataCustomer, setCounterDataCustomer] = React.useState(null);
    const [loadingCounter, setLoadingCounter] = React.useState(false);

    const limitData = 10;
    const refOffsetData = React.useRef(0);
    const [hasMore, setHasMore] = React.useState(false);

    const [btnLoadingMore, setBtnLoadingMore] = React.useState(false);
    const [loadingProses, setLoadingProses] = React.useState(false);
    const [loadingTyping, setLoadingTyping] = React.useState(false);
    const [dataCustomer, setDataCustomer] = React.useState(null);

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.querySelector(".containerLuarTopNavbar") && setHeightNavTop(document.querySelector(".containerLuarTopNavbar").clientHeight);
        document.body.style.backgroundColor = "#FAF9F9";

        return () => {
            mountedRef.current = false;
            document.body.style.backgroundColor = "#fff";
        }
    }, [])

    React.useEffect(() => {
        setLoadingCounter(true);
        refOffsetData.current = 0;

        axios({
            method: 'post',
            url: BASE_URL + '/pre_customer_all_customer',
            data: {
                limit_data: limitData,
                offset_data: refOffsetData.current
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            document.body.style.backgroundColor = "#FAF9F9";
            setLoadingCounter(false);
            setCounterDataCustomer(response.data.counter_customer['k1']);
            // ! nanti cek klo data customernya kosong

            if (response.data.counter_customer['k1']) {
                var counter_all = 0;
                response.data.counter_customer['k1'].forEach(element => {
                    counter_all = counter_all + parseInt(element['b'][0].count_customer ? element['b'][0].count_customer : 0);
                });

                setCount_all(counter_all);
            }

            response.data.data_customer ? setDataCustomer(response.data.data_customer) : setDataCustomer(null);
            response.data.data_customer && response.data.data_customer.length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

            if (response.data.data_customer) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingCounter(false);
            handleWarningModal(error.message)
        })
    }, []);

    const handleGoCustomer = (idCustomer) => {
        history.push('/' + prefixUrl.owner + '/customer/' + idCustomer);
    }

    const handleSearch = (val) => {
        refOffsetData.current = 0;
        setLoadingTyping(true);

        handler && clearTimeout(handler);

        handler = setTimeout(() => {
            axios({
                method: 'post',
                url: BASE_URL + '/for_pre_customer_filter',
                data: {
                    limit_data: limitData,
                    offset_data: refOffsetData.current,
                    inputan: val,
                    filter_ktgr_cust: refKtgrCust.current.value,
                    filter_gender_cust: refGenderCust.current.value
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setLoadingTyping(false);

                response.data.data_customer ? setDataCustomer(response.data.data_customer) : setDataCustomer(null);
                response.data.data_customer && response.data.data_customer.length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

                if (response.data.data_customer) { refOffsetData.current = refOffsetData.current + limitData };
            }).catch(error => {
                if (!mountedRef.current) return null;
                setLoadingTyping(false);
                handleWarningModal(error.message)
            })
        }, 1000);
    }

    const handleFilterList = (prmKtgrCust, prmGenderCust) => {
        refOffsetData.current = 0;
        setLoadingProses(true);

        axios({
            method: 'post',
            url: BASE_URL + '/for_pre_customer_filter',
            data: {
                limit_data: limitData,
                offset_data: refOffsetData.current,
                inputan: refInputan.current.value,
                filter_ktgr_cust: prmKtgrCust,
                filter_gender_cust: prmGenderCust
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingProses(false);

            response.data.data_customer ? setDataCustomer(response.data.data_customer) : setDataCustomer(null);
            response.data.data_customer && response.data.data_customer.length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

            if (response.data.data_customer) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;
            setLoadingProses(false);
            handleWarningModal(error.message)
        })
    }

    const moreData = () => {
        setBtnLoadingMore(true);

        axios({
            method: 'post',
            url: BASE_URL + '/for_pre_customer_filter',
            data: {
                limit_data: limitData,
                offset_data: refOffsetData.current,
                inputan: refInputan.current.value,
                filter_ktgr_cust: refKtgrCust.current.value,
                filter_gender_cust: refGenderCust.current.value
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingMore(false);

            dataCustomer && response.data.data_customer && setDataCustomer([...dataCustomer, ...response.data.data_customer]);

            dataCustomer && response.data.data_customer && [...dataCustomer, ...response.data.data_customer].length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

            if (response.data.data_customer) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);
            handleWarningModal(error.message)
        })
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop shadow={true} titleNavTop="Customer" />

            <Layout titleTab="Customer">
                <Row>
                    <Col
                        xs={12}
                        className="pt-3 bgWhite"
                    >
                        {!loadingCounter && counterDataCustomer &&
                            <Card className={`${reduxWindowWidth > batasFluid ? 'px-4 pt-4 pb-1' : 'px-3 pt-3 pb-0'} shadow-sm brSoftMedium`}>
                                <Row>
                                    <Col xs={6} md={3} className="mb-3 flexRowJustifyAlignCenter">
                                        <div className="f13 fontweight500 mb-1">
                                            Semua Customer
                                        </div>
                                        <div className="f13 flexAlignCenter">
                                            <div className="mr-2">
                                                <Image
                                                    src={manAvatar}
                                                    alt='avatar'
                                                    roundedCircle
                                                    style={{
                                                        width: '25px',
                                                        height: '25px'
                                                    }}
                                                />

                                                <Image
                                                    src={womanAvatar}
                                                    alt='avatar'
                                                    roundedCircle
                                                    style={{
                                                        width: '25px',
                                                        height: '25px',
                                                        marginLeft: '-15px'
                                                    }}
                                                />

                                                <Image
                                                    src={perusahaanAvatar}
                                                    alt='avatar'
                                                    roundedCircle
                                                    style={{
                                                        width: '25px',
                                                        height: '25px',
                                                        marginLeft: '-15px'
                                                    }}
                                                />

                                                <Image
                                                    src={rekananTeknisiAvatar}
                                                    alt='avatar'
                                                    roundedCircle
                                                    style={{
                                                        width: '25px',
                                                        height: '25px',
                                                        marginLeft: '-15px'
                                                    }}
                                                />
                                            </div>

                                            <div>{count_all}</div>
                                        </div>
                                    </Col>

                                    {Object.keys(counterDataCustomer).map((key) => {
                                        return (
                                            <Col xs={6} md={3} className="mb-3 flexRowJustifyAlignCenter"
                                                key={key + 'pc9878'}
                                            >
                                                <div className="f13 fontweight500 mb-1">
                                                    {counterDataCustomer[key].nama_ck}
                                                </div>

                                                <div className="f13 flexAlignCenter">
                                                    <div className="mr-2">
                                                        {counterDataCustomer[key].slug === 'perorangan' &&
                                                            <div>
                                                                <Image
                                                                    src={manAvatar}
                                                                    alt='avatar'
                                                                    roundedCircle
                                                                    style={{
                                                                        width: '25px',
                                                                        height: '25px'
                                                                    }}
                                                                />

                                                                <Image
                                                                    src={womanAvatar}
                                                                    alt='avatar'
                                                                    roundedCircle
                                                                    style={{
                                                                        width: '25px',
                                                                        height: '25px',
                                                                        marginLeft: '-15px'
                                                                    }}
                                                                />
                                                            </div>
                                                        }

                                                        {counterDataCustomer[key].slug === 'perusahaan' &&
                                                            <div>
                                                                <Image
                                                                    src={perusahaanAvatar}
                                                                    alt='avatar'
                                                                    roundedCircle
                                                                    style={{
                                                                        width: '25px',
                                                                        height: '25px'
                                                                    }}
                                                                />
                                                            </div>
                                                        }

                                                        {counterDataCustomer[key].slug === 'rekanan_teknisi' &&
                                                            <div>
                                                                <Image
                                                                    src={rekananTeknisiAvatar}
                                                                    alt='avatar'
                                                                    roundedCircle
                                                                    style={{
                                                                        width: '25px',
                                                                        height: '25px'
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                    </div>

                                                    <div>
                                                        {counterDataCustomer[key]['b'][0].count_customer}
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Card>
                        }

                        {loadingCounter && <PlaceHolderElement1 prmMT="" prmPX="" />}
                    </Col>

                    {!loadingCounter && counterDataCustomer &&
                        <Col
                            xs={12}
                            className="bgWhite borderBottomEee"
                            style={{
                                position: 'sticky',
                                top: `${heightNavTop}px`,
                                zIndex: '3'
                            }}
                        >
                            <Row>
                                <Col xs={12} className="mt-3">

                                    <InputGroup>
                                        <FormControl
                                            size="sm"
                                            placeholder="Ketik Nama, Alamat, No HP Customer"
                                            className="removeShadowOnFocus"
                                            style={{ borderRight: '0 solid #eee' }}
                                            ref={refInputan}
                                            onKeyUp={(e) => handleSearch(e.target.value)}
                                            onChange={(e) => handleSearch(e.target.value)}
                                        />

                                        <InputGroup.Append>
                                            <InputGroup.Text id="ad-cbd-9890" className="bgColorForm">
                                                {loadingTyping &&
                                                    <Spinner animation="border" size="sm" variant="info" />
                                                }
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>

                                <Col xs={6} className="mt-2 pr-1">
                                    <Form.Control
                                        custom
                                        as="select"
                                        size="sm"
                                        ref={refKtgrCust}
                                        onChange={(e) => handleFilterList(e.target.value, refGenderCust.current.value)}
                                    >
                                        <option value="semua">Semua Customer</option>
                                        {
                                            counterDataCustomer &&
                                            Object.keys(counterDataCustomer).map((key) => {
                                                return (
                                                    <option
                                                        key={'kcfcb21631728' + key}
                                                        value={counterDataCustomer[key].id_ck}>
                                                        {counterDataCustomer[key].nama_ck}
                                                    </option>
                                                )
                                            })
                                        }
                                    </Form.Control>
                                </Col>

                                <Col xs={6} className="mt-2 pl-1">
                                    <Form.Group>
                                        <Form.Control
                                            custom
                                            as="select"
                                            size="sm"
                                            ref={refGenderCust}
                                            onChange={(e) => handleFilterList(refKtgrCust.current.value, e.target.value)}
                                        >
                                            <option value="semua">Semua Gender</option>
                                            <option value="1">Pria</option>
                                            <option value="0">Wanita</option>
                                            <option value="3">Tanpa Gender</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    }

                    <Col xs={12}>
                        {dataCustomer && counterDataCustomer && !loadingProses &&
                            <div className="mt-3">
                                <Masonry
                                    breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 1 : 2) : 2) : 3) : 3}`}
                                    className="my-masonry-grid-12"
                                    columnClassName="my-masonry-grid_column-12"
                                >
                                    {Object.keys(dataCustomer).map((key) => {
                                        return (
                                            <Card
                                                key={'hp48kl' + key}

                                                className={`px-2 py-2 ${Object.keys(dataCustomer).length === (parseInt(key) + 1) ? 'mb-0' : 'mb-3'} shadow-sm brSoftMedium cp`}

                                                onClick={() => handleGoCustomer(dataCustomer[key].id_c)}
                                            >
                                                <div className="flexAlignCenter">
                                                    <div className="ml-2">
                                                        <CustomerAvatar
                                                            prmSlug={dataCustomer[key].slug}
                                                            prmGender={dataCustomer[key].gender}
                                                            ukuran={{ width: '40px', height: '40px' }}
                                                        />
                                                    </div>

                                                    <div className="ml-3 wordBreakAll">
                                                        <div className="color636363 bold">
                                                            {dataCustomer[key].nama_c}
                                                        </div>

                                                        <div className="color808080 f13">
                                                            {dataCustomer[key].nama_ck}
                                                        </div>

                                                        <div className="color808080 f13 mt-2">
                                                            {dataCustomer[key].alamat}
                                                        </div>

                                                        <div className="color808080 f13">
                                                            {dataCustomer[key].no_hp}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        )
                                    })
                                    }
                                </Masonry>
                            </div>
                        }

                        <div className="text-center mt-3">
                            {dataCustomer && counterDataCustomer && hasMore && !loadingProses &&
                                <div className="text-center">
                                    <ButtonLoading
                                        prmLoading={btnLoadingMore}
                                        btnClass="btnXsOverride"
                                        btnVariant="info"
                                        btnOnClick={moreData}
                                        caption="Load More"
                                    />
                                </div>
                            }

                            {dataCustomer && counterDataCustomer && !hasMore && !loadingProses &&
                                <div className="text-center colorAaa">
                                    No More Data
                                </div>
                            }
                        </div>
                    </Col>

                    {counterDataCustomer && loadingProses &&
                        <Col xs={12}>
                            <div>
                                <PlaceHolderElement5Flex />
                            </div>
                        </Col>
                    }

                    {counterDataCustomer && !dataCustomer && !loadingCounter && !loadingProses &&
                        <Col xs={12}>
                            <div className="flexRowJustifyAlignCenter">
                                <LottiePausAstronot />
                            </div>
                        </Col>
                    }


                </Row>
            </Layout>

        </React.Fragment>
    );
}

export default PreCustomer;
