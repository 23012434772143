import React from 'react';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import 'font-awesome/css/font-awesome.min.css';
import { Row, Col, Card, Image, Form, Modal, Button } from 'react-bootstrap';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import CustomerAvatar from "../components/CustomerAvatar";
import soldering from "../assets/images/system/soldering.png";
import { ROOT_BASE_URL, BASE_URL_DUA, batasFluid, levelUser, metodeBayar, widthLG, widthMD, widthSM, widthXS } from "../utils/globalVar";
import { sqlDateToLongHumanDate, formatNumberCanZero, deko } from "../utils/globalFunc";
import Layout from "../components/Layout";
import NavbarTopOwner from "../components/NavbarTop";
import NavbarTopTeknisi from "../components/teknisi/NavbarTop";
import Masonry from 'react-masonry-css';
import ModalAlertWarning from '../components/ModalAlertWarning';
import PlaceHolderElement1 from "../components/PlaceHolderElement1";
import KopDokumen from "../components/KopDokumen";

const DokumenTandaTerima = () => {

    const parameter = useParams();
    const idTransaksi = parameter.id;

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [dataProses, setDataProses] = React.useState(null);
    const [dataKelengkapan, setDataKelengkapan] = React.useState(null);
    const [dataLabel, setDataLabel] = React.useState(null);
    const [penerima, setPenerima] = React.useState('');

    const [loadingDataProses, setLoadingDataProses] = React.useState(false);
    const [listFotoTransaksi, setListFotoTransaksi] = React.useState(null);
    const [modalZoomImage, setModalZoomImage] = React.useState(false);
    const [srcFoto, setSrcFoto] = React.useState('');

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [])

    React.useEffect(() => {
        setLoadingDataProses(true)

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/tanda_terima',
            data: {
                id_transaksi: idTransaksi,
                id_user_login: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            if (response.data && response.data.data_proses) {
                setDataProses(response.data.data_proses);
                setDataKelengkapan(response.data.data_kelengkapan);
                setListFotoTransaksi(response.data.data_foto_transaksi);
                setDataLabel(response.data.data_label);
                setPenerima(response.data.penerima);
            } else {
                setDataProses(null);
            }

            setLoadingDataProses(false)
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingDataProses(false)
            handleWarningModal('Warning : ' + error.message)
        })
    }, [idTransaksi, reduxLogin.syswebappi])

    const handleUangMuka = (prmUangMuka) => {
        if (!prmUangMuka || prmUangMuka === '0') {
            return '-';
        } else {
            return 'Rp ' + formatNumberCanZero(prmUangMuka);
        }
    }

    const handleMetodeUangMuka = (prmMetode) => {
        if (!prmMetode) {
            return '';
        } else {
            let hasil = '';

            Object.keys(metodeBayar).forEach(element => {
                if (element === prmMetode) {
                    hasil = '(Metode ' + metodeBayar[element] + ')';
                    return
                }
            });

            return hasil;
        }
    }

    const styles = StyleSheet.create({
        flexRow: {
            display: 'flex',
            flexDirection: "row",
            flexWrap: "nowrap"
        },
        subFlexRow: {
            flexBasis: 'auto',
            flexGrow: '1',
        },
        dataMasterSubSection: {
            display: 'flex',
            flexDirection: "row",
            flexWrap: "nowrap",
            marginBottom: '3px'
        },
        dataMasterCaption: {
            marginRight: '8px',
            width: '85px'
        },
        dataMasterContent: {
            flexBasis: 'auto',
            flexGrow: '1',
        },
        judulBarang: {
            fontWeight: 'bold',
            width: '200px',
            textAlign: 'center',
            paddingTop: '4px',
            paddingBottom: '4px',
            borderRight: '1px solid #000'
        },
        containerJudul: {
            display: 'flex',
            flexDirection: "row",
            flexWrap: "nowrap",
            borderBottom: '1px solid #000'
        },
        judulKeluhan: {
            fontWeight: 'bold',
            flexBasis: 'auto',
            flexGrow: '1',
            textAlign: 'center',
            paddingTop: '4px',
            paddingBottom: '4px'
        },
        containerSectionA: {
            display: 'flex',
            flexDirection: "row",
            flexWrap: "nowrap",
            marginBottom: '4px',
            fontSize: '10px'
        },
        containerSectionB: {
            display: 'flex',
            flexDirection: "row",
            flexWrap: "nowrap",
            marginBottom: '3px',
            fontSize: '10px'
        },
        serahTerima: {
            display: 'flex',
            flexDirection: "column",
            flexWrap: "nowrap",
            flexBasis: 'auto',
            flexGrow: '1',
            justifyContent: 'center',
            alignItems: 'center'
        }
    });

    const PdfTandaTerima = () => {
        return (
            <Document>
                <Page size="a5" orientation="landscape" style={{ padding: '16px 32px 16px 32px' }}>
                    {
                        dataProses &&
                        <View>
                            <KopDokumen />

                            <View style={{ marginTop: '10px' }}>
                                <Text style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'center' }}>
                                    Tanda Terima
                                </Text>
                            </View>

                            <View style={{ marginTop: '16px', fontSize: '10px' }}>
                                <View style={styles.flexRow}>
                                    <View style={styles.subFlexRow}>
                                        <View style={styles.dataMasterSubSection}>
                                            <Text style={styles.dataMasterCaption}>
                                                No Nota
                                            </Text>

                                            <Text style={styles.dataMasterContent}>
                                                : {dataProses[0].id_transaksi}
                                            </Text>
                                        </View>

                                        <View style={styles.dataMasterSubSection}>
                                            <Text style={styles.dataMasterCaption}>
                                                Customer
                                            </Text>

                                            <Text style={styles.dataMasterContent}>
                                                : {dataProses[0].nama}
                                            </Text>
                                        </View>
                                    </View>

                                    <View style={styles.subFlexRow}>
                                        <View style={styles.dataMasterSubSection}>
                                            <Text style={styles.dataMasterCaption}>
                                                Telepon
                                            </Text>

                                            <Text style={styles.dataMasterContent}>
                                                : {dataProses[0].no_hp}
                                            </Text>
                                        </View>

                                        <View style={styles.dataMasterSubSection}>
                                            <Text style={styles.dataMasterCaption}>
                                                Tanggal Masuk
                                            </Text>

                                            <Text style={styles.dataMasterContent}>
                                                : {dataProses[0].tanggal_masuk ? sqlDateToLongHumanDate(dataProses[0].tanggal_masuk) : ' - '}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={{ border: '1px solid #000', marginTop: '10px', fontSize: '10px' }}>
                                <View style={styles.containerJudul}>
                                    <Text style={styles.judulBarang}>
                                        Barang
                                    </Text>

                                    <Text style={styles.judulKeluhan}>
                                        Keluhan / Kerusakan
                                    </Text>
                                </View>

                                <View style={{ display: 'flex', flexDirection: "row", flexWrap: "nowrap" }}>
                                    <Text style={{ width: '200px', borderRight: '1px solid #000', padding: '4px' }}>
                                        {dataProses[0].nama_barang}
                                    </Text>

                                    <Text style={{ flexBasis: 'auto', flexGrow: '1', padding: '4px' }}>
                                        {dataProses[0].kerusakan}
                                    </Text>
                                </View>
                            </View>

                            <View style={{ marginTop: '10px' }}>
                                <View style={styles.containerSectionA}>
                                    <Text style={{ marginRight: '8px', width: '120px' }}>
                                        Kelengkapan Barang
                                    </Text>

                                    <Text style={{ flexBasis: 'auto', flexGrow: '1' }}>
                                        : {dataLabel ? Object.keys(dataLabel).map((keyDL) => {
                                            return (
                                                <Text key={keyDL + 'dl_78'}>
                                                    <Text>{dataLabel[keyDL].label}</Text>
                                                    <Text>
                                                        {dataLabel.length === (Number(keyDL) + 1) ? '' : ', '}
                                                    </Text>
                                                </Text>
                                            )
                                        })
                                            :
                                            <Text>Tidak ada</Text>
                                        }
                                    </Text>
                                </View>

                                <View style={styles.containerSectionB}>
                                    <Text style={{ marginRight: '8px', width: '120px' }}>
                                        Uang Muka
                                    </Text>

                                    <Text style={{ flexBasis: 'auto', flexGrow: '1' }}>
                                        : {handleUangMuka(dataProses[0].dp)}
                                        {' '}
                                        {handleMetodeUangMuka(dataProses[0].metode_dp)}
                                    </Text>
                                </View>
                            </View>

                            <View break style={{ marginTop: '16px' }}>
                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: "row",
                                        justifyContent: 'space-between',
                                        flexWrap: "nowrap"
                                    }}
                                >
                                    <View
                                        style={{
                                            flexBasis: 'auto',
                                            flexGrow: '0.55',
                                            fontSize: '8px'
                                        }}
                                    >
                                        <View>
                                            <Text>Catatan : </Text>
                                        </View>

                                        <View style={{ marginTop: '6px' }}>
                                            <View style={styles.flexRow}>
                                                <View style={{ width: '4px', marginRight: '2px' }}>
                                                    <Text>-</Text>
                                                </View>

                                                <View style={styles.subFlexRow}>
                                                    <Text>
                                                        Barang dapat diambil apabila sudah menyelesaikan pembayaran dan membawa bukti Tanda Terima ini
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>

                                        <View style={{ marginTop: '3px' }}>
                                            <View style={styles.flexRow}>
                                                <View style={{ width: '4px', marginRight: '2px' }}>
                                                    <Text>-</Text>
                                                </View>

                                                <View style={styles.subFlexRow}>
                                                    <Text>
                                                        Pembayaran dapat dilakukan Tunai/Transfer BCA 8100 877 989 (Hans Adrian)
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>

                                        <View style={{ marginTop: '3px' }}>
                                            <View style={styles.flexRow}>
                                                <View style={{ width: '4px', marginRight: '2px' }}>
                                                    <Text>-</Text>
                                                </View>

                                                <View style={styles.subFlexRow}>
                                                    <Text>
                                                        Kehilangan/kerusakan barang akibat tidak diambil oleh customer dalam jangka waktu lebih dari 1 bulan, diluar tanggung jawab kami.
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                    <View
                                        style={{
                                            display: 'flex',
                                            flexDirection: "row",
                                            justifyContent: 'space-between',
                                            flexWrap: "nowrap",
                                            fontSize: '10px',
                                            flexBasis: 'auto',
                                            flexGrow: '0.4'
                                        }}
                                    >
                                        <View style={styles.serahTerima}>
                                            <View style={{ marginBottom: '32px' }}>
                                                <Text>Menyerahkan,</Text>
                                            </View>

                                            <View>
                                                <Text>Customer</Text>
                                            </View>
                                        </View>

                                        <View style={styles.serahTerima}>
                                            <View style={{ marginBottom: '32px' }}>
                                                <Text>Menerima,</Text>
                                            </View>

                                            <View>
                                                <Text>Lumos</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>

                        </View>
                    }
                </Page>
            </Document>
        )
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            {deko(reduxLogin.syswebappj) === levelUser.owner ?
                <NavbarTopOwner
                    shadow={true}
                    titleNavTop="Cetak Tanda Terima"
                    prmBackButton={true}
                />
                :
                <NavbarTopTeknisi
                    shadow={true}
                    titleNavTop="Cetak Tanda Terima"
                    prmBackButton={true}
                />
            }

            <Layout titleTab="Cetak Tanda Terima">
                <Row>
                    <Col xs={12} className="mt-3">
                        {
                            dataProses && !loadingDataProses &&
                            <React.Fragment>
                                <Card
                                    className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                >
                                    <div className="flex">
                                        <div>
                                            <CustomerAvatar
                                                prmSlug={dataProses[0].slug_ck}
                                                prmGender={dataProses[0].gender}
                                                ukuran={{ width: '55px', height: '55px' }}
                                            />
                                        </div>

                                        <div className="ml-3 wordBreakAll" style={{ width: '100%' }}>
                                            <div className="color636363 bold">
                                                {dataProses[0].nama}
                                            </div>

                                            <div className="color808080 f14">
                                                {dataProses[0].nama_ktgr_cust}
                                            </div>

                                            <div className="color808080 f14 mt-2">
                                                {dataProses[0].alamat}
                                            </div>

                                            {deko(reduxLogin.syswebappj) === levelUser.owner &&
                                                <div className="color808080 f14">
                                                    {dataProses[0].no_hp}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Card>

                                <Card
                                    className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                >
                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">No Nota</div>
                                        <div className="color808080">{dataProses[0].id_transaksi}</div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">Tanggal Masuk</div>
                                        <div className="color808080">{sqlDateToLongHumanDate(dataProses[0].tanggal_masuk)}</div>
                                    </div>

                                    <div className="f14">
                                        <div className="color636363 fontweight600 mb-1">Penerima</div>
                                        <div className="color808080">{penerima}</div>
                                    </div>
                                </Card>

                                <Card
                                    className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                >
                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">
                                            Kategori Barang
                                        </div>
                                        <div className="color808080">{dataProses[0].nama_barang_kategori}</div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">Barang</div>
                                        <div className="color808080">{dataProses[0].nama_barang}</div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600">Keluhan</div>
                                        <div className="color808080">{dataProses[0].kerusakan}</div>
                                    </div>

                                    <div className="f14 mb-3">
                                        <div className="color636363 fontweight600">Foto Barang</div>

                                        {listFotoTransaksi ?
                                            <div className="text-center mt-2">
                                                <Masonry
                                                    breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 4 : 5) : 5) : 6) : 6}`}
                                                    className="my-masonry-grid"
                                                    columnClassName="my-masonry-grid_column"
                                                >
                                                    {Object.keys(listFotoTransaksi).map((key) => {
                                                        return (
                                                            <img
                                                                key={"lfkp87hjsdk" + key}
                                                                className="rounded-lg width100 cp"
                                                                src={ROOT_BASE_URL + listFotoTransaksi[key].foto}
                                                                alt={'foto'}
                                                                onClick={() => {
                                                                    setSrcFoto(ROOT_BASE_URL + listFotoTransaksi[key].foto)
                                                                    setModalZoomImage(true);
                                                                }}
                                                            />
                                                        )
                                                    })}
                                                </Masonry>
                                            </div>
                                            :
                                            <div className="color808080">
                                                Tidak ada data foto
                                            </div>
                                        }
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-2">
                                            Form Cek Barang {dataKelengkapan && dataKelengkapan[0].nama_kategori}
                                        </div>
                                        <div className="color808080">
                                            {
                                                dataKelengkapan &&
                                                Object.keys(dataKelengkapan).map((key) => {
                                                    return (
                                                        <React.Fragment key={key + 'kkl7js'}>
                                                            <Form.Row className="mb-2">
                                                                <Form.Group
                                                                    as={Col}
                                                                    xs="12"
                                                                    className="mb-0"
                                                                >

                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        custom
                                                                    >
                                                                        <Form.Check.Input
                                                                            readOnly
                                                                            disabled
                                                                            type="checkbox"
                                                                            defaultChecked={parseInt(dataKelengkapan[key].check)}
                                                                        />

                                                                        <Form.Check.Label
                                                                            style={{ paddingTop: '2px' }}
                                                                        >
                                                                            {dataKelengkapan[key].nama_tdk}
                                                                        </Form.Check.Label>

                                                                        <div className="mt-1 color808080 f14">
                                                                            {dataKelengkapan[key].keterangan}
                                                                        </div>
                                                                    </Form.Check>
                                                                </Form.Group>

                                                            </Form.Row>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600">Uang Muka</div>
                                        <div className="color808080">
                                            {handleUangMuka(dataProses[0].dp)}
                                            {' '}
                                            {handleMetodeUangMuka(dataProses[0].metode_dp)}
                                        </div>
                                    </div>

                                    <div className="f14">
                                        <div className="color636363 fontweight600">Catatan</div>
                                        <div className="color808080">
                                            {dataProses[0].catatan ? dataProses[0].catatan : '-'}
                                        </div>
                                    </div>
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                >
                                    <div className="f14 mb-3 colorTheme fontweight600">
                                        Kelengkapan Barang
                                    </div>

                                    <div>
                                        <div className="color636363 fontweight600">
                                            Kelengkapan Utama :
                                        </div>
                                        <div className="color808080 mt-1">{dataProses[0].nama_barang}</div>
                                    </div>

                                    <div className="mt-3">
                                        <div className="color636363 fontweight600 mb-1">
                                            Kelengkapan Lainnya :
                                        </div>

                                        {dataLabel ? Object.keys(dataLabel).map((keyDL) => {
                                            return (
                                                <span
                                                    key={keyDL + 'dl_78'}
                                                    className={`color808080`}
                                                >
                                                    <span>{dataLabel[keyDL].label}</span>
                                                    <span>{dataLabel.length === (Number(keyDL) + 1) ? '' : ', '}</span>
                                                </span>
                                            )
                                        })
                                            :
                                            <div className="color808080">Tidak ada</div>
                                        }
                                    </div>
                                </Card>

                                <div className="mt-4">
                                    <PDFDownloadLink document={<PdfTandaTerima />} fileName={`tanda_terima_${idTransaksi}.pdf`}>
                                        {({ blob, url, loading, error }) =>
                                            <Button
                                                disabled={loading}
                                                variant='info'
                                                size="sm"
                                                className="btnXsOverride width100"
                                            >
                                                <span>Download Tanda Terima</span>
                                            </Button>
                                        }
                                    </PDFDownloadLink>
                                </div>
                            </React.Fragment>
                        }

                        {
                            !dataProses && loadingDataProses &&
                            <PlaceHolderElement1 prmMT="" prmPX="" />
                        }

                        {
                            !dataProses && !loadingDataProses &&
                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-5' : 'p-4'} shadow-sm brSoftMedium text-center f13`}>
                                <div className="f14 mb-3 color808080">
                                    Tidak ada data
                                </div>

                                <div className="flexJustifyAlignCenter">
                                    <Image
                                        src={soldering}
                                        style={{
                                            width: '75%'
                                        }}
                                    />
                                </div>
                            </Card>
                        }
                    </Col>
                </Row>
            </Layout>

            <Modal
                size="lg"
                show={modalZoomImage}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body className="p-0">
                    <div className="flexJustifyAlignCenter">
                        <span
                            onClick={() => setModalZoomImage(false)}
                            className="fa fa-close cp f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                            style={{
                                borderRadius: '50%',
                                width: '35px',
                                height: '35px',
                                position: 'absolute',
                                top: '15px',
                                right: '17px'
                            }}
                        />

                        <img
                            src={srcFoto}
                            alt='foto'
                            style={{ width: '100%' }}
                        />
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment>
    );
}

export default DokumenTandaTerima;
