import React from 'react';
import axios from "axios";
import Masonry from 'react-masonry-css';
import { Row, Col, Button, Spinner, InputGroup, FormControl } from 'react-bootstrap';
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import 'font-awesome/css/font-awesome.min.css';

import { BASE_URL, batasFluid, levelUser } from "../../utils/globalVar";
import { dateTimeToHumanDateTime, deko } from "../../utils/globalFunc";
import NavbarTopOwner from "../../components/NavbarTop";
import NavbarTopTeknisi from "../../components/teknisi/NavbarTop";
import Layout from "../../components/Layout";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ButtonLoading from "../../components/ButtonLoading";
import ModalConfirmation from "../../components/ModalConfirmation";
import LottiePausAstronot from "../../components/LottiePausAstronot";
import PlaceHolderElement5Flex from "../../components/PlaceHolderElement5Flex";

const ListStokBarang = () => {

    const history = useHistory();

    const parameter = useParams();
    const idTransaksi = parameter.idTransaksi;
    const idTDP = parameter.idTDP;

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [loadingDataInit, setLoadingDataInit] = React.useState(false);
    const [loadingSearch, setLoadingSearch] = React.useState(false);
    const [dataStok, setDataStok] = React.useState(null);
    const refInputCariBarang = React.useRef('');

    const [heightNavTop, setHeightNavTop] = React.useState(0);
    const limitData = 10;
    const refOffsetData = React.useRef(0);
    const [hasMore, setHasMore] = React.useState(true);
    const refCountAllStok = React.useRef(0);

    const [prmBtnLoading, setPrmBtnLoading] = React.useState(false);

    const [textInputSearch, setTextInputSearch] = React.useState('');
    const [btnLoadingMore, setBtnLoadingMore] = React.useState(false);

    const [modalPickBarang, setModalPickBarang] = React.useState(false);
    const refNamaForPickBarang = React.useRef('');
    const refIdS = React.useRef('');
    const refIdSK = React.useRef('');

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.querySelector(".containerLuarTopNavbar") && setHeightNavTop(document.querySelector(".containerLuarTopNavbar").clientHeight);

        return () => mountedRef.current = false;
    }, [])

    const moreData = () => {
        // ! ok
        setBtnLoadingMore(true);

        axios({
            method: 'post',
            url: BASE_URL + '/cari_stok_part',
            data: {
                inputan: textInputSearch,
                limit_data: limitData,
                offset_data: refOffsetData.current,
                id_transaksi: idTransaksi,
                id_tdp: idTDP,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (response.data.cek_parameter === false) {
                history.push('/notfound')
            } else {
                if (!mountedRef.current) return null;

                setBtnLoadingMore(false);

                dataStok && response.data.data_stok && setDataStok([...dataStok, ...response.data.data_stok]);
                dataStok && response.data.data_stok && [...dataStok, ...response.data.data_stok].length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);
                if (response.data.data_stok) { refOffsetData.current = refOffsetData.current + limitData };

                refCountAllStok.current = response.data.count_all_stok;
            }
        }).catch(error => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);
            handleWarningModal(error.message)
        })
    }

    const getData = React.useCallback((dt) => {
        // ! ok
        axios({
            method: 'post',
            url: BASE_URL + '/cari_stok_part',
            data: dt
        }).then((response) => {
            if (response.data.cek_parameter === false) {
                history.push('/notfound')
            } else {
                if (!mountedRef.current) return null;

                setLoadingDataInit(false);
                setLoadingSearch(false);

                response.data.data_stok ? setDataStok(response.data.data_stok) : setDataStok(null);

                if (response.data.data_stok) { refOffsetData.current = refOffsetData.current + limitData };

                refCountAllStok.current = response.data.count_all_stok;

                response.data.data_stok && response.data.data_stok.length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);
            }
        }).catch(error => {
            if (!mountedRef.current) return null;
            setLoadingDataInit(false);
            setLoadingSearch(false);
            handleWarningModal(error.message)
        })
    }, [history]);

    React.useEffect(() => {
        // ! ok
        !textInputSearch && setLoadingDataInit(true);
        textInputSearch && setLoadingSearch(true);

        refOffsetData.current = 0;

        const handler = setTimeout(() => {
            const dt = {
                inputan: textInputSearch,
                limit_data: limitData,
                offset_data: refOffsetData.current,
                id_transaksi: idTransaksi,
                id_tdp: idTDP,
                id_user: deko(reduxLogin.syswebappi)
            }

            getData(dt)
        }, 1000);

        return () => clearTimeout(handler);
    }, [textInputSearch, getData, idTDP, idTransaksi, reduxLogin.syswebappi]);

    const handleGunakan = () => {
        setPrmBtnLoading(true);

        axios({
            method: 'post',
            url: BASE_URL + '/gunakan_item_stok',
            data: {
                id_tdp: idTDP,
                id_s: refIdS.current,
                id_sk: refIdSK.current,
                id_user: deko(reduxLogin.syswebappi),
                id_transaksi: idTransaksi
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setPrmBtnLoading(false);
            setModalPickBarang(false);

            response.data.flag_hasil === 'access-denied' && handleWarningModal(<React.Fragment><div className="alert alert-info">Progres tanggal {dateTimeToHumanDateTime(response.data.tanggal_progres)} sedang dikerjakan oleh {response.data.nama_user}. <br /><br />Anda tidak diperkenankan untuk mengakses progres ini.</div><div className="alert alert-warning"><span className="fa fa-info-circle" /> Tips : Buat progres baru untuk menambahkan part atau item ini.</div></React.Fragment>);

            response.data.flag_hasil === true && history.push('/working/' + idTransaksi + '/' + idTDP);

            response.data.flag_hasil === false && handleWarningModal('Error : ' + response.data.pesan);

            response.data.flag_hasil === 'no-data' && handleWarningModal('Data Stok Tidak Ditemukan.');

            if (response.data.flag_hasil === 'not-found') {
                handleWarningModal(<React.Fragment><div className="alert alert-danger">Proses pengerjaan barang sudah tidak available. Kembali ke halaman sebelumnya untuk memuat data terbaru.</div></React.Fragment>);
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setPrmBtnLoading(false);
            handleWarningModal(error.message)
        })
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            {deko(reduxLogin.syswebappj) === levelUser.owner &&
                <NavbarTopOwner
                    shadow={true}
                    titleNavTop="Daftar Stok"
                    prmBackButton={true}
                    prmOnBack={`/working/${idTransaksi}`}
                />
            }

            {deko(reduxLogin.syswebappj) === levelUser.teknisi &&
                <NavbarTopTeknisi
                    shadow={true}
                    titleNavTop="Daftar Stok"
                    prmBackButton={true}
                    prmOnBack={`/working/${idTransaksi}`}
                />
            }

            <Layout titleTab="Daftar Stok">
                <Row>
                    <Col
                        xs={12}
                        className="pt-3"
                        style={{
                            position: 'sticky',
                            top: `${heightNavTop}px`,
                            zIndex: '3',
                            backgroundColor: '#fff'
                        }}
                    >
                        <InputGroup className="mb-3">
                            <FormControl
                                size="sm"
                                type="text"
                                placeholder="Cari Barang Disini"
                                ref={refInputCariBarang}
                                value={textInputSearch}
                                onChange={(e) => setTextInputSearch(e.target.value)}

                                className="removeShadowOnFocus"
                                style={{ borderRight: '0 solid #eee' }}
                            />

                            <InputGroup.Append>
                                <InputGroup.Text id="ad-cbd-9890" className="bgColorForm">
                                    {loadingSearch &&
                                        <Spinner animation="border" size="sm" variant="info" />
                                    }
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>

                    {loadingDataInit && <Col xs={12} className="mt-3"><PlaceHolderElement5Flex /></Col>}

                    {!loadingDataInit && dataStok &&
                        <Col xs={12} className="mt-1">

                            <Masonry
                                breakpointCols={`${reduxWindowWidth > batasFluid ? 4 : 2}`}
                                className="my-masonry-grid-12"
                                columnClassName="my-masonry-grid_column-12"
                            >
                                {Object.keys(dataStok).map((key) => {
                                    return (
                                        <div
                                            key={key}
                                            className="shadow-sm flexRowJustifyAlignCenter brSoftMedium wordBreakAll"
                                            style={{ borderRadius: '6px' }}
                                        >
                                            <div
                                                className="pt-3 pb-2 px-3 bgEee"
                                                style={{
                                                    flex: 1,
                                                    width: '100%',
                                                    borderRadius: '6px 6px 0 0',
                                                    borderBottom: '1px solid #eee'
                                                }}
                                            >
                                                <div
                                                    className="f13 color808080"
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className="colorTheme fontweight600 mb-1">
                                                        {dataStok[key].nama_s}
                                                    </div>

                                                    <div className="f12">
                                                        {dataStok[key].merk} {dataStok[key].combine_field}
                                                    </div>

                                                    <div className="f12">
                                                        {dataStok[key].tipe}
                                                    </div>

                                                    <div className="f12">
                                                        {dataStok[key].serial_number ? 'SN ' + dataStok[key].serial_number : 'SN -'}
                                                    </div>

                                                    <div className="f12">
                                                        Garansi {dataStok[key].masa_garansi} Bulan
                                                    </div>

                                                    <div className="f12 flexJustifyBeetwenAlignCenter mt-1">
                                                        <div>
                                                            Stok {dataStok[key].jumlah}
                                                        </div>
                                                        <div>
                                                            {Number(dataStok[key].is_original) ? 'Ori' : 'Non Ori'}
                                                        </div>
                                                    </div>

                                                    <div className="f11 mt-2 colorAaa">
                                                        Kategori {dataStok[key].nama_sk}
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className="py-2 px-3 f12 color808080"
                                                style={{ width: '100%', borderRadius: '0 0 6px 6px' }}
                                            >
                                                <div className="text-center">
                                                    <Button
                                                        variant="outline-info"
                                                        size="sm"
                                                        className="btnXsOverride"
                                                        onClick={() => {
                                                            refNamaForPickBarang.current = dataStok[key].nama_s;
                                                            refIdS.current = dataStok[key].id_s;
                                                            refIdSK.current = dataStok[key].id_sk;
                                                            setModalPickBarang(true);
                                                        }}
                                                    >
                                                        Gunakan
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Masonry>

                            <div className="text-center mt-3">
                                {hasMore ?
                                    <div className="text-center">
                                        <ButtonLoading
                                            prmLoading={btnLoadingMore}
                                            btnClass="btnXsOverride"
                                            btnVariant="info"
                                            btnOnClick={moreData}
                                            caption="Load More"
                                        />
                                    </div>
                                    :
                                    <div className="text-center colorAaa">
                                        No More Data
                                    </div>
                                }
                            </div>
                        </Col>
                    }

                    {!loadingDataInit && !dataStok &&
                        <Col xs={12} className="mt-2 flexRowJustifyAlignCenter">
                            <LottiePausAstronot caption="Stok tidak tersedia" />
                        </Col>
                    }
                </Row>
            </Layout>

            <ModalConfirmation
                showModal={modalPickBarang}
                hideModal={() => setModalPickBarang(false)}
                title="Konfirmasi"
                bodyCaption={`Gunakan ${refNamaForPickBarang.current} ?`}
                btnCloseClass="px-4"
                btnCloseCaption="Close"
                bLPrm={prmBtnLoading}
                bLBtnClass="ml-3"
                bLOnClick={handleGunakan}
                bLCaption="Gunakan"
            />

        </React.Fragment >
    );
}

export default ListStokBarang;
