import React from 'react';
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Form, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import CustomerAvatar from "../components/CustomerAvatar";
import { widthLG, widthMD, widthSM, widthXS, batasFluid, BASE_URL, levelUser } from "../utils/globalVar";
import { sqlDateToLongHumanDate, deko } from "../utils/globalFunc";
import ModalAlertWarning from "../components/ModalAlertWarning";
import LottiePausAstronot from "../components/LottiePausAstronot";
import PlaceHolderElement4Flex from "../components/PlaceHolderElement4Flex";
import ButtonLoading from '../components/ButtonLoading';
import BarcodeScanner from '../components/BarcodeScanner';

const ProsesDua = ({ heightNavTop, heightNavProsesScroll }) => {

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const history = useHistory();
    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    const refUrutkan = React.useRef('desc');
    const refBatasHari = React.useRef('all');
    const refCustomer = React.useRef('all');
    const refBarang = React.useRef('all');

    const [loadingProses, setLoadingProses] = React.useState(false);
    const [dataProses, setDataProses] = React.useState(null);
    const [listKategoriCustomer, setListKategoriCustomer] = React.useState(null);
    const [listBarangKategori, setListBarangKategori] = React.useState(null);
    const [batasHariNota, setBatasHariNota] = React.useState(0);

    const [prmTdkAdaHasil, setPrmTdkAdaHasil] = React.useState(true);
    const [textInputSearch, setTextInputSearch] = React.useState('');

    const [searchBarHeight, setSearchBarHeight] = React.useState(0);

    const limitData = 10;
    const refOffsetData = React.useRef(0);
    const [hasMore, setHasMore] = React.useState(false);
    const [btnLoadingMore, setBtnLoadingMore] = React.useState(false);
    const [loadingTyping, setLoadingTyping] = React.useState(false);

    const [modalBarcodeScanner, setModalBarcodeScanner] = React.useState(false);
    const handleScanBarcode = (err, result) => {
        if (result) {
            setTextInputSearch(result.text);
            setModalBarcodeScanner(false);
        }
    }

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.querySelector(".containerSearchNota") && setSearchBarHeight(document.querySelector(".containerSearchNota").clientHeight);

        return () => mountedRef.current = false;
    }, []);

    const moreData = () => {
        setBtnLoadingMore(true);

        axios({
            method: 'post',
            url: BASE_URL + '/proses_flag_limit_google',
            data: {
                inputan: textInputSearch,
                urutkan: refUrutkan.current.value,
                filter_batas_hari: refBatasHari.current.value,
                filter_customer: refCustomer.current.value,
                filter_barang_kategori: refBarang.current.value,
                flag_proses: 2,
                limit_data: limitData,
                offset_data: refOffsetData.current,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingMore(false);

            response.data.data_nota ? setPrmTdkAdaHasil(true) : setPrmTdkAdaHasil(false);

            dataProses && response.data.data_nota && setDataProses([...dataProses, ...response.data.data_nota]);

            response.data.data_customer_kategori && setListKategoriCustomer(response.data.data_customer_kategori);
            response.data.data_barang_kategori && setListBarangKategori(response.data.data_barang_kategori);
            response.data.data_batas_hari_nota ? setBatasHariNota(response.data.data_batas_hari_nota) : setBatasHariNota(0);

            dataProses && response.data.data_nota && [...dataProses, ...response.data.data_nota].length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

            if (response.data.data_nota) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);
            handleWarningModal(error.message)
        })
    }

    const getData = React.useCallback((dt) => {
        axios({
            method: 'post',
            url: BASE_URL + '/proses_flag_limit_google',
            data: dt
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingProses(false);
            setLoadingTyping(false);

            response.data.data_nota ? setPrmTdkAdaHasil(true) : setPrmTdkAdaHasil(false);
            response.data.data_nota ? setDataProses(response.data.data_nota) : setDataProses(null);
            response.data.data_customer_kategori && setListKategoriCustomer(response.data.data_customer_kategori);
            response.data.data_barang_kategori && setListBarangKategori(response.data.data_barang_kategori);
            response.data.data_batas_hari_nota ? setBatasHariNota(response.data.data_batas_hari_nota) : setBatasHariNota(0);

            response.data.data_nota && response.data.data_nota.length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

            if (response.data.data_nota) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;
            setLoadingProses(false);
            setLoadingTyping(false);
            handleWarningModal(error.message)
        })
    }, []);

    React.useEffect(() => {
        !textInputSearch && setLoadingProses(true);
        textInputSearch && setLoadingTyping(true);

        refOffsetData.current = 0;

        const handler = setTimeout(() => {
            const dt = {
                inputan: textInputSearch,
                urutkan: refUrutkan.current.value,
                filter_batas_hari: refBatasHari.current.value,
                filter_customer: refCustomer.current.value,
                filter_barang_kategori: refBarang.current.value,
                flag_proses: 2,
                limit_data: limitData,
                offset_data: refOffsetData.current,
                id_user: deko(reduxLogin.syswebappi)
            }

            getData(dt)
        }, textInputSearch ? 1000 : 500);

        return () => clearTimeout(handler);
    }, [textInputSearch, getData, reduxLogin.syswebappi]);

    const onChangePilihan = (prmUrutkan, prmBatasHari, prmCustomer, prmBarang) => {
        setLoadingProses(true);

        refOffsetData.current = 0;

        const dt = {
            inputan: textInputSearch,
            urutkan: prmUrutkan,
            filter_batas_hari: prmBatasHari,
            filter_customer: prmCustomer,
            filter_barang_kategori: prmBarang,
            flag_proses: 2,
            limit_data: limitData,
            offset_data: refOffsetData.current,
            id_user: deko(reduxLogin.syswebappi)
        }

        getData(dt);
    }

    return (
        <React.Fragment>

            <BarcodeScanner
                modalBarcodeScanner={modalBarcodeScanner}
                onSken={(satu, dua) => handleScanBarcode(satu, dua)}
                closeModalScanBarcode={() => setModalBarcodeScanner(false)}
            />

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <Col xs={12} className="mt-0 px-0">
                <div
                    className="mb-0 px-3 py-3 containerSearchNota"
                    style={{
                        position: 'sticky',
                        top: `${heightNavTop + heightNavProsesScroll + 0.5}px`,
                        zIndex: '3',
                        backgroundColor: '#fff'
                    }}
                >
                    <InputGroup>
                        <FormControl
                            size="sm"
                            placeholder="Ketik No Nota / Customer / Barang"
                            className="removeShadowOnFocus"
                            style={{ borderRight: '0 solid #eee' }}
                            value={textInputSearch}
                            onChange={(e) => setTextInputSearch(e.target.value)}
                        />

                        <InputGroup.Append>
                            <InputGroup.Text id="ad-cbd-9890" className="bgColorForm">
                                {loadingTyping &&
                                    <Spinner animation="border" size="sm" variant="info" />
                                }

                                <span className="fa fa-barcode ml-2" onClick={() => setModalBarcodeScanner(true)} />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </div>

                <div className="pt-1 px-3 bgWhite">
                    <Row>
                        <Col xs={6} className="pr-2 mb-3">
                            <Form.Control
                                custom
                                as="select"
                                size="sm"
                                ref={refUrutkan}
                                onChange={(e) => onChangePilihan(e.target.value, refBatasHari.current.value, refCustomer.current.value, refBarang.current.value)}
                            >
                                <option value="desc">Tanggal Terbaru</option>
                                <option value="asc">Tanggal Terlama</option>
                            </Form.Control>
                        </Col>

                        <Col xs={6} className="pl-2 mb-3">
                            <Form.Control
                                custom
                                as="select"
                                size="sm"
                                ref={refCustomer}
                                onChange={(e) => onChangePilihan(refUrutkan.current.value, refBatasHari.current.value, e.target.value, refBarang.current.value)}
                            >
                                <option value="all">Semua Customer</option>
                                {
                                    listKategoriCustomer &&
                                    Object.keys(listKategoriCustomer).map((key) => {
                                        return (
                                            <option
                                                key={'kcfcbajhsd56' + key}
                                                value={listKategoriCustomer[key].id}>
                                                {listKategoriCustomer[key].nama}
                                            </option>
                                        )
                                    })
                                }
                            </Form.Control>
                        </Col>

                        <Col xs={6} className="pr-2 mb-3">
                            <Form.Control
                                custom
                                as="select"
                                size="sm"
                                ref={refBatasHari}
                                onChange={(e) => onChangePilihan(refUrutkan.current.value, e.target.value, refCustomer.current.value, refBarang.current.value)}
                            >
                                <option value="all">Semua Batas Hari</option>
                                <option value={`over-${batasHariNota}`}>Lebih Dari {batasHariNota} Hari</option>
                                <option value={`less-${batasHariNota}`}>Kurang Dari {batasHariNota} Hari</option>
                            </Form.Control>
                        </Col>

                        <Col xs={6} className="pl-2 mb-3">
                            <Form.Control
                                custom
                                as="select"
                                size="sm"
                                ref={refBarang}
                                onChange={(e) => onChangePilihan(refUrutkan.current.value, refBatasHari.current.value, refCustomer.current.value, e.target.value)}
                            >
                                <option value="all">Semua Barang</option>
                                {
                                    listBarangKategori &&
                                    Object.keys(listBarangKategori).map((key) => {
                                        return (
                                            <option
                                                key={'bkajsdh9' + key}
                                                value={listBarangKategori[key].id}
                                            >
                                                {listBarangKategori[key].nama}
                                            </option>
                                        )
                                    })
                                }
                            </Form.Control>
                        </Col>
                    </Row>
                </div>

                <div
                    style={{
                        position: 'sticky',
                        top: `${heightNavTop + heightNavProsesScroll - 0.5 + searchBarHeight}px`,
                        zIndex: '2',
                        borderBottom: '1px solid #eee'
                    }}
                    className="mb-3 shadow-sm"
                />

                {loadingProses && <PlaceHolderElement4Flex />}

                {
                    !loadingProses && !prmTdkAdaHasil && !dataProses &&
                    <div className="flexRowJustifyAlignCenter">
                        <LottiePausAstronot />
                    </div>
                }

                {
                    !loadingProses && dataProses &&
                    <div>
                        <div className="px-3 mb-2">
                            <Masonry
                                breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 1 : 2) : 2) : 3) : 3}`}
                                className="my-masonry-grid-12"
                                columnClassName="my-masonry-grid_column-12"
                            >
                                {Object.keys(dataProses).map((key) => {
                                    return (
                                        <Card
                                            key={'prosesSatu' + key}
                                            className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3 cp`}

                                            onClick={() => {
                                                (deko(reduxLogin.syswebappj) === levelUser.teknisi || deko(reduxLogin.syswebappj) === levelUser.owner) && history.push('/working/' + dataProses[key].id_transaksi)
                                            }}
                                        >
                                            <div style={{ width: '100%' }}>

                                                <div className="flex">
                                                    <div className="mr-3">
                                                        <CustomerAvatar
                                                            prmSlug={dataProses[key].slug_ck}
                                                            prmGender={dataProses[key].gender}
                                                            ukuran={{ width: '40px', height: '40px' }}
                                                        />
                                                    </div>

                                                    <div>
                                                        <div className="bold color636363">
                                                            {dataProses[key].nama}
                                                        </div>
                                                        <div className="mb-1 color808080">
                                                            {dataProses[key].nama_cust_ktgr}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div><hr className="mt-2 mb-3" /></div>

                                                <div className="f13">
                                                    <span className="fontweight500 mr-2 color636363">No Nota</span>
                                                    <span className="color808080">{dataProses[key].id_transaksi}</span>
                                                </div>

                                                <div className="f13">
                                                    <span className="fontweight500 mr-2 color636363">
                                                        Kategori Barang
                                                    </span>
                                                    <span className="color808080">
                                                        {dataProses[key].nama_barang_kategori ? dataProses[key].nama_barang_kategori : 'Belum Ada Kategori'}
                                                    </span>
                                                </div>

                                                <div className="f13 mt-2">
                                                    <span className="fontweight500 mr-2 color636363">
                                                        Nama Barang
                                                    </span>
                                                    <span className="color808080">
                                                        {dataProses[key].nama_barang}
                                                    </span>
                                                </div>

                                                <div className="f13">
                                                    <span className="fontweight500 mr-2 color636363">
                                                        Keluhan
                                                    </span>
                                                    <span className="color808080">
                                                        {dataProses[key].kerusakan}
                                                    </span>
                                                </div>

                                                <div className="f13 mt-2">
                                                    <span className="fontweight500 mr-2 color636363">
                                                        Tanggal Nota
                                                    </span>
                                                    <span className="color808080">
                                                        {sqlDateToLongHumanDate(dataProses[key].tanggal_masuk)}
                                                    </span>
                                                </div>

                                                <div className={`f13 ${parseInt(dataProses[key].DateDiffNota) > batasHariNota ? 'colorSoftRed' : 'colorTheme'}`}>
                                                    <span className="fontweight500 mr-2">
                                                        {parseInt(dataProses[key].DateDiffNota) > 0 ? `Dibuat ${dataProses[key].DateDiffNota} hari yang lalu` : 'Dibuat hari ini'}
                                                    </span>
                                                </div>
                                            </div>
                                        </Card>
                                    )
                                })}
                            </Masonry>
                        </div>

                        {hasMore ?
                            <div className="text-center">
                                <ButtonLoading
                                    prmLoading={btnLoadingMore}
                                    btnClass="btnXsOverride"
                                    btnVariant="info"
                                    btnOnClick={moreData}
                                    caption="Load More"
                                />
                            </div>
                            :
                            <div className="text-center colorAaa">
                                No More Data
                            </div>
                        }
                    </div>
                }
            </Col>

        </React.Fragment>
    );
}

export default ProsesDua;
