import React from 'react';
import axios from "axios";
import { Row, Col, Modal, Form, Button, Card, Spinner } from 'react-bootstrap';
import { useSelector } from "react-redux";
import 'font-awesome/css/font-awesome.min.css';
import Calendar from 'react-calendar';
import moment from 'moment';
// import { useHistory } from 'react-router-dom';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import { BASE_URL_DUA, batasFluid } from "../../utils/globalVar";
import { isoDateToSqlDate, isoDateToShortHumanDate, sqlDateToHumanDate, formatNumberCanZero, formatNumberCanZeroMinus, deko } from "../../utils/globalFunc";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import LottiePausAstronot from '../../components/LottiePausAstronot';
import PlaceHolderLoading from "../../components/PlaceHolderElement3";
import PlaceHolderLoadingInit from "../../components/PlaceHolderElement2";
import ButtonLoading from '../../components/ButtonLoading';

const Pendapatan = () => {

    // const history = useHistory();

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);
    const mountedRef = React.useRef(true);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const limitData = 1;
    const refOffsetData = React.useRef(0);
    const [hasMore, setHasMore] = React.useState(false);
    const [btnLoadingMore, setBtnLoadingMore] = React.useState(false);

    const tglAwalCaption = 'Semua Tgl Awal';
    const tglAkhirCaption = 'Semua Tgl Akhir';

    const [dataFilter, setDataFilter] = React.useState({
        tanggalAwal: moment().subtract(1, 'months').format(),
        tanggalAkhir: new Date()
    });

    const [dataSummary, setDataSummary] = React.useState({
        grandTotal: 0,
        jumlahProject: 0,
        jumlahPengel: 0,
        grandPengel: 0,
        saldo: 0
    });

    const [modalTglAwal, setModalTglAwal] = React.useState(false);
    const [modalTglAkhir, setModalTglAkhir] = React.useState(false);

    const [loadingInit, setLoadingInit] = React.useState(false);
    const [loadingData, setLoadingData] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [showIfNoData, setShowIfNoData] = React.useState(false);

    const getTotal = (elementByr, elementDp, elementDiskon) => {
        let byr = 0;
        let dp = elementDp ? elementDp : 0;
        let diskon = 0;
        let total = 0;

        byr = sumTotal(elementByr);
        diskon = sumDiskon(elementDiskon, elementByr);
        total = byr - dp - diskon;

        return total;

    }

    const sumTotal = (elementByr) => {
        let res = 0;

        if (elementByr) {
            elementByr.forEach(elST => {
                res = res + Number(elST.total);
            })
        }

        return res;
    }

    const sumDiskon = (elementDiskon, elementByr) => {
        let res = 0;

        if (elementDiskon) {
            elementDiskon.forEach(item => {
                res = res + (item.flag === 'rupiah' ? Number(item.jumlah) : (Number(item.jumlah) / 100) * sumTotal(elementByr));
            })
        }

        return res;
    }

    const sumDiskonFn = (elementDiskon, totalByr) => {
        let res = 0;

        if (elementDiskon) {
            elementDiskon.forEach(item => {
                res = res + (item.flag === 'rupiah' ? Number(item.jumlah) : (Number(item.jumlah) / 100) * totalByr);
            })
        }

        return res;
    }

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, []);

    React.useEffect(() => {
        setLoadingInit(true);

        refOffsetData.current = 0;

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/get_penda_pengel',
            data: {
                id_user: deko(reduxLogin.syswebappi),
                tanggal_awal: moment().subtract(1, 'months').format(),
                tanggal_akhir: new Date(),

                limit_data: limitData,
                offset_data: refOffsetData.current
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);

            response.data.data && response.data.data.length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

            if (response.data.data) {
                setData(response.data.data);
                setShowIfNoData(false);
            } else {
                setData(null);
                setShowIfNoData(true);
            }

            if (response.data.data_all) {

                let grandTotal = 0;
                let jmlProject = 0;
                let dp = 0;

                let jmlItem = 0;
                let tPengel = 0;
                let grandPengel = 0;

                response.data.data_all.forEach(element => {
                    let tByr = 0;

                    if (element.flag === 'pendapatan') {
                        dp = element.dp ? element.dp : 0;

                        jmlProject = jmlProject + 1;

                        tByr = sumTotal(element.data_pembayaran);

                        grandTotal = grandTotal + tByr - sumDiskonFn(element.data_diskon, tByr) - dp;
                    }

                    if (element.flag !== 'pendapatan') {
                        jmlItem = jmlItem + 1;

                        tPengel = Number(element.jumlah);

                        grandPengel = grandPengel + tPengel;
                    }
                });

                setDataSummary({
                    grandTotal: grandTotal,
                    jumlahProject: jmlProject,
                    jumlahPengel: jmlItem,
                    grandPengel: grandPengel,
                    saldo: grandTotal - grandPengel
                });
            }

            if (response.data.data) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);
            handleWarningModal('Error : ' + error.message)
        })
    }, [reduxLogin.syswebappi]);

    const moreData = () => {
        setBtnLoadingMore(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/get_penda_pengel',
            data: {
                id_user: deko(reduxLogin.syswebappi),

                tanggal_awal: dataFilter.tanggalAwal === tglAwalCaption ? 'semua' : isoDateToSqlDate(dataFilter.tanggalAwal),

                tanggal_akhir: dataFilter.tanggalAkhir === tglAkhirCaption ? 'semua' : isoDateToSqlDate(dataFilter.tanggalAkhir),

                limit_data: limitData,
                offset_data: refOffsetData.current
            }
        }).then((response) => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);

            data && response.data.data && [...data, ...response.data.data].length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

            data && response.data.data && setData([...data, ...response.data.data]);

            if (response.data.data) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);
            handleWarningModal('Warning : ' + error.message);
        })
    };

    const handleTampilkan = () => {
        setLoadingData(true);

        refOffsetData.current = 0;

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/get_penda_pengel',
            data: {
                id_user: deko(reduxLogin.syswebappi),

                tanggal_awal: dataFilter.tanggalAwal === tglAwalCaption ? 'semua' : isoDateToSqlDate(dataFilter.tanggalAwal),

                tanggal_akhir: dataFilter.tanggalAkhir === tglAkhirCaption ? 'semua' : isoDateToSqlDate(dataFilter.tanggalAkhir),

                limit_data: limitData,
                offset_data: refOffsetData.current
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingData(false);

            response.data.data && response.data.data.length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

            if (response.data.data) {
                setData(response.data.data);
                setShowIfNoData(false);
            } else {
                setData(null);
                setShowIfNoData(true);
            }

            if (response.data.data_all) {

                let grandTotal = 0;
                let jmlProject = 0;
                let dp = 0;

                let jmlItem = 0;
                let tPengel = 0;
                let grandPengel = 0;

                response.data.data_all.forEach(element => {
                    if (element.flag === 'pendapatan') {
                        dp = element.dp ? element.dp : 0;

                        jmlProject = jmlProject + 1;

                        grandTotal = grandTotal + sumTotal(element.data_pembayaran) - sumDiskon(element.data_diskon, element.data_pembayaran) - dp;
                    }

                    if (element.flag !== 'pendapatan') {
                        jmlItem = jmlItem + 1;

                        tPengel = Number(element.jumlah);

                        grandPengel = grandPengel + tPengel;
                    }
                });

                setDataSummary({
                    grandTotal: grandTotal,
                    jumlahProject: jmlProject,
                    jumlahPengel: jmlItem,
                    grandPengel: grandPengel,
                    saldo: grandTotal - grandPengel
                });
            }

            if (response.data.data) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingData(false);
            handleWarningModal('Error : ' + error.message)
        })
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop
                shadow={true}
                titleNavTop="Pendapatan"
            />

            <Layout titleTab="Pendapatan">

                <Row className="">
                    {!loadingInit &&
                        <Col xs={12}>

                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} mt-3 shadow-sm brSoftMedium`}>
                                <Row>
                                    <Col xs={12} className="mb-3">
                                        <div>
                                            Periode
                                        </div>
                                    </Col>

                                    <Col xs={6} className="pr-1">
                                        <div className="flexAlignCenter bgInputImportant rounded-sm">
                                            <Form.Control
                                                readOnly
                                                className="textInputPendaPengel removeShadowOnFocus bgInputImportant"
                                                size="sm"
                                                type="text"
                                                placeholder="Tanggal Awal"
                                                value={dataFilter.tanggalAwal === tglAwalCaption ? tglAwalCaption : isoDateToShortHumanDate(dataFilter.tanggalAwal)}
                                                onClick={() => setModalTglAwal(true)}
                                            />

                                            {dataFilter.tanggalAwal !== tglAwalCaption && <span
                                                className="fa fa-close color808080 ml-2 mr-2 cp"
                                                onClick={() => setDataFilter({
                                                    ...dataFilter,
                                                    tanggalAwal: tglAwalCaption
                                                })}
                                            />
                                            }
                                        </div>
                                    </Col>

                                    <Col xs={6} className="pl-1">
                                        <div className="flexAlignCenter bgInputImportant rounded-sm">
                                            <Form.Control
                                                readOnly
                                                className="textInputPendaPengel removeShadowOnFocus bgInputImportant"
                                                size="sm"
                                                type="text"
                                                placeholder="Tanggal Akhir"
                                                value={dataFilter.tanggalAkhir === tglAkhirCaption ? tglAkhirCaption : isoDateToShortHumanDate(dataFilter.tanggalAkhir)}
                                                onClick={() => setModalTglAkhir(true)}
                                            />

                                            {dataFilter.tanggalAkhir !== tglAkhirCaption && <span
                                                className="fa fa-close color808080 ml-2 mr-2 cp"
                                                onClick={() => setDataFilter({
                                                    ...dataFilter,
                                                    tanggalAkhir: tglAkhirCaption
                                                })}
                                            />
                                            }
                                        </div>
                                    </Col>

                                    <Col xs={12} className="mt-3">
                                        <div className="flexJustifyBeetwenAlignCenter">
                                            <div className="colorAaa f11 pr-3">
                                                Periode berdasarkan tanggal pelunasan pembayaran
                                            </div>

                                            <div>
                                                <Button
                                                    variant="info"
                                                    size="sm"
                                                    className="btnXsOverride px-3 rounded-pill"
                                                    onClick={handleTampilkan}
                                                >
                                                    Tampilkan
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </Card>
                        </Col>
                    }

                    {loadingInit &&
                        <Col xs={12} className="mt-3">
                            <PlaceHolderLoadingInit />
                        </Col>
                    }

                    {loadingData &&
                        <Col xs={12} className="mt-3 px-0">
                            <PlaceHolderLoading
                                caption={
                                    <React.Fragment>
                                        Mengambil Data <Spinner
                                            as="span"
                                            className="ml-2"
                                            animation="border"
                                            variant="secondary"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </React.Fragment>
                                }
                            />
                        </Col>
                    }

                    {showIfNoData && !loadingData &&
                        <Col xs={12} className="mt-3">
                            <div className="flexRowJustifyAlignCenter">
                                <LottiePausAstronot />
                            </div>
                        </Col>
                    }

                    {!loadingData && data && !showIfNoData &&
                        <Col xs={12} className="mt-3">
                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium`}>
                                <div className="flexJustifyBeetwenAlignCenter borderBottomEee pb-3">
                                    <div className="flex1 text-center">
                                        <div className="fontweight500" style={{ color: '#19A776' }}>
                                            Rp {formatNumberCanZeroMinus(dataSummary.grandTotal)}
                                        </div>

                                        <div className="mt-1 color808080 f12">Pendapatan</div>

                                        <div className="color808080 f11">({formatNumberCanZero(dataSummary.jumlahProject)} Project)</div>
                                    </div>

                                    <div className="flex1 text-center">
                                        <div className="fontweight500" style={{ color: 'red' }}>
                                            Rp {formatNumberCanZeroMinus(dataSummary.grandPengel)}
                                        </div>

                                        <div className="mt-1 color808080 f12">Pengeluaran</div>

                                        <div className="color808080 f11">({formatNumberCanZero(dataSummary.jumlahPengel)} Item)</div>
                                    </div>
                                </div>

                                <div className="flexJustifyBeetwenAlignCenter fontweight500 px-2 py-3 borderBottomEee" style={{ color: '#19A776' }}>
                                    <div>Saldo</div>
                                    <div>Rp {formatNumberCanZeroMinus(dataSummary.saldo)}</div>
                                </div>

                                <div className="pb-3 f13">
                                    {Object.keys(data).map((keyResDL) => {
                                        return (
                                            data[keyResDL].flag === 'pendapatan' ?
                                                <div key={keyResDL + 'penda_43789'}>
                                                    <div
                                                        className="flexJustifyBeetwenAlignCenter py-2 px-2 mb-1 bgSoft"
                                                        style={{ color: '#19A776' }}
                                                    >
                                                        <div>
                                                            {data[keyResDL].flag_tanggal ? sqlDateToHumanDate(data[keyResDL].flag_tanggal) : ' - '}
                                                        </div>

                                                        <div>
                                                            Rp {formatNumberCanZeroMinus(getTotal(data[keyResDL].data_pembayaran, data[keyResDL].dp, data[keyResDL].data_diskon))}
                                                        </div>
                                                    </div>

                                                    <div className="f12 pt-1 pb-3 px-2 color808080">
                                                        Project {data[keyResDL].id_transaksi}
                                                    </div>
                                                </div>
                                                :
                                                <div key={keyResDL + 'pengel_43789'}>
                                                    <div
                                                        className="flexJustifyBeetwenAlignCenter py-2 px-2 mb-1 bgSoft"
                                                        style={{ color: 'red' }}
                                                    >
                                                        <div>
                                                            {data[keyResDL].flag_tanggal ? sqlDateToHumanDate(data[keyResDL].flag_tanggal) : ' - '}
                                                        </div>

                                                        <div>
                                                            Rp {formatNumberCanZero(data[keyResDL].jumlah)}
                                                        </div>
                                                    </div>

                                                    <div className="f12 pt-1 pb-3 px-2 color808080">
                                                        {data[keyResDL].nama}
                                                    </div>
                                                </div>
                                        )
                                    })}
                                </div>

                                <div className="mt-3 pb-3">
                                    {hasMore ?
                                        <div className="text-center">
                                            <ButtonLoading
                                                prmLoading={btnLoadingMore}
                                                btnClass="btnXsOverride"
                                                btnVariant="info"
                                                btnOnClick={moreData}
                                                caption="Load More"
                                            />
                                        </div>
                                        :
                                        <div className="text-center colorAaa">
                                            No More Data
                                        </div>
                                    }
                                </div>
                            </Card>
                        </Col>
                    }
                </Row>
            </Layout>

            <Modal
                show={modalTglAwal}
                onHide={() => setModalTglAwal(false)}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tanggal Awal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flexJustifyAlignCenter pt-1 pb-3">
                        <Calendar
                            defaultView="month"
                            onChange={(value) => {
                                setDataFilter({ ...dataFilter, tanggalAwal: value });
                                setModalTglAwal(false);
                            }}
                            value={dataFilter.tanggalAwal === tglAwalCaption ? new Date() : dataFilter.tanggalAwal}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={modalTglAkhir}
                onHide={() => setModalTglAkhir(false)}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tanggal Akhir</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flexJustifyAlignCenter pt-1 pb-3">
                        <Calendar
                            defaultView="month"
                            onChange={(value) => {
                                setDataFilter({ ...dataFilter, tanggalAkhir: value });
                                setModalTglAkhir(false);
                            }}
                            value={dataFilter.tanggalAkhir === tglAkhirCaption ? new Date() : dataFilter.tanggalAkhir}
                        />
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment >
    );
}

export default Pendapatan;
