import React from 'react';
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Form } from 'react-bootstrap';

import { BASE_URL } from "../utils/globalVar";
import { VFCannotNull, VFNoHpFormat, deko, removeStrip } from "../utils/globalFunc";
import ModalAlertWarning from './ModalAlertWarning';
import ButtonLoading from "../components/ButtonLoading";

const FormCustomerBaru = () => {

    const history = useHistory();
    const reduxLogin = useSelector(data => data.lumoslored);

    const [listKategoriCustomer, setListKategoriCustomer] = React.useState(null);
    const [warningKategoriCustomer, setWarningKategoriCustomer] = React.useState('');

    const initDataForm = {
        nama: '',
        kategori: '0',
        noHp: '',
        alamat: '',
        gender: '1'
    }

    const [stateInputan, setStateInputan] = React.useState(initDataForm);
    const [btnLoadingSubmit, setBtnLoadingSubmit] = React.useState(false);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        return () => {
            mountedRef.current = false;
        }
    }, []);

    const handleSetState = (event) => {
        let nam = event.target.name;
        let val = event.target.value;

        let relSelected = '';

        if (nam === 'kategori') {
            relSelected = event.target.selectedOptions[0].getAttribute("rel");
        }

        if (nam === 'kategori' && relSelected === 'perusahaan') {
            setStateInputan({ ...stateInputan, [nam]: val, gender: '3' });

            document.getElementById('inputGenderCustBaru').disabled = true;
        } else {
            setStateInputan({ ...stateInputan, [nam]: val });
            document.getElementById('inputGenderCustBaru').disabled = false;
        }
    }

    const handleSubmitForm = () => {
        if (VFCannotNull(stateInputan.nama)) {
            document.querySelector("#inputNamaCustBaru").focus();
            handleWarningModal('Nama belum diisi.')
        } else if (VFNoHpFormat(removeStrip(stateInputan.noHp))) {
            document.querySelector("#inputHPCustBaru").focus();
            handleWarningModal('Format No HP (+62, 62, 08) minimum 10 - 13 digit yang terdiri dari angka dan atau tanda plus.')
        } else if (VFCannotNull(stateInputan.noHp)) {
            document.querySelector("#inputHPCustBaru").focus();
            handleWarningModal('No HP belum diisi.')
        } else if (stateInputan.kategori === '0') {
            document.querySelector("#inputKategoriCust").focus();
            handleWarningModal('Kategori belum dipilih.')
        } else if (VFCannotNull(stateInputan.alamat)) {
            document.querySelector("#inputAlamatCustBaru").focus();
            handleWarningModal('Alamat belum diisi.')
        } else {
            setBtnLoadingSubmit(true);

            axios({
                method: 'post',
                url: BASE_URL + '/daftar_customer_baru_dan_transaksi',
                data: {
                    nama: stateInputan.nama,
                    kategori: stateInputan.kategori,
                    no_hp: removeStrip(stateInputan.noHp),
                    alamat: stateInputan.alamat,
                    gender: stateInputan.gender,
                    id_user_login: deko(reduxLogin.syswebappi)
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingSubmit(false);

                // berhasil insert customer dan berhasil insert transaksi master
                if (response.data.flag_status === true && response.data.id_transaksi !== null) {
                    history.push('/transaksi/' + response.data.id_transaksi);
                }

                //  berhasil insert customer tapi gagal insert transaksi master
                if (response.data.flag_status === true && response.data.id_transaksi === null) {
                    handleWarningModal('Gagal membuat data transaksi !');
                }

                //  gagal insert customer
                if (response.data.flag_status === false) {
                    handleWarningModal(response.data.pesan_error);
                }

                // customer yg akan diinsert sdh ada sebelumnya di database (tidak terjadi insert customer)
                if (response.data.flag_status === 'isset') {
                    handleWarningModal('Customer dengan No HP ' + stateInputan.noHp + " telah terdaftar sebelumnya dengan nama '" + response.data.pesan + "'");
                }

            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingSubmit(false);
                handleWarningModal(error.message)
            })
        }
    }

    React.useEffect(() => {
        setWarningKategoriCustomer('')

        axios({
            method: 'post',
            url: BASE_URL + '/get_kategori_customer'
        }).then((response) => {
            if (!mountedRef.current) return null;

            if (response.data === null) {
                setWarningKategoriCustomer('(Data Kategori Customer tidak ada)')
            }
            else {
                setListKategoriCustomer(response.data)
            }

        }).catch(error => {
            if (!mountedRef.current) return null;

            handleWarningModal(error.message)
        })
    }, [])

    return (
        <Col xs={12} className="mt-3">

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <Form>
                <Form.Group controlId="inputNamaCustBaru">
                    <Form.Label>
                        Nama Customer
                    </Form.Label>
                    <Form.Control
                        name="nama"
                        size="sm"
                        type="text"
                        placeholder="Nama"
                        value={stateInputan.nama}
                        onChange={handleSetState}
                    />
                </Form.Group>

                <Form.Group controlId="inputHPCustBaru">
                    <Form.Label>
                        <span>
                            No Handphone
                        </span>
                    </Form.Label>
                    <Form.Control
                        name="noHp"
                        size="sm"
                        type="text"
                        placeholder="081234567890"
                        value={stateInputan.noHp}
                        onChange={handleSetState}
                    />
                </Form.Group>

                <Form.Group controlId="inputKategoriCust">
                    <Form.Label>
                        Kategori
                        {warningKategoriCustomer !== '' &&
                            <span
                                onClick={() => handleWarningModal('Pastikan data Kategori Customer sudah dibuat !. Atau muat ulang halaman.')
                                }>
                                <span className="colorSoftRed f12"> {warningKategoriCustomer} &nbsp;&nbsp;</span>
                                <span className="fa fa-question-circle f16" />
                            </span>
                        }
                    </Form.Label>
                    <Form.Control
                        custom
                        name="kategori"
                        as="select"
                        size="sm"
                        value={stateInputan.kategori}
                        onChange={handleSetState}
                    >
                        <option value="0">Kategori Customer</option>
                        {
                            listKategoriCustomer !== null &&
                            Object.keys(listKategoriCustomer).map((key) => {
                                return (
                                    <option
                                        key={'kcfcb21631728' + key}
                                        value={listKategoriCustomer[key].id}
                                        rel={listKategoriCustomer[key].slug}
                                    >
                                        {listKategoriCustomer[key].nama}
                                    </option>
                                )
                            })
                        }
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="inputGenderCustBaru">
                    <Form.Label>Gender</Form.Label>
                    <Form.Control
                        custom
                        name="gender"
                        as="select"
                        size="sm"
                        value={stateInputan.gender}
                        onChange={handleSetState}
                    >
                        <option value="1">Pria</option>
                        <option value="0">Wanita</option>
                        <option value="3">Tanpa Gender</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="inputAlamatCustBaru">
                    <Form.Label>
                        Alamat
                    </Form.Label>
                    <Form.Control
                        name="alamat"
                        size="sm"
                        type="text"
                        placeholder="Alamat"
                        value={stateInputan.alamat}
                        onChange={handleSetState}
                    />
                </Form.Group>

                <div className="flexJustifyAlignCenter">
                    <ButtonLoading
                        prmLoading={btnLoadingSubmit}
                        btnClass="mt-4 px-3"
                        btnVariant="info"
                        btnOnClick={handleSubmitForm}
                        caption="Simpan &amp; Lanjut Transaksi"
                    />
                </div>
            </Form>
        </Col >
    );
}

export default FormCustomerBaru;
