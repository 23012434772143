import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import axios from "axios";
import { Col, InputGroup, FormControl, Spinner, Card } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import CustomerAvatar from "../components/CustomerAvatar";
import { widthLG, widthMD, widthSM, widthXS, BASE_URL, levelUser } from "../utils/globalVar";
import { deko } from "../utils/globalFunc";
import ButtonLoading from "../components/ButtonLoading";
import ModalAlertWarning from './ModalAlertWarning';
import LottiePausAstronot from "../components/LottiePausAstronot";

const FormCustomerLama = () => {

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [textInputSearch, setTextInputSearch] = React.useState('');

    const limitData = 10;
    const refOffsetData = React.useRef(0);
    const [hasMore, setHasMore] = React.useState(true);

    const [loadingSearch, setLoadingSearch] = React.useState(false);
    const [btnLoadingMore, setBtnLoadingMore] = React.useState(false);

    const reduxLogin = useSelector(data => data.lumoslored);
    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const history = useHistory();

    const [hasilPencarian, setHasilPencarian] = React.useState(null);
    const [tidakAdaHasil, setTidakAdaHasil] = React.useState(false);

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        return () => mountedRef.current = false;
    }, []);

    const moreData = () => {
        setBtnLoadingMore(true);

        axios({
            method: 'post',
            url: BASE_URL + '/cari_customer',
            data: {
                inputan: textInputSearch,
                limit_data: limitData,
                offset_data: refOffsetData.current,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingMore(false);

            hasilPencarian && response.data.data_cust && setHasilPencarian([...hasilPencarian, ...response.data.data_cust]);

            hasilPencarian && response.data.data_cust && [...hasilPencarian, ...response.data.data_cust].length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

            if (response.data.data_cust) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);
            handleWarningModal(error.message)
        })
    }

    const getData = React.useCallback((dt) => {
        axios({
            method: 'post',
            url: BASE_URL + '/cari_customer',
            data: dt
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingSearch(false);

            if (response.data.data_cust) {
                setHasilPencarian(response.data.data_cust);
                setTidakAdaHasil(false);
            } else {
                setHasilPencarian(null);
                setTidakAdaHasil(true);
            }

            if (response.data.data_cust) { refOffsetData.current = refOffsetData.current + limitData };

            response.data.data_cust && response.data.data_cust.length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);
        }).catch(error => {
            if (!mountedRef.current) return null;
            setLoadingSearch(false);
            setTidakAdaHasil(false);
            handleWarningModal(error.message)
        })
    }, []);

    React.useEffect(() => {
        refOffsetData.current = 0;
        let handler = null;

        if (textInputSearch) {
            textInputSearch && setLoadingSearch(true);

            handler = setTimeout(() => {
                const dt = {
                    inputan: textInputSearch,
                    limit_data: limitData,
                    offset_data: refOffsetData.current,
                    id_user: deko(reduxLogin.syswebappi)
                }

                getData(dt)
            }, 1000);
        } else {
            const dt = {
                inputan: '',
                limit_data: limitData,
                offset_data: refOffsetData.current,
                id_user: deko(reduxLogin.syswebappi)
            }

            getData(dt)
            // setLoadingSearch(false);
            // setHasilPencarian(null);
            // setTidakAdaHasil(false);
        }

        return () => clearTimeout(handler);
    }, [textInputSearch, getData, reduxLogin.syswebappi]);

    const handleNewTransaksi = (idCustomer) => {
        axios({
            method: 'post',
            url: BASE_URL + '/new_transaksi',
            data: {
                id_customer: idCustomer,
                id_user_login: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            if (response.data.status_isset === true) {
                history.push('/transaksi/' + response.data.pesan);
            }

            if (response.data.status_isset === false) {
                handleWarningModal('Warning : ' + response.data.pesan)
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            handleWarningModal('Warning : ' + error.message)
        })
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <Col xs={12} className="mt-2">
                <InputGroup className="mb-3">
                    <FormControl
                        size="sm"
                        type="text"
                        placeholder="Cari Customer"
                        value={textInputSearch}
                        onChange={(e) => setTextInputSearch(e.target.value)}

                        className="removeShadowOnFocus"
                        style={{ borderRight: '0 solid #eee' }}
                    />

                    <InputGroup.Append>
                        <InputGroup.Text id="ad-cbd-9890" className="bgColorForm">
                            {loadingSearch &&
                                <Spinner animation="border" size="sm" variant="info" />
                            }
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            </Col>

            <Col xs={12} className="mt-2 mb-3">

                {hasilPencarian && !loadingSearch &&
                    <div>
                        <div>
                            <Masonry
                                breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 1 : 2) : 2) : 3) : 3}`}
                                className="my-masonry-grid-12"
                                columnClassName="my-masonry-grid_column-12"
                            >
                                {Object.keys(hasilPencarian).map((key) => {
                                    return (
                                        <Card
                                            key={'HasilPencarian' + key}

                                            className={`px-2 py-2 ${Object.keys(hasilPencarian).length === (parseInt(key) + 1) ? 'mb-0' : 'mb-3'} shadow-sm cp brSoftMedium`}

                                            onClick={() => handleNewTransaksi(hasilPencarian[key].id)}
                                        >
                                            <div className="flex">
                                                <div className="ml-2">
                                                    <CustomerAvatar
                                                        prmSlug={hasilPencarian[key].slug_ck}
                                                        prmGender={hasilPencarian[key].gender}
                                                        ukuran={{ width: '40px', height: '40px' }}
                                                    />
                                                </div>

                                                <div className="ml-3 wordBreakAll">
                                                    <div className="color636363 bold">
                                                        {hasilPencarian[key].nama}
                                                    </div>

                                                    <div className="color808080 f13">
                                                        {hasilPencarian[key].nama_cust_ktgr}
                                                    </div>

                                                    <div className="color808080 f13 mt-2">
                                                        {hasilPencarian[key].alamat}
                                                    </div>

                                                    {deko(reduxLogin.syswebappj) === levelUser.owner &&
                                                        <div className="color808080 f13">
                                                            {hasilPencarian[key].no_hp}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </Card>
                                    )
                                })}
                            </Masonry>
                        </div>

                        <div className="text-center mt-3">
                            {hasMore ?
                                <div className="text-center">
                                    <ButtonLoading
                                        prmLoading={btnLoadingMore}
                                        btnClass="btnXsOverride"
                                        btnVariant="info"
                                        btnOnClick={moreData}
                                        caption="Load More"
                                    />
                                </div>
                                :
                                <div className="text-center colorAaa">
                                    No More Data
                                </div>
                            }
                        </div>
                    </div>
                }

                {
                    !hasilPencarian && tidakAdaHasil &&
                    <div className="flexRowJustifyAlignCenter">
                        <LottiePausAstronot />
                    </div>
                }
            </Col >

        </React.Fragment >
    );
}

export default FormCustomerLama;
