import React from 'react';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Calendar from 'react-calendar';
import 'font-awesome/css/font-awesome.min.css';
import { Row, Col, Card, Image, Form, Modal, InputGroup, Button, Spinner } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import CustomerAvatar from "../../components/CustomerAvatar";
import soldering from "../../assets/images/system/soldering.png";
import { ROOT_BASE_URL, BASE_URL, BASE_URL_DUA, batasFluid, prefixUrl, metodeBayar, kategoriServis, reminderMaintenance, widthLG, widthMD, widthSM, widthXS, levelUser } from "../../utils/globalVar";
import { isoDateToSqlDate, sqlDateToLongHumanDate, dateTimeToHumanDateTime, formatNumberCanZero, formatNumber, formatNumberCanZeroMinus, handleCallBack, deko } from "../../utils/globalFunc";
import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ButtonLoading from "../../components/ButtonLoading";
import ModalConfirmation from "../../components/ModalConfirmation";
import PlaceHolderElement1 from "../../components/PlaceHolderElement1";

const ProsesSelesai = () => {

    const scrollToBottomRef = React.createRef();

    const history = useHistory();

    const parameter = useParams();
    const idTransaksi = parameter.id;
    const callbackUrl = parameter.callbackUrl;

    const location = useLocation();

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [srcFoto, setSrcFoto] = React.useState('');
    const [modalZoomImage, setModalZoomImage] = React.useState(false);

    const [loadingDataProses, setLoadingDataProses] = React.useState(false);

    const [modalTambahItemJasa, setModalTambahItemJasa] = React.useState(false);
    const [btnLoadingTambahItemJasa, setBtnLoadingTambahItemJasa] = React.useState(false);
    const [inputTambahItemJasa, setInputTambahItemJasa] = React.useState({ itemJasa: '', qty: '', harga: '' });
    const [inputUpdateItemJasa, setInputUpdateItemJasa] = React.useState({
        itemJasa: '',
        qty: '',
        harga: '',
        idTDByr: '',
        prmFocus: '',
        useStok: ''
    });

    const refInputItemJasa = React.useRef('');
    const refInputQty = React.useRef('');
    const refInputHarga = React.useRef('');

    const refMetodePelunasan = React.useRef();

    const [modalUpdateItemJasa, setModalUpdateItemJasa] = React.useState(false);
    const [btnLoadingUpdateItemJasa, setBtnLoadingUpdateItemJasa] = React.useState(false);

    const refUpdateItemJasa = React.useRef('');
    const refUpdateQty = React.useRef('');
    const refUpdateHarga = React.useRef('');

    const [modalHapusBiaya, setModalHapusBiaya] = React.useState(false);
    const [btnLoadHapusBiaya, setBtnLoadHapusBiaya] = React.useState(false);

    const [prmGoBottom, setPrmGoBottom] = React.useState(false);
    const [modalHapusDiskon, setModalHapusDiskon] = React.useState(false);
    const [btnLoadingHapusDiskon, setBtnLoadingHapusDiskon] = React.useState(false);

    const [loadingKategoriServis, setLoadingKategoriServis] = React.useState(false);

    const [modalReminderAt, setModalReminderAt] = React.useState(false);
    const [btnLoadingReminderAt, setBtnLoadingReminderAt] = React.useState(false);
    const [reminderAtModal, setReminderAtModal] = React.useState('');
    const refReminderAt = React.useRef();

    const [loadingLunas, setLoadingLunas] = React.useState(false);
    const [loadingMetodeBayar, setLoadingMetodeBayar] = React.useState(false);

    const [modalCalendarTglAmbil, setModalCalendarTglAmbil] = React.useState(false);
    const [loadingTanggalAmbil, setLoadingTanggalAmbil] = React.useState(false);

    const [modalCalendarTglPelunasan, setModalCalendarTglPelunasan] = React.useState(false);
    const [loadingTanggalPelunasan, setLoadingTanggalPelunasan] = React.useState(false);

    const [btnLoadingCatatanSelesai, setBtnLoadingCatatanSelesai] = React.useState(false);
    const [modalCatatanSelesai, setModalCatatanSelesai] = React.useState(false);
    const [catatanSelesaiModal, setCatatanSelesaiModal] = React.useState('');

    const [modalAmbil, setModalAmbil] = React.useState(false);
    const [btnLoadingAmbil, setBtnLoadingAmbil] = React.useState(false);

    const [resumeBiaya, setResumeBiaya] = React.useState({
        subTotal: '',
        diskon: '',
        total: ''
    });

    const [modalDiskonManual, setModalDiskonManual] = React.useState(false);
    const [diskonManual, setDiskonManual] = React.useState('');
    const [btnLoadingDiskonManual, setBtnLoadingDiskonManual] = React.useState(false);
    const refDiskonManual = React.useRef();

    const [stateData, setStateData] = React.useState({
        dataPenggunaanStok: {},
        dataProses: null,
        dataKelengkapan: null,
        listFotoTransaksi: null,
        dataProgres: null,
        dataPenggunaanStokInclusion: null,
        dataTDBYR: null,
        dataDiskon: null,
        kategoriServis: '',
        reminderAt: '',
        isLunas: '',
        tanggalAmbil: '',
        tanggalPelunasan: '',
        catatanSelesai: '',
        dataLabel: null,
        penerima: '',
        metodeBayar: ''
    });

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [])

    React.useEffect(() => {
        setLoadingDataProses(true);

        axios({
            method: 'post',
            url: BASE_URL + '/proses_ending',
            data: {
                id_transaksi: idTransaksi,
                flag_proses: 4
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            document.body.style.backgroundColor = "#FAF9F9";
            setLoadingDataProses(false);

            if (response.data.data_proses) {
                setStateData(s => ({
                    ...s,
                    dataPenggunaanStok: response.data.data_penggunaan_stok,
                    dataProses: response.data.data_proses,
                    dataKelengkapan: response.data.data_kelengkapan,
                    listFotoTransaksi: response.data.data_foto_transaksi,
                    dataProgres: response.data.data_progres['k1'],
                    dataPenggunaanStokInclusion: response.data.data_penggunaan_stok_inclusion,
                    dataTDBYR: response.data.data_tdbyr,
                    dataDiskon: response.data.data_diskon,
                    kategoriServis: response.data.data_proses[0].kategori_servis ? response.data.data_proses[0].kategori_servis : '',
                    reminderAt: response.data.data_proses[0].reminder_at ? response.data.data_proses[0].reminder_at : '',
                    isLunas: response.data.data_proses[0].is_lunas ? response.data.data_proses[0].is_lunas : '',
                    tanggalAmbil: response.data.data_proses[0].tanggal_ambil ? response.data.data_proses[0].tanggal_ambil : '',
                    tanggalPelunasan: response.data.data_proses[0].tanggal_pelunasan ? response.data.data_proses[0].tanggal_pelunasan : '',
                    catatanSelesai: response.data.data_proses[0].catatan_selesai ? response.data.data_proses[0].catatan_selesai : '',
                    dataLabel: response.data.data_label,
                    penerima: response.data.penerima,
                    metodeBayar: response.data.data_proses[0].metode_pelunasan ? response.data.data_proses[0].metode_pelunasan : ''
                }));

                handleSubTotalSummary(response.data.data_tdbyr, response.data.data_diskon, response.data.data_proses[0].dp);

                setPrmGoBottom(true);
            } else {
                setStateData(s => ({ ...s, dataProses: null }));
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingDataProses(false);
            handleWarningModal(error.message);
        })
    }, [idTransaksi]);

    const handleSubTotalSummary = (arrSTS, prmDataDiskon, prmDp) => {

        const resDp = prmDp ? Number(prmDp) : 0;
        let resDiskon = 0;

        if (arrSTS) {
            var st = 0;
            arrSTS.forEach(element => {
                st = st + Number(element.total ? element.total : 0);
            });

            resDiskon = prmDataDiskon ? (prmDataDiskon[0].flag === 'rupiah' ? Number(prmDataDiskon[0].jumlah) : (Number(prmDataDiskon[0].jumlah) / 100 * st).toFixed(0)) : 0;

            setResumeBiaya({
                subTotal: st,
                diskon: resDiskon,
                total: st - resDp - resDiskon
            })
        } else {
            resDiskon = prmDataDiskon ? (prmDataDiskon[0].flag === 'rupiah' ? Number(prmDataDiskon[0].jumlah) : Number(prmDataDiskon[0].jumlah) / 100 * 0) : 0;

            setResumeBiaya({
                subTotal: 0,
                diskon: resDiskon,
                total: 0 - resDp - resDiskon
            })
        }
    }

    const handleTambahItemJasa = () => {
        if (!inputTambahItemJasa.itemJasa) {
            refInputItemJasa.current.style.border = '1px solid red';
            refInputItemJasa.current.focus();
        } else if (!inputTambahItemJasa.qty) {
            refInputQty.current.style.border = '1px solid red';
            refInputQty.current.focus();
        } else if (!inputTambahItemJasa.harga) {
            refInputHarga.current.style.border = '1px solid red';
            refInputHarga.current.focus();
        } else {
            setBtnLoadingTambahItemJasa(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/tambah_item_jasa',
                data: {
                    id_transaksi: idTransaksi,
                    item_jasa: inputTambahItemJasa.itemJasa,
                    qty: inputTambahItemJasa.qty.replaceAll(',', ''),
                    harga: inputTambahItemJasa.harga.replaceAll(',', '')
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingTambahItemJasa(false);
                setModalTambahItemJasa(false);

                if (response.data.flag_status === true) {
                    setStateData(s => ({
                        ...s,
                        dataTDBYR: response.data.data_tdbyr,
                        dataDiskon: response.data.data_diskon
                    }));

                    handleSubTotalSummary(response.data.data_tdbyr, response.data.data_diskon, stateData.dataProses[0].dp);
                }

                response.data.flag_status === false && handleWarningModal('Error : ' + response.data.pesan);

                if (response.data.flag_status === 'not-found') {
                    handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
                }

                setInputTambahItemJasa({ itemJasa: '', qty: '', harga: '' });
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingTambahItemJasa(false);
                setModalTambahItemJasa(true);
                handleWarningModal(error.message);
            })
        }
    }

    const setDataModalUpdateItemJasa = (itemJasa, jumlah, harga, prmFocus, idTDByr, useStok, hargaAsli) => {
        setInputUpdateItemJasa({
            itemJasa: itemJasa,
            qty: jumlah,
            harga: harga,
            idTDByr: idTDByr,
            prmFocus: prmFocus,
            useStok: useStok,
            prmHargaAsli: hargaAsli
        });

        setModalUpdateItemJasa(true);
    }

    const focusFieldUpdate = () => {
        inputUpdateItemJasa.prmFocus === 'itemJasa' && refUpdateItemJasa.current.focus();
        if (!parseInt(inputUpdateItemJasa.useStok)) {
            inputUpdateItemJasa.prmFocus === 'qty' && refUpdateQty.current.focus();
        }
        inputUpdateItemJasa.prmFocus === 'harga' && refUpdateHarga.current.focus();
    }

    const handleUpdateItemJasa = () => {
        if (!inputUpdateItemJasa.itemJasa) {
            refUpdateItemJasa.current.style.border = '1px solid red';
            refUpdateItemJasa.current.focus();
        } else if (!inputUpdateItemJasa.qty) {
            refUpdateQty.current.style.border = '1px solid red';
            refUpdateQty.current.focus();
        } else if (!inputUpdateItemJasa.harga) {
            refUpdateHarga.current.style.border = '1px solid red';
            refUpdateHarga.current.focus();
        } else {
            setBtnLoadingUpdateItemJasa(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/update_item_jasa',
                data: {
                    id_transaksi: idTransaksi,
                    item_jasa: inputUpdateItemJasa.itemJasa,
                    qty: inputUpdateItemJasa.qty.replaceAll(',', ''),
                    harga: inputUpdateItemJasa.harga.replaceAll(',', ''),
                    id_tdbyr: inputUpdateItemJasa.idTDByr
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingUpdateItemJasa(false);
                setModalUpdateItemJasa(false);

                if (response.data.flag_status === true) {
                    setStateData(s => ({
                        ...s,
                        dataTDBYR: response.data.data_tdbyr,
                        dataDiskon: response.data.data_diskon
                    }));

                    handleSubTotalSummary(response.data.data_tdbyr, response.data.data_diskon, stateData.dataProses[0].dp);
                }

                response.data.flag_status === false && handleWarningModal('Error : ' + response.data.pesan);

                if (response.data.flag_status === 'not-found') {
                    handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
                }

                setInputUpdateItemJasa({ itemJasa: '', qty: '', harga: '', idTDByr: '', prmFocus: '', useStok: '' });
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingUpdateItemJasa(false);
                setModalUpdateItemJasa(true);
                handleWarningModal(error.message);
            })
        }
    }

    const handleHapusBiaya = (prmItemJasa, prmId) => {
        setInputUpdateItemJasa({ ...inputUpdateItemJasa, itemJasa: prmItemJasa, idTDByr: prmId });
        setModalHapusBiaya(true);
    }

    const handleDoHapusBiaya = () => {
        setBtnLoadHapusBiaya(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/hapus_biaya',
            data: {
                id_transaksi: idTransaksi,
                id_tdbyr: inputUpdateItemJasa.idTDByr
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadHapusBiaya(false);
            setModalHapusBiaya(false);

            if (response.data.flag_status === true) {
                setStateData(s => ({
                    ...s,
                    dataTDBYR: response.data.data_tdbyr,
                    dataDiskon: response.data.data_diskon
                }));

                handleSubTotalSummary(response.data.data_tdbyr, response.data.data_diskon, stateData.dataProses[0].dp);
            }

            response.data.flag_status === false && handleWarningModal('Error : ' + response.data.pesan);

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }

            setInputUpdateItemJasa({ itemJasa: '', qty: '', harga: '', idTDByr: '', prmFocus: '', useStok: '' });
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadHapusBiaya(false);
            setModalHapusBiaya(true);
            handleWarningModal(error.message);
        })
    }

    const handleHapusDiskon = () => {
        setBtnLoadingHapusDiskon(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/hapus_penggunaan_diskon',
            data: {
                id_transaksi: idTransaksi
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusDiskon(false);
            setModalHapusDiskon(false);

            if (response.data.flag_status === true) {
                setStateData(s => ({
                    ...s,
                    dataTDBYR: response.data.data_tdbyr,
                    dataDiskon: response.data.data_diskon
                }));

                handleSubTotalSummary(response.data.data_tdbyr, response.data.data_diskon, stateData.dataProses[0].dp);
            }

            response.data.flag_status === false && handleWarningModal('Error : ' + response.data.pesan);

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusDiskon(false);
            setModalHapusBiaya(true);
            handleWarningModal(error.message);
        })
    }

    React.useEffect(() => {
        !loadingDataProses && scrollToBottomRef.current && location.state?.goBottom === true && scrollToBottomRef && scrollToBottomRef.current.scrollIntoView({ block: 'start' });

        setTimeout(() => {
            if (location.state?.goBottom === true) delete location.state.goBottom;
        }, 500);
    }, [scrollToBottomRef, location, loadingDataProses, prmGoBottom]);

    const handleUangMuka = (prmUangMuka) => {
        if (!prmUangMuka || prmUangMuka === '0') {
            return '-';
        } else {
            return 'Rp ' + formatNumberCanZero(prmUangMuka);
        }
    }

    const handleMetodeUangMuka = (prmMetode) => {
        if (!prmMetode) {
            return '';
        } else {
            let hasil = '';

            Object.keys(metodeBayar).forEach(element => {
                if (element === prmMetode) {
                    hasil = '(Metode ' + metodeBayar[element] + ')';
                    return
                }
            });

            return hasil;
        }
    }

    const handleDiskonManual = () => {
        setBtnLoadingDiskonManual(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/diskon_manual',
            data: {
                id_transaksi: idTransaksi,
                jumlah: diskonManual.replaceAll(',', '')
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingDiskonManual(false);
            setModalDiskonManual(false);

            if (response.data.flag_status === true) {
                setStateData(s => ({
                    ...s,
                    dataTDBYR: response.data.data_tdbyr,
                    dataDiskon: response.data.data_diskon
                }));

                handleSubTotalSummary(response.data.data_tdbyr, response.data.data_diskon, stateData.dataProses[0].dp);
                setDiskonManual('');
            }

            response.data.flag_status === false && handleWarningModal('Error : ' + response.data.pesan);

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingDiskonManual(false);
            setModalDiskonManual(true);
            handleWarningModal(error.message);
        })
    }

    const handleKategoriServis = (prmKategoriServis) => {
        setLoadingKategoriServis(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/kategori_servis',
            data: {
                id_transaksi: idTransaksi,
                kategori_servis: prmKategoriServis
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingKategoriServis(false);

            if (response.data.flag_status === true) {
                prmKategoriServis === reminderMaintenance && setStateData(s => ({ ...s, kategoriServis: prmKategoriServis }));

                prmKategoriServis !== reminderMaintenance && setStateData(s => ({ ...s, kategoriServis: prmKategoriServis, reminderAt: '' }));
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.error);
            }

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingKategoriServis(false);
            handleWarningModal(error.message);
        })
    }

    const handleReminderAt = () => {
        setBtnLoadingReminderAt(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/change_reminder_at',
            data: {
                id_transaksi: idTransaksi,
                reminder_at: reminderAtModal.replaceAll(",", "")
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingReminderAt(false);
            setModalReminderAt(false);

            if (response.data.flag_status === true) {
                setStateData(s => ({ ...s, reminderAt: reminderAtModal }));
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.error);
            }

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingReminderAt(false);
            setModalReminderAt(false);
            handleWarningModal(error.message);
        })
    }

    const handleLunas = (prmLunas) => {
        setLoadingLunas(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/change_lunas',
            data: {
                id_transaksi: idTransaksi,
                prm_lunas: prmLunas === '' ? '0' : prmLunas,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingLunas(false);

            if (response.data.flag_status === true) {
                prmLunas === '1' && setStateData(s => ({ ...s, isLunas: prmLunas }));

                prmLunas !== '1' && setStateData(s => ({ ...s, isLunas: prmLunas, tanggalPelunasan: '', metodeBayar: '' }));
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.error);
            }

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingLunas(false);
            handleWarningModal(error.message);
        })
    }

    const handleTanggalAmbil = (prm) => {
        setLoadingTanggalAmbil(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/set_tanggal_ambil',
            data: {
                tanggal_ambil: isoDateToSqlDate(prm),
                id_transaksi: idTransaksi
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingTanggalAmbil(false);

            if (response.data.flag_status === true) {
                setStateData(s => ({ ...s, tanggalAmbil: prm }));
            }

            response.data.flag_status === false && handleWarningModal('Error : ' + response.data.error);

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingTanggalAmbil(false);
            handleWarningModal('Error : ' + error.message)
        })
    }

    const handleTanggalLunas = (prm) => {
        setLoadingTanggalPelunasan(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/set_tanggal_pelunasan',
            data: {
                tanggal_ambil: isoDateToSqlDate(prm),
                id_transaksi: idTransaksi
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingTanggalPelunasan(false);

            if (response.data.flag_status === true) {
                setStateData(s => ({ ...s, tanggalPelunasan: prm }));
            }

            response.data.flag_status === false && handleWarningModal('Error : ' + response.data.error);

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingTanggalPelunasan(false);
            handleWarningModal('Error : ' + error.message)
        })
    }

    const handleSimpanCatatanSelesai = () => {
        setBtnLoadingCatatanSelesai(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/set_catatan_selesai',
            data: {
                catatan_selesai: catatanSelesaiModal,
                id_transaksi: idTransaksi
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingCatatanSelesai(false);
            setModalCatatanSelesai(false);

            if (response.data.flag_status === true) {
                setStateData(s => ({ ...s, catatanSelesai: catatanSelesaiModal }));
            }

            response.data.flag_status === false && handleWarningModal('Error : ' + response.data.error);

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingCatatanSelesai(false);
            setModalCatatanSelesai(false);
            handleWarningModal('Error : ' + error.message)
        })
    }

    const periksaItemJasa = () => {
        let res = true;

        if (stateData.dataTDBYR) {
            stateData.dataTDBYR.forEach(element => {
                if (!element.total || !element.jumlah || !element.harga) {
                    res = false;
                }
            });
        } else {
            res = true;
        }

        return res;
    }

    const handleAmbil = () => {
        if (!stateData.isLunas) {
            setModalAmbil(false);
            handleWarningModal('Status pelunasan wajib diisi.')
        } else if (stateData.isLunas === '1' && !stateData.metodeBayar) {
            setModalAmbil(false);
            handleWarningModal('Jika pembayaran telah lunas, metode pembayaran wajib diisi.')
        } else if (stateData.isLunas === '1' && !stateData.tanggalPelunasan) {
            setModalAmbil(false);
            handleWarningModal('Jika pembayaran telah lunas, tanggal lunas wajib diisi.')
        } else if (!stateData.kategoriServis) {
            setModalAmbil(false);
            handleWarningModal('Kategori servis wajib diisi.')
        } else if (!stateData.tanggalAmbil) {
            setModalAmbil(false);
            handleWarningModal('Tanggal ambil wajib diisi.')
        } else if (!periksaItemJasa()) {
            setModalAmbil(false);
            handleWarningModal('Ada biaya item jasa yang belum diisi ! Isi data qty dan biaya terlebih dahulu.')
        } else {
            setBtnLoadingAmbil(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/submit_ambil',
                data: {
                    id_transaksi: idTransaksi,
                    id_user: deko(reduxLogin.syswebappi)
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingAmbil(false);
                setModalAmbil(false);

                if (response.data.flag_status === true) {
                    history.push('/' + prefixUrl.owner + '/proses/prosesEmpat');
                }

                response.data.flag_status === false && handleWarningModal('Error : ' + response.data.error);

                if (response.data.flag_status === 'not-found') {
                    handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
                }

                if (response.data.flag_status === 'not-found-user') {
                    handleWarningModal('Anda tidak berhak mengakses proses ini !');
                }

                if (response.data.flag_status === 'no-update') {
                    handleWarningModal('Terjadi kesalahan. Silahkan ulangi kembali.');
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingAmbil(false);
                setModalAmbil(false);
                handleWarningModal('Error : ' + error.message)
            })
        }
    }

    const handleMetodeBayar = (prmVal) => {
        setLoadingMetodeBayar(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/change_metode_bayar',
            data: {
                metode: prmVal,
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingMetodeBayar(false);

            if (response.data.status_hasil === true) {
                setStateData(s => ({ ...s, metodeBayar: response.data.metode_pelunasan }));
            }

            if (response.data.status_hasil === false) {
                handleWarningModal('Error : ' + response.data.pesan_error);
            }

            if (response.data.status_hasil === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingMetodeBayar(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop
                shadow={true}
                titleNavTop="Selesai &amp; Siap Ambil"
                prmBackButton={true}
                prmOnBack={`${callbackUrl ? '/' + handleCallBack(deko(reduxLogin.syswebappj), levelUser, callbackUrl, prefixUrl) : '/' + prefixUrl.owner + '/proses/prosesEmpat'}`}
            />

            <Layout titleTab="Selesai &amp; Siap Ambil">
                <Row> <Col xs={12} className="mt-3">
                    {stateData.dataProses && !loadingDataProses && <React.Fragment>
                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3 bgWhite`}>
                            <div className="flex">
                                <div>
                                    <CustomerAvatar
                                        prmSlug={stateData.dataProses[0].slug_ck}
                                        prmGender={stateData.dataProses[0].gender}
                                        ukuran={{ width: '55px', height: '55px' }}
                                    />
                                </div>

                                <div className="ml-3 wordBreakAll" style={{ width: '100%' }}>
                                    <div className="color636363 bold">
                                        {stateData.dataProses[0].nama}
                                    </div>

                                    <div className="color808080 f14">
                                        {stateData.dataProses[0].nama_ktgr_cust}
                                    </div>

                                    <div className="color808080 f14 mt-2">
                                        {stateData.dataProses[0].alamat}
                                    </div>

                                    <div className="color808080 f14">
                                        {stateData.dataProses[0].no_hp}
                                    </div>
                                </div>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600 mb-1">No Nota</div>
                                <div className="color808080">{stateData.dataProses[0].id_transaksi}</div>
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600 mb-1">Tanggal Masuk</div>
                                <div className="color808080">{stateData.dataProses[0].tanggal_masuk ? sqlDateToLongHumanDate(stateData.dataProses[0].tanggal_masuk) : ''}</div>
                            </div>

                            <div className="f14">
                                <div className="color636363 fontweight600 mb-1">Penerima</div>
                                <div className="color808080">{stateData.penerima}</div>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600 mb-1">
                                    Kategori Barang
                                </div>
                                <div className="color808080">{stateData.dataProses[0].nama_barang_kategori}</div>
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600 mb-1">Barang</div>
                                <div className="color808080">{stateData.dataProses[0].nama_barang}</div>
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600">Keluhan</div>
                                <div className="color808080">{stateData.dataProses[0].kerusakan}</div>
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600">Foto Barang</div>

                                {stateData.listFotoTransaksi ? <div className="text-center mt-2">
                                    <Masonry
                                        breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 4 : 5) : 5) : 6) : 6}`}
                                        className="my-masonry-grid"
                                        columnClassName="my-masonry-grid_column"
                                    >
                                        {Object.keys(stateData.listFotoTransaksi).map((key) => {
                                            return (
                                                <img
                                                    key={"lfkp87hjsdk" + key}
                                                    src={ROOT_BASE_URL + stateData.listFotoTransaksi[key].foto}
                                                    alt={'foto'}
                                                    style={{ width: '100%', borderRadius: '6px' }}
                                                    className="cp"
                                                    onClick={() => {
                                                        setSrcFoto(ROOT_BASE_URL + stateData.listFotoTransaksi[key].foto)
                                                        setModalZoomImage(true);
                                                    }}
                                                />
                                            )
                                        })}
                                    </Masonry>
                                </div>
                                    :
                                    <div className="color808080">
                                        Tidak ada
                                    </div>
                                }
                            </div>

                            <div className="f14 mb-2">
                                <div className="color636363 fontweight600">Uang Muka</div>
                                <div className="color808080">
                                    {handleUangMuka(stateData.dataProses[0].dp)}
                                    {' '}
                                    {handleMetodeUangMuka(stateData.dataProses[0].metode_dp)}
                                </div>
                            </div>

                            <div className="f14">
                                <div className="color636363 fontweight600">Catatan</div>
                                <div className="color808080 whiteSpacePreLine">
                                    {stateData.dataProses[0].catatan ? stateData.dataProses[0].catatan : '-'}
                                </div>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                        >
                            <div className="f14 mb-3 colorTheme fontweight600">
                                Kelengkapan Barang
                            </div>

                            <div>
                                <div className="color636363 fontweight600">
                                    Kelengkapan Utama :
                                </div>
                                <div className="color808080 mt-1">{stateData.dataProses[0].nama_barang}</div>
                            </div>

                            <div className="mt-3">
                                <div className="color636363 fontweight600 mb-1">
                                    Kelengkapan Lainnya :
                                </div>

                                {stateData.dataLabel ? Object.keys(stateData.dataLabel).map((keyDL) => {
                                    return (
                                        <span
                                            key={keyDL + 'dl_78'}
                                            className={`color808080`}
                                        >
                                            <span>{stateData.dataLabel[keyDL].label}</span>
                                            <span>{stateData.dataLabel.length === (Number(keyDL) + 1) ? '' : ', '}</span>
                                        </span>
                                    )
                                })
                                    :
                                    <div className="color808080">Tidak ada</div>
                                }
                            </div>
                        </Card>

                        <div className={`mb-3`}>
                            <div className="mb-3 text-center fontweight600">Proses Pengerjaan</div>
                            {stateData.dataProgres !== null ? <React.Fragment>{Object.keys(stateData.dataProgres).map((key) => {
                                return (
                                    <Card
                                        key={key}
                                        className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} ${stateData.dataProgres && Object.keys(stateData.dataProgres).length === (parseInt(key) + 1) ? '' : 'mb-3'} bgWhite shadow-sm brSoftMedium color636363 wordBreakAll`}
                                    >
                                        <div className="flex pb-2 mb-3 borderBottomEee">
                                            <div
                                                className="bgTheme colorWhite f10 mr-3 flexJustifyAlignCenter"
                                                style={{ width: '20px', minWidth: '20px', height: '20px', minHeight: '20px', marginTop: '2px', borderRadius: '50%' }}
                                            >
                                                {parseInt(key) + 1}
                                            </div>

                                            <div style={{ flex: 1 }}>
                                                <div className="fontweight600 colorTheme">
                                                    {stateData.dataProgres[key]['nama_user']}
                                                </div>

                                                <div className="f13 colorAaa">
                                                    {stateData.dataProgres[key]['tanggal_progres'] ? dateTimeToHumanDateTime(stateData.dataProgres[key]['tanggal_progres']) : ''} WIB
                                                </div>
                                            </div>
                                        </div>

                                        <div className="color808080">
                                            {stateData.dataProgres[key].flag_urutan === '1' ? 'Project diambil oleh ' + stateData.dataProgres[key].nama_user :
                                                <div>

                                                    <div className="flex">
                                                        <div className="flex1 whiteSpacePreLine">
                                                            {stateData.dataProgres[key].progres}
                                                        </div>
                                                    </div>

                                                    {stateData.dataProgres[key].id_tdp in stateData.dataPenggunaanStok &&
                                                        <div className="borderTopEee mt-3">
                                                            <div className="colorTheme mt-3">
                                                                Penggunaan Part Stok Barang
                                                            </div>

                                                            <div className="mt-3">
                                                                {stateData.dataProgres[key].id_tdp in stateData.dataPenggunaanStok && Object.keys(stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu).map((keyPS) => {
                                                                    return (
                                                                        <div
                                                                            key={keyPS}
                                                                            className={`flex ${Object.keys(stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu).length === (parseInt(keyPS) + 1) ? '' : 'mb-3'}`}
                                                                        >
                                                                            <div className="mr-2">
                                                                                {(parseInt(keyPS) + 1) + '. '}
                                                                            </div>

                                                                            <div style={{ flex: 1 }}>
                                                                                <div>
                                                                                    {stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].nama} {' '} {stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].merk} {' '} {stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].tipe} {' '} {stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].serial_number ? 'SN ' + stateData.dataPenggunaanStok[stateData.dataProgres[key].id_tdp].yu[keyPS].serial_number : 'SN -'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>


                                        {stateData.dataProgres[key]['b'] !== null && <div className="mt-3 pt-3 borderTopEee">
                                            <Masonry
                                                breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 5 : 5) : 5) : 6) : 6}`}
                                                className="my-masonry-grid"
                                                columnClassName="my-masonry-grid_column"
                                            >
                                                {Object.keys(stateData.dataProgres[key]['b']).map((subKey) => {
                                                    return (
                                                        <img
                                                            key={subKey}
                                                            src={ROOT_BASE_URL + stateData.dataProgres[key]['b'][subKey].foto}
                                                            alt={`foto ${subKey}`}
                                                            className="cp"
                                                            style={{ width: '100%', borderRadius: '6px' }}

                                                            onClick={() => {
                                                                setSrcFoto(ROOT_BASE_URL + stateData.dataProgres[key]['b'][subKey].foto)
                                                                setModalZoomImage(true);
                                                            }}
                                                        />
                                                    )
                                                })}
                                            </Masonry>
                                        </div>
                                        }
                                    </Card>
                                )
                            })}
                            </React.Fragment>
                                :
                                <div className="color808080 mt-3">
                                    Belum ada progres pengerjaan
                                </div>
                            }
                        </div>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="f14">
                                <div className="color636363 fontweight600 mb-3">
                                    Form Q.C. Barang
                                </div>

                                <div className="color808080">
                                    Kategori Form : {stateData.dataKelengkapan ? stateData.dataKelengkapan[0].nama_kategori : 'Tidak Ada'}
                                </div>


                                {stateData.dataKelengkapan &&
                                    <div className="color808080 mt-3">
                                        {Object.keys(stateData.dataKelengkapan).map((key) => {
                                            return (<React.Fragment key={key + 'kkl7js'}>

                                                {key === '0' &&
                                                    <div className="color636363 mb-3 pb-3 borderBottomEee">
                                                        <span className="mr-3">Q.C.</span>
                                                        <span>Kelengkapan</span>
                                                    </div>
                                                }

                                                <Form.Row className="mb-2">
                                                    <Form.Group
                                                        as={Col}
                                                        xs="10"
                                                        controlId={`fg_01_pqc_${key}`}
                                                        className="mb-0"
                                                    >
                                                        <Form.Check
                                                            type="checkbox"
                                                            id={`fgfc_pqc_${key}`}
                                                            custom
                                                        >
                                                            <Form.Check.Input
                                                                type="checkbox"
                                                                readOnly
                                                                disabled

                                                                defaultChecked={parseInt(stateData.dataKelengkapan[key].check_qc)}

                                                                value={parseInt(stateData.dataKelengkapan[key].check_qc)}
                                                            />

                                                            <Form.Check.Label
                                                                className="ml-1"
                                                                style={{ paddingTop: '2px' }}
                                                            >
                                                                <div className="flexAlignCenter ml-3">
                                                                    {
                                                                        parseInt(stateData.dataKelengkapan[key].check) ?
                                                                            <span
                                                                                className="fa fa-check f10 mr-1"
                                                                                style={{ color: '#007BFF' }}
                                                                            />
                                                                            :
                                                                            <span
                                                                                className="fa fa-close f10 mr-1"
                                                                                style={{ color: '#ccc' }}
                                                                            />
                                                                    }

                                                                    <span>
                                                                        {stateData.dataKelengkapan[key].nama_tdk}
                                                                    </span>
                                                                </div>
                                                            </Form.Check.Label>

                                                            <div className="mt-2 color808080 f14 ml-4">
                                                                {stateData.dataKelengkapan[key].keterangan}
                                                            </div>

                                                            <div
                                                                className="mt-2 color808080 f14 ml-4"
                                                            >
                                                                {stateData.dataKelengkapan[key].keterangan_qc &&
                                                                    <div className="borderTopEee pt-2">
                                                                        <div className="mb-0">
                                                                            Keterangan Q.C. :
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div id={`ketCapt_so7_pqc_${key}`}>
                                                                    {stateData.dataKelengkapan[key].keterangan_qc}
                                                                </div>
                                                            </div>
                                                        </Form.Check>
                                                    </Form.Group>
                                                </Form.Row>

                                                {(stateData.dataKelengkapan.length - 1) !== parseInt(key) && <hr />}
                                            </React.Fragment>
                                            )
                                        })
                                        }
                                    </div>
                                }
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="color636363 fontweight600 mb-3">Rincian Biaya</div>

                            <div className="color808080">
                                <div>
                                    Stok item yang digunakan : {!stateData.dataPenggunaanStokInclusion && 'Tidak Ada'}
                                </div>

                                <div className="mt-2">
                                    {stateData.dataPenggunaanStokInclusion && Object.keys(stateData.dataPenggunaanStokInclusion).map((key) => {
                                        return (
                                            <Card key={key + 'inclsn_ps_879j'} className="p-2 mb-2">
                                                <div>
                                                    {stateData.dataPenggunaanStokInclusion[key].nama + ' ' + stateData.dataPenggunaanStokInclusion[key].merk + ' ' + stateData.dataPenggunaanStokInclusion[key].tipe + ' SN ' + (stateData.dataPenggunaanStokInclusion[key].serial_number ? stateData.dataPenggunaanStokInclusion[key].serial_number : '-')}
                                                </div>

                                                <div>Supplier : {stateData.dataPenggunaanStokInclusion[key].supplier ? stateData.dataPenggunaanStokInclusion[key].supplier : ' - '}</div>

                                                <div>Masa Garansi : {stateData.dataPenggunaanStokInclusion[key].masa_garansi ? stateData.dataPenggunaanStokInclusion[key].masa_garansi : ' - '} Bulan</div>

                                                <div>Tanggal Pembelian : {stateData.dataPenggunaanStokInclusion[key].tanggal_beli ? sqlDateToLongHumanDate(stateData.dataPenggunaanStokInclusion[key].tanggal_beli) : ' - '}</div>

                                                <div>Harga Beli : Rp {formatNumberCanZero(stateData.dataPenggunaanStokInclusion[key].harga_beli)}</div>

                                                <div>Original : {stateData.dataPenggunaanStokInclusion[key].is_original ? 'Ya' : 'Tidak'}</div>
                                            </Card>
                                        )
                                    })
                                    }
                                </div>
                            </div>

                            <div className="mt-4">
                                {stateData.dataTDBYR && Object.keys(stateData.dataTDBYR).map((key) => {
                                    return (
                                        <div
                                            key={key + 'ps_7987gh'}
                                            className="flexAlignTopReverse mb-3 borderBottomEeeChild"
                                        >
                                            <div className="flex1">
                                                <Form.Row className="mb-3">
                                                    <Col xs={12} md={7} className="mb-2">
                                                        <Form.Control
                                                            readOnly
                                                            className="bgInputImportant"
                                                            size="sm"
                                                            type="text"
                                                            placeholder="Item/Jasa"

                                                            value={stateData.dataTDBYR[key].item_jasa}

                                                            onClick={() => setDataModalUpdateItemJasa(stateData.dataTDBYR[key].item_jasa, stateData.dataTDBYR[key].jumlah, stateData.dataTDBYR[key].harga, 'itemJasa', stateData.dataTDBYR[key].id, stateData.dataTDBYR[key].use_stok, parseInt(stateData.dataTDBYR[key].use_stok) ? formatNumberCanZero(stateData.dataTDBYR[key].harga_asli) : 'Harga')}
                                                        />
                                                    </Col>

                                                    <Col xs={4} md={2} className="mb-2">
                                                        <InputGroup size="sm">
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text>Qty</InputGroup.Text>
                                                            </InputGroup.Prepend>

                                                            <Form.Control
                                                                readOnly
                                                                disabled={parseInt(stateData.dataTDBYR[key].use_stok) ? true : false}
                                                                className="bgInputImportant"
                                                                size="sm"
                                                                type="text"
                                                                placeholder="Qty"
                                                                value={formatNumberCanZero(stateData.dataTDBYR[key].jumlah)}
                                                                onClick={() => setDataModalUpdateItemJasa(stateData.dataTDBYR[key].item_jasa, stateData.dataTDBYR[key].jumlah, stateData.dataTDBYR[key].harga, 'qty', stateData.dataTDBYR[key].id, stateData.dataTDBYR[key].use_stok, parseInt(stateData.dataTDBYR[key].use_stok) ? formatNumberCanZero(stateData.dataTDBYR[key].harga_asli) : 'Harga')}
                                                            />
                                                        </InputGroup>
                                                    </Col>

                                                    <Col xs={8} md={3} className="mb-2">
                                                        <InputGroup size="sm">
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text>Rp</InputGroup.Text>
                                                            </InputGroup.Prepend>

                                                            <Form.Control
                                                                readOnly
                                                                className="bgInputImportant"
                                                                type="text"
                                                                placeholder={parseInt(stateData.dataTDBYR[key].use_stok) ? formatNumberCanZero(stateData.dataTDBYR[key].harga_asli) : 'Harga'}

                                                                value={formatNumberCanZero(stateData.dataTDBYR[key].harga)}

                                                                onClick={() => setDataModalUpdateItemJasa(stateData.dataTDBYR[key].item_jasa, stateData.dataTDBYR[key].jumlah, stateData.dataTDBYR[key].harga, 'harga', stateData.dataTDBYR[key].id, stateData.dataTDBYR[key].use_stok, parseInt(stateData.dataTDBYR[key].use_stok) ? formatNumberCanZero(stateData.dataTDBYR[key].harga_asli) : 'Harga')}
                                                            />
                                                        </InputGroup>
                                                    </Col>


                                                    <Col
                                                        xs={4}
                                                        md={9}
                                                        className="flexJustifyStartAlignCenter"
                                                    >
                                                        {parseInt(stateData.dataTDBYR[key].use_stok) === 0 &&
                                                            <Button
                                                                variant="link"
                                                                size="sm"
                                                                className="btnXsOverride px-1 colorTheme"
                                                                onClick={() => handleHapusBiaya(stateData.dataTDBYR[key].item_jasa, stateData.dataTDBYR[key].id)}
                                                            >
                                                                <span className="fa fa-trash" /> Hapus
                                                            </Button>
                                                        }
                                                    </Col>

                                                    <Col
                                                        xs={8}
                                                        md={3}
                                                        className="text-right"
                                                    >
                                                        <InputGroup size="sm">
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text>Rp</InputGroup.Text>
                                                            </InputGroup.Prepend>

                                                            <Form.Control
                                                                readOnly
                                                                disabled
                                                                className=""
                                                                id={`total_${key}`}
                                                                size="sm"
                                                                type="text"
                                                                placeholder="Total"
                                                                value={formatNumberCanZero(stateData.dataTDBYR[key].total)}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Row>
                                            </div>

                                            <div className="mr-2 pt-2">
                                                {parseInt(key) + 1}
                                            </div>
                                        </div>
                                    )
                                })}

                                <div className="flexJustifyAlignCenter">
                                    <Button
                                        className="px-3"
                                        variant="outline-info"
                                        size="sm"
                                        onClick={() => setModalTambahItemJasa(true)}
                                    >
                                        <span className="fa fa-plus-circle" /> Tambah Biaya Item/Jasa
                                    </Button>
                                </div>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div ref={scrollToBottomRef} />

                            <div>
                                <div className="flexAlignCenter mb-3">
                                    <div className="flex1">Sub Total</div>
                                    <div className="flex2">
                                        <InputGroup size="sm">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>Rp</InputGroup.Text>
                                            </InputGroup.Prepend>

                                            <Form.Control
                                                readOnly
                                                disabled
                                                className="bgInputImportant"
                                                value={formatNumberCanZero(resumeBiaya.subTotal)}
                                                size="sm"
                                                type="text"
                                                placeholder="Sub Total"
                                            />
                                        </InputGroup>
                                    </div>
                                </div>

                                <div className="flexAlignCenter mb-3">
                                    <div className="flex1">Uang Muka</div>
                                    <div className="flex2">
                                        <InputGroup size="sm">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>Rp</InputGroup.Text>
                                            </InputGroup.Prepend>

                                            <Form.Control
                                                readOnly
                                                disabled
                                                className="bgInputImportant"
                                                size="sm"
                                                type="text"
                                                placeholder="Uang Muka"
                                                value={stateData.dataProses[0].dp ? formatNumberCanZero(stateData.dataProses[0].dp) : '0'}
                                            />
                                        </InputGroup>
                                    </div>
                                </div>

                                <div className="flexAlignTop mb-3">
                                    <div className="flex1">Diskon</div>
                                    <div className="flex2">
                                        <div className="colorTheme">
                                            <div
                                                className="cp"
                                                onClick={() => history.push('/' + prefixUrl.owner + '/caridiskon/' + idTransaksi)}
                                            >
                                                {stateData.dataDiskon && stateData.dataDiskon[0].keterangan ? 'Ganti Diskon' : 'Gunakan Diskon'}
                                            </div>

                                            {stateData.dataDiskon && stateData.dataDiskon[0].keterangan &&
                                                <div className="f13 mt-1 color808080">
                                                    <div className="flexJustifyBeetwenAlignCenter">
                                                        <div>
                                                            Diskon{' '}
                                                            {stateData.dataDiskon[0].flag === 'rupiah' && 'Rp '}
                                                            {formatNumberCanZero(stateData.dataDiskon[0].jumlah)}
                                                            {stateData.dataDiskon[0].flag !== 'rupiah' && '%'}
                                                        </div>

                                                        <div
                                                            className="colorTheme pl-2 cp"
                                                            onClick={() => setModalHapusDiskon(true)}
                                                        >
                                                            <span className="fa fa-trash" />
                                                        </div>
                                                    </div>

                                                    <div>{stateData.dataDiskon[0].keterangan}</div>
                                                </div>
                                            }
                                        </div>

                                        <div className="mt-2">
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text>Rp</InputGroup.Text>
                                                </InputGroup.Prepend>

                                                <Form.Control
                                                    readOnly
                                                    className="bgInputImportant"
                                                    type="text"
                                                    placeholder="Diskon"
                                                    value={resumeBiaya.diskon === 0 ? '' : formatNumberCanZero(resumeBiaya.diskon)}
                                                    onClick={() => setModalDiskonManual(true)}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </div>

                                <div className="flexAlignCenter mb-3">
                                    <div className="flex1">Total</div>
                                    <div className="flex2">
                                        <InputGroup size="sm">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>Rp</InputGroup.Text>
                                            </InputGroup.Prepend>

                                            <Form.Control
                                                readOnly
                                                disabled
                                                className="bgInputImportant"
                                                size="sm"
                                                type="text"
                                                placeholder="Total"
                                                value={formatNumberCanZeroMinus(resumeBiaya.total)}
                                            />
                                        </InputGroup>
                                    </div>
                                </div>

                                <div className="flexAlignCenter">
                                    <div className="flex1">
                                        <span>Lunas</span>

                                        {loadingLunas &&
                                            <Spinner
                                                as="span"
                                                className="ml-1"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        }
                                    </div>
                                    <div className="flex2">
                                        <Form.Control
                                            custom
                                            as="select"
                                            size="sm"
                                            value={stateData.isLunas}
                                            onChange={e => handleLunas(e.target.value)}
                                        >
                                            <option value="">Status Lunas</option>
                                            <option value="1">Lunas</option>
                                            <option value="0">Belum Lunas</option>
                                        </Form.Control>
                                    </div>
                                </div>

                                {/* down */}
                                {stateData.isLunas && stateData.isLunas === '1' &&
                                    <div className="flexAlignCenter mt-3">
                                        <div className="flex1">
                                            <span>Metode</span>

                                            {loadingMetodeBayar &&
                                                <Spinner
                                                    as="span"
                                                    className="ml-1"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            }
                                        </div>

                                        <div className="flex2">
                                            <Form.Control
                                                custom
                                                as="select"
                                                size="sm"
                                                ref={refMetodePelunasan}
                                                value={stateData.metodeBayar}
                                                onChange={(e) => handleMetodeBayar(e.target.value)}
                                            >
                                                <option value="">Metode Pembayaran</option>
                                                {Object.keys(metodeBayar).map((keyMP) => {
                                                    return (
                                                        <option
                                                            key={keyMP + 'mbT9889j'}
                                                            value={keyMP}
                                                        >
                                                            {metodeBayar[keyMP]}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Control>
                                        </div>
                                    </div>
                                }
                                {/* up */}

                                {stateData.isLunas && stateData.isLunas === '1' &&
                                    <div className="flexAlignCenter mt-3">
                                        <div className="flex1">
                                            <span>Tanggal Lunas</span>

                                            {loadingTanggalPelunasan &&
                                                <Spinner
                                                    as="span"
                                                    className="ml-1"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            }
                                        </div>

                                        <div className="flex2">
                                            <Form.Control
                                                readOnly
                                                className="bgInputImportant"
                                                size="sm"
                                                type="text"
                                                placeholder="Tanggal Lunas"
                                                value={stateData.tanggalPelunasan ? sqlDateToLongHumanDate(isoDateToSqlDate(stateData.tanggalPelunasan)) : ''}
                                                onClick={() => setModalCalendarTglPelunasan(true)}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                            <div className="color636363 fontweight600 mb-3">Rincian Pengambilan</div>

                            <div>
                                <Form.Group className="mb-0">
                                    <Form.Label>
                                        <span>Kategori Servis</span>

                                        {loadingKategoriServis &&
                                            <Spinner
                                                as="span"
                                                className="ml-1"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        }
                                    </Form.Label>

                                    <Form.Control
                                        custom
                                        as="select"
                                        size="sm"
                                        value={stateData.kategoriServis}
                                        onChange={e => handleKategoriServis(e.target.value)}
                                    >
                                        <option value="">Kategori Servis</option>
                                        {Object.keys(kategoriServis).map((key) => {
                                            return (
                                                <option
                                                    key={key + 'ks_hu878'}
                                                    value={key}
                                                >
                                                    {kategoriServis[key]}
                                                </option>
                                            )
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </div>

                            {stateData.kategoriServis && stateData.kategoriServis === reminderMaintenance &&
                                <div className="mt-3">
                                    <Form.Group className="mb-0">
                                        <Form.Label>Reminder Untuk Maintenance</Form.Label>

                                        <InputGroup size="sm">
                                            <Form.Control
                                                readOnly
                                                className="bgInputImportant"
                                                size="sm"
                                                type="text"
                                                placeholder="Misal : 6"
                                                value={formatNumberCanZero(stateData.reminderAt)}
                                                onClick={() => {
                                                    setReminderAtModal(stateData.reminderAt);
                                                    setModalReminderAt(true);
                                                }}
                                            />

                                            <InputGroup.Append>
                                                <InputGroup.Text>Bulan Mendatang</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>

                                    <div className="colorAaa f12 mt-1">Kosongkan jika tidak akan direminder</div>
                                </div>
                            }

                            <div className="mt-3">
                                <Form.Group className="mb-0">
                                    <Form.Label>Tanggal Ambil</Form.Label>

                                    {loadingTanggalAmbil &&
                                        <Spinner
                                            as="span"
                                            className="ml-1"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    }

                                    <Form.Control
                                        readOnly
                                        className="bgInputImportant"
                                        size="sm"
                                        type="text"
                                        placeholder="Tanggal Ambil"
                                        value={stateData.tanggalAmbil ? sqlDateToLongHumanDate(isoDateToSqlDate(stateData.tanggalAmbil)) : ''}
                                        onClick={() => setModalCalendarTglAmbil(true)}
                                    />
                                </Form.Group>
                            </div>
                        </Card>

                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium`}>
                            <div className="color636363 fontweight600 mb-3">Catatan</div>

                            <div>
                                <Form.Group className="mb-0">
                                    <Form.Control
                                        readOnly
                                        as="textarea"
                                        size="sm"
                                        rows={3}
                                        placeholder="Catatan (opsional)"

                                        onClick={() => {
                                            setCatatanSelesaiModal(stateData.catatanSelesai);
                                            setModalCatatanSelesai(true);
                                        }}
                                        className="inputOverride"
                                        value={stateData.catatanSelesai}
                                    />
                                </Form.Group>
                            </div>
                        </Card>

                        <div className="flexJustifyAlignCenter">
                            <Button
                                variant="info"
                                size="sm"
                                className="mt-4 py-2 width100"
                                onClick={() => setModalAmbil(true)}
                            >
                                Serahkan Barang Kepada Customer
                            </Button>
                        </div>

                    </React.Fragment>
                    }

                    {
                        loadingDataProses &&
                        <PlaceHolderElement1 prmMT="" prmPX="" />
                    }

                    {!stateData.dataProses && !loadingDataProses &&
                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-5' : 'p-4'} shadow-sm brSoftMedium text-center f13`}>
                            <div className="f14 mb-3 color808080">
                                Tidak ada data
                            </div>

                            <div className="flexJustifyAlignCenter">
                                <Image
                                    src={soldering}
                                    style={{
                                        width: '75%'
                                    }}
                                />
                            </div>
                        </Card>
                    }
                </Col>
                </Row>
            </Layout>

            <Modal
                size="lg"
                show={modalZoomImage}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body className="p-0">
                    <div className="flexJustifyAlignCenter">
                        <span
                            onClick={() => setModalZoomImage(false)}
                            className="fa fa-close f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                            style={{
                                borderRadius: '50%',
                                width: '35px',
                                height: '35px',
                                position: 'absolute',
                                top: '15px',
                                right: '17px'
                            }}
                        />

                        <img src={srcFoto} alt='foto' style={{ width: '100%' }} />
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={modalTambahItemJasa}
                onShow={() => refInputItemJasa.current.focus()}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f15 fontweight600 mb-4">Tambah Item/Jasa</div>

                    <div className="flex1">
                        <Form.Row>
                            <Col xs={12} className="mb-2">
                                <Form.Control
                                    name="itemJasa"
                                    ref={refInputItemJasa}
                                    size="sm"
                                    type="text"
                                    placeholder="Item/Jasa"
                                    value={inputTambahItemJasa.itemJasa}
                                    onChange={(e) => setInputTambahItemJasa({ ...inputTambahItemJasa, itemJasa: e.target.value })}
                                />

                                <div className="mt-1 mb-2 f11 colorAaa">Caption ini akan menjadi keterangan item/jasa pada bukti pembayaran</div>
                            </Col>

                            <Col xs={4} className="mb-2">
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Qty</InputGroup.Text>
                                    </InputGroup.Prepend>

                                    <Form.Control
                                        ref={refInputQty}
                                        name="qty"
                                        type="text"
                                        placeholder="Qty"
                                        value={formatNumberCanZero(inputTambahItemJasa.qty)}
                                        onChange={(e) => setInputTambahItemJasa({ ...inputTambahItemJasa, qty: formatNumber(e.target.value) })}
                                    />
                                </InputGroup>
                            </Col>

                            <Col xs={8} className="mb-2">
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Rp</InputGroup.Text>
                                    </InputGroup.Prepend>

                                    <Form.Control
                                        ref={refInputHarga}
                                        name="harga"
                                        type="text"
                                        placeholder="Harga"
                                        value={formatNumberCanZero(inputTambahItemJasa.harga)}
                                        onChange={(e) => setInputTambahItemJasa({ ...inputTambahItemJasa, harga: formatNumber(e.target.value) })}
                                    />
                                </InputGroup>
                            </Col>

                            <Col xs={12} className="mt-4">
                                <div className="flexJustifyAlignCenter">
                                    <div className="mr-3">
                                        <Button
                                            variant="light"
                                            size="sm"
                                            className="px-4"
                                            onClick={() => setModalTambahItemJasa(false)}
                                        >
                                            Close
                                        </Button>
                                    </div>

                                    <div>
                                        <ButtonLoading
                                            prmLoading={btnLoadingTambahItemJasa}
                                            btnClass="px-3"
                                            btnVariant="info"
                                            btnOnClick={handleTambahItemJasa}
                                            caption="Tambah"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                    </div>
                </Modal.Body>
            </Modal>



            <Modal
                show={modalUpdateItemJasa}
                onShow={focusFieldUpdate}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f15 fontweight600 mb-4">Update Item/Jasa</div>

                    <div className="flex1">
                        <Form.Row>
                            <Col xs={12} className="mb-2">
                                <Form.Control
                                    name="itemJasa"
                                    ref={refUpdateItemJasa}
                                    size="sm"
                                    type="text"
                                    placeholder="Item/Jasa"
                                    value={inputUpdateItemJasa.itemJasa}
                                    onChange={(e) => setInputUpdateItemJasa({ ...inputUpdateItemJasa, itemJasa: e.target.value })}
                                />

                                <div className="mt-1 mb-2 f11 colorAaa">Caption ini akan menjadi keterangan item/jasa pada bukti pembayaran</div>
                            </Col>

                            <Col xs={4} className="mb-2">
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Qty</InputGroup.Text>
                                    </InputGroup.Prepend>

                                    <Form.Control
                                        readOnly={parseInt(inputUpdateItemJasa.useStok) ? true : false}
                                        disabled={parseInt(inputUpdateItemJasa.useStok) ? true : false}
                                        ref={refUpdateQty}
                                        name="qty"
                                        type="text"
                                        placeholder="Qty"
                                        value={formatNumberCanZero(inputUpdateItemJasa.qty)}
                                        onChange={(e) => setInputUpdateItemJasa({ ...inputUpdateItemJasa, qty: formatNumber(e.target.value) })}
                                    />
                                </InputGroup>
                            </Col>

                            <Col xs={8} className="mb-2">
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Rp</InputGroup.Text>
                                    </InputGroup.Prepend>

                                    <Form.Control
                                        ref={refUpdateHarga}
                                        name="harga"
                                        type="text"
                                        placeholder={inputUpdateItemJasa.prmHargaAsli}
                                        value={formatNumberCanZero(inputUpdateItemJasa.harga)}
                                        onChange={(e) => setInputUpdateItemJasa({ ...inputUpdateItemJasa, harga: formatNumber(e.target.value) })}
                                    />
                                </InputGroup>
                            </Col>

                            <Col xs={12} className="mt-4">
                                <div className="flexJustifyAlignCenter">
                                    <div className="mr-3">
                                        <Button
                                            variant="light"
                                            size="sm"
                                            className="px-4"
                                            onClick={() => setModalUpdateItemJasa(false)}
                                        >
                                            Close
                                        </Button>
                                    </div>

                                    <div>
                                        <ButtonLoading
                                            prmLoading={btnLoadingUpdateItemJasa}
                                            btnClass="px-3"
                                            btnVariant="info"
                                            btnOnClick={handleUpdateItemJasa}
                                            caption="Update"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                    </div>
                </Modal.Body>
            </Modal>

            <ModalConfirmation
                showModal={modalHapusBiaya}
                hideModal={() => setModalHapusBiaya(false)}
                title="Konfirmasi"
                bodyCaption={`Hapus Biaya '${inputUpdateItemJasa.itemJasa}' ?`}
                btnCloseClass="px-3"
                btnCloseCaption="Close"
                bLPrm={btnLoadHapusBiaya}
                bLBtnClass="ml-3 px-3"
                bLOnClick={handleDoHapusBiaya}
                bLCaption="Hapus"
            />

            <ModalConfirmation
                showModal={modalHapusDiskon}
                hideModal={() => setModalHapusDiskon(false)}
                title="Konfirmasi"
                bodyCaption="Hapus Diskon ?"
                btnCloseClass="px-3"
                btnCloseCaption="Close"
                bLPrm={btnLoadingHapusDiskon}
                bLBtnClass="ml-3 px-3"
                bLOnClick={handleHapusDiskon}
                bLCaption="Hapus"
            />


            <Modal
                show={modalDiskonManual}
                onShow={() => {
                    refDiskonManual.current.focus();
                }}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f16 color636363 fontweight600 mb-3">Diskon</div>

                    <div>
                        <InputGroup size="sm" className="mb-0">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Rp</InputGroup.Text>
                            </InputGroup.Prepend>

                            <Form.Control
                                type="text"
                                ref={refDiskonManual}
                                size="sm"
                                placeholder="Diskon"
                                value={diskonManual ? formatNumberCanZero(diskonManual) : ''}
                                onChange={(e) => setDiskonManual(formatNumberCanZero(e.target.value))}
                            />
                        </InputGroup>
                    </div>

                    <div className="mt-4 flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="px-3"
                            onClick={() => setModalDiskonManual(false)}
                        >
                            Close
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadingDiskonManual}
                            btnClass="ml-3"
                            btnVariant="info"
                            btnOnClick={handleDiskonManual}
                            caption="Simpan"
                        />
                    </div>
                </Modal.Body>
            </Modal>



            <Modal
                show={modalReminderAt}
                onShow={() => {
                    refReminderAt.current.focus();
                }}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f16 color636363 fontweight600 mb-3">Reminder Maintenance</div>

                    <div>
                        <InputGroup size="sm">
                            <Form.Control
                                type="text"
                                ref={refReminderAt}
                                size="sm"
                                placeholder="Misal : 6"
                                value={formatNumberCanZero(reminderAtModal)}
                                onChange={(e) => setReminderAtModal(formatNumber(e.target.value))}
                            />

                            <InputGroup.Append>
                                <InputGroup.Text>Bulan Mendatang</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>

                    <div className="colorAaa f12 mt-1">Kosongkan jika tidak akan direminder</div>

                    <div className="mt-4 flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="px-3"
                            onClick={() => setModalReminderAt(false)}
                        >
                            Close
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadingReminderAt}
                            btnClass="ml-3"
                            btnVariant="info"
                            btnOnClick={handleReminderAt}
                            caption="Simpan"
                        />
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={modalCalendarTglAmbil}
                onHide={() => setModalCalendarTglAmbil(false)}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tanggal Ambil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flexJustifyAlignCenter pt-1 pb-3">
                        <Calendar
                            defaultView="month"
                            onChange={(value) => {
                                handleTanggalAmbil(value)
                                setModalCalendarTglAmbil(false);
                            }}
                            value={stateData.tanggalAmbil ? new Date(stateData.tanggalAmbil) : new Date()}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={modalCalendarTglPelunasan}
                onHide={() => setModalCalendarTglPelunasan(false)}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tanggal Lunas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flexJustifyAlignCenter pt-1 pb-3">
                        <Calendar
                            defaultView="month"
                            onChange={(value) => {
                                handleTanggalLunas(value)
                                setModalCalendarTglPelunasan(false);
                            }}
                            value={stateData.tanggalPelunasan ? new Date(stateData.tanggalPelunasan) : new Date()}
                        />
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={modalCatatanSelesai}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f16 color636363 fontweight600 mb-3">Catatan</div>

                    <div>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            size="sm"
                            placeholder="Catatan"
                            value={catatanSelesaiModal}
                            onChange={(e) => setCatatanSelesaiModal(e.target.value)}
                        />
                    </div>

                    <div className="mt-4 flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="px-3"
                            onClick={() => setModalCatatanSelesai(false)}
                        >
                            Close
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadingCatatanSelesai}
                            btnClass="ml-3"
                            btnVariant="info"
                            btnOnClick={handleSimpanCatatanSelesai}
                            caption="Simpan"
                        />
                    </div>
                </Modal.Body>
            </Modal>


            <ModalConfirmation
                showModal={modalAmbil}
                hideModal={() => setModalAmbil(false)}
                title="Konfirmasi"
                bodyCaption={<React.Fragment><div className="alert alert-info"><div className="mb-4"><span className="fa fa-exclamation-circle" /> Pastikan pengisian data telah benar.</div><div>Serahkan Barang Kepada Customer ?</div></div></React.Fragment>}
                btnCloseClass="px-3"
                btnCloseCaption="Close"
                bLPrm={btnLoadingAmbil}
                bLBtnClass="ml-3 px-3"
                bLOnClick={handleAmbil}
                bLCaption="Ya, Serahkan"
            />

        </React.Fragment>
    );
}

export default ProsesSelesai;
