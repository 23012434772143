import React from 'react';
import axios from "axios";
import { Row, Col, Modal, Form, Button, InputGroup } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import Calendar from 'react-calendar';
import moment from 'moment';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import { BASE_URL_DUA, batasFluid, prefixUrl } from "../../utils/globalVar";
import { formatNumberCanZero, formatNumber, sqlYmDateToTahunBulan, getDateYm, sqlDateToSqlYm, sqlDateToYmDate, isoDateToSqlDate, sqlDateToLongHumanDate, sliceIsoDateToDateYm, VFNotNumber, deko } from "../../utils/globalFunc";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import LottiePausAstronot from '../../components/LottiePausAstronot';
import ButtonLoading from "../../components/ButtonLoading";
import ModalConfirmation from "../../components/ModalConfirmation";

const AnggaranBelanjaBarang = () => {
    // info : modul AnggaranBelanjaBarang ini harusnya namanya modul Pengeluaran, tapi karena sudah terlanjur, yasudahlah

    const history = useHistory();

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    const [loadingInit, setLoadingInit] = React.useState(false);
    const [dataAnggaran, setDataAnggaran] = React.useState(null);
    const [dataKategori, setDataKategori] = React.useState(null);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [showModalCalendar, setShowModalCalendar] = React.useState(false);
    const [modalTambahCBS, setModalTambahCBS] = React.useState(false);
    const [btnLoadingTambahCBS, setBtnLoadingTambahCBS] = React.useState(false);
    const [dataTambahCBS, setDataTambahCBS] = React.useState({
        nama: '',
        keterangan: '',
        kategori: '',
        jumlah: '',
        tanggal: new Date()
    });

    const [showModalCalendarEdit, setShowModalCalendarEdit] = React.useState(false);
    const [modalEditCBS, setModalEditCBS] = React.useState(false);
    const [dataEditCBS, setDataEditCBS] = React.useState({
        id: '',
        nama: '',
        keterangan: '',
        kategori: '',
        jumlah: '',
        tanggal: new Date()
    });
    const [btnLoadingEditCBS, setBtnLoadingEditCBS] = React.useState(false);

    const [modalHapusCBS, setModalHapusCBS] = React.useState(false);
    const [btnLoadingHapusCBS, setBtnLoadingHapusCBS] = React.useState(false);

    const [filterKategori, setFilterKategori] = React.useState({
        id: 'all',
        nama: '',
        prm: false,
        bulan: new Date()
    });

    const [modalFilter, setModalFilter] = React.useState(false);
    const [modalFilterBulan, setModalFilterBulan] = React.useState(false);

    const refNamaCBS = React.useRef();
    const refJumlahCBS = React.useRef();
    const refKategoriCBS = React.useRef();

    const refNamaCBSEdit = React.useRef();
    const refJumlahCBSEdit = React.useRef();
    const refKategoriCBSEdit = React.useRef();

    const [constYm, setConstYm] = React.useState(moment().startOf('month').format('YYYY-MM'));

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        setLoadingInit(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/get_data_anggaran_belanja_stok',
            data: {
                date_ym: getDateYm(),
                id_user: deko(reduxLogin.syswebappi),
                kategori: 'all'
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);

            setDataAnggaran(response.data.data_anggaran ? response.data.data_anggaran['k1'] : null);
            setDataKategori(response.data.data_kategori);

        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }, [reduxLogin.syswebappi]);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";

        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, []);

    const handleSimpanCBS = () => {
        refNamaCBS.current.style.border = 'unset';
        refJumlahCBS.current.style.border = 'unset';
        refKategoriCBS.current.style.border = 'unset';

        if (!dataTambahCBS.kategori) {
            refKategoriCBS.current.style.border = '1px solid red';
        } else if (!dataTambahCBS.nama) {
            refNamaCBS.current.style.border = '1px solid red';
            refNamaCBS.current.focus();
        } else if (!dataTambahCBS.jumlah.replaceAll(',', '')) {
            refJumlahCBS.current.style.border = '1px solid red';
            refJumlahCBS.current.focus();
        } else if (VFNotNumber(dataTambahCBS.jumlah.replaceAll(',', ''))) {
            refJumlahCBS.current.style.border = '1px solid red';
            refJumlahCBS.current.focus();
        } else {
            setBtnLoadingTambahCBS(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/tambah_anggaran_belanja_stok',
                data: {
                    nama: dataTambahCBS.nama,
                    kategori: dataTambahCBS.kategori,
                    keterangan: dataTambahCBS.keterangan,
                    jumlah: dataTambahCBS.jumlah.replaceAll(',', ''),
                    tanggal: isoDateToSqlDate(dataTambahCBS.tanggal),
                    date_ym: sliceIsoDateToDateYm(dataTambahCBS.tanggal),
                    id_user: deko(reduxLogin.syswebappi),
                    filter_kategori: filterKategori.id
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingTambahCBS(false);
                setModalTambahCBS(false);

                if (response.data.flag_status === true) {
                    setDataAnggaran(response.data.data_anggaran ? response.data.data_anggaran['k1'] : null);
                    setConstYm(sliceIsoDateToDateYm(dataTambahCBS.tanggal));
                    setDataTambahCBS({
                        nama: '',
                        keterangan: '',
                        kategori: '',
                        jumlah: '',
                        tanggal: new Date()
                    });
                }

                response.data.flag_status === false && handleWarningModal('Error : ' + response.data.error);
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingTambahCBS(false);
                setModalTambahCBS(false);
                handleWarningModal('Warning : ' + error.message);
            });
        }
    }

    const handleUpdateCBS = () => {
        refNamaCBSEdit.current.style.border = 'unset';
        refJumlahCBSEdit.current.style.border = 'unset';
        refKategoriCBSEdit.current.style.border = 'unset';

        if (!dataEditCBS.kategori) {
            refKategoriCBSEdit.current.style.border = '1px solid red';
        } else if (!dataEditCBS.nama) {
            refNamaCBSEdit.current.style.border = '1px solid red';
            refNamaCBSEdit.current.focus();
        } else if (!dataEditCBS.jumlah.replaceAll(',', '')) {
            refJumlahCBSEdit.current.style.border = '1px solid red';
            refJumlahCBSEdit.current.focus();
        } else if (VFNotNumber(dataEditCBS.jumlah.replaceAll(',', ''))) {
            refJumlahCBSEdit.current.style.border = '1px solid red';
            refJumlahCBSEdit.current.focus();
        } else {
            setBtnLoadingEditCBS(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/edit_anggaran_belanja_stok',
                data: {
                    id: dataEditCBS.id,
                    nama: dataEditCBS.nama,
                    kategori: dataEditCBS.kategori,
                    keterangan: dataEditCBS.keterangan,
                    jumlah: dataEditCBS.jumlah.replaceAll(',', ''),
                    tanggal: isoDateToSqlDate(dataEditCBS.tanggal),
                    date_ym: sliceIsoDateToDateYm(dataEditCBS.tanggal),
                    id_user: deko(reduxLogin.syswebappi),
                    filter_kategori: filterKategori.id
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingEditCBS(false);
                setModalEditCBS(false);

                if (response.data.flag_status === true) {
                    setDataAnggaran(response.data.data_anggaran && response.data.data_anggaran['k1']);
                    setDataEditCBS({
                        id: '',
                        nama: '',
                        kategori: '',
                        keterangan: '',
                        jumlah: '',
                        tanggal: new Date()
                    });
                }

                response.data.flag_status === false && handleWarningModal('Error : ' + response.data.error);
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingEditCBS(false);
                setModalEditCBS(false);
                handleWarningModal('Warning : ' + error.message);
            });
        }
    }

    const handleHapusCBS = () => {
        setBtnLoadingHapusCBS(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/hapus_anggaran_belanja_stok',
            data: {
                id: dataEditCBS.id,
                date_ym: sliceIsoDateToDateYm(dataEditCBS.tanggal),
                id_user: deko(reduxLogin.syswebappi),
                kategori: filterKategori.id
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusCBS(false);
            setModalHapusCBS(false);
            modalEditCBS && setModalEditCBS(false);

            if (response.data.flag_status === true) {
                setDataAnggaran(response.data.data_anggaran && response.data.data_anggaran['k1']);
                setDataEditCBS({
                    id: '',
                    nama: '',
                    kategori: '',
                    keterangan: '',
                    jumlah: '',
                    tanggal: new Date()
                });
            }

            response.data.flag_status === false && handleWarningModal('Error : ' + response.data.error);

            response.data.flag_status === 'not-found' && handleWarningModal('Data tidak ditemukan. Data mungkin sudah dihapus.');
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusCBS(false);
            setModalHapusCBS(false);
            modalEditCBS && setModalEditCBS(false);
            handleWarningModal('Warning : ' + error.message);
        });
    }

    const getDataAnggaranByYm = (prmYm, prmKategori) => {
        setLoadingInit(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/get_data_anggaran_belanja_stok',
            data: {
                date_ym: prmYm,
                id_user: deko(reduxLogin.syswebappi),
                kategori: prmKategori
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);

            setDataAnggaran(response.data.data_anggaran ? response.data.data_anggaran['k1'] : null);

        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }

    const handleYmPlusOne = () => {
        const newYm = moment(constYm, 'YYYY-MM').add(1, 'months').format('YYYY-MM');

        setConstYm(newYm);
        getDataAnggaranByYm(newYm, filterKategori.id);
    }

    const handleYmMinusOne = () => {
        const newYm = moment(constYm, 'YYYY-MM').subtract(1, 'months').format('YYYY-MM');

        setConstYm(newYm);
        getDataAnggaranByYm(newYm, filterKategori.id);
    }

    const handleGoPengeluaranKategori = () => {
        history.push('/' + prefixUrl.owner + '/pengeluarankategori');
    }

    const handleFilterKategori = () => {
        let el = document.querySelector("#filter_kategori");

        const nm_f_k = el.options[el.selectedIndex].getAttribute('nama_kategori');
        const id_f_k = el.options[el.selectedIndex].getAttribute('value');

        if (id_f_k === 'all') {
            setFilterKategori({ ...filterKategori, prm: false });
        } else {
            setFilterKategori({ ...filterKategori, nama: nm_f_k, prm: true });
        }

        getDataAnggaranByYm(sqlDateToSqlYm(isoDateToSqlDate(filterKategori.bulan)), id_f_k);
        setModalFilter(false);
        setConstYm(sqlDateToSqlYm(isoDateToSqlDate(filterKategori.bulan)));
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop
                shadow={true}
                titleNavTop="Pengeluaran"
            />

            <Layout titleTab="Pengeluaran">

                <Row>
                    <Col xs={12} className="mt-4 mb-5">

                        <div className="flexJustifyAlignCenter mb-4">
                            <div className="color808080 f12 cp">
                                <span
                                    className="fa fa-chevron-left px-2"
                                    onClick={handleYmMinusOne}
                                />
                            </div>

                            <div
                                className="px-3 fontweight600 text-center"
                                style={{
                                    width: '150px'
                                }}
                            >
                                {constYm ? sqlYmDateToTahunBulan(constYm) : ''}
                            </div>

                            <div className="color808080 f12 cp">
                                <span
                                    className="fa fa-chevron-right px-2"
                                    onClick={handleYmPlusOne}
                                />
                            </div>
                        </div>

                        {
                            filterKategori.prm &&
                            <div className="colorAaa f12 mb-3">
                                Filter berdasarkan kategori "{filterKategori.nama}"
                            </div>
                        }

                        {
                            !loadingInit && dataAnggaran &&
                            Object.keys(dataAnggaran).map((key) => {
                                return (
                                    <div
                                        key={key + 'da_84359'}
                                        className={`bgWhite ${reduxWindowWidth > batasFluid ? 'p-3' : 'py-2 px-3'} borderRadiusCard shadow-sm brSoft mb-3`}
                                    >

                                        <div className="flexJustifyBeetwenAlignCenter pt-2 pb-3 borderBottomEee">
                                            <div>{dataAnggaran[key].perbulan ? sqlYmDateToTahunBulan(dataAnggaran[key].perbulan) : ''}</div>
                                            <div>{formatNumberCanZero(dataAnggaran[key].sum_jumlah)}</div>
                                        </div>

                                        {dataAnggaran[key]['b'] &&
                                            Object.keys(dataAnggaran[key]['b']).map((subKey) => {
                                                return (
                                                    <div
                                                        key={subKey + 'sb_da_987j'}
                                                        className="flexAlignTop py-3 borderBottomEeeChild color636363 cp"
                                                        onDoubleClick={() => {
                                                            setDataEditCBS({
                                                                id: dataAnggaran[key]['b'][subKey].id_anak,
                                                                nama: dataAnggaran[key]['b'][subKey].nama,
                                                                kategori: dataAnggaran[key]['b'][subKey].id_pengeluaran_kategori,
                                                                jumlah: formatNumber(dataAnggaran[key]['b'][subKey].jumlah),
                                                                keterangan: dataAnggaran[key]['b'][subKey].keterangan ? dataAnggaran[key]['b'][subKey].keterangan : '',
                                                                tanggal: new Date(dataAnggaran[key]['b'][subKey].tanggal)
                                                            });

                                                            setModalEditCBS(true);
                                                        }}
                                                    >
                                                        <div style={{ width: '30px' }} className="">
                                                            <div
                                                                className="flexJustifyAlignCenter bgTheme colorWhite f10"
                                                                style={{
                                                                    width: '23px',
                                                                    height: '23px',
                                                                    borderRadius: '50%'
                                                                }}
                                                            >
                                                                {dataAnggaran[key]['b'][subKey].tanggal.substr(-2)}
                                                            </div>
                                                        </div>

                                                        <div style={{ flex: 1 }} className="mx-1">
                                                            <div>
                                                                {dataAnggaran[key]['b'][subKey].nama}
                                                            </div>

                                                            {dataAnggaran[key]['b'][subKey].keterangan &&
                                                                <div className="mt-1 color808080 whiteSpacePreLine">
                                                                    {dataAnggaran[key]['b'][subKey].keterangan}
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="text-right" style={{ flex: 0.45 }}>
                                                            {formatNumberCanZero(dataAnggaran[key]['b'][subKey].jumlah)}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }

                        {
                            !loadingInit && dataAnggaran &&
                            <div className="colorAaa f12 text-center">Double click to edit or delete data</div>
                        }

                        {
                            loadingInit &&
                            <div className="flexRowJustifyAlignCenter color808080 mt-5">
                                Loading..
                            </div>
                        }

                        {
                            !loadingInit && !dataAnggaran &&
                            <div className="flexRowJustifyAlignCenter mt-5">
                                <LottiePausAstronot />
                            </div>
                        }
                    </Col>
                </Row>
            </Layout>

            <div style={{ position: 'fixed', bottom: '20px', right: '25px' }}>
                <div
                    className="flexJustifyAlignCenter colorWhite bgSoftRed cp"
                    style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                    onClick={() => setModalTambahCBS(true)}
                >
                    <span className="fa fa-plus f16" />
                </div>
            </div>

            <div style={{ position: 'fixed', bottom: '20px', left: '25px' }}>
                <div
                    className="flexJustifyAlignCenter colorWhite bg808080 cp"
                    style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                    onClick={handleGoPengeluaranKategori}
                >
                    <span className="fa fa-server f16" />
                </div>
            </div>

            <div style={{ position: 'fixed', bottom: '20px', left: '80px' }}>
                <div
                    className="flexJustifyAlignCenter colorWhite bgTheme cp"
                    style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                    onClick={() => setModalFilter(true)}
                >
                    <span className="fa fa-filter f16" />
                </div>
            </div>

            <Modal
                show={modalTambahCBS}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f16 color636363 fontweight600 mb-4">Tambah Pengeluaran</div>

                    <div>
                        <Form.Group className="mb-3">
                            <Form.Control
                                readOnly
                                className="bgInputImportant"
                                size="sm"
                                type="text"
                                placeholder="Tanggal"
                                value={sqlDateToLongHumanDate(isoDateToSqlDate(dataTambahCBS.tanggal))}
                                onChange={(e) => setDataTambahCBS({ ...dataTambahCBS, tanggal: e.target.value })}
                                onClick={() => {
                                    setModalTambahCBS(false);
                                    setShowModalCalendar(true);
                                }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                custom
                                ref={refKategoriCBS}
                                name="kategori"
                                as="select"
                                size="sm"
                                value={dataTambahCBS.kategori}
                                onChange={(e) => setDataTambahCBS({ ...dataTambahCBS, kategori: e.target.value })}
                            >
                                <option value="">Pilih Kategori</option>
                                {
                                    dataKategori &&
                                    Object.keys(dataKategori).map((key) => {
                                        return (
                                            <option
                                                key={'kp_879' + key}
                                                value={dataKategori[key].id}
                                            >
                                                {dataKategori[key].nama}
                                            </option>
                                        )
                                    })
                                }
                            </Form.Control>

                            {!dataKategori &&
                                <div className="mt-1 color808080 f13">
                                    <span className="fa fa-question-circle mr-2" />
                                    Pastikan Kategori Pengeluaran sudah dibuat.
                                </div>
                            }
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control
                                ref={refNamaCBS}
                                size="sm"
                                type="text"
                                placeholder="Item Pengeluaran"
                                value={dataTambahCBS.nama}
                                onChange={(e) => setDataTambahCBS({ ...dataTambahCBS, nama: e.target.value })}
                            />
                        </Form.Group>

                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Rp</InputGroup.Text>
                            </InputGroup.Prepend>

                            <Form.Control
                                ref={refJumlahCBS}
                                size="sm"
                                type="text"
                                placeholder="Biaya"
                                value={dataTambahCBS.jumlah}
                                onChange={(e) => setDataTambahCBS({ ...dataTambahCBS, jumlah: formatNumber(e.target.value) })}
                            />
                        </InputGroup>

                        <Form.Group className="mb-3">
                            <Form.Control
                                size="sm"
                                as="textarea"
                                rows={3}
                                placeholder="Keterangan (opsional)"
                                value={dataTambahCBS.keterangan}
                                onChange={(e) => setDataTambahCBS({ ...dataTambahCBS, keterangan: e.target.value })}
                            />
                        </Form.Group>
                    </div>

                    <div className="mt-4 flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="px-3"
                            onClick={() => setModalTambahCBS(false)}
                        >
                            Close
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadingTambahCBS}
                            btnClass="ml-3"
                            btnVariant="info"
                            btnOnClick={handleSimpanCBS}
                            caption="Simpan"
                        />
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={showModalCalendar}
                onHide={() => {
                    setShowModalCalendar(false);
                    setModalTambahCBS(true);
                }}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tanggal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flexJustifyAlignCenter pt-1 pb-3">
                        <Calendar
                            defaultView="month"
                            onChange={(value) => {
                                setShowModalCalendar(false);
                                setModalTambahCBS(true);
                                setDataTambahCBS({ ...dataTambahCBS, tanggal: value });
                            }}
                            value={new Date(dataTambahCBS.tanggal)}
                        />
                    </div>
                </Modal.Body>
            </Modal>



            {/* Batas */}

            <Modal
                show={modalEditCBS}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="flexJustifyBeetwenAlignCenter f16 mb-4">
                        <div className="color636363 fontweight600">Edit Pengeluaran</div>
                        <div
                            className="colorWhite f12 flexJustifyAlignCenter bgTheme mr-1 cp"
                            onClick={() => {
                                setModalEditCBS(false);
                                setModalHapusCBS(true);
                            }}
                            style={{
                                width: '23px',
                                height: '23px',
                                borderRadius: '50%',
                            }}
                        >
                            <span className="fa fa-trash" />
                        </div>
                    </div>

                    <div>
                        <Form.Group className="mb-3">
                            <Form.Control
                                readOnly
                                className="bgInputImportant"
                                size="sm"
                                type="text"
                                placeholder="Tanggal"
                                value={sqlDateToLongHumanDate(isoDateToSqlDate(dataEditCBS.tanggal))}
                                onChange={(e) => setDataEditCBS({ ...dataEditCBS, tanggal: e.target.value })}
                                onClick={() => {
                                    setModalEditCBS(false);
                                    setShowModalCalendarEdit(true);
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control
                                custom
                                ref={refKategoriCBSEdit}
                                name="kategori"
                                as="select"
                                size="sm"
                                value={dataEditCBS.kategori}
                                onChange={(e) => setDataEditCBS({ ...dataEditCBS, kategori: e.target.value })}
                            >
                                <option value="">Pilih Kategori</option>
                                {
                                    dataKategori &&
                                    Object.keys(dataKategori).map((key) => {
                                        return (
                                            <option
                                                key={'kp_879' + key}
                                                value={dataKategori[key].id}
                                            >
                                                {dataKategori[key].nama}
                                            </option>
                                        )
                                    })
                                }
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control
                                ref={refNamaCBSEdit}
                                size="sm"
                                type="text"
                                placeholder="Item Pengeluaran"
                                value={dataEditCBS.nama}
                                onChange={(e) => setDataEditCBS({ ...dataEditCBS, nama: e.target.value })}
                            />
                        </Form.Group>

                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Rp</InputGroup.Text>
                            </InputGroup.Prepend>

                            <Form.Control
                                ref={refJumlahCBSEdit}
                                size="sm"
                                type="text"
                                placeholder="Biaya"
                                value={dataEditCBS.jumlah}
                                onChange={(e) => setDataEditCBS({ ...dataEditCBS, jumlah: formatNumber(e.target.value) })}
                            />
                        </InputGroup>

                        <Form.Group className="mb-3">
                            <Form.Control
                                size="sm"
                                as="textarea"
                                rows={3}
                                placeholder="Keterangan (opsional)"
                                value={dataEditCBS.keterangan}
                                onChange={(e) => setDataEditCBS({ ...dataEditCBS, keterangan: e.target.value })}
                            />
                        </Form.Group>
                    </div>

                    <div className="mt-4 flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="px-3"
                            onClick={() => setModalEditCBS(false)}
                        >
                            Close
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadingEditCBS}
                            btnClass="ml-3"
                            btnVariant="info"
                            btnOnClick={handleUpdateCBS}
                            caption="Update"
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={showModalCalendarEdit}
                onHide={() => {
                    setShowModalCalendarEdit(false);
                    setModalEditCBS(true);
                }}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tanggal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flexJustifyAlignCenter pt-1 pb-3">
                        <Calendar
                            defaultView="month"
                            onChange={(value) => {
                                setShowModalCalendarEdit(false);
                                setModalEditCBS(true);
                                setDataEditCBS({ ...dataEditCBS, tanggal: value });
                            }}
                            value={new Date(dataEditCBS.tanggal)}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <ModalConfirmation
                showModal={modalHapusCBS}
                hideModal={() => {
                    setModalHapusCBS(false);
                    setModalEditCBS(true);
                }}
                title="Konfirmasi"
                bodyCaption={`Hapus Catatan '${dataEditCBS.nama}' ?`}
                btnCloseClass="px-4"
                btnCloseCaption="Close"
                bLPrm={btnLoadingHapusCBS}
                bLBtnClass="ml-3"
                bLOnClick={handleHapusCBS}
                bLCaption="Ya, Hapus"
            />


            <Modal
                show={modalFilter}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f16 color636363 fontweight600 mb-4">Filter</div>

                    <div>
                        <Form.Control
                            custom
                            as="select"
                            id="filter_kategori"
                            size="sm"
                            value={filterKategori.id}
                            onChange={(e) => setFilterKategori({ ...filterKategori, id: e.target.value })}
                        >
                            <option value="all" nama_kategori="">Semua Kategori</option>
                            {dataKategori &&
                                Object.keys(dataKategori).map((key) => {
                                    return (
                                        <option
                                            key={'kpf_88779' + key}
                                            value={dataKategori[key].id}
                                            nama_kategori={dataKategori[key].nama}
                                        >
                                            {dataKategori[key].nama}
                                        </option>
                                    )
                                })
                            }
                        </Form.Control>
                    </div>

                    <div className="mt-3">
                        <Form.Control
                            readOnly
                            className="bgInputImportant"
                            size="sm"
                            type="text"
                            placeholder="Bulan"
                            value={sqlDateToYmDate(isoDateToSqlDate(filterKategori.bulan))}
                            onChange={(e) => setFilterKategori({ ...filterKategori, bulan: e.target.value })}
                            onClick={() => {
                                setModalFilter(false);
                                setModalFilterBulan(true);
                            }}
                        />
                    </div>

                    <div className="mt-5 flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="px-3 mr-3"
                            onClick={() => setModalFilter(false)}
                        >
                            Close
                        </Button>

                        <Button
                            variant="info"
                            size="sm"
                            className="px-4"
                            onClick={handleFilterKategori}
                        >
                            Filter
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={modalFilterBulan}
                onHide={() => {
                    setModalFilterBulan(false);
                    setModalFilter(true);
                }}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Bulan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flexJustifyAlignCenter pt-1 pb-3">
                        <Calendar
                            view="year"
                            onClickMonth={(value) => {
                                setModalFilterBulan(false);
                                setModalFilter(true);
                                setFilterKategori({ ...filterKategori, bulan: value })
                            }}
                            value={filterKategori.bulan}
                        />
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment >
    );
}

export default AnggaranBelanjaBarang;
