import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

/*
info : Contoh penggunaan ButtonLoading
const [prmBtnLoading, setPrmBtnLoading] = React.useState(false);

<ButtonLoading
    prmLoading={prmBtnLoading}
    btnClass="btnXsOverride"
    btnVariant="info"
    btnOnClick={handleSubmit}
    caption="Submit"
/>
*/

const ButtonLoading = ({ prmLoading, btnClass, btnVariant, btnOnClick, caption }) => {

    return (
        <Button
            className={btnClass}
            variant={btnVariant}
            type="button"
            size="sm"
            disabled={prmLoading}
            onClick={btnOnClick}
        >
            <div className="flexJustifyAlignCenter">
                <div className="flex flexNoWrap">
                    <div>
                        {prmLoading &&
                            <Spinner
                                as="span"
                                className="mr-2"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                    </div>

                    <div>
                        <span>{caption}</span>
                    </div>

                </div>
            </div>
        </Button>
    );
}

export default ButtonLoading;
