import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import axios from "axios";
import { Row, Col, InputGroup, FormControl, Spinner, Card } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import 'font-awesome/css/font-awesome.min.css';

import Layout from "../components/Layout";
import NavbarTopOwner from "../components/NavbarTop";
import NavbarTopTeknisi from "../components/teknisi/NavbarTop";
import CustomerAvatar from "../components/CustomerAvatar";
import { widthLG, widthMD, widthSM, widthXS, batasFluid, BASE_URL_DUA, levelUser } from "../utils/globalVar";
import { deko } from "../utils/globalFunc";
import ButtonLoading from "../components/ButtonLoading";
import ModalAlertWarning from "../components/ModalAlertWarning";
import LottiePausAstronot from "../components/LottiePausAstronot";
import BarcodeScanner from '../components/BarcodeScanner';

const PreCetakDokumen = () => {

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    const history = useHistory();

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const limitData = 10;
    const refOffsetData = React.useRef(0);
    const [textInputSearch, setTextInputSearch] = React.useState('');
    const refDokumen = React.useRef('');
    const [hasMore, setHasMore] = React.useState(true);
    const [loadingSearch, setLoadingSearch] = React.useState(false);
    const [btnLoadingMore, setBtnLoadingMore] = React.useState(false);
    const [tidakAdaHasil, setTidakAdaHasil] = React.useState(false);

    const [hasilPencarian, setHasilPencarian] = React.useState(null);
    const [loadingDokumen, setLoadingDokumen] = React.useState(false);

    const [modalBarcodeScanner, setModalBarcodeScanner] = React.useState(false);
    const handleScanBarcode = (err, result) => {
        if (result) {
            setTextInputSearch(result.text);
            setModalBarcodeScanner(false);
        }
    }

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [])

    const moreData = () => {
        setBtnLoadingMore(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/cari_dokumen',
            data: {
                inputan: textInputSearch,
                limit_data: limitData,
                offset_data: refOffsetData.current,
                id_user: deko(reduxLogin.syswebappi),
                dokumen: refDokumen.current.value
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingMore(false);

            hasilPencarian && response.data.data_label && setHasilPencarian([...hasilPencarian, ...response.data.data_label]);

            hasilPencarian && response.data.data_label && [...hasilPencarian, ...response.data.data_label].length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

            if (response.data.data_label) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);
            handleWarningModal(error.message)
        })
    }

    const getData = React.useCallback((dt) => {
        axios({
            method: 'post',
            url: BASE_URL_DUA + '/cari_dokumen',
            data: dt
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingSearch(false);
            setLoadingDokumen(false);

            if (response.data.data_label) {
                setHasilPencarian(response.data.data_label);
                setTidakAdaHasil(false);
            } else {
                setHasilPencarian(null);
                setTidakAdaHasil(true);
            }

            if (response.data.data_label) { refOffsetData.current = refOffsetData.current + limitData };

            response.data.data_label && response.data.data_label.length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);
        }).catch(error => {
            if (!mountedRef.current) return null;
            setLoadingSearch(false);
            setLoadingDokumen(false);
            setTidakAdaHasil(false);
            handleWarningModal(error.message)
        })
    }, []);

    React.useEffect(() => {
        refOffsetData.current = 0;
        let handler = null;

        if (textInputSearch && refDokumen.current.value) {
            textInputSearch && setLoadingSearch(true);

            handler = setTimeout(() => {
                const dt = {
                    inputan: textInputSearch,
                    limit_data: limitData,
                    offset_data: refOffsetData.current,
                    id_user: deko(reduxLogin.syswebappi),
                    dokumen: refDokumen.current.value
                }

                getData(dt)
            }, 1000);
        } else {
            setLoadingSearch(false);
            setHasilPencarian(null);
            setTidakAdaHasil(false);
            setLoadingDokumen(false);
        }

        return () => clearTimeout(handler);
    }, [textInputSearch, getData, reduxLogin.syswebappi]);

    const handleGoCetakLabel = (idTransaksi) => {
        if (refDokumen.current.value === 'label') {
            history.push('/cetaklabel/' + idTransaksi);
        } else if (refDokumen.current.value === 'tanda_terima') {
            history.push('/tandaterima/' + idTransaksi);
        } else if (refDokumen.current.value === 'bukti_bayar') {
            history.push('/buktibayar/' + idTransaksi);
        }
    }

    const onChangeDokumen = (prmDokumen) => {
        if (textInputSearch && prmDokumen) {
            refOffsetData.current = 0;

            const dt = {
                inputan: textInputSearch,
                limit_data: limitData,
                offset_data: refOffsetData.current,
                id_user: deko(reduxLogin.syswebappi),
                dokumen: prmDokumen
            }

            setLoadingDokumen(true);

            getData(dt);
        } else {
            setLoadingSearch(false);
            setHasilPencarian(null);
            setTidakAdaHasil(false);
            setLoadingDokumen(false);
        }
    }

    return (
        <React.Fragment>

            <BarcodeScanner
                modalBarcodeScanner={modalBarcodeScanner}
                onSken={(satu, dua) => handleScanBarcode(satu, dua)}
                closeModalScanBarcode={() => setModalBarcodeScanner(false)}
            />

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            {deko(reduxLogin.syswebappj) === levelUser.owner && <NavbarTopOwner shadow={true} titleNavTop="Cetak Dokumen" />}

            {deko(reduxLogin.syswebappj) === levelUser.teknisi && <NavbarTopTeknisi shadow={true} titleNavTop="Cetak Dokumen" />}

            <Layout titleTab="Cetak Dokumen">
                <Row>
                    <Col xs={12} md={6} className="mt-3">
                        <div className="color636363 mb-2">
                            Dokumen
                        </div>

                        <FormControl
                            custom
                            name="gender"
                            as="select"
                            size="sm"
                            ref={refDokumen}
                            onChange={(e) => onChangeDokumen(e.target.value)}
                        >
                            <option value="">Pilih Dokumen</option>
                            <option value="label">Label</option>
                            <option value="tanda_terima">Tanda Terima</option>
                            <option value="bukti_bayar">Bukti Bayar</option>
                        </FormControl>
                    </Col>

                    <Col xs={12} md={6} className="mt-3">
                        <div className="color636363 mb-2">
                            Transaksi
                        </div>

                        <InputGroup className="mb-3">
                            <FormControl
                                size="sm"
                                type="text"
                                placeholder="Ketik No Nota / Customer / Nama Barang"
                                value={textInputSearch}
                                onChange={(e) => setTextInputSearch(e.target.value)}

                                className="removeShadowOnFocus"
                                style={{ borderRight: '0 solid #eee' }}
                            />

                            <InputGroup.Append>
                                <InputGroup.Text id="ad-cbd-9890" className="bgColorForm">
                                    {loadingSearch &&
                                        <Spinner animation="border" size="sm" variant="info" />
                                    }

                                    <span className="fa fa-barcode ml-2" onClick={() => setModalBarcodeScanner(true)} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>

                    <Col xs={12} className="mt-3 mb-3">
                        {hasilPencarian && !loadingDokumen && !loadingSearch &&
                            <div>
                                <div>
                                    <Masonry
                                        breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 1 : 2) : 2) : 3) : 3}`}
                                        className="my-masonry-grid-12"
                                        columnClassName="my-masonry-grid_column-12"
                                    >
                                        {Object.keys(hasilPencarian).map((key) => {
                                            return (
                                                <Card
                                                    key={'HasilPencarian' + key}

                                                    className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} ${Object.keys(hasilPencarian).length === (parseInt(key) + 1) ? 'mb-0' : 'mb-3'} shadow-sm brSoftMedium cp`}

                                                    onClick={() => handleGoCetakLabel(hasilPencarian[key].id_transaksi)}
                                                >
                                                    <div>
                                                        <div className="flex">
                                                            <div className="mr-3">
                                                                <CustomerAvatar
                                                                    prmSlug={hasilPencarian[key].slug_ck}
                                                                    prmGender={hasilPencarian[key].gender}
                                                                    ukuran={{ width: '40px', height: '40px' }}
                                                                />
                                                            </div>

                                                            <div>
                                                                <div className="color636363 bold">
                                                                    {hasilPencarian[key].nama}
                                                                </div>

                                                                <div className="color808080 f13">
                                                                    {hasilPencarian[key].nama_cust_ktgr}
                                                                </div>

                                                                <div className="color808080 f13 mt-2">
                                                                    {hasilPencarian[key].alamat}
                                                                </div>

                                                                {deko(reduxLogin.syswebappj) === levelUser.owner &&
                                                                    <div className="color808080 f13">
                                                                        {hasilPencarian[key].no_hp}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="wordBreakAll" style={{ width: '100%' }}>
                                                            <div><hr className="mt-2 mb-2" /></div>

                                                            <div className="color808080 f13">
                                                                <span className="fontweight600 mr-2">No Nota :</span>
                                                                {hasilPencarian[key].id_transaksi}
                                                            </div>

                                                            <div className="color808080 f13">
                                                                <span className="fontweight600 mr-2">Barang :</span>
                                                                {hasilPencarian[key].nama_barang}
                                                            </div>

                                                            <div className="color808080 f13">
                                                                <span className="fontweight600 mr-2">Keluhan :</span>
                                                                {hasilPencarian[key].kerusakan}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            )
                                        })}
                                    </Masonry>
                                </div>

                                <div className="text-center mt-3">
                                    {hasMore ?
                                        <div className="text-center">
                                            <ButtonLoading
                                                prmLoading={btnLoadingMore}
                                                btnClass="btnXsOverride"
                                                btnVariant="info"
                                                btnOnClick={moreData}
                                                caption="Load More"
                                            />
                                        </div>
                                        :
                                        <div className="text-center colorAaa">
                                            No More Data
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {
                            loadingDokumen &&
                            <div className="color808080 text-center">
                                Mengambil Data..
                            </div>
                        }

                        {
                            !hasilPencarian && tidakAdaHasil && !loadingDokumen &&
                            <div className="flexRowJustifyAlignCenter">
                                <LottiePausAstronot />
                            </div>
                        }
                    </Col >
                </Row>
            </Layout>

        </React.Fragment>
    );
}

export default PreCetakDokumen;
