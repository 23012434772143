import { WINDOW_WIDTH } from "./type";

const initData = window.innerWidth;

export const reducer = (state = initData, action) => {

    switch (action.type) {
        case WINDOW_WIDTH:
            return action.prm

        default: return state
    }

}