import { IS_LOGIN } from "./type";

const initData = null;

export const reducer = (state = initData, action) => {

    switch (action.type) {
        case IS_LOGIN:
            return action.prm

        default: return state
    }

}