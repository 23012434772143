import React from 'react';
import { Row, Col, Nav } from 'react-bootstrap';
import { useSelector } from "react-redux";

import { levelUser } from "../../utils/globalVar";
import { deko } from "../../utils/globalFunc";
import FormCustomerLama from '../../components/FormCustomerLama';
import FormCustomerBaru from '../../components/FormCustomerBaru';
import Layout from "../../components/Layout";
import NavbarTopOwner from "../../components/NavbarTop";
import NavbarTopTeknisi from "../../components/teknisi/NavbarTop";

const Transaksi = () => {

    const reduxLogin = useSelector(data => data.lumoslored);

    const [formCustLama, setFormCustLama] = React.useState(true);
    const [formCustBaru, setFormCustBaru] = React.useState(false);

    const handleFormCustomer = (prm) => {
        if (prm === 'customerLama') {
            setFormCustLama(true)
            setFormCustBaru(false)
        } else if (prm === 'customerBaru') {
            setFormCustLama(false)
            setFormCustBaru(true)
        }
    }

    return (
        <React.Fragment>

            {deko(reduxLogin.syswebappj) === levelUser.owner && <NavbarTopOwner shadow={false} titleNavTop="Transaksi" />}
            {deko(reduxLogin.syswebappj) === levelUser.teknisi && <NavbarTopTeknisi shadow={false} titleNavTop="Transaksi" />}

            <Layout titleTab="Transaksi">
                <Row>

                    <Col xs={12} className="px-3 mt-2 mb-2">

                        <Nav
                            defaultActiveKey="customerLama"
                            onSelect={(selectedKey) => handleFormCustomer(selectedKey)}
                            className="navPreTransaksi navRelContainer"
                            style={{ borderBottom: '1px solid #ddd' }}
                        >
                            <Nav.Item className="mr-4">
                                <Nav.Link eventKey="customerLama" className="mr-2">
                                    <div><span>Customer Lama</span></div>
                                    <div><hr className="hrCaption" /></div>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link eventKey="customerBaru">
                                    <div><span>Customer Baru</span></div>
                                    <div><hr className="hrCaption" /></div>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>

                    {formCustLama && <FormCustomerLama />}

                    {formCustBaru && <FormCustomerBaru />}
                </Row>

            </Layout>

        </React.Fragment>
    );
}

export default Transaksi;
