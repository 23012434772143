import React from 'react';
import axios from 'axios';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import '../styles/overRideBootstrap/overRideBootstrap.scss';
import { useSelector, useDispatch } from "react-redux";
import { windowWidth } from "../redux/reduxWindowWidth/action";
import { is_login } from "../redux/reduxLogin/action";

import OwnHome from "./owner/Home";
import OwnPreCustomer from "./owner/PreCustomer";
import OwnCustomer from "./owner/Customer";
import OwnProses from "./owner/Proses";
import OwnProsesQC from "./owner/ProsesQC";
import OwnProsesSelesai from "./owner/ProsesSelesai";
import OwnStokBarang from "./owner/StokBarang";
import OwnStokBarangTambah from "./owner/StokBarangTambah";
import OwnStokBarangKategoriTambah from "./owner/StokBarangKategoriTambah";
import OwnStokBarangKategoriEdit from "./owner/StokBarangKategoriEdit";
import OwnStokTambah from "./owner/StokTambah";
import OwnStokEdit from "./owner/StokEdit";
import OwnDiskon from "./owner/Diskon";
import OwnCariDiskon from "./owner/CariDiskon";
import OwnAnggaranBelanjaBarang from "./owner/AnggaranBelanjaBarang";
import OwnPenilaianKaryawan from "./owner/PenilaianKaryawan";
import OwnProjectCancel from "./owner/ProjectCancel";
import OwnProjectCancelDetil from "./owner/ProjectCancelDetil";
import OwnProsesSudahDiambil from "./owner/ProsesSudahDiambil";
import OwnKelolaAkunUser from "./owner/KelolaAkunUser";
import OwnPengeluaranKategori from "./owner/PengeluaranKategori";
import OwnPendapatan from "./owner/Pendapatan";
import OwnReport from "./owner/Report";

import Login from "./Login";
import EmpatKosongEmpat from "./EmpatKosongEmpat";
import EmpatKosongEmpatGeneral from "./EmpatKosongEmpatGeneral";
import Transaksi from "./owner/Transaksi";
import ConfirmDoProsesSatu from "./owner/ConfirmDoProsesSatu";
import ProsesPengerjaan from "./owner/ProsesPengerjaan";
import ListStokBarang from "./owner/ListStokBarang";
import PreTransaksi from "./owner/PreTransaksi";
import CetakLabel from "./owner/CetakLabel";
import KategoriBarang from "./owner/KategoriBarang";
import FormKelengkapan from "./owner/FormKelengkapan";
import KelolaSubKelengkapan from "./owner/KelolaSubKelengkapan";
import PreCetakDokumen from "./PreCetakDokumen";
import DokumenTandaTerima from "./DokumenTandaTerima";
import DokumenBuktiBayar from "./DokumenBuktiBayar";
import CekSerialNumber from "./CekSerialNumber";
import PencarianCepat from "./PencarianCepat";

import TknHome from "./teknisi/Home";
import TknProses from "./teknisi/Proses";
import TknMenungguQC from "./teknisi/MenungguQC";
import TknAkun from "./teknisi/Akun";

import { prefixUrl, levelUser, otspc, BASE_URL } from '../utils/globalVar';
import { deko } from '../utils/globalFunc';

import '../styles/index.scss';

// import MediaQueryTest from "../components/MediaQueryTest";
import ModalAlertWarning from "../components/ModalAlertWarning";

const App = () => {

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const dispatch = useDispatch();
    const reduxLogin = useSelector(data => data.lumoslored);

    const mountedRef = React.useRef(true);

    // console.log('rdxLogin ', reduxLogin);

    React.useEffect(() => {
        if (reduxLogin && reduxLogin.syswebappi && reduxLogin.syswebappj && reduxLogin.otspc && (reduxLogin.otspc === otspc) && reduxLogin.syswebappn) {
            axios({
                method: 'post',
                url: BASE_URL + '/cek_user_id',
                data: {
                    id_user: deko(reduxLogin.syswebappi)
                }
            }).then((response) => {
                if (!response.data.res) {
                    dispatch(is_login(null));
                }

                if (!mountedRef.current) return null;
            }).catch(error => {
                handleWarningModal(error.message)
            })
        } else {
            dispatch(is_login(null));
        }

        return () => mountedRef.current = false;
    }, [reduxLogin, dispatch]);

    const GeneralRoute = ({ children, ...rest }) => {
        return (
            <Route {...rest}>
                {(reduxLogin && reduxLogin.syswebappj && reduxLogin.syswebappi && reduxLogin.syswebappn && (reduxLogin.otspc === otspc)) ? children : <Redirect to="/login" />}
            </Route >
        )
    }

    const OwnerRoute = ({ children, ...rest }) => {
        return (
            <Route {...rest}>
                {(reduxLogin && reduxLogin.syswebappj && reduxLogin.syswebappi && reduxLogin.syswebappn && (reduxLogin.otspc === otspc) && (deko(reduxLogin.syswebappj) === levelUser.owner)) ? children : <Redirect to="/login" />}
            </Route >
        )
    }

    const TeknisiRoute = ({ children, ...rest }) => {
        return (
            <Route {...rest}>
                {(reduxLogin && reduxLogin.syswebappj && reduxLogin.syswebappi && reduxLogin.syswebappn && (reduxLogin.otspc === otspc) && (deko(reduxLogin.syswebappj) === levelUser.teknisi)) ? children : <Redirect to="/login" />}
            </Route >
        )
    }

    const OwnerTeknisiRoute = ({ children, ...rest }) => {
        return (
            <Route {...rest}>
                {
                    reduxLogin && reduxLogin.syswebappj && reduxLogin.syswebappi && reduxLogin.syswebappn && (reduxLogin.otspc === otspc) && (deko(reduxLogin.syswebappj) === levelUser.owner || deko(reduxLogin.syswebappj) === levelUser.teknisi) ? children : <Redirect to="/login" />
                }
            </Route >
        )
    }

    React.useEffect(() => {
        let timeoutId = null;

        const resizeListener = () => {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                dispatch(windowWidth(window.innerWidth));
            }, 150);
        }

        window.addEventListener('resize', resizeListener);
        return () => window.removeEventListener('resize', resizeListener);
    });

    return (
        <React.Fragment>
            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            {/* <MediaQueryTest /> */}

            <BrowserRouter>

                <Switch>

                    <GeneralRoute exact path="/">
                        {
                            reduxLogin && (deko(reduxLogin.syswebappj) === levelUser.owner) ? <OwnHome /> : reduxLogin && (deko(reduxLogin.syswebappj) === levelUser.teknisi) ? <TknHome /> : <Login />
                        }
                    </GeneralRoute>

                    <Route path="/login">
                        <Login />
                    </Route>

                    <OwnerRoute path={`/${prefixUrl.owner}/precustomer`}>
                        <OwnPreCustomer />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/customer/:id`}>
                        <OwnCustomer />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/proses/:nav?`}>
                        <OwnProses />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/qc/:id/:callbackUrl?`}>
                        <OwnProsesQC />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/selesai/:id/:callbackUrl?`}>
                        <OwnProsesSelesai />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/sudahdiambil/:id/:callbackUrl?`}>
                        <OwnProsesSudahDiambil />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/stokbarang`}>
                        <OwnStokBarang />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/tambahstokbarang/:id`}>
                        <OwnStokBarangTambah />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/tambahstokbarangkategori`}>
                        <OwnStokBarangKategoriTambah />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/editstokbarangkategori/:id`}>
                        <OwnStokBarangKategoriEdit />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/stoktambah/:id`}>
                        <OwnStokTambah />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/stokedit/:idSK/:idS`}>
                        <OwnStokEdit />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/diskon`}>
                        <OwnDiskon />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/caridiskon/:id`}>
                        <OwnCariDiskon />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/anggaranbelanjabarang`}>
                        <OwnAnggaranBelanjaBarang />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/penilaiankaryawan`}>
                        <OwnPenilaianKaryawan />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/projectcancel`}>
                        <OwnProjectCancel />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/projectcanceldetil/:id`}>
                        <OwnProjectCancelDetil />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/kelolaakunuser`}>
                        <OwnKelolaAkunUser />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/pengeluarankategori`}>
                        <OwnPengeluaranKategori />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/pendapatan`}>
                        <OwnPendapatan />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/cekserialnumber`}>
                        <CekSerialNumber />
                    </OwnerRoute>

                    <OwnerRoute path={`/${prefixUrl.owner}/report`}>
                        <OwnReport />
                    </OwnerRoute>

                    {/* batas */}

                    <OwnerTeknisiRoute path={`/formkelengkapan`}>
                        <FormKelengkapan />
                    </OwnerTeknisiRoute>

                    <OwnerTeknisiRoute path={`/kelolaformkelengkapan/:id`}>
                        <KelolaSubKelengkapan />
                    </OwnerTeknisiRoute>

                    <OwnerTeknisiRoute path={`/precetakdokumen`}>
                        <PreCetakDokumen />
                    </OwnerTeknisiRoute>

                    <OwnerTeknisiRoute path={`/cetaklabel/:id`}>
                        <CetakLabel />
                    </OwnerTeknisiRoute>

                    <OwnerTeknisiRoute path={`/pretransaksi`}>
                        <PreTransaksi />
                    </OwnerTeknisiRoute>

                    <OwnerTeknisiRoute path={`/transaksi/:id/:callbackUrl?`}>
                        <Transaksi />
                    </OwnerTeknisiRoute>

                    <OwnerTeknisiRoute path={`/confirmdoproses/:id/:callbackUrl?`}>
                        <ConfirmDoProsesSatu />
                    </OwnerTeknisiRoute>

                    <OwnerTeknisiRoute path={`/tandaterima/:id`}>
                        <DokumenTandaTerima />
                    </OwnerTeknisiRoute>

                    <OwnerTeknisiRoute path={`/buktibayar/:id`}>
                        <DokumenBuktiBayar />
                    </OwnerTeknisiRoute>

                    <OwnerTeknisiRoute path={`/working/:id/:callbackUrl?`}>
                        <ProsesPengerjaan />
                    </OwnerTeknisiRoute>

                    <OwnerTeknisiRoute path={`/liststokbarang/:idTransaksi/:idTDP`}>
                        <ListStokBarang />
                    </OwnerTeknisiRoute>

                    <OwnerTeknisiRoute path={`/kategoribarang`}>
                        <KategoriBarang />
                    </OwnerTeknisiRoute>

                    <OwnerTeknisiRoute path={`/pencariancepat`}>
                        <PencarianCepat />
                    </OwnerTeknisiRoute>

                    <GeneralRoute path="/notfound">
                        <EmpatKosongEmpat />
                    </GeneralRoute>

                    {/* Star Teknisi Routes */}

                    <TeknisiRoute path={`/${prefixUrl.teknisi}/proses/:nav?`}>
                        <TknProses />
                    </TeknisiRoute>

                    <TeknisiRoute path={`/${prefixUrl.teknisi}/menungguqc/:id/:callbackUrl?`}>
                        <TknMenungguQC />
                    </TeknisiRoute>

                    <TeknisiRoute path={`/${prefixUrl.teknisi}/akunk`}>
                        <TknAkun />
                    </TeknisiRoute>

                    {/* End Teknisi Routes */}

                    <Route path="/notfoundpage">
                        <EmpatKosongEmpatGeneral />
                    </Route>

                    <Route path="/*">
                        {
                            reduxLogin && reduxLogin.syswebappj && reduxLogin.syswebappi ?
                                <EmpatKosongEmpat />
                                :
                                <EmpatKosongEmpatGeneral />
                        }
                    </Route>

                </Switch>

            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;
