import React from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import 'font-awesome/css/font-awesome.min.css';
import 'react-html5-camera-photo/build/css/index.css';
import { Row, Col, Card, Image, Form, Modal, Spinner, Button } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import CustomerAvatar from "../../components/CustomerAvatar";
import soldering from "../../assets/images/system/soldering.png";
import { ROOT_BASE_URL, BASE_URL, batasFluid, prefixUrl, metodeBayar, widthLG, widthMD, widthSM, widthXS, levelUser } from "../../utils/globalVar";
import { sqlDateToLongHumanDate, dateTimeToHumanDateTime, formatNumberCanZero, handleCallBack, deko } from "../../utils/globalFunc";
import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ButtonLoading from "../../components/ButtonLoading";
import PlaceHolderElement1 from "../../components/PlaceHolderElement1";

const ProsesQC = () => {

    const history = useHistory();

    const parameter = useParams();
    const idTransaksi = parameter.id;
    const callbackUrl = parameter.callbackUrl;

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [srcFoto, setSrcFoto] = React.useState('');
    const [modalZoomImage, setModalZoomImage] = React.useState(false);

    const [loadingDataProses, setLoadingDataProses] = React.useState(false);
    const [dataPenggunaanStok, setDataPenggunaanStok] = React.useState({});
    const [dataProses, setDataProses] = React.useState(null);
    const [dataLabel, setDataLabel] = React.useState(null);
    const [dataKelengkapan, setDataKelengkapan] = React.useState(null);
    const [listFotoTransaksi, setListFotoTransaksi] = React.useState(null);
    const [dataProgres, setDataProgres] = React.useState(null);
    const [btnLoadUpdateKetKelQC, setBtnLoadUpdateKetKelQC] = React.useState(false);
    const [penerima, setPenerima] = React.useState('');

    const [modalTolakQC, setModalTolakQC] = React.useState(false);
    const [btnLoadingTolakQC, setBtnLoadingTolakQC] = React.useState(false);
    const refKetTolakQC = React.useRef('');

    const [modalApproveQC, setModalApproveQC] = React.useState(false);
    const [btnLoadingApproveQC, setBtnLoadingApproveQC] = React.useState(false);
    const refKetApproveQC = React.useRef('');

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [])


    React.useEffect(() => {
        setLoadingDataProses(true)

        axios({
            method: 'post',
            url: BASE_URL + '/proses_qc',
            data: {
                id_transaksi: idTransaksi
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            if (response.data && response.data.data_proses !== null) {
                setDataPenggunaanStok(response.data.data_penggunaan_stok);
                setDataProses(response.data.data_proses);
                setDataKelengkapan(response.data.data_kelengkapan);
                setListFotoTransaksi(response.data.data_foto_transaksi);
                response.data.data_progres && setDataProgres(response.data.data_progres['k1']);
                setDataLabel(response.data.data_label);
                setPenerima(response.data.penerima);
            } else {
                setDataProses(null);
            }

            setLoadingDataProses(false)
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingDataProses(false)
            handleWarningModal(error.message)
        })
    }, [idTransaksi]);

    const handleOnChangeCbKelengkapan = (e, prm_id_tdk, key) => {

        if (document.querySelector(".loadCb" + key)) {
            document.querySelector(".loadCb" + key).classList.remove("d-none");
        }

        axios({
            method: 'post',
            url: BASE_URL + '/update_each_cb_kelengkapan',
            data: {
                id_tdk: prm_id_tdk,
                prm_checked: e.target.checked,
                id_transaksi: idTransaksi,
                field: 'check_qc',
                flag_proses: 3,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            if (document.querySelector(".loadCb" + key)) {
                document.querySelector(".loadCb" + key).classList.add("d-none");
            }

            if (response.data.status_flag === true) {
                setDataKelengkapan(response.data.hasil);
            }

            if (response.data.status_flag === false) {
                handleWarningModal('Error : ' + response.data.hasil);
            }

            if (response.data.status_flag === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            if (document.querySelector(".loadCb" + key)) {
                document.querySelector(".loadCb" + key).classList.add("d-none");
            }
            handleWarningModal('Warning : ' + error.message)
        })
    }

    const toggleFormEditSubKlngkpnQC = (prm) => {
        let boxKlngkpn = document.querySelector("#boxKetKlngkpnQC_" + prm);
        boxKlngkpn.classList.toggle("d-none");

        let captShow = document.querySelector("#ketCapt_so7_pqc_" + prm);
        captShow.classList.toggle("d-none");

        let faBtn = document.querySelector("#yu_pqc_" + prm);
        faBtn.classList.toggle("fa-pencil");
        faBtn.classList.toggle("fa-close");

        document.querySelector("#fgtak0jh_pqc_" + prm).value = captShow.innerHTML;
        document.querySelector("#fgtak0jh_pqc_" + prm).focus();
    }

    const handleSimpanKetKlngkpnQC = (idTdk, key) => {
        setBtnLoadUpdateKetKelQC(true);
        let valKet = document.querySelector("#fgtak0jh_pqc_" + key).value;
        let faBtn = document.querySelector("#yu_pqc_" + key);

        axios({
            method: 'post',
            url: BASE_URL + '/update_keterangan_kelengkapan_by_id',
            data: {
                id_tdk: idTdk,
                value_ket: valKet,
                id_transaksi: idTransaksi,
                field: 'keterangan_qc',
                flag_proses: 3,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadUpdateKetKelQC(false);

            if (response.data.status_flag === true) {
                setDataKelengkapan(response.data.hasil);

                document.querySelector("#boxKetKlngkpnQC_" + key).classList.add('d-none');
                document.querySelector("#ketCapt_so7_pqc_" + key).classList.remove('d-none');

                faBtn.classList.remove("fa-pencil");
                faBtn.classList.remove("fa-close");
                faBtn.classList.add("fa-pencil");
            }

            if (response.data.status_flag === false) {
                handleWarningModal('Error : ' + response.data.hasil);
            }

            if (response.data.status_flag === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadUpdateKetKelQC(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }

    const handleTolakQC = () => {
        refKetTolakQC.current.style.border = 'unset';

        if (!refKetTolakQC.current.value) {
            refKetTolakQC.current.style.border = '1px solid red';
            refKetTolakQC.current.focus();
        } else {
            setBtnLoadingTolakQC(true);

            axios({
                method: 'post',
                url: BASE_URL + '/tolak_qc',
                data: {
                    ket_tolak_qc: refKetTolakQC.current.value,
                    id_user_login: deko(reduxLogin.syswebappi),
                    id_transaksi: idTransaksi
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingTolakQC(false);
                setModalTolakQC(false);

                if (response.data.flag_status === true) {
                    history.push('/' + prefixUrl.owner + '/proses/prosesTiga');
                }

                response.data.flag_status === false && handleWarningModal('Error Process : ' + response.data.pesan);

                if (response.data.flag_status === 'not-found') {
                    handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingTolakQC(false);
                setModalTolakQC(false);
                handleWarningModal('Warning : ' + error.message)
            })
        }
    }

    const handleApproveQC = () => {
        setBtnLoadingApproveQC(true);

        axios({
            method: 'post',
            url: BASE_URL + '/approve_qc',
            data: {
                ket_approve_qc: refKetApproveQC.current.value,
                id_user_login: deko(reduxLogin.syswebappi),
                id_transaksi: idTransaksi
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingApproveQC(false);
            setModalApproveQC(false);

            if (response.data.flag_status === true) {
                history.push('/' + prefixUrl.owner + '/proses/prosesTiga');
            }

            response.data.flag_status === false && handleWarningModal('Error Process : ' + response.data.pesan);

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingApproveQC(false);
            setModalApproveQC(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }

    const handleUangMuka = (prmUangMuka) => {
        if (!prmUangMuka || prmUangMuka === '0') {
            return '-';
        } else {
            return 'Rp ' + formatNumberCanZero(prmUangMuka);
        }
    }

    const handleMetodeUangMuka = (prmMetode) => {
        if (!prmMetode) {
            return '';
        } else {
            let hasil = '';

            Object.keys(metodeBayar).forEach(element => {
                if (element === prmMetode) {
                    hasil = '(Metode ' + metodeBayar[element] + ')';
                    return
                }
            });

            return hasil;
        }
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop
                shadow={true}
                titleNavTop="Proses Q.C."
                prmBackButton={true}
                prmOnBack={`${callbackUrl ? '/' + handleCallBack(deko(reduxLogin.syswebappj), levelUser, callbackUrl, prefixUrl) : '/' + prefixUrl.owner + '/proses/prosesTiga'}`}
            />

            <Layout titleTab="Proses Q.C.">
                <Row>
                    <Col xs={12} className="mt-3">
                        {dataProses && !loadingDataProses &&
                            <React.Fragment>
                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                                    <div className="flex">
                                        <div>
                                            <CustomerAvatar
                                                prmSlug={dataProses[0].slug_ck}
                                                prmGender={dataProses[0].gender}
                                                ukuran={{ width: '55px', height: '55px' }}
                                            />
                                        </div>

                                        <div className="ml-3 wordBreakAll" style={{ width: '100%' }}>
                                            <div className="color636363 bold">
                                                {dataProses[0].nama}
                                            </div>

                                            <div className="color808080 f14">
                                                {dataProses[0].nama_ktgr_cust}
                                            </div>

                                            <div className="color808080 f14 mt-2">
                                                {dataProses[0].alamat}
                                            </div>

                                            <div className="color808080 f14">
                                                {dataProses[0].no_hp}
                                            </div>
                                        </div>
                                    </div>
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">No Nota</div>
                                        <div className="color808080">{dataProses[0].id_transaksi}</div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">Tanggal Masuk</div>
                                        <div className="color808080">{dataProses[0].tanggal_masuk ? sqlDateToLongHumanDate(dataProses[0].tanggal_masuk) : ''}</div>
                                    </div>

                                    <div className="f14">
                                        <div className="color636363 fontweight600 mb-1">Penerima</div>
                                        <div className="color808080">{penerima}</div>
                                    </div>
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-4`}>
                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">
                                            Kategori Barang
                                        </div>
                                        <div className="color808080">{dataProses[0].nama_barang_kategori}</div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">Barang</div>
                                        <div className="color808080">{dataProses[0].nama_barang}</div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600">Keluhan</div>
                                        <div className="color808080">{dataProses[0].kerusakan}</div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600">Foto Barang</div>

                                        {listFotoTransaksi ? <div className="text-center mt-2">
                                            <Masonry
                                                breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 4 : 5) : 5) : 6) : 6}`}
                                                className="my-masonry-grid"
                                                columnClassName="my-masonry-grid_column"
                                            >
                                                {Object.keys(listFotoTransaksi).map((key) => {
                                                    return (
                                                        <img
                                                            key={"lfkp87hjsdk" + key}
                                                            src={ROOT_BASE_URL + listFotoTransaksi[key].foto}
                                                            alt={'foto'}
                                                            style={{ width: '100%', borderRadius: '6px' }}
                                                            className="cp"
                                                            onClick={() => {
                                                                setSrcFoto(ROOT_BASE_URL + listFotoTransaksi[key].foto)
                                                                setModalZoomImage(true);
                                                            }}
                                                        />
                                                    )
                                                })}
                                            </Masonry>
                                        </div>
                                            :
                                            <div className="color808080">
                                                Tidak ada data foto
                                            </div>
                                        }
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600">Uang Muka</div>
                                        <div className="color808080">
                                            {handleUangMuka(dataProses[0].dp)}
                                            {' '}
                                            {handleMetodeUangMuka(dataProses[0].metode_dp)}
                                        </div>
                                    </div>

                                    <div className="f14">
                                        <div className="color636363 fontweight600">Catatan</div>
                                        <div className="color808080 whiteSpacePreLine">
                                            {dataProses[0].catatan ? dataProses[0].catatan : '-'}
                                        </div>
                                    </div>
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                >
                                    <div className="f14 mb-3 colorTheme fontweight600">
                                        Kelengkapan Barang
                                    </div>

                                    <div>
                                        <div className="color636363 fontweight600">
                                            Kelengkapan Utama :
                                        </div>
                                        <div className="color808080 mt-1">{dataProses[0].nama_barang}</div>
                                    </div>

                                    <div className="mt-3">
                                        <div className="color636363 fontweight600 mb-1">
                                            Kelengkapan Lainnya :
                                        </div>

                                        {dataLabel ? Object.keys(dataLabel).map((keyDL) => {
                                            return (
                                                <span
                                                    key={keyDL + 'dl_78'}
                                                    className={`color808080`}
                                                >
                                                    <span>{dataLabel[keyDL].label}</span>
                                                    <span>{dataLabel.length === (Number(keyDL) + 1) ? '' : ', '}</span>
                                                </span>
                                            )
                                        })
                                            :
                                            <div className="color808080">Tidak ada</div>
                                        }
                                    </div>
                                </Card>

                                <div className={`mb-3`}>
                                    <div className="mb-3 text-center fontweight600">Proses Pengerjaan</div>
                                    {dataProgres !== null ? <React.Fragment>{Object.keys(dataProgres).map((key) => {
                                        return (
                                            <Card
                                                key={key}
                                                className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} ${dataProgres && Object.keys(dataProgres).length === (parseInt(key) + 1) ? '' : 'mb-3'} bgWhite shadow-sm brSoftMedium color636363 wordBreakAll`}
                                            >
                                                <div className="flex pb-2 mb-3 borderBottomEee">
                                                    <div
                                                        className="bgTheme colorWhite f10 mr-3 flexJustifyAlignCenter"
                                                        style={{ width: '20px', minWidth: '20px', height: '20px', minHeight: '20px', marginTop: '2px', borderRadius: '50%' }}
                                                    >
                                                        {parseInt(key) + 1}
                                                    </div>

                                                    <div style={{ flex: 1 }}>
                                                        <div className="fontweight600 colorTheme">
                                                            {dataProgres[key]['nama_user']}
                                                        </div>

                                                        <div className="f13 colorAaa">
                                                            {dataProgres[key]['tanggal_progres'] ? dateTimeToHumanDateTime(dataProgres[key]['tanggal_progres']) : ''} WIB
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="color808080">
                                                    {dataProgres[key].flag_urutan === '1' ? 'Project diambil oleh ' + dataProgres[key].nama_user :
                                                        <div>

                                                            <div className="flex">
                                                                <div className="flex1 whiteSpacePreLine">
                                                                    {dataProgres[key].progres}
                                                                </div>
                                                            </div>

                                                            {dataProgres[key].id_tdp in dataPenggunaanStok &&
                                                                <div className="borderTopEee mt-3">
                                                                    <div className="colorTheme mt-3">
                                                                        Penggunaan Part Stok Barang
                                                                    </div>

                                                                    <div className="mt-3">
                                                                        {dataProgres[key].id_tdp in dataPenggunaanStok && Object.keys(dataPenggunaanStok[dataProgres[key].id_tdp].yu).map((keyPS) => {
                                                                            return (
                                                                                <div
                                                                                    key={keyPS}
                                                                                    className={`flex ${Object.keys(dataPenggunaanStok[dataProgres[key].id_tdp].yu).length === (parseInt(keyPS) + 1) ? '' : 'mb-3'}`}
                                                                                >
                                                                                    <div className="mr-2">
                                                                                        {(parseInt(keyPS) + 1) + '. '}
                                                                                    </div>

                                                                                    <div style={{ flex: 1 }}>
                                                                                        <div>
                                                                                            {dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].nama} {' '} {dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].merk} {' '} {dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].tipe} {' '} {dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].serial_number ? 'SN ' + dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].serial_number : 'SN -'}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>


                                                {dataProgres[key]['b'] !== null && <div className="mt-3 pt-3 borderTopEee">
                                                    <Masonry
                                                        breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 5 : 5) : 5) : 6) : 6}`}
                                                        className="my-masonry-grid"
                                                        columnClassName="my-masonry-grid_column"
                                                    >
                                                        {Object.keys(dataProgres[key]['b']).map((subKey) => {
                                                            return (
                                                                <img
                                                                    key={subKey}
                                                                    src={ROOT_BASE_URL + dataProgres[key]['b'][subKey].foto}
                                                                    alt={`foto ${subKey}`}
                                                                    style={{ width: '100%', borderRadius: '6px' }}

                                                                    onClick={() => {
                                                                        setSrcFoto(ROOT_BASE_URL + dataProgres[key]['b'][subKey].foto)
                                                                        setModalZoomImage(true);
                                                                    }}
                                                                />
                                                            )
                                                        })}
                                                    </Masonry>
                                                </div>
                                                }
                                            </Card>
                                        )
                                    })}
                                    </React.Fragment>
                                        :
                                        <div className="color808080 mt-3">
                                            Belum ada progres pengerjaan
                                        </div>
                                    }
                                </div>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                                    <div className="f14">
                                        <div className="color636363 fontweight600 mb-3">
                                            Form Q.C. Barang
                                        </div>

                                        <div className="mb-4 color808080">
                                            Kategori Form : {dataKelengkapan ? dataKelengkapan[0].nama_kategori : 'Tidak Ada'}
                                        </div>

                                        <div className="color808080">
                                            {dataKelengkapan && Object.keys(dataKelengkapan).map((key) => {
                                                return (<React.Fragment key={key + 'kkl7js'}>

                                                    {key === '0' &&
                                                        <div className="color636363 mb-3 pb-3 borderBottomEee">
                                                            <span className="mr-3">Q.C.</span>
                                                            <span>Item</span>
                                                        </div>
                                                    }

                                                    <Form.Row className="mb-2">
                                                        <Form.Group
                                                            as={Col}
                                                            xs="10"
                                                            controlId={`fg_01_pqc_${key}`}
                                                            className="mb-0"
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`fgfc_pqc_${key}`}
                                                                custom
                                                            >
                                                                <Form.Check.Input
                                                                    type="checkbox"

                                                                    onChange={(e) => {
                                                                        handleOnChangeCbKelengkapan(e, dataKelengkapan[key].id_tdk, key)
                                                                    }}

                                                                    checked={parseInt(dataKelengkapan[key].check_qc)}

                                                                    value={parseInt(dataKelengkapan[key].check_qc)}
                                                                />

                                                                <Form.Check.Label
                                                                    className="cp ml-1"
                                                                    style={{ paddingTop: '2px' }}
                                                                >
                                                                    <div className="flexAlignCenter ml-3">
                                                                        {
                                                                            parseInt(dataKelengkapan[key].check) ?
                                                                                <span
                                                                                    className="fa fa-check f10 mr-1"
                                                                                    style={{ color: '#007BFF' }}
                                                                                />
                                                                                :
                                                                                <span
                                                                                    className="fa fa-close f10 mr-1"
                                                                                    style={{ color: '#ccc' }}
                                                                                />
                                                                        }

                                                                        <span>
                                                                            {dataKelengkapan[key].nama_tdk}
                                                                        </span>

                                                                        <Spinner
                                                                            as="span"
                                                                            className={`ml-1 d-none loadCb${key}`}
                                                                            animation="border"
                                                                            variant="info"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </div>
                                                                </Form.Check.Label>

                                                                <div
                                                                    className="mt-2 color808080 f14 ml-4"
                                                                // id={`ketCapt_so7_pqc_${key}`}
                                                                >
                                                                    {dataKelengkapan[key].keterangan}
                                                                </div>

                                                                <div
                                                                    className="mt-2 color808080 f14 ml-4"
                                                                >
                                                                    {dataKelengkapan[key].keterangan_qc &&
                                                                        <div className="borderTopEee pt-2">
                                                                            <div className="mb-0">
                                                                                Keterangan Q.C. :
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div id={`ketCapt_so7_pqc_${key}`}>
                                                                        {dataKelengkapan[key].keterangan_qc}
                                                                    </div>
                                                                </div>
                                                            </Form.Check>

                                                            <div
                                                                className={`boxKetKlngkpnQC d-none mt-2`}
                                                                id={`boxKetKlngkpnQC_${key}`}
                                                            >
                                                                <Form.Group controlId={`fgtak0jh_pqc_${key}`}>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        size="sm"
                                                                        rows={3}
                                                                        placeholder="Keterangan Q.C."
                                                                        className="mt-1"
                                                                    />
                                                                </Form.Group>

                                                                <div className="mt-2">
                                                                    <ButtonLoading
                                                                        prmLoading={btnLoadUpdateKetKelQC}
                                                                        btnClass="btnXsOverride"
                                                                        btnVariant="info"
                                                                        btnOnClick={() => {
                                                                            handleSimpanKetKlngkpnQC(dataKelengkapan[key].id_tdk, key)
                                                                        }}
                                                                        caption="Simpan"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Form.Group>

                                                        <Form.Group
                                                            as={Col}
                                                            xs="2"
                                                            controlId={`fg_trig_edt_ket_pqc_${key}`}
                                                            className="mb-0 cp text-right flexJustifyAlignCenter"
                                                            onClick={() => toggleFormEditSubKlngkpnQC(key)}
                                                        >
                                                            <span id={`yu_pqc_${key}`} className="fa fa-pencil color808080 f14" />
                                                        </Form.Group>

                                                    </Form.Row>

                                                    {(dataKelengkapan.length - 1) !== parseInt(key) && <hr />}
                                                </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Card>

                                <Row className="mt-4">
                                    <Col xs={6} className="pr-1">
                                        <Button
                                            variant="light"
                                            size="sm"
                                            className="width100"
                                            onClick={() => setModalTolakQC(true)}
                                        >
                                            Tolak Q.C.
                                        </Button>
                                    </Col>

                                    <Col xs={6} className="pl-1">
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            className="width100"
                                            onClick={() => setModalApproveQC(true)}
                                        >
                                            Approve Q.C.
                                        </Button>
                                    </Col>
                                </Row>

                            </React.Fragment>
                        }

                        {
                            !dataProses && loadingDataProses &&
                            <PlaceHolderElement1 prmMT="" prmPX="" />
                        }

                        {!dataProses && !loadingDataProses &&
                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-5' : 'p-4'} shadow-sm brSoftMedium text-center f13`}>
                                <div className="f14 mb-3 color808080">
                                    Tidak ada data
                                </div>

                                <div className="flexJustifyAlignCenter">
                                    <Image
                                        src={soldering}
                                        style={{
                                            width: '75%'
                                        }}
                                    />
                                </div>
                            </Card>
                        }
                    </Col>
                </Row>
            </Layout>

            <Modal
                show={modalZoomImage}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body className="p-0">
                    <div className="flexJustifyAlignCenter">
                        <span
                            onClick={() => setModalZoomImage(false)}
                            className="fa fa-close f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                            style={{
                                borderRadius: '50%',
                                width: '35px',
                                height: '35px',
                                position: 'absolute',
                                top: '15px',
                                right: '17px'
                            }}
                        />

                        <img src={srcFoto} alt='foto' style={{ width: '100%' }} />
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={modalTolakQC}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f16 fontweight600 mb-4">Keterangan Tolak Q.C.</div>

                    <div className="color636363" style={{ marginBottom: '2rem' }}>
                        <Form.Control
                            ref={refKetTolakQC}
                            as="textarea"
                            size="sm"
                            rows={3}
                            placeholder="Keterangan / Alasan"
                            className=""
                        />
                    </div>

                    <div className="flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="mr-3 px-4"
                            onClick={() => setModalTolakQC(false)}
                        >
                            Close
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadingTolakQC}
                            btnClass="px-3"
                            btnVariant="info"
                            btnOnClick={handleTolakQC}
                            caption="Tolak Q.C."
                        />
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={modalApproveQC}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="f16 fontweight600 mb-4">Approve Q.C.</div>

                    <div className="color636363">
                        <Form.Control
                            ref={refKetApproveQC}
                            as="textarea"
                            size="sm"
                            rows={3}
                            placeholder="Keterangan (Opsional)"
                            className=""
                        />
                    </div>

                    <div className="mt-2 f12 color808080" style={{ marginBottom: '2rem' }}>
                        Approve Q.C. menandakan proses pengerjaan barang telah selesai dikerjakan dan telah melalui tahapan Q.C. dan barang siap untuk diambil oleh customer.
                    </div>

                    <div className="flexJustifyAlignCenter">
                        <Button
                            variant="light"
                            size="sm"
                            className="mr-3 px-4"
                            onClick={() => setModalApproveQC(false)}
                        >
                            &nbsp;&nbsp;&nbsp;&nbsp;Close&nbsp;&nbsp;&nbsp;&nbsp;
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadingApproveQC}
                            btnClass="px-3"
                            btnVariant="info"
                            btnOnClick={handleApproveQC}
                            caption="Approve Q.C."
                        />
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment>
    );
}

export default ProsesQC;
