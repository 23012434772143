import React from 'react';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import 'font-awesome/css/font-awesome.min.css';
import 'react-html5-camera-photo/build/css/index.css';
import { Row, Col, Card, Image, Form, Modal } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import CustomerAvatar from "../../components/CustomerAvatar";
import soldering from "../../assets/images/system/soldering.png";
import { ROOT_BASE_URL, BASE_URL, batasFluid, prefixUrl, levelUser, metodeBayar, widthLG, widthMD, widthSM, widthXS } from "../../utils/globalVar";
import { sqlDateToLongHumanDate, dateTimeToHumanDateTime, formatNumberCanZero, deko } from "../../utils/globalFunc";
import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import PlaceHolderElement1 from "../../components/PlaceHolderElement1";

const ProsesCancelDetil = () => {

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const parameter = useParams();
    const idTransaksi = parameter.id;

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    const [dataProses, setDataProses] = React.useState(null);
    const [dataLabel, setDataLabel] = React.useState(null);
    const [dataKelengkapan, setDataKelengkapan] = React.useState(null);
    const [loadingDataProses, setLoadingDataProses] = React.useState(false);
    const [listFotoTransaksi, setListFotoTransaksi] = React.useState(null);
    const [modalZoomImage, setModalZoomImage] = React.useState(false);
    const [srcFoto, setSrcFoto] = React.useState('');
    const [dataProgres, setDataProgres] = React.useState(null);
    const [dataPenggunaanStok, setDataPenggunaanStok] = React.useState({});
    const [penerima, setPenerima] = React.useState('');

    const [fotoKelola, setFotoKelola] = React.useState({ foto: '' });
    const [modalZoomImageProgres, setModalZoomImageProgres] = React.useState(false);

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [])

    React.useEffect(() => {
        setLoadingDataProses(true)

        axios({
            method: 'post',
            url: BASE_URL + '/proses_detil_cancel',
            data: {
                id_transaksi: idTransaksi
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingDataProses(false);

            if (response.data.status_hasil === true) {
                setDataPenggunaanStok(response.data.data_penggunaan_stok);
                setDataProses(response.data.data_proses);
                setDataKelengkapan(response.data.data_kelengkapan);
                setListFotoTransaksi(response.data.data_foto_transaksi);
                response.data.data_progres && setDataProgres(response.data.data_progres['k1']);
                setDataLabel(response.data.data_label);
                setPenerima(response.data.penerima);
            }

            if (response.data.status_hasil === false) {
                setDataProses(null);
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingDataProses(false)
            handleWarningModal(error.message)
        })
    }, [idTransaksi])

    const handleUangMuka = (prmUangMuka) => {
        if (!prmUangMuka || prmUangMuka === '0') {
            return '-';
        } else {
            return 'Rp ' + formatNumberCanZero(prmUangMuka);
        }
    }

    const handleMetodeUangMuka = (prmMetode) => {
        if (!prmMetode) {
            return '';
        } else {
            let hasil = '';

            Object.keys(metodeBayar).forEach(element => {
                if (element === prmMetode) {
                    hasil = '(Metode ' + metodeBayar[element] + ')';
                    return
                }
            });

            return hasil;
        }
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop
                shadow={true}
                titleNavTop="Project Yang Dicancel"
                prmBackButton={true}
                prmOnBack={`${deko(reduxLogin.syswebappj) === levelUser.owner ? '/' + prefixUrl.owner + '/projectcancel' : '/' + prefixUrl.teknisi + '/projectcancel'}`}
            />

            <Layout titleTab="Project Yang Dicancel">
                <Row>
                    <Col xs={12} className="mt-3">
                        {dataProses && !loadingDataProses &&
                            <React.Fragment>
                                <Card
                                    className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                >
                                    <div className="flex">
                                        <div>
                                            <CustomerAvatar
                                                prmSlug={dataProses[0].slug_ck}
                                                prmGender={dataProses[0].gender}
                                                ukuran={{ width: '55px', height: '55px' }}
                                            />
                                        </div>

                                        <div className="ml-3 wordBreakAll" style={{ width: '100%' }}>
                                            <div className="color636363 bold">
                                                {dataProses[0].nama}
                                            </div>

                                            <div className="color808080 f14">
                                                {dataProses[0].nama_ktgr_cust}
                                            </div>

                                            <div className="color808080 f14 mt-2">
                                                {dataProses[0].alamat}
                                            </div>

                                            {deko(reduxLogin.syswebappj) === levelUser.owner &&
                                                <div className="color808080 f14">
                                                    {dataProses[0].no_hp}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">No Nota</div>
                                        <div className="color808080">{dataProses[0].id_transaksi}</div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">Tanggal Masuk</div>
                                        <div className="color808080">{sqlDateToLongHumanDate(dataProses[0].tanggal_masuk)}</div>
                                    </div>

                                    <div className="f14">
                                        <div className="color636363 fontweight600 mb-1">Penerima</div>
                                        <div className="color808080">{penerima}</div>
                                    </div>
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">
                                            Kategori Barang
                                        </div>
                                        <div className="color808080">{dataProses[0].nama_barang_kategori}</div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600 mb-1">Barang</div>
                                        <div className="color808080">{dataProses[0].nama_barang}</div>
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600">Keluhan</div>
                                        <div className="color808080">{dataProses[0].kerusakan}</div>
                                    </div>

                                    <div className="f14 mb-3">
                                        <div className="color636363 fontweight600">Foto Barang</div>

                                        {listFotoTransaksi ?
                                            <div className="text-center mt-2">
                                                <Masonry
                                                    breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 4 : 5) : 5) : 6) : 6}`}
                                                    className="my-masonry-grid"
                                                    columnClassName="my-masonry-grid_column"
                                                >
                                                    {Object.keys(listFotoTransaksi).map((key) => {
                                                        return (
                                                            <img
                                                                key={"lfkp87hjsdk" + key}
                                                                src={ROOT_BASE_URL + listFotoTransaksi[key].foto}
                                                                alt={'foto'}
                                                                style={{ width: '100%' }}
                                                                className="cp rounded-lg"
                                                                onClick={() => {
                                                                    setSrcFoto(ROOT_BASE_URL + listFotoTransaksi[key].foto)
                                                                    setModalZoomImage(true);
                                                                }}
                                                            />
                                                        )
                                                    })}
                                                </Masonry>
                                            </div>
                                            :
                                            <div className="color808080">
                                                Tidak ada
                                            </div>
                                        }
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600">
                                            Kelengkapan Barang {dataKelengkapan && dataKelengkapan[0].nama_kategori}
                                        </div>

                                        {dataKelengkapan &&
                                            <div className="color808080 mt-2">
                                                {dataKelengkapan && Object.keys(dataKelengkapan).map((key) => {
                                                    return (
                                                        <React.Fragment key={key + 'kkl7js'}>
                                                            <Form.Row className="mb-2">
                                                                <Form.Group
                                                                    as={Col}
                                                                    xs="12"
                                                                    className="mb-0"
                                                                >
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        custom
                                                                    >
                                                                        <Form.Check.Input
                                                                            readOnly
                                                                            disabled
                                                                            type="checkbox"
                                                                            defaultChecked={parseInt(dataKelengkapan[key].check)}
                                                                        />

                                                                        <Form.Check.Label
                                                                            style={{ paddingTop: '2px' }}
                                                                        >
                                                                            {dataKelengkapan[key].nama_tdk}
                                                                        </Form.Check.Label>

                                                                        <div className="mt-1 color808080 f14">
                                                                            {dataKelengkapan[key].keterangan}
                                                                        </div>
                                                                    </Form.Check>
                                                                </Form.Group>

                                                            </Form.Row>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        }

                                        {!dataKelengkapan && <div className="color808080">Tidak ada</div>}
                                    </div>

                                    <div className="f14 mb-2">
                                        <div className="color636363 fontweight600">Uang Muka</div>
                                        <div className="color808080">
                                            {handleUangMuka(dataProses[0].dp)}
                                            {' '}
                                            {handleMetodeUangMuka(dataProses[0].metode_dp)}
                                        </div>
                                    </div>

                                    <div className="f14">
                                        <div className="color636363 fontweight600">Catatan</div>
                                        <div className="color808080 whiteSpacePreLine">
                                            {dataProses[0].catatan ? dataProses[0].catatan : '-'}
                                        </div>
                                    </div>
                                </Card>

                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                >
                                    <div className="f14 mb-3 colorTheme fontweight600">
                                        Kelengkapan Barang
                                    </div>

                                    <div>
                                        <div className="color636363 fontweight600">
                                            Kelengkapan Utama :
                                        </div>
                                        <div className="color808080 mt-1">{dataProses[0].nama_barang}</div>
                                    </div>

                                    <div className="mt-3">
                                        <div className="color636363 fontweight600 mb-1">
                                            Kelengkapan Lainnya :
                                        </div>

                                        {dataLabel ? Object.keys(dataLabel).map((keyDL) => {
                                            return (
                                                <span
                                                    key={keyDL + 'dl_78'}
                                                    className={`color808080`}
                                                >
                                                    <span>{dataLabel[keyDL].label}</span>
                                                    <span>{dataLabel.length === (Number(keyDL) + 1) ? '' : ', '}</span>
                                                </span>
                                            )
                                        })
                                            :
                                            <div className="color808080">Tidak ada</div>
                                        }
                                    </div>
                                </Card>

                                <Card
                                    className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                >
                                    <div className="f14">
                                        <div className="f14 mb-2 colorTheme fontweight600">
                                            Keterangan Cancel Project
                                        </div>

                                        <div className="f14 mb-2">
                                            <div className="color636363 fontweight600 mb-1">Tanggal Cancel</div>
                                            <div className="color808080">{sqlDateToLongHumanDate(dataProses[0].tanggal_cancel)}</div>
                                        </div>

                                        <div className="f14 mb-2">
                                            <div className="color636363 fontweight600 mb-1">Alasan Cancel</div>
                                            <div className="color808080 whiteSpacePreLine">{dataProses[0].alasan_cancel}</div>
                                        </div>

                                        <div className="f14 mb-2">
                                            <div className="color636363 fontweight600 mb-1">Dicancel Oleh</div>
                                            <div className="color808080">{dataProses[0].id_user_cancel === deko(reduxLogin.syswebappi) ? deko(reduxLogin.syswebappn) : 'Data user tidak ditemukan'}</div>
                                        </div>
                                    </div>
                                </Card>

                                <Card
                                    className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                                >
                                    <div className="f14">
                                        <div className="f14 mb-2 colorTheme fontweight600">
                                            Saran Pengerjaan
                                        </div>

                                        <div className="color636363 mb-0">
                                            Disarankan untuk dikerjakan oleh {dataProses[0].nama_user_recommended_todo ? dataProses[0].nama_user_recommended_todo : ' (Tidak ada) '}
                                        </div>
                                    </div>
                                </Card>

                                <div className="f14 color636363 fontweight600 mt-4 mb-3">
                                    Progres Pengerjaan
                                </div>

                                <div rel="containerProgresPengerjaan">
                                    {dataProgres ?
                                        <React.Fragment>
                                            {Object.keys(dataProgres).map((key) => {
                                                return (
                                                    <Card
                                                        key={key}
                                                        className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3 color636363`}
                                                    >
                                                        <div className="flex pb-2 mb-3 borderBottomEee">
                                                            <div
                                                                className="bgTheme colorWhite f10 mr-3 flexJustifyAlignCenter"
                                                                style={{ width: '20px', minWidth: '20px', height: '20px', minHeight: '20px', marginTop: '2px', borderRadius: '50%' }}
                                                            >
                                                                {parseInt(key) + 1}
                                                            </div>

                                                            <div style={{ flex: 1 }}>
                                                                <div className="fontweight600 colorTheme">
                                                                    {dataProgres[key]['nama_user']}
                                                                </div>

                                                                <div className="f13 colorAaa">
                                                                    {dateTimeToHumanDateTime(dataProgres[key]['tanggal_progres'])} WIB
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="color808080">
                                                            {<div>

                                                                <div className="flex">
                                                                    <div className="flex1 whiteSpacePreLine">
                                                                        {dataProgres[key].progres}
                                                                    </div>
                                                                </div>

                                                                {dataProgres[key].id_tdp in dataPenggunaanStok &&
                                                                    <div className="borderTopEee mt-3">
                                                                        <div className="colorTheme mt-3">
                                                                            Penggunaan Part Stok Barang
                                                                        </div>

                                                                        <div className="mt-3">
                                                                            {dataProgres[key].id_tdp in dataPenggunaanStok && Object.keys(dataPenggunaanStok[dataProgres[key].id_tdp].yu).map((keyPS) => {
                                                                                return (
                                                                                    <div
                                                                                        key={keyPS}
                                                                                        className={`flex ${Object.keys(dataPenggunaanStok[dataProgres[key].id_tdp].yu).length === (parseInt(keyPS) + 1) ? '' : 'mb-3'}`}
                                                                                    >
                                                                                        <div className="mr-2">
                                                                                            {(parseInt(keyPS) + 1) + '. '}
                                                                                        </div>

                                                                                        <div style={{ flex: 1 }}>
                                                                                            <div>
                                                                                                {dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].nama} {' '} {dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].merk} {' '} {dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].tipe} {' '} {dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].serial_number ? 'SN ' + dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].serial_number : 'SN -'}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            }
                                                        </div>


                                                        {dataProgres[key]['b'] !== null && <div className="mt-3 pt-3 borderTopEee">
                                                            <Masonry
                                                                breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 5 : 5) : 5) : 6) : 6}`}
                                                                className="my-masonry-grid"
                                                                columnClassName="my-masonry-grid_column"
                                                            >
                                                                {Object.keys(dataProgres[key]['b']).map((subKey) => {
                                                                    return (
                                                                        <img
                                                                            key={subKey}
                                                                            src={ROOT_BASE_URL + dataProgres[key]['b'][subKey].foto}
                                                                            alt={`foto ${subKey}`}
                                                                            style={{ width: '100%', borderRadius: '6px' }}
                                                                            className="cp"

                                                                            onClick={() => {
                                                                                setFotoKelola({
                                                                                    foto: ROOT_BASE_URL + dataProgres[key]['b'][subKey].foto
                                                                                })
                                                                                setModalZoomImageProgres(true);
                                                                            }}
                                                                        />
                                                                    )
                                                                })}
                                                            </Masonry>
                                                        </div>
                                                        }
                                                    </Card>
                                                )
                                            })}
                                        </React.Fragment>
                                        :
                                        <div className="color808080 mt-3">
                                            Belum ada progres pengerjaan
                                        </div>
                                    }
                                </div>
                            </React.Fragment>
                        }

                        {
                            !dataProses && loadingDataProses && <PlaceHolderElement1 prmMT="" prmPX="" />
                        }

                        {
                            !dataProses && !loadingDataProses &&
                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-5' : 'p-4'} shadow-sm brSoftMedium text-center f13`}>
                                <div className="f14 mb-3 color808080">
                                    Tidak ada data
                                </div>

                                <div className="flexJustifyAlignCenter">
                                    <Image src={soldering} style={{ width: '75%' }} />
                                </div>
                            </Card>
                        }
                    </Col>
                </Row>
            </Layout>

            <Modal
                size="lg"
                show={modalZoomImage}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body className="p-0">
                    <div className="flexJustifyAlignCenter">
                        <span
                            onClick={() => setModalZoomImage(false)}
                            className="fa fa-close cp f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                            style={{
                                borderRadius: '50%',
                                width: '35px',
                                height: '35px',
                                position: 'absolute',
                                top: '15px',
                                right: '17px'
                            }}
                        />

                        <img src={srcFoto} alt='foto' style={{ width: '100%' }} />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={modalZoomImageProgres}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body className="p-0">
                    <span
                        onClick={() => setModalZoomImageProgres(false)}
                        className="fa fa-close cp f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                        style={{
                            borderRadius: '50%',
                            width: '35px',
                            height: '35px',
                            position: 'absolute',
                            top: '15px',
                            right: '17px'
                        }}
                    />

                    <img src={fotoKelola.foto} alt='foto' style={{ width: '100%' }} />
                </Modal.Body>
            </Modal>

        </React.Fragment>
    );
}

export default ProsesCancelDetil;
