import React from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

import "../../styles/navbarTop.scss";
import DotMenu from "./DotMenu";
import BackButtonNavTop from "../teknisi/BackButtonNavTop";
import SideMenu from "./SideMenu";
import { batasFluid } from "../../utils/globalVar";

const NavbarTop = ({ shadow, titleNavTop, prmBackButton, prmOnBack, prmLocationState }) => {

    const history = useHistory();
    const reduxWindowWidth = useSelector(data => data.lumoswinwid);

    const [sideMenuState, setSideMenuState] = React.useState('');

    const closeSideMenu = () => {
        setSideMenuState('hide-menu')
    }

    const clearStateSideMenu = () => {
        setSideMenuState('')
    }

    return (
        <React.Fragment>

            <Container fluid className={`containerLuarTopNavbar bgWhite ${shadow ? 'shadow-sm' : ''}`}>
                <Row>
                    <Col xs={12} className="pl-0 pr-0">

                        <Container fluid={reduxWindowWidth > batasFluid ? false : true}>
                            <Row className="px-0">
                                <Col xs={12} className="px-0">
                                    <div className="flexJustifyBeetwenAlignCenter">

                                        <div className="py-2 pr-3 flexAlignCenter flex1">

                                            {prmBackButton === true ?
                                                prmOnBack ?
                                                    <div
                                                        className="flexJustifyAlignCenter containerBackNavTop cp"
                                                        onClick={() => { prmLocationState ? history.push(prmOnBack, prmLocationState) : history.push(prmOnBack) }}
                                                    >
                                                        <BackButtonNavTop />
                                                    </div>
                                                    :
                                                    <div
                                                        className="flexJustifyAlignCenter containerBackNavTop cp"
                                                        onClick={() => { history.goBack() }}
                                                    >
                                                        <BackButtonNavTop />
                                                    </div>
                                                :
                                                <div
                                                    className="mr-2 cp"
                                                    onClick={() => setSideMenuState('show-menu')}
                                                >
                                                    <DotMenu bgDot="bgSoftDark" />
                                                </div>
                                            }

                                            <div className="bold colorSoftDark f18">
                                                {titleNavTop}
                                            </div>
                                        </div>

                                        {/* <div
                                            onClick={() => window.location.reload()}
                                            className="py-2 pr-3 refreshPageNavbar"
                                        >
                                            <span className="fa fa-circle-o f20" />
                                        </div> */}
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </Col>
                </Row>
            </Container>

            <SideMenu
                sideMenuState={sideMenuState}
                closeSideMenu={closeSideMenu}
                clearStateSideMenu={clearStateSideMenu}
            />

        </React.Fragment>
    );
}

export default NavbarTop;
