import React from 'react';
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import { Modal } from 'react-bootstrap';

const BarcodeScanner = ({ modalBarcodeScanner, onSken, closeModalScanBarcode }) => {
    return (
        <Modal
            show={modalBarcodeScanner}
            backdrop="static"
            keyboard={false}
            animation={false}
            centered
        >
            <Modal.Body>
                <div>
                    <BarcodeScannerComponent
                        width="100%"
                        height="100%"
                        onUpdate={(err, result) => onSken(err, result)}
                    />
                </div>

                <div className="flexJustifyAlignCenter mt-3">
                    <div
                        className="flexJustifyAlignCenter colorWhite bgAaa cp"
                        style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                        onClick={closeModalScanBarcode}
                    >
                        <span className="fa fa-close f20" />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default BarcodeScanner;
