import React from 'react';
import axios from "axios";
import { useSelector } from "react-redux";
import { Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/teknisi/NavbarTop";
import { BASE_URL_DUA, batasFluid } from "../../utils/globalVar";
import { VFCannotNull, deko } from "../../utils/globalFunc";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ButtonLoading from "../../components/ButtonLoading";
import PlaceHolderElement2 from "../../components/PlaceHolderElement2";

const KelolaAkunUser = () => {

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    const [dataInit, setDatainit] = React.useState(null);
    const [loadingInit, setLoadingInit] = React.useState(false);

    const refResetPassword = React.useRef();

    const [dataForAction, setDataForAction] = React.useState({
        id: '',
        nama: ''
    });

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [modalResetPassword, setModalResetPassword] = React.useState(false);
    const [btnLoadingResetPassword, setBtnLoadingResetPassword] = React.useState(false);

    const [modalEditUN, setModalEditUN] = React.useState(false);
    const [btnLoadingEditUN, setBtnLoadingEditUN] = React.useState(false);
    const refNewUN = React.useRef('');

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        setLoadingInit(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/get_data_user_by_id',
            data: {
                id: deko(reduxLogin.syswebappi),
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);
            setDatainit(response.data.data);
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);
            handleWarningModal('Warning : ' + error.message)
        });

        document.body.style.backgroundColor = "#FAF9F9";

        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [reduxLogin.syswebappi])

    const handleResetPassword = () => {
        refResetPassword.current.style.border = 'unset';

        if (VFCannotNull(refResetPassword.current.value)) {
            refResetPassword.current.style.border = '1px solid red';
            refResetPassword.current.focus();
        } else if (refResetPassword.current.value.length < 3 || refResetPassword.current.value.length > 8) {
            refResetPassword.current.style.border = '1px solid red';
            refResetPassword.current.focus();
        } else {
            setBtnLoadingResetPassword(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/reset_password',
                data: {
                    password: refResetPassword.current.value,
                    id_target: dataForAction.id,
                    id_user: deko(reduxLogin.syswebappi)
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingResetPassword(false);
                setModalResetPassword(false);

                if (response.data.flag_status === true) {
                    handleWarningModal('Reset Password Berhasil. Silahkan login ulang.')
                }

                if (response.data.flag_status === false) {
                    handleWarningModal('Warning : ' + response.data.pesan)
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingResetPassword(false);
                handleWarningModal('Warning : ' + error.message)
            });
        }
    }

    const handleEditUN = () => {
        refNewUN.current.style.border = 'unset';

        if (VFCannotNull(refNewUN.current.value)) {
            refNewUN.current.style.border = '1px solid red';
            refNewUN.current.focus();
        } else if (refNewUN.current.value.length < 3 || refNewUN.current.value.length > 20) {
            refNewUN.current.style.border = '1px solid red';
            refNewUN.current.focus();
        } else {
            setBtnLoadingEditUN(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/edit_username',
                data: {
                    un: refNewUN.current.value,
                    id_target: dataForAction.id,
                    id_user: deko(reduxLogin.syswebappi)
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingEditUN(false);
                setModalEditUN(false);

                if (response.data.flag_status === true) {
                    setDatainit(response.data.data);
                    setDataForAction({ id: '', nama: '' });
                    handleWarningModal('Update Username Berhasil. Silahkan login ulang.')
                }

                if (response.data.flag_status === false) {
                    handleWarningModal('Warning : ' + response.data.pesan)
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingEditUN(false);
                handleWarningModal('Warning : ' + error.message)
            });
        }
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop shadow={true} titleNavTop="Akun" />

            <Layout titleTab="Akun">

                <Row>
                    {dataInit && !loadingInit &&
                        <Col
                            xs={12}
                            className="mt-3"
                        >
                            <Card
                                className={`${reduxWindowWidth > batasFluid ? 'p-3' : 'p-3'} shadow-sm brSoftMedium`}
                            >
                                <Row>
                                    <Col xs={12} className="mb-2">
                                        <div className="color636363 fontweight600">
                                            <span>{dataInit[0].nama}</span>
                                        </div>
                                    </Col>

                                    <Col xs={12} className="mb-3">
                                        <div className="color808080">
                                            Username : {dataInit[0].username}
                                        </div>
                                    </Col>

                                    <Col xs={12}>
                                        <div className="flexJustifyBeetwenAlignCenter">
                                            <div
                                                className="colorTheme"
                                                onClick={() => {
                                                    setDataForAction({ ...dataForAction, id: dataInit[0].id, nama: dataInit[0].username })
                                                    setModalEditUN(true);
                                                }}
                                            >
                                                Edit Username
                                            </div>

                                            <div
                                                className="colorTheme"
                                                onClick={() => {
                                                    setDataForAction({
                                                        id: dataInit[0].id,
                                                        nama: dataInit[0].nama
                                                    })
                                                    setModalResetPassword(true);
                                                }}
                                            >
                                                Reset Password
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    }

                    {loadingInit &&
                        <Col xs={12} className="mt-4">
                            <PlaceHolderElement2 prmMT="" prmPX="" />
                        </Col>
                    }

                    {!loadingInit && !dataInit &&
                        <Col xs={12} className="mt-4">
                            <div className="flexRowJustifyAlignCenter">
                                Tidak ada data
                            </div>
                        </Col>
                    }
                </Row>

            </Layout>

            <Modal
                show={modalResetPassword}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="fontweight600 pb-3 borderBottomEee">
                        Reset Password
                    </div>

                    <div className="mt-3">
                        <Form.Group>
                            <Form.Label>
                                <div>Password Baru</div>
                                <div className="f12 colorAaa">3 - 8 karakter</div>
                            </Form.Label>
                            <Form.Control
                                ref={refResetPassword}
                                size="sm"
                                type="password"
                                placeholder="Password Baru"
                            />
                        </Form.Group>

                        <div className="mt-3 text-center">
                            <Button
                                variant="light"
                                size="sm"
                                className="ml-2"
                                onClick={() => setModalResetPassword(false)}
                            >
                                Kembali
                            </Button>

                            <ButtonLoading
                                prmLoading={btnLoadingResetPassword}
                                btnClass="px-3 ml-3"
                                btnVariant="info"
                                btnOnClick={handleResetPassword}
                                caption="Simpan"
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={modalEditUN}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div>
                        <div className="fontweight600">Edit Username</div>
                        <div className="f12 colorAaa">3 - 20 karakter</div>
                    </div>

                    <div className="mt-3">
                        <div>
                            <Form.Control
                                ref={refNewUN}
                                size="sm"
                                type="text"
                                defaultValue={dataForAction.nama}
                                placeholder="Username"
                            />
                        </div>

                        <div className="mt-4 text-center">
                            <Button
                                variant="light"
                                size="sm"
                                className="ml-2"
                                onClick={() => setModalEditUN(false)}
                            >
                                Kembali
                            </Button>

                            <ButtonLoading
                                prmLoading={btnLoadingEditUN}
                                btnClass="px-3 ml-3"
                                btnVariant="info"
                                btnOnClick={handleEditUN}
                                caption="Simpan"
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment >
    );
}

export default KelolaAkunUser;
