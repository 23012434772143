import React from 'react';
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import LoadingStaticCenter from "../../components/LoadingStaticCenter";
import { VFCannotNull, VFNotNumber } from "../../utils/globalFunc";
import { BASE_URL, prefixUrl } from "../../utils/globalVar";

const StokBarangKategoriEdit = () => {

    const history = useHistory();

    const parameter = useParams();
    const idSK = parameter.id;

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const mountedRef = React.useRef(true);

    const refNama = React.useRef('');
    const refStokMinimal = React.useRef('');

    const [loadingSubmit, setLoadingSubmit] = React.useState(false);
    const [loadingOnLoad, setLoadingOnLoad] = React.useState(false);

    React.useEffect(() => {
        return () => {
            mountedRef.current = false;
        }
    }, []);

    React.useEffect(() => {
        setLoadingOnLoad(true);

        axios({
            method: 'post',
            url: BASE_URL + '/get_stok_barang_kategori',
            data: {
                id_sk: idSK
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingOnLoad(false);

            refNama.current.value = response.data[0].nama ? response.data[0].nama : '';
            refStokMinimal.current.value = parseInt(response.data[0].stok_minimal) ? parseInt(response.data[0].stok_minimal) : 0;
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingOnLoad(false);
            handleWarningModal('Warning : ' + error.message)
        })
    }, [idSK])

    const handleSubmitForm = () => {
        const el = document.getElementsByClassName("inptFieldTBS");
        for (var i in el) {
            if (el[i].type === "text" || el[i].type === "number") {
                el[i].style.border = 'unset'
            }
        }

        if (VFCannotNull(refNama.current.value)) {
            refNama.current.style.border = '1px solid red';
            refNama.current.focus();
        } else if (VFCannotNull(refStokMinimal.current.value)) {
            refStokMinimal.current.style.border = '1px solid red';
            refStokMinimal.current.focus();
        } else if (VFNotNumber(refStokMinimal.current.value)) {
            refStokMinimal.current.style.border = '1px solid red';
            refStokMinimal.current.focus();
        } else if (refStokMinimal.current.value.length > 1 && refStokMinimal.current.value.charAt(0) === '0') {
            refStokMinimal.current.style.border = '1px solid red';
            refStokMinimal.current.focus();
            handleWarningModal('Format salah');
        } else {
            setLoadingSubmit(true);

            axios({
                method: 'post',
                url: BASE_URL + '/edit_stok_barang_kategori',
                data: {
                    nama: refNama.current.value,
                    stok_minimal: refStokMinimal.current.value,
                    id_sk: idSK
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setLoadingSubmit(false);
                response.data.status_flag === true && history.push('/' + prefixUrl.owner + '/tambahstokbarang/' + idSK);
                response.data.status_flag === false && handleWarningModal('Error : ' + response.data.pesan);
            }).catch(error => {
                if (!mountedRef.current) return null;

                setLoadingSubmit(false);
                handleWarningModal('Warning : ' + error.message)
            })
        }
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop
                shadow={true}
                titleNavTop="Edit Kategori Barang"
                prmBackButton={true}
                prmOnBack={`/${prefixUrl.owner}/tambahstokbarang/${idSK}`}
            />

            <Layout titleTab="Edit Kategori Barang">
                <Row>

                    {loadingOnLoad && <Col xs={12} className="mt-3"><LoadingStaticCenter /></Col>}

                    {!loadingOnLoad &&
                        <Col xs={12} className="mt-4">
                            <Form.Group controlId="inputNamaBrgKategori">
                                <Form.Label>
                                    Nama Kategori Barang
                                </Form.Label>
                                <Form.Control
                                    className="inptFieldTBS"
                                    ref={refNama}
                                    name="inputNamaBrgKategori"
                                    size="sm"
                                    type="text"
                                    placeholder="Kategori Barang"
                                />
                            </Form.Group>

                            <Form.Group controlId="inputStokMinimal">
                                <Form.Label>
                                    Stok Minimal
                                </Form.Label>
                                <Form.Control
                                    className="inptFieldTBS"
                                    ref={refStokMinimal}
                                    name="inputStokMinimal"
                                    size="sm"
                                    type="number"
                                    placeholder="Stok Minimal"
                                />
                            </Form.Group>

                            <div className="flexJustifyAlignCenter">
                                <Button
                                    className="mt-4 px-4"
                                    variant="info"
                                    type="button"
                                    size="sm"
                                    disabled={loadingSubmit}
                                    onClick={handleSubmitForm}
                                >
                                    {loadingSubmit &&
                                        <Spinner
                                            as="span"
                                            className="mr-2"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    }
                                    <span>Simpan</span>
                                </Button>
                            </div>

                        </Col>
                    }
                </Row>
            </Layout>

        </React.Fragment >
    );
}

export default StokBarangKategoriEdit;
