import React from 'react';
import axios from "axios";
import { useSelector } from "react-redux";
import { Row, Col, Card, Button, Modal, Form, InputGroup } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import { BASE_URL_DUA, batasFluid } from "../../utils/globalVar";
import { formatNumber } from "../../utils/globalFunc";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ModalConfirmation from "../../components/ModalConfirmation";
import ButtonLoading from "../../components/ButtonLoading";
import LottiePausAstronot from "../../components/LottiePausAstronot";
import PlaceHolderElement2 from "../../components/PlaceHolderElement2";

const Diskon = () => {

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);

    const [dataDiskon, setDataDiskon] = React.useState(null);
    const [loadingInit, setLoadingInit] = React.useState(false);
    const [inputan, setInputan] = React.useState({ nama: '', tipe: 'rupiah', jumlah: '' });

    const refNama = React.useRef();
    const refJumlah = React.useRef();

    const [modalTambah, setModalTambah] = React.useState(false);
    const [btnLoadingTambah, setBtnLoadingTambah] = React.useState(false);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [modalHapus, setModalHapus] = React.useState({ modal: false, id: '' });
    const [btnLoadingHapus, setBtnLoadingHapus] = React.useState(false);

    const [modalEdit, setModalEdit] = React.useState({ modal: false, id: '' });
    const [btnLoadingEdit, setBtnLoadingEdit] = React.useState(false);
    const [inputanEdit, setInputanEdit] = React.useState({ nama: '', tipe: '', jumlah: '' });
    const refNamaEdit = React.useRef();
    const refJumlahEdit = React.useRef();

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        setLoadingInit(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/get_all_diskon',
            data: {
                dummy: 'sjkdfh87'
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);
            setDataDiskon(response.data.data_diskon);
        }).catch(error => {
            setLoadingInit(false);
            handleWarningModal('Warning : ' + error.message)
        });

        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [])

    const handleSubmit = () => {
        refNama.current.style.border = 'unset';
        refJumlah.current.style.border = 'unset';

        document.querySelector(".warningPersentase").innerHTML = '';
        document.querySelector(".errorTambah").innerHTML = '&nbsp;'

        if (!inputan.nama.trim()) {
            refNama.current.style.border = '1px solid red';
        } else if (!inputan.jumlah) {
            refJumlah.current.style.border = '1px solid red';
        } else if (inputan.tipe === 'persen' && parseInt(inputan.jumlah.replaceAll(',', '')) > 100) {
            refJumlah.current.style.border = '1px solid red';
            document.querySelector(".warningPersentase").innerHTML = 'Persentase Maksimum 100%';
        } else {
            setBtnLoadingTambah(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/tambah_diskon',
                data: {
                    nama: inputan.nama,
                    tipe: inputan.tipe,
                    jumlah: inputan.jumlah.replaceAll(',', '')
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingTambah(false);

                if (response.data.flag_status) {
                    setModalTambah(false);

                    setDataDiskon(response.data.data_diskon);
                    setInputan({ nama: '', tipe: 'rupiah', jumlah: '' });
                } else {
                    document.querySelector(".errorTambah").innerHTML = 'Error : ' + response.data.pesan
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingTambah(false);
                handleWarningModal('Warning : ' + error.message)
            });
        }
    }


    const handlePersentase = (e) => {
        const prm = e.target.value.replaceAll(',', '');

        document.querySelector(".warningPersentase").innerHTML = '';
        refJumlah.current.style.border = 'unset';

        if (parseInt(prm) > 100) {
            refJumlah.current.style.border = '1px solid red';
            document.querySelector(".warningPersentase").innerHTML = 'Persentase Maksimum 100%';
        } else {
            setInputan({ ...inputan, jumlah: formatNumber(prm) })
        }
    }

    const handlePersentaseEdit = (e) => {
        const prm = e.target.value.replaceAll(',', '');

        document.querySelector(".warningPersentaseEdit").innerHTML = '';
        refJumlahEdit.current.style.border = 'unset';

        if (parseInt(prm) > 100) {
            refJumlahEdit.current.style.border = '1px solid red';
            document.querySelector(".warningPersentaseEdit").innerHTML = 'Persentase Maksimum 100%';
        } else {
            setInputanEdit({ ...inputanEdit, jumlah: formatNumber(prm) })
        }
    }

    const doHapusDiskon = () => {
        setBtnLoadingHapus(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/hapus_diskon',
            data: {
                id: modalHapus.id
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapus(false);
            setModalHapus({ modal: false, id: '' });

            if (response.data.flag_status) {
                setDataDiskon(response.data.data_diskon);
            } else {
                handleWarningModal('Error : ' + response.data.pesan)
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapus(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }

    const handleEdit = () => {
        refNamaEdit.current.style.border = 'unset';
        refJumlahEdit.current.style.border = 'unset';

        document.querySelector(".warningPersentaseEdit").innerHTML = '';
        document.querySelector(".errorEdit").innerHTML = '&nbsp;'

        if (!inputanEdit.nama.trim()) {
            refNamaEdit.current.style.border = '1px solid red';
        } else if (!inputanEdit.jumlah) {
            refJumlahEdit.current.style.border = '1px solid red';
        } else if (inputanEdit.tipe === 'persen' && parseInt(inputanEdit.jumlah.replaceAll(',', '')) > 100) {
            refJumlahEdit.current.style.border = '1px solid red';
            document.querySelector(".warningPersentaseEdit").innerHTML = 'Persentase Maksimum 100%';
        } else {
            setBtnLoadingEdit(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/edit_diskon',
                data: {
                    nama: inputanEdit.nama,
                    tipe: inputanEdit.tipe,
                    jumlah: inputanEdit.jumlah.replaceAll(',', ''),
                    id: modalEdit.id
                }
            }).then((response) => {
                setBtnLoadingEdit(false);

                if (response.data.flag_status === true) {
                    setModalEdit({ modal: false, id: '' });

                    setDataDiskon(response.data.data_diskon);
                    setInputanEdit({ nama: '', tipe: '', jumlah: '' });
                }

                if (response.data.flag_status === false) {
                    document.querySelector(".errorEdit").innerHTML = 'Error : ' + response.data.pesan
                }
            }).catch(error => {
                setBtnLoadingEdit(false);
                handleWarningModal('Warning : ' + error.message)
            });
        }
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop shadow={true} titleNavTop="Diskon" />

            <Layout titleTab="Diskon">

                <Row>
                    {!loadingInit &&
                        <Col xs={12} className="mt-4 mb-2">
                            <div className="text-right">
                                <div>
                                    <Button
                                        variant="info"
                                        size="sm"
                                        className="btnXsOverride"
                                        onClick={() => setModalTambah(true)}
                                    >
                                        <span className="fa fa-plus-circle mr-2" />
                                        <span>Buat Diskon</span>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    }

                    {dataDiskon && !loadingInit &&
                        Object.keys(dataDiskon).map((key) => {
                            return (
                                <Col
                                    key={key + 'dd7348jh'}
                                    xs={12}
                                    md={6}
                                    className="mt-3"
                                >
                                    <Card
                                        className={`${reduxWindowWidth > batasFluid ? 'p-3' : 'p-3'} shadow-sm brSoftMedium`}
                                    >
                                        <div className="fontweight500">
                                            Diskon{' '}
                                            {dataDiskon[key].flag === 'rupiah' && 'Rp '}
                                            {formatNumber(dataDiskon[key].jumlah)}
                                            {dataDiskon[key].flag === 'persen' && '%'}
                                        </div>

                                        <div className="color808080 f13">
                                            {dataDiskon[key].nama}
                                        </div>

                                        <div className="f13 mt-3">
                                            <Button
                                                variant="outline-info"
                                                size="sm"
                                                className="btnXsOverride px-3"
                                                onClick={() => {
                                                    setInputanEdit({
                                                        nama: dataDiskon[key].nama,
                                                        tipe: dataDiskon[key].flag,
                                                        jumlah: dataDiskon[key].jumlah
                                                    });

                                                    setModalEdit({ modal: true, id: dataDiskon[key].id })
                                                }}
                                            >
                                                Edit
                                            </Button>

                                            <Button
                                                variant="outline-info"
                                                size="sm"
                                                className="btnXsOverride px-3 ml-2"
                                                onClick={() => {
                                                    setModalHapus({ modal: true, id: dataDiskon[key].id })
                                                }}
                                            >
                                                Hapus
                                            </Button>
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })
                    }

                    {loadingInit &&
                        <Col xs={12} className="mt-4">
                            <PlaceHolderElement2 prmMT="" prmPX="" />
                        </Col>
                    }

                    {!loadingInit && !dataDiskon &&
                        <Col xs={12} className="mt-4">
                            <div className="flexRowJustifyAlignCenter">
                                <LottiePausAstronot caption={`Belum ada daftar diskon.
                                Klik tombol Buat Diskon untuk menambahkan diskon.`} />
                            </div>
                        </Col>
                    }
                </Row>

            </Layout>



            <Modal
                show={modalTambah}
                onShow={() => document.querySelector(".errorTambah").innerHTML = '&nbsp;'}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="fontweight600 pb-3 borderBottomEee">
                        Buat Diskon Baru
                    </div>

                    <div className="mt-3">
                        <Form.Group controlId="nama">
                            <Form.Label>
                                Nama Diskon
                            </Form.Label>
                            <Form.Control
                                ref={refNama}
                                size="sm"
                                type="text"
                                placeholder="Nama Diskon"
                                value={inputan.nama}
                                onChange={(e) => setInputan({ ...inputan, nama: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="tipe">
                            <Form.Label>
                                Tipe
                            </Form.Label>

                            <Form.Control
                                custom
                                as="select"
                                size="sm"
                                value={inputan.tipe}
                                onChange={(e) => {
                                    document.querySelector(".warningPersentase").innerHTML = '';
                                    refJumlah.current.style.border = 'unset';
                                    setInputan({ ...inputan, tipe: e.target.value, jumlah: '' });
                                }}
                            >
                                <option value="rupiah">Rupiah (Rp)</option>
                                <option value="persen">Persentase (%)</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="jumlah">
                            <Form.Label>
                                Jumlah <span className="colorRed warningPersentase" />
                            </Form.Label>

                            <InputGroup className="mb-1" size="sm">
                                {inputan.tipe === 'rupiah' &&
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Rp</InputGroup.Text>
                                    </InputGroup.Prepend>
                                }

                                <Form.Control
                                    ref={refJumlah}
                                    type="text"
                                    placeholder="Jumlah Diskon"
                                    value={inputan.jumlah}
                                    onChange={(e) => inputan.tipe === 'rupiah' ? setInputan({ ...inputan, jumlah: formatNumber(e.target.value) }) : handlePersentase(e)}
                                />

                                {inputan.tipe === 'persen' &&
                                    <InputGroup.Append>
                                        <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup.Append>
                                }
                            </InputGroup>
                        </Form.Group>

                        <div className="mt-1 errorTambah" />

                        <div className="mt-3 text-center">
                            <Button
                                variant="light"
                                size="sm"
                                className="ml-2"
                                onClick={() => setModalTambah(false)}
                            >
                                Kembali
                            </Button>

                            <ButtonLoading
                                prmLoading={btnLoadingTambah}
                                btnClass="px-3 ml-3"
                                btnVariant="info"
                                btnOnClick={handleSubmit}
                                caption="Simpan"
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <ModalConfirmation
                showModal={modalHapus.modal}
                hideModal={() => setModalHapus({ ...modalHapus, modal: false })}
                title="Konfirmasi"
                bodyCaption="Hapus diskon ini ?"
                btnCloseClass="px-4"
                btnCloseCaption="Close"
                bLPrm={btnLoadingHapus}
                bLBtnClass="ml-3 px-3"
                bLOnClick={doHapusDiskon}
                bLCaption="Hapus"
            />


            <Modal
                show={modalEdit.modal}
                onShow={() => document.querySelector(".errorEdit").innerHTML = '&nbsp;'}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="fontweight600 pb-3 borderBottomEee">
                        Edit Diskon
                    </div>

                    <div className="mt-3">
                        <Form.Group>
                            <Form.Label>
                                Nama Diskon
                            </Form.Label>
                            <Form.Control
                                ref={refNamaEdit}
                                size="sm"
                                type="text"
                                placeholder="Nama Diskon"
                                value={inputanEdit.nama}
                                onChange={(e) => setInputanEdit({ ...inputanEdit, nama: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Tipe
                            </Form.Label>

                            <Form.Control
                                custom
                                as="select"
                                size="sm"
                                value={inputanEdit.tipe}
                                onChange={(e) => {
                                    document.querySelector(".warningPersentaseEdit").innerHTML = '';
                                    refJumlahEdit.current.style.border = 'unset';
                                    setInputanEdit({ ...inputanEdit, tipe: e.target.value, jumlah: '' });
                                }}
                            >
                                <option value="rupiah">Rupiah (Rp)</option>
                                <option value="persen">Persentase (%)</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Jumlah <span className="colorRed warningPersentaseEdit" />
                            </Form.Label>

                            <InputGroup className="mb-1" size="sm">
                                {inputanEdit.tipe === 'rupiah' &&
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Rp</InputGroup.Text>
                                    </InputGroup.Prepend>
                                }

                                <Form.Control
                                    ref={refJumlahEdit}
                                    type="text"
                                    placeholder="Jumlah Diskon"
                                    value={inputanEdit.jumlah}
                                    onChange={(e) => inputanEdit.tipe === 'rupiah' ? setInputanEdit({ ...inputanEdit, jumlah: formatNumber(e.target.value) }) : handlePersentaseEdit(e)}
                                />

                                {inputanEdit.tipe === 'persen' &&
                                    <InputGroup.Append>
                                        <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup.Append>
                                }
                            </InputGroup>
                        </Form.Group>

                        <div className="mt-1 errorEdit" />

                        <div className="mt-3 text-center">
                            <Button
                                variant="light"
                                size="sm"
                                className="ml-2"
                                onClick={() => setModalEdit({ ...modalEdit, modal: false })}
                            >
                                Kembali
                            </Button>

                            <ButtonLoading
                                prmLoading={btnLoadingEdit}
                                btnClass="px-3 ml-3"
                                btnVariant="info"
                                btnOnClick={handleEdit}
                                caption="Simpan"
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment >
    );
}

export default Diskon;
