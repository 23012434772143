import React from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Camera from 'react-html5-camera-photo';
import 'font-awesome/css/font-awesome.min.css';
import 'react-html5-camera-photo/build/css/index.css';
import { Row, Col, Card, Image, Form, Modal, Button } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import CustomerAvatar from "../../components/CustomerAvatar";
import soldering from "../../assets/images/system/soldering.png";
import { ROOT_BASE_URL, BASE_URL, BASE_URL_DUA, batasFluid, prefixUrl, levelUser, metodeBayar, widthLG, widthMD, widthSM, widthXS } from "../../utils/globalVar";
import { sqlDateToLongHumanDate, dateTimeToHumanDateTime, formatNumberCanZero, VFCannotNull, handleCallBack, deko } from "../../utils/globalFunc";
import Layout from "../../components/Layout";
import NavbarTopOwner from "../../components/NavbarTop";
import NavbarTopTeknisi from "../../components/teknisi/NavbarTop";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ButtonLoading from "../../components/ButtonLoading";
import ModalConfirmation from "../../components/ModalConfirmation";
import PlaceHolderElement1 from "../../components/PlaceHolderElement1";

const ProsesPengerjaan = () => {

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const parameter = useParams();
    const idTransaksi = parameter.id;
    const callbackUrl = parameter.callbackUrl;

    const history = useHistory();

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    const [dataProses, setDataProses] = React.useState(null);
    const [dataLabel, setDataLabel] = React.useState(null);
    const [dataKelengkapan, setDataKelengkapan] = React.useState(null);
    const [loadingDataProses, setLoadingDataProses] = React.useState(false);
    const [listFotoTransaksi, setListFotoTransaksi] = React.useState(null);
    const [modalZoomImage, setModalZoomImage] = React.useState(false);
    const [srcFoto, setSrcFoto] = React.useState('');
    const [dataProgres, setDataProgres] = React.useState(null);
    const [dataPenggunaanStok, setDataPenggunaanStok] = React.useState({});
    const [penerima, setPenerima] = React.useState('');

    const [srcCam, setSrcCam] = React.useState('');
    const [srcFile, setSrcFile] = React.useState('');
    const [showCam, setShowCam] = React.useState(false);
    const [showFile, setShowFile] = React.useState(false);
    const [fotoKelola, setFotoKelola] = React.useState({ foto: '', id: '', idTdp: '', prmDel: false });
    const [inputProgres, setInputProgres] = React.useState('');
    const [modalZoomImageProgres, setModalZoomImageProgres] = React.useState(false);
    const [modalBatalkanPS, setModalBatalkanPS] = React.useState(false);
    const [modalHapusProgres, setModalHapusProgres] = React.useState(false);
    const [modalPostingProgres, setModalPostingProgres] = React.useState(false);

    const refIdS = React.useRef('');
    const refIdSK = React.useRef('');
    const refIdTDPS = React.useRef('');
    const refIdTDP = React.useRef('');

    const refNewProgres = React.useRef();
    const [modalNewProgres, setModalNewProgres] = React.useState(false);

    const [btnLoadingTambahProgres, setBtnLoadingTambahProgres] = React.useState(false);
    const [btnLoadingBatalkanPS, setBtnLoadingBatalkanPS] = React.useState(false);
    const [btnLoadingHapusProgres, setBtnLoadingHapusProgres] = React.useState(false);
    const [btnLoadingPostingProgres, setBtnLoadingPostingProgres] = React.useState(false);
    const [btnLoadingFinishProgres, setBtnLoadingFinishProgres] = React.useState(false);
    const [btnLoadingSimpanFoto, setBtnLoadingSimpanFoto] = React.useState(false);
    const [btnLoadingUploadFile, setBtnLoadingUploadFile] = React.useState(false);
    const [btnLoadingEditProgres, setBtnLoadingEditProgres] = React.useState(false);
    const [btnLoadingHapusFoto, setBtnLoadingHapusFoto] = React.useState(false);

    const [btnLoadingCancel, setBtnLoadingCancel] = React.useState(false);
    const [modalCancel, setModalCancel] = React.useState(false);
    const [dataUsedStok, setDataUsedStok] = React.useState(false);

    const refAlasanCancel = React.useRef();
    const [alasanCancel, setAlasanCancel] = React.useState('');
    const [modalBerhasilCancel, setModalBerhasilCancel] = React.useState(false);

    const refEditProgres = React.useRef('');
    const refIdEditProgres = React.useRef('');
    const [modalEditProgres, setModalEditProgres] = React.useState(false);

    const [modalSubmit, setModalSubmit] = React.useState(false);

    const [prmFacingMode, setPrmFacingMode] = React.useState('environment');

    const mountedRef = React.useRef(true);

    const flipCamera = () => {
        if (prmFacingMode === 'environment') { setPrmFacingMode('user') }
        else { setPrmFacingMode('environment') }
    }

    const handleTakePhoto = (dataUri) => {
        setTimeout(() => { setSrcCam(dataUri) }, 1000);
        setTimeout(() => { window.navigator.vibrate(200) }, 1100);
    }

    const showCloseFoto = () => {
        document.querySelector("#closeFlag").classList.remove("d-none");
        document.querySelector("#closeFlag").classList.add("closeFlagBlock");
    }

    const hideCloseFoto = () => {
        document.querySelector("#closeFlag").classList.add("d-none");
        document.querySelector("#closeFlag").classList.remove("closeFlagBlock");
    }

    React.useEffect(() => {
        srcCam && setShowCam(false)
    }, [srcCam])

    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const handleSimpanFoto = (prmSrc) => {
        // info : akses denaid = ok
        // info : cek no url id_tdp = ok
        // info : cek no url id_transaksi = ok

        setBtnLoadingSimpanFoto(true);

        let formdata = new FormData();
        formdata.append('id_transaksi', idTransaksi);
        formdata.append('id_tdp', refIdTDP.current);
        formdata.append('id_user', deko(reduxLogin.syswebappi));
        formdata.append('foto', dataURLtoFile(prmSrc, `${Math.random(10)}.jpg`));

        axios({
            url: BASE_URL + '/insertProgresFoto',
            method: 'POST',
            data: formdata,
            headers: {
                Accept: 'application/json', 'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingSimpanFoto(false);
            setSrcCam('');

            if (response.data.status_hasil === true) {
                setDataPenggunaanStok(response.data.data_penggunaan_stok);
                setDataProgres(response.data.data_progres['k1']);
            }

            if (response.data.status_hasil === false) {
                handleWarningModal('Error : ' + response.data.pesan);
            }

            if (response.data.status_hasil === 'tidak-sesuai') {
                handleWarningModal('Warning : ' + response.data.pesan);
            }

            if (response.data.status_hasil === 'access-denied') {
                handleWarningModal(<React.Fragment><div className="alert alert-info">Progres tanggal {response.data.tanggal_progres ? dateTimeToHumanDateTime(response.data.tanggal_progres) : ' - '} sedang dikerjakan oleh {response.data.nama_user}. <br /><br />Anda tidak diperkenankan untuk mengakses progres ini.</div></React.Fragment>);
            }

            if (response.data.status_hasil === 'not-found-id-tdp') {
                handleWarningModal(<React.Fragment><div className="alert alert-info">Progres tidak ditemukan. <br /><br />Refresh halaman untuk mendapatkan data terbaru.</div></React.Fragment>);
            }

            if (response.data.status_hasil === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingSimpanFoto(false);
            setSrcCam('');
            handleWarningModal('Warning : ' + error.message)
        });
    }

    const changeNameFileLabel = (selector, target) => {
        const choosen_fie = document.querySelector(selector);
        let dot = (choosen_fie.files[0].name).length < 30 ? '' : '..';
        document.querySelector(target).innerHTML = (choosen_fie.files[0].name).substr(0, 30) + dot;
    }

    const handleUploadFileFoto = () => {
        // info : akses denaid = ok
        // info : cek no url id_tdp = ok
        // info : cek no url id_transaksi = ok

        if (!srcFile) {
            handleWarningModal('File foto belum dipilih');
            return false;
        }

        setBtnLoadingUploadFile(true);

        let formdata = new FormData();
        formdata.append('id_transaksi', idTransaksi);
        formdata.append('id_tdp', refIdTDP.current);
        formdata.append('id_user', deko(reduxLogin.syswebappi));
        formdata.append('foto', srcFile);

        axios({
            url: BASE_URL + '/insertProgresFoto',
            method: 'POST',
            data: formdata,
            headers: {
                Accept: 'application/json', 'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingUploadFile(false);

            if (response.data.status_hasil === true) {
                setDataPenggunaanStok(response.data.data_penggunaan_stok);
                setDataProgres(response.data.data_progres['k1']);
                setSrcFile('');
                setShowFile(false);
            }

            if (response.data.status_hasil === false) {
                handleWarningModal('Error : ' + response.data.pesan);
            }

            if (response.data.status_hasil === 'tidak-sesuai') {
                handleWarningModal('Warning : ' + response.data.pesan);
            }

            if (response.data.status_hasil === 'access-denied') {
                handleWarningModal(<React.Fragment><div className="alert alert-info">Progres tanggal {response.data.tanggal_progres ? dateTimeToHumanDateTime(response.data.tanggal_progres) : ' - '} sedang dikerjakan oleh {response.data.nama_user}. <br /><br />Anda tidak diperkenankan untuk mengakses progres ini.</div></React.Fragment>);
            }

            if (response.data.status_hasil === 'not-found-id-tdp') {
                handleWarningModal(<React.Fragment><div className="alert alert-info">Progres tidak ditemukan. <br /><br />Refresh halaman untuk mendapatkan data terbaru.</div></React.Fragment>);
            }

            if (response.data.status_hasil === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingUploadFile(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [])

    React.useEffect(() => {
        setLoadingDataProses(true)

        axios({
            method: 'post',
            url: BASE_URL + '/proses_pengerjaan',
            data: {
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingDataProses(false);

            if (response.data.status_hasil === true) {
                setDataPenggunaanStok(response.data.data_penggunaan_stok);
                setDataProses(response.data.data_proses);
                setDataKelengkapan(response.data.data_kelengkapan);
                setListFotoTransaksi(response.data.data_foto_transaksi);
                response.data.data_progres && setDataProgres(response.data.data_progres['k1']);
                setDataLabel(response.data.data_label);
                setPenerima(response.data.penerima);
            }

            if (response.data.status_hasil === false) {
                setDataProses(null);
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingDataProses(false)
            handleWarningModal(error.message)
        })
    }, [idTransaksi, reduxLogin.syswebappi])

    const handleSimpanProgres = () => {
        // info : cek no url id_transaksi = ok

        refNewProgres.current.style.border = 'unset';

        if (!inputProgres) {
            refNewProgres.current.style.border = '1px solid red';
            refNewProgres.current.focus();
            return false;
        }

        setBtnLoadingTambahProgres(true);

        axios({
            method: 'post',
            url: BASE_URL + '/simpan_progres',
            data: {
                id_transaksi: idTransaksi,
                id_user_login: deko(reduxLogin.syswebappi),
                progres: inputProgres
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingTambahProgres(false);
            setModalNewProgres(false);

            if (response.data.status_hasil === true) {
                setDataPenggunaanStok(response.data.data_penggunaan_stok);
                setDataProgres(response.data.data_progres['k1']);
                setInputProgres('');
            }

            if (response.data.status_hasil === 'isset-unposting-progres') {
                handleWarningModal('Tidak dapat menambahkan progres. Terdapat progres yang belum di posting. Posting progres terlebih dahulu !.');
            }

            if (response.data.status_hasil === false) {
                handleWarningModal('Error : ' + response.data.pesan)
            }

            if (response.data.status_hasil === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingTambahProgres(false);
            handleWarningModal(error.message)
        })
    }

    const handleHapusFotoProgres = () => {
        // info : akses denaid = ok
        // info : cek no url id_tdpf = ok
        // info : cek no url id_transaksi = ok

        setBtnLoadingHapusFoto(true);

        axios({
            url: BASE_URL + '/hapus_foto_progres',
            method: 'POST',
            data: {
                id_tdpf: fotoKelola.id,
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi),
                id_tdp: fotoKelola.idTdp
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusFoto(false);
            setModalZoomImageProgres(false);

            if (response.data.status_hasil === true) {
                setDataPenggunaanStok(response.data.data_penggunaan_stok);
                setDataProgres(response.data.data_progres['k1']);
            }

            if (response.data.status_hasil === false) {
                handleWarningModal('Silahkan ulangi proses. Error : ' + response.data.pesan_error);
            }

            if (response.data.status_hasil === 'access-denied') {
                handleWarningModal(<React.Fragment><div className="alert alert-info">Progres tanggal {response.data.tanggal_progres ? dateTimeToHumanDateTime(response.data.tanggal_progres) : ' - '} sedang dikerjakan oleh {response.data.nama_user}. <br /><br />Anda tidak diperkenankan untuk mengakses progres ini.</div></React.Fragment>);
            }

            if (response.data.status_hasil === 'not-found-id-tdpf') {
                handleWarningModal(<React.Fragment><div className="alert alert-info">Foto tidak ditemukan dalam progres. <br /><br />Refresh halaman untuk mendapatkan data terbaru.</div></React.Fragment>);
            }

            if (response.data.status_hasil === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusFoto(false);
            setModalZoomImageProgres(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }

    const handleEditProgres = (prmIdTdp, prmProgres) => {
        refIdEditProgres.current = prmIdTdp;
        refEditProgres.current = prmProgres;
        setModalEditProgres(true);
    }

    const handleSimpanEditProgres = () => {
        // info : akses denaid = ok
        // info : cek no url id_tdp = ok
        // info : cek no url id_transaksi = ok

        setBtnLoadingEditProgres(true);

        axios({
            url: BASE_URL + '/edit_progres',
            method: 'POST',
            data: {
                id_tdp: refIdEditProgres.current,
                progres: refEditProgres.current.value,
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingEditProgres(false);
            setModalEditProgres(false);

            if (response.data.status_hasil === true) {
                setDataProgres(response.data.data_progres['k1']);
            }

            if (response.data.status_hasil === false) {
                handleWarningModal('Silahkan ulangi proses. Error : ' + response.data.pesan_error);
            }

            if (response.data.status_hasil === 'access-denied') {
                handleWarningModal(<React.Fragment><div className="alert alert-info">Progres tanggal {response.data.tanggal_progres ? dateTimeToHumanDateTime(response.data.tanggal_progres) : ' - '} sedang dikerjakan oleh {response.data.nama_user}. <br /><br />Anda tidak diperkenankan untuk mengakses progres ini.</div></React.Fragment>);
            }

            if (response.data.status_hasil === 'not-found-id-tdp') {
                handleWarningModal(<React.Fragment><div className="alert alert-info">Progres tidak ditemukan. <br /><br />Refresh halaman untuk mendapatkan data terbaru.</div></React.Fragment>);
            }

            if (response.data.status_hasil === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;
            setBtnLoadingEditProgres(false);
            setModalEditProgres(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }

    const handleBatalkanPS = () => {
        // info : akses denaid = ok
        // info : cek no url id_tdps = ok
        // info : cek no url id_transaksi = ok

        setBtnLoadingBatalkanPS(true);

        axios({
            url: BASE_URL + '/batalkan_penggunaan_stok',
            method: 'POST',
            data: {
                id_s: refIdS.current,
                id_sk: refIdSK.current,
                id_tdps: refIdTDPS.current,
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi),
                id_tdp: refIdTDP.current
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingBatalkanPS(false);
            setModalBatalkanPS(false);

            if (response.data.flag_status === true) {
                // response.data.data_penggunaan_stok === null && setDataUsedStok(false);

                setDataPenggunaanStok(response.data.data_penggunaan_stok);
                setDataProgres(response.data.data_progres['k1']);
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.pesan + '. Warning : ' + response.data.flag_pesan);
            }

            if (response.data.flag_status === 'access-denied') {
                handleWarningModal(<React.Fragment><div className="alert alert-info">Progres tanggal {response.data.tanggal_progres ? dateTimeToHumanDateTime(response.data.tanggal_progres) : ' - '} sedang dikerjakan oleh {response.data.nama_user}. <br /><br />Anda tidak diperkenankan untuk mengakses progres ini.</div></React.Fragment>);
            }

            if (response.data.flag_status === 'not-found-id-tdps') {
                handleWarningModal('Data tidak ditemukan. Silahkan refresh halaman untuk memuat data terbaru.');
            }

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingBatalkanPS(false);
            setModalBatalkanPS(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }

    const handleHapusProgres = () => {
        // info : akses denaid = ok
        // info : cek no url id_tdp = ok
        // info : cek no url id_transaksi = ok

        setBtnLoadingHapusProgres(true);

        axios({
            url: BASE_URL + '/hapus_progres',
            method: 'POST',
            data: {
                id_tdp: refIdTDP.current,
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusProgres(false);
            setModalHapusProgres(false);

            if (response.data.flag_status === 'cannot-delete') {
                handleWarningModal('Untuk menghapus progres, hapus seluruh progres foto dan batalkan seluruh penggunaan stok barang terlebih dahulu.');
            }

            if (response.data.flag_status === true) {
                setDataPenggunaanStok(response.data.data_penggunaan_stok);
                setDataProgres(response.data.data_progres['k1']);
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.pesan);
            }

            if (response.data.flag_status === 'access-denied') {
                handleWarningModal(<React.Fragment><div className="alert alert-info">Progres tanggal {response.data.tanggal_progres ? dateTimeToHumanDateTime(response.data.tanggal_progres) : ' - '} sedang dikerjakan oleh {response.data.nama_user}. <br /><br />Anda tidak diperkenankan untuk mengakses progres ini.</div></React.Fragment>);
            }

            if (response.data.flag_status === 'not-found-id-tdp') {
                handleWarningModal('Data tidak ditemukan. Silahkan refresh halaman untuk memuat data terbaru.');
            }

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapusProgres(false);
            setModalHapusProgres(false);
            handleWarningModal('Warning : ' + error.message);
        });
    }

    const handlePostingProgres = () => {
        // info : akses denaid = ok
        // info : cek no url id_tdp = ok
        // info : cek no url id_transaksi = ok

        setBtnLoadingPostingProgres(true);

        axios({
            url: BASE_URL + '/posting_progres',
            method: 'POST',
            data: {
                id_tdp: refIdTDP.current,
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingPostingProgres(false);
            setModalPostingProgres(false);

            if (response.data.flag_status === true) {
                setDataPenggunaanStok(response.data.data_penggunaan_stok);
                setDataProgres(response.data.data_progres['k1']);
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.pesan);
            }

            if (response.data.flag_status === 'access-denied') {
                handleWarningModal(<React.Fragment><div className="alert alert-info">Progres tanggal {response.data.tanggal_progres ? dateTimeToHumanDateTime(response.data.tanggal_progres) : ' - '} sedang dikerjakan oleh {response.data.nama_user}. <br /><br />Anda tidak diperkenankan untuk mengakses progres ini.</div></React.Fragment>);
            }

            if (response.data.flag_status === 'sudah-diposting') {
                handleWarningModal(<React.Fragment><div className="alert alert-info">Progres ini sudah diposting oleh {response.data.nama_user}. <br /><br />Refresh halaman untuk mendapatkan data terbaru.</div></React.Fragment>);
            }

            if (response.data.flag_status === 'not-found-id-tdp') {
                handleWarningModal(<React.Fragment><div className="alert alert-info">Progres tidak ditemukan. <br /><br />Refresh halaman untuk mendapatkan data terbaru.</div></React.Fragment>);
            }

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingPostingProgres(false);
            setModalPostingProgres(false);
            handleWarningModal('Warning : ' + error.message);
        });
    }

    const handleFinishProgres = () => {
        // info : cek no url id_transaksi = ok

        setBtnLoadingFinishProgres(true);

        axios({
            url: BASE_URL + '/submit_progres',
            method: 'POST',
            data: {
                id_transaksi: idTransaksi,
                id_user_login: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingFinishProgres(false);
            setModalSubmit(false)

            response.data.flag_status === 'isset-unposting' && handleWarningModal('Terdapat progres yang belum di posting. Selesaikan atau batalkan terlebih dahulu progres yang belum diselesaikan.');

            response.data.flag_status === false && handleWarningModal('Error : ' + response.data.pesan);

            if (response.data.flag_status === true) {
                deko(reduxLogin.syswebappj) === levelUser.owner && history.push('/' + prefixUrl.owner + '/proses/prosesDua');

                deko(reduxLogin.syswebappj) === levelUser.teknisi && history.push('/' + prefixUrl.teknisi + '/proses/prosesDua');
            }

            if (response.data.flag_status === 'not-found') {
                handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingFinishProgres(false);
            setModalSubmit(false);
            handleWarningModal('Warning : ' + error.message);
        });
    }

    const handleGunakanPart = (prmIdTdp) => {
        // info link ke file ListStokBarang.js
        // info : cek no url id_transaksi = ok

        (deko(reduxLogin.syswebappj) === levelUser.owner || deko(reduxLogin.syswebappj) === levelUser.teknisi) && history.push('/liststokbarang/' + idTransaksi + '/' + prmIdTdp);
    }

    const handleUangMuka = (prmUangMuka) => {
        if (!prmUangMuka || prmUangMuka === '0') {
            return '-';
        } else {
            return 'Rp ' + formatNumberCanZero(prmUangMuka);
        }
    }

    const handleMetodeUangMuka = (prmMetode) => {
        if (!prmMetode) {
            return '';
        } else {
            let hasil = '';

            Object.keys(metodeBayar).forEach(element => {
                if (element === prmMetode) {
                    hasil = '(Metode ' + metodeBayar[element] + ')';
                    return
                }
            });

            return hasil;
        }
    }

    const handleCancel = () => {
        // info : cek no url id_transaksi = ok

        refAlasanCancel.current.style.border = 'unset';

        if (VFCannotNull(alasanCancel)) {
            refAlasanCancel.current.style.border = '1px solid red';
            refAlasanCancel.current.focus();
        } else {
            setBtnLoadingCancel(true);

            axios({
                url: BASE_URL_DUA + '/cancel_proses',
                method: 'POST',
                data: {
                    id_transaksi: idTransaksi,
                    id_user_login: deko(reduxLogin.syswebappi),
                    alasan_cancel: alasanCancel
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingCancel(false);
                setModalCancel(false);

                if (response.data.flag_status === 'cek_used_stok') {
                    setDataUsedStok(true);
                }

                response.data.flag_status === 'isset-unposting' && handleWarningModal('Terdapat progres yang belum di posting. Batalkan terlebih dahulu progres yang belum diselesaikan.');

                response.data.flag_status === false && handleWarningModal('Error : ' + response.data.pesan);

                if (response.data.flag_status === true) {
                    setModalBerhasilCancel(true);
                }

                if (response.data.flag_status === 'not-found') {
                    handleWarningModal('Transaksi ini sudah tidak available. Muat ulang halaman ini untuk mendapatkan data terbaru.');
                }

                if (response.data.flag_status === 'not-found-user') {
                    handleWarningModal('Anda tidak berhak mengakses proses ini');
                }

                if (response.data.flag_status === 'cannot-access') {
                    handleWarningModal('Anda tidak berhak mengakses proses ini !');
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingCancel(false);
                setModalCancel(false);
                handleWarningModal('Warning : ' + error.message);
            });
        }
    }

    const handleBerhasliCancelProject = () => {
        deko(reduxLogin.syswebappj) === levelUser.owner && history.push('/' + prefixUrl.owner + '/proses/prosesDua');
        deko(reduxLogin.syswebappj) !== levelUser.owner && history.push('/notfound');
    }

    return (<React.Fragment>

        <ModalAlertWarning
            showWarningModal={warningModal}
            hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
            paramText={warningText}
        />

        <Modal
            show={showCam}
            className="modalNoBorderRadius"
            backdrop="static"
            keyboard={false}
            animation={false}
            centered
        >

            <Modal.Body className="p-0">
                <div className="d-none" id="closeFlag">
                    <span
                        onClick={() => {
                            setShowCam(false);
                            setSrcCam('');
                            if (document.querySelector("#closeFlag")) {
                                hideCloseFoto()
                            }
                        }}

                        className="fa fa-close cp f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                        style={{
                            borderRadius: '50%',
                            width: '35px',
                            height: '35px',
                            position: 'absolute',
                            top: '15px',
                            right: '17px',
                            zIndex: '1'
                        }}
                    />

                    <span
                        onClick={flipCamera}
                        className="fa fa-retweet cp f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                        style={{
                            borderRadius: '50%',
                            width: '35px',
                            height: '35px',
                            position: 'absolute',
                            top: '15px',
                            left: '17px',
                            zIndex: '1'
                        }}
                    />
                </div>

                <Camera
                    imageType="jpg"
                    onCameraError={(error) => { handleWarningModal(error); }}
                    isDisplayStartCameraError={false}
                    idealFacingMode={prmFacingMode}
                    isImageMirror={false}
                    idealResolution={{ width: 1920, height: 1440 }}
                    onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                    onCameraStart={() => {
                        document.querySelector("#closeFlag") && showCloseFoto()
                        console.log('start camera');
                    }}
                    onCameraStop={() => { console.log('stop camera'); }}
                    imageCompression={0.3}
                    style={{ width: '100%' }}
                />
            </Modal.Body>
        </Modal>


        {/* Show Src Cam */}
        <Modal
            show={srcCam ? true : false}
            backdrop="static"
            keyboard={false}
            animation={false}
            centered
        >
            <Modal.Body className="p-0">
                <div className="flexJustifyAlignCenter">
                    <span
                        onClick={() => setSrcCam('')}
                        className="fa fa-close cp f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                        style={{
                            borderRadius: '50%',
                            width: '35px',
                            height: '35px',
                            position: 'absolute',
                            top: '15px',
                            right: '17px'
                        }}
                    />

                    <img
                        src={srcCam}
                        alt={srcCam}
                        style={{ width: '100%' }}
                    />
                </div>

                <div className="py-3 px-3">
                    <Button
                        variant="light"
                        size="sm"
                        className="btnXsOverride mr-2"
                        onClick={() => {
                            setSrcCam('');
                            setShowCam(true);
                        }}
                    >
                        Foto Ulang
                    </Button>

                    <ButtonLoading
                        prmLoading={btnLoadingSimpanFoto}
                        btnClass="btnXsOverride px-3"
                        btnVariant="info"
                        btnOnClick={() => handleSimpanFoto(srcCam)}
                        caption="Simpan"
                    />
                </div>
            </Modal.Body>
        </Modal>

        {deko(reduxLogin.syswebappj) === levelUser.owner ?
            <NavbarTopOwner
                shadow={true}
                titleNavTop="Proses Pengerjaan"
                prmBackButton={true}
                prmOnBack={`${callbackUrl ? '/' + handleCallBack(deko(reduxLogin.syswebappj), levelUser, callbackUrl, prefixUrl) : '/' + prefixUrl.owner + '/proses/prosesDua'}`}

            />
            :
            <NavbarTopTeknisi
                shadow={true}
                titleNavTop="Proses Pengerjaan"
                prmBackButton={true}
                prmOnBack={`${callbackUrl ? '/' + handleCallBack(deko(reduxLogin.syswebappj), levelUser, callbackUrl, prefixUrl) : '/' + prefixUrl.teknisi + '/proses/prosesDua'}`}
            />
        }

        <Layout titleTab="Proses Pengerjaan">
            <Row>
                <Col xs={12} className="mt-3">
                    {dataProses && !loadingDataProses &&
                        <React.Fragment>
                            <Card
                                className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                            >
                                <div className="flex">
                                    <div>
                                        <CustomerAvatar
                                            prmSlug={dataProses[0].slug_ck}
                                            prmGender={dataProses[0].gender}
                                            ukuran={{ width: '55px', height: '55px' }}
                                        />
                                    </div>

                                    <div className="ml-3 wordBreakAll" style={{ width: '100%' }}>
                                        <div className="color636363 bold">
                                            {dataProses[0].nama}
                                        </div>

                                        <div className="color808080 f14">
                                            {dataProses[0].nama_ktgr_cust}
                                        </div>

                                        <div className="color808080 f14 mt-2">
                                            {dataProses[0].alamat}
                                        </div>

                                        {deko(reduxLogin.syswebappj) === levelUser.owner &&
                                            <div className="color808080 f14">
                                                {dataProses[0].no_hp}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Card>

                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                                <div className="f14 mb-2">
                                    <div className="color636363 fontweight600 mb-1">No Nota</div>
                                    <div className="color808080">{dataProses[0].id_transaksi}</div>
                                </div>

                                <div className="f14 mb-2">
                                    <div className="color636363 fontweight600 mb-1">Tanggal Masuk</div>
                                    <div className="color808080">{dataProses[0].tanggal_masuk ? sqlDateToLongHumanDate(dataProses[0].tanggal_masuk) : ''}</div>
                                </div>

                                <div className="f14">
                                    <div className="color636363 fontweight600 mb-1">Penerima</div>
                                    <div className="color808080">{penerima}</div>
                                </div>
                            </Card>

                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                                <div className="f14 mb-2">
                                    <div className="color636363 fontweight600 mb-1">
                                        Kategori Barang
                                    </div>
                                    <div className="color808080">{dataProses[0].nama_barang_kategori}</div>
                                </div>

                                <div className="f14 mb-2">
                                    <div className="color636363 fontweight600 mb-1">Barang</div>
                                    <div className="color808080">{dataProses[0].nama_barang}</div>
                                </div>

                                <div className="f14 mb-2">
                                    <div className="color636363 fontweight600">Keluhan</div>
                                    <div className="color808080">{dataProses[0].kerusakan}</div>
                                </div>

                                <div className="f14 mb-3">
                                    <div className="color636363 fontweight600">Foto Barang</div>

                                    {
                                        listFotoTransaksi ?
                                            <div className="text-center mt-2">
                                                <Masonry
                                                    breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 4 : 5) : 5) : 6) : 6}`}
                                                    className="my-masonry-grid"
                                                    columnClassName="my-masonry-grid_column"
                                                >
                                                    {Object.keys(listFotoTransaksi).map((key) => {
                                                        return (
                                                            <img
                                                                key={"lfkp87hjsdk" + key}
                                                                src={ROOT_BASE_URL + listFotoTransaksi[key].foto}
                                                                alt={'foto'}
                                                                style={{ width: '100%' }}
                                                                className="cp rounded-lg"
                                                                onClick={() => {
                                                                    setSrcFoto(ROOT_BASE_URL + listFotoTransaksi[key].foto)
                                                                    setModalZoomImage(true);
                                                                }}
                                                            />
                                                        )
                                                    })}
                                                </Masonry>
                                            </div>
                                            :
                                            <div className="color808080">
                                                Tidak ada
                                            </div>
                                    }
                                </div>

                                <div className="f14 mb-2">
                                    <div className="color636363 fontweight600">
                                        Form Cek Barang {dataKelengkapan && dataKelengkapan[0].nama_kategori}
                                    </div>

                                    {dataKelengkapan &&
                                        <div className="color808080 mt-2">
                                            {dataKelengkapan && Object.keys(dataKelengkapan).map((key) => {
                                                return (
                                                    <React.Fragment key={key + 'kkl7js'}>
                                                        <Form.Row className="mb-2">
                                                            <Form.Group
                                                                as={Col}
                                                                xs="12"
                                                                className="mb-0"
                                                            >
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    custom
                                                                >
                                                                    <Form.Check.Input
                                                                        readOnly
                                                                        disabled
                                                                        type="checkbox"
                                                                        defaultChecked={parseInt(dataKelengkapan[key].check)}
                                                                    />

                                                                    <Form.Check.Label
                                                                        style={{ paddingTop: '2px' }}
                                                                    >
                                                                        {dataKelengkapan[key].nama_tdk}
                                                                    </Form.Check.Label>

                                                                    <div className="mt-1 color808080 f14">
                                                                        {dataKelengkapan[key].keterangan}
                                                                    </div>
                                                                </Form.Check>
                                                            </Form.Group>

                                                        </Form.Row>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    }

                                    {!dataKelengkapan && <div className="color808080">Tidak ada</div>}
                                </div>

                                <div className="f14 mb-2">
                                    <div className="color636363 fontweight600">Uang Muka</div>
                                    <div className="color808080">
                                        {handleUangMuka(dataProses[0].dp)}
                                        {' '}
                                        {handleMetodeUangMuka(dataProses[0].metode_dp)}
                                    </div>
                                </div>

                                <div className="f14">
                                    <div className="color636363 fontweight600">Catatan</div>
                                    <div className="color808080 whiteSpacePreLine">
                                        {dataProses[0].catatan ? dataProses[0].catatan : '-'}
                                    </div>
                                </div>
                            </Card>

                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}
                            >
                                <div className="f14 mb-3 colorTheme fontweight600">
                                    Kelengkapan Barang
                                </div>

                                <div>
                                    <div className="color636363 fontweight600">
                                        Kelengkapan Utama :
                                    </div>
                                    <div className="color808080 mt-1">{dataProses[0].nama_barang}</div>
                                </div>

                                <div className="mt-3">
                                    <div className="color636363 fontweight600 mb-1">
                                        Kelengkapan Lainnya :
                                    </div>

                                    {dataLabel ? Object.keys(dataLabel).map((keyDL) => {
                                        return (
                                            <span
                                                key={keyDL + 'dl_78'}
                                                className={`color808080`}
                                            >
                                                <span>{dataLabel[keyDL].label}</span>
                                                <span>{dataLabel.length === (Number(keyDL) + 1) ? '' : ', '}</span>
                                            </span>
                                        )
                                    })
                                        :
                                        <div className="color808080">Tidak ada</div>
                                    }
                                </div>
                            </Card>

                            <div className="f14 color636363 fontweight600 mt-4 mb-3">
                                Progres Pengerjaan
                            </div>

                            <div rel="containerProgresPengerjaan">
                                {dataProgres !== null ?
                                    <React.Fragment>
                                        {Object.keys(dataProgres).map((key) => {
                                            return (
                                                <Card
                                                    key={key}
                                                    className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3 color636363`}
                                                >
                                                    <div className="flex pb-2 mb-3 borderBottomEee">
                                                        <div
                                                            className="bgTheme colorWhite f10 mr-3 flexJustifyAlignCenter"
                                                            style={{ width: '20px', minWidth: '20px', height: '20px', minHeight: '20px', marginTop: '2px', borderRadius: '50%' }}
                                                        >
                                                            {parseInt(key) + 1}
                                                        </div>

                                                        <div style={{ flex: 1 }}>
                                                            <div className="fontweight600 colorTheme">
                                                                {dataProgres[key]['nama_user']}
                                                            </div>

                                                            <div className="f13 colorAaa">
                                                                {dataProgres[key]['tanggal_progres'] ? dateTimeToHumanDateTime(dataProgres[key]['tanggal_progres']) : ' - '} WIB
                                                            </div>
                                                        </div>

                                                        {dataProgres[key].flag_urutan === '0' &&
                                                            <div
                                                                className="pl-3 flexAlignCenter colorTheme f16 cp"
                                                                onClick={() => {
                                                                    refIdTDP.current = dataProgres[key].id_tdp;
                                                                    setModalHapusProgres(true);
                                                                }}
                                                            >
                                                                <span className="fa fa-trash" />
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="color808080">
                                                        {<div>

                                                            <div className="flex">
                                                                <div className="flex1 whiteSpacePreLine">
                                                                    {dataProgres[key].progres}
                                                                </div>

                                                                {dataProgres[key].flag_urutan === '0' &&
                                                                    <div
                                                                        className="colorTheme pl-3 cp"
                                                                        onClick={() => handleEditProgres(dataProgres[key].id_tdp, dataProgres[key].progres)}
                                                                    >
                                                                        <span className="fa fa-pencil" />
                                                                    </div>
                                                                }
                                                            </div>

                                                            {dataProgres[key].id_tdp in dataPenggunaanStok &&
                                                                <div className="borderTopEee mt-3">
                                                                    <div className="colorTheme mt-3">
                                                                        Penggunaan Part Stok Barang
                                                                    </div>

                                                                    <div className="mt-3">
                                                                        {dataProgres[key].id_tdp in dataPenggunaanStok && Object.keys(dataPenggunaanStok[dataProgres[key].id_tdp].yu).map((keyPS) => {
                                                                            return (
                                                                                <div
                                                                                    key={keyPS}
                                                                                    className={`flex ${Object.keys(dataPenggunaanStok[dataProgres[key].id_tdp].yu).length === (parseInt(keyPS) + 1) ? '' : 'mb-3'}`}
                                                                                >
                                                                                    <div className="mr-2">
                                                                                        {(parseInt(keyPS) + 1) + '. '}
                                                                                    </div>

                                                                                    <div style={{ flex: 1 }}>
                                                                                        <div>
                                                                                            {dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].nama} {' '} {dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].merk} {' '} {dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].tipe} {' '} {dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].serial_number ? 'SN ' + dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].serial_number : 'SN -'}
                                                                                        </div>
                                                                                    </div>

                                                                                    {dataProgres[key].flag_urutan === '0' && <div
                                                                                        className="pl-3 cp"
                                                                                        onClick={() => {
                                                                                            refIdS.current = dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].id_s;

                                                                                            refIdSK.current = dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].id_sk;

                                                                                            refIdTDPS.current = dataPenggunaanStok[dataProgres[key].id_tdp].yu[keyPS].id_tdps;

                                                                                            refIdTDP.current = dataProgres[key].id_tdp;

                                                                                            setModalBatalkanPS(true);
                                                                                        }}
                                                                                    >
                                                                                        <span className="fa fa-close cp colorTheme" />
                                                                                    </div>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }

                                                            {dataProgres[key].flag_urutan === '0' && <div className="flex mt-3">
                                                                <div
                                                                    className="colorTheme f12 whiteSpaceNoWrap brTheme py-1 px-3 cp"
                                                                    style={{ borderRadius: '6px' }}
                                                                    onClick={() => handleGunakanPart(dataProgres[key].id_tdp)}
                                                                >
                                                                    <span className="fa fa-microchip mr-1" />
                                                                    Tambah Part
                                                                </div>
                                                            </div>
                                                            }
                                                        </div>
                                                        }
                                                    </div>


                                                    {dataProgres[key]['b'] !== null && <div className="mt-3 pt-3 borderTopEee">
                                                        <Masonry
                                                            breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 5 : 5) : 5) : 6) : 6}`}
                                                            className="my-masonry-grid"
                                                            columnClassName="my-masonry-grid_column"
                                                        >
                                                            {Object.keys(dataProgres[key]['b']).map((subKey) => {
                                                                return (
                                                                    <img
                                                                        key={subKey}
                                                                        src={ROOT_BASE_URL + dataProgres[key]['b'][subKey].foto}
                                                                        alt={`foto ${subKey}`}
                                                                        style={{ width: '100%', borderRadius: '3px' }}
                                                                        className="cp"

                                                                        onClick={() => {
                                                                            setFotoKelola({
                                                                                foto: ROOT_BASE_URL + dataProgres[key]['b'][subKey].foto,
                                                                                id: dataProgres[key]['b'][subKey].id_tdpf,
                                                                                idTdp: dataProgres[key].id_tdp,
                                                                                prmDel: dataProgres[key].flag_urutan === '0' ? true : false
                                                                            })
                                                                            setModalZoomImageProgres(true);
                                                                        }}
                                                                    />
                                                                )
                                                            })}
                                                        </Masonry>
                                                    </div>
                                                    }

                                                    {
                                                        dataProgres[key].flag_urutan === '0' && <div className="flex flexWrap mt-2 pt-3 borderTopEeeX">
                                                            <div
                                                                className="colorTheme f12 mr-2 whiteSpaceNoWrap brTheme py-1 px-3 mb-2 cp"
                                                                style={{ borderRadius: '6px' }}
                                                                onClick={() => {
                                                                    refIdTDP.current = dataProgres[key].id_tdp;
                                                                    setSrcCam('');
                                                                    setSrcFile('');
                                                                    setShowCam(true);
                                                                    setShowFile(false);
                                                                }}
                                                            >
                                                                <span className="fa fa-camera mr-1" />
                                                                <span>Camera</span>
                                                            </div>

                                                            <div
                                                                className="colorTheme f12 whiteSpaceNoWrap brTheme py-1 px-3 mb-2 cp"
                                                                style={{ borderRadius: '6px' }}
                                                                onClick={() => {
                                                                    refIdTDP.current = dataProgres[key].id_tdp;
                                                                    setSrcCam('');
                                                                    setSrcFile('');
                                                                    setShowFile(true);
                                                                    setShowCam(false);
                                                                }}
                                                            >
                                                                <span className="fa fa-image mr-1" />
                                                                File Foto
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        showFile && dataProgres[key].flag_urutan === '0' &&
                                                        <div
                                                            className="mt-1 mb-2 py-2 px-2 bgDdd"
                                                            style={{ borderRadius: '6px' }}
                                                        >
                                                            <Form.File id={`foto-file-${key}`} custom>
                                                                <Form.File.Input
                                                                    onChange={(e) => {
                                                                        changeNameFileLabel("#foto-file-" + key, "#label-foto-" + key);
                                                                        setSrcFile(e.target.files[0])
                                                                    }}
                                                                />

                                                                <Form.File.Label data-browse="Cari Foto" id={`label-foto-${key}`}>
                                                                    Foto
                                                                </Form.File.Label>
                                                            </Form.File>

                                                            <div>
                                                                <Button
                                                                    variant="light"
                                                                    size="sm"
                                                                    className="btnXsOverride mt-3 mr-3"
                                                                    onClick={() => {
                                                                        setSrcFile('');
                                                                        setShowFile(false);
                                                                    }}
                                                                >
                                                                    Close
                                                                </Button>

                                                                <ButtonLoading
                                                                    prmLoading={btnLoadingUploadFile}
                                                                    btnClass="btnXsOverride mt-3"
                                                                    btnVariant="info"
                                                                    btnOnClick={handleUploadFileFoto}
                                                                    caption="Upload"
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        dataProgres[key].flag_urutan === '0' &&
                                                        <div className="mt-2 text-center pt-3 borderTopEee">
                                                            <Button
                                                                className="px-3 btnXsOverride"
                                                                variant="info"
                                                                size="sm"
                                                                onClick={() => {
                                                                    refIdTDP.current = dataProgres[key].id_tdp;
                                                                    setModalPostingProgres(true);
                                                                }}
                                                            >
                                                                Posting Progres
                                                            </Button>
                                                        </div>
                                                    }
                                                </Card>
                                            )
                                        })}

                                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3 color636363`}>

                                            <div className="flex mb-3">
                                                <div
                                                    className="bgTheme colorWhite f10 mr-3 flexJustifyAlignCenter"
                                                    style={{ width: '20px', height: '20px', marginTop: '2px', borderRadius: '50%' }}
                                                >
                                                    {Object.keys(dataProgres).length + 1}
                                                </div>

                                                <div className="color808080">
                                                    Tambah progres oleh {deko(reduxLogin.syswebappn)}
                                                </div>
                                            </div>

                                            <div
                                                style={{ width: '100%' }}
                                                className="color808080 mr-3"
                                            >
                                                <Form.Control
                                                    readOnly
                                                    as="textarea"
                                                    size="sm"
                                                    rows={3}
                                                    placeholder="Progres / Keterangan"
                                                    className="inputOverride"
                                                    value={inputProgres}
                                                    // onChange={(e) => setInputProgres(e.target.value)}
                                                    onClick={() => setModalNewProgres(true)}
                                                />
                                            </div>

                                            <div className="mt-2 f12 colorAaa">
                                                Foto dan part barang dapat ditambahkan setelah menyimpan progres / keterangan.
                                            </div>
                                        </Card>

                                        <div className="width100 mt-4">
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                className="width100"
                                                onClick={() => setModalSubmit(true)}
                                            >
                                                Selesai Dikerjakan &amp; Lanjut Q.C.
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <div className="color808080 mt-3">
                                        Belum ada progres pengerjaan
                                    </div>
                                }
                            </div>
                        </React.Fragment>
                    }

                    {
                        dataProses && !loadingDataProses && deko(reduxLogin.syswebappj) === levelUser.owner &&
                        <div className="my-3 borderTopEee">

                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mt-3 color636363`}>

                                <div className="fontweightBold colorSoftRed">Cancel Pengerjaan</div>

                                <div className="alert alert-danger f13 mt-3">
                                    Membatalkan proses ini akan menghapus project transaksi ini sehingga proses tidak akan dapat dilanjutkan.
                                </div>

                                <div>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        className="btnXsOverride"
                                        onClick={() => setModalCancel(true)}
                                    >
                                        Ya, Cancel
                                    </Button>
                                </div>

                                {dataPenggunaanStok && Object.keys(dataPenggunaanStok).length > 0 && dataUsedStok &&
                                    <div>
                                        <div className="mt-3 pt-3 borderTopEee">
                                            <div className="alert alert-warning f13">
                                                <span className="fa fa-exclamation-circle" /> Terdapat penggunaan stok yang harus dibatalkan terlebih dahulu.
                                                <br />
                                                Stok yang dibatalkan akan dikembalikan ke daftar stok.
                                            </div>
                                        </div>

                                        {Object.keys(dataPenggunaanStok).map((key) => {
                                            return (
                                                <div key={key + 'dus_9789'}>
                                                    {dataPenggunaanStok[key].yu && Object.keys(dataPenggunaanStok[key].yu).map((subKey) => {
                                                        return (
                                                            <Card
                                                                key={subKey + 'skdus_9789'}
                                                                className="mt-3 py-2 px-3 brSoftMedium shadow-sm"
                                                            >
                                                                <div>
                                                                    {dataPenggunaanStok[key].yu[subKey].nama + ' ' + dataPenggunaanStok[key].yu[subKey].merk + ' ' + dataPenggunaanStok[key].yu[subKey].tipe}
                                                                </div>

                                                                <div className="">
                                                                    <span
                                                                        className="cp colorTheme"
                                                                        onClick={() => {
                                                                            refIdS.current = dataPenggunaanStok[key].yu[subKey].id_s;

                                                                            refIdSK.current = dataPenggunaanStok[key].yu[subKey].id_sk;

                                                                            refIdTDPS.current = dataPenggunaanStok[key].yu[subKey].id_tdps;

                                                                            refIdTDP.current = dataPenggunaanStok[key].yu[subKey].id_p;

                                                                            setModalBatalkanPS(true);
                                                                        }}
                                                                    >
                                                                        Batalkan
                                                                    </span>
                                                                </div>
                                                            </Card>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </Card>
                        </div>
                    }

                    {
                        !dataProses && loadingDataProses && <PlaceHolderElement1 prmMT="" prmPX="" />
                    }

                    {
                        !dataProses && !loadingDataProses &&
                        <Card className={`${reduxWindowWidth > batasFluid ? 'p-5' : 'p-4'} shadow-sm brSoftMedium text-center f13`}>
                            <div className="f14 mb-3 color808080">
                                Tidak ada data
                            </div>

                            <div className="flexJustifyAlignCenter">
                                <Image src={soldering} style={{ width: '75%' }} />
                            </div>
                        </Card>
                    }
                </Col>
            </Row>
        </Layout>

        <Modal
            size="lg"
            show={modalZoomImage}
            backdrop="static"
            keyboard={false}
            animation={false}
            centered
        >
            <Modal.Body className="p-0">
                <div className="flexJustifyAlignCenter">
                    <span
                        onClick={() => setModalZoomImage(false)}
                        className="fa fa-close cp f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                        style={{
                            borderRadius: '50%',
                            width: '35px',
                            height: '35px',
                            position: 'absolute',
                            top: '15px',
                            right: '17px'
                        }}
                    />

                    <img src={srcFoto} alt='foto' style={{ width: '100%' }} />
                </div>
            </Modal.Body>
        </Modal>



        <Modal
            size="lg"
            show={modalZoomImageProgres}
            backdrop="static"
            keyboard={false}
            animation={false}
            centered
        >
            <Modal.Body className="p-0">
                <span
                    onClick={() => setModalZoomImageProgres(false)}
                    className="fa fa-close cp f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                    style={{
                        borderRadius: '50%',
                        width: '35px',
                        height: '35px',
                        position: 'absolute',
                        top: '15px',
                        right: '17px'
                    }}
                />

                {fotoKelola.prmDel &&
                    <span
                        onClick={() => handleHapusFotoProgres()}
                        className="fa fa-trash cp f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                        style={{
                            borderRadius: '50%',
                            width: '35px',
                            height: '35px',
                            position: 'absolute',
                            top: '15px',
                            left: '17px',
                            zIndex: '1'
                        }}
                    />
                }

                {btnLoadingHapusFoto &&
                    <span
                        className="f12 colorBlack bgWhite px-2 py-1 rounded-sm"
                        style={{
                            position: 'absolute',
                            top: '18px',
                            left: '60px',
                            zIndex: '1'
                        }}
                    >
                        Loading..
                    </span>
                }

                <img src={fotoKelola.foto} alt='foto' style={{ width: '100%' }} />
            </Modal.Body>
        </Modal>



        <Modal
            show={modalEditProgres}
            backdrop="static"
            keyboard={false}
            animation={false}
            centered
        >
            <Modal.Body>
                <div className="f16 mb-4 fontweight600">Progres Pengerjaan</div>

                <Form.Group className="mb-0">
                    <Form.Control
                        as="textarea"
                        rows={4}
                        size="sm"
                        placeholder="Progres"
                        defaultValue={refEditProgres.current}
                        ref={refEditProgres}
                    />
                </Form.Group>

                <div className="flexJustifyAlignCenter mt-4">
                    <Button
                        variant="light"
                        size="sm"
                        className="px-3"
                        onClick={() => setModalEditProgres(false)}
                    >
                        Close
                    </Button>

                    <ButtonLoading
                        prmLoading={btnLoadingEditProgres}
                        btnClass="ml-3 px-3"
                        btnVariant="info"
                        btnOnClick={handleSimpanEditProgres}
                        caption="Simpan"
                    />
                </div>
            </Modal.Body>
        </Modal>


        <ModalConfirmation
            showModal={modalBatalkanPS}
            hideModal={() => setModalBatalkanPS(false)}
            title="Konfirmasi"
            bodyCaption="Batalkan Penggunaan Stok Barang ?"
            btnCloseClass="px-4"
            btnCloseCaption="Close"
            bLPrm={btnLoadingBatalkanPS}
            bLBtnClass="ml-3"
            bLOnClick={handleBatalkanPS}
            bLCaption="Ya, Batalkan Penggunaan"
        />

        <ModalConfirmation
            showModal={modalHapusProgres}
            hideModal={() => setModalHapusProgres(false)}
            title="Konfirmasi"
            bodyCaption="Hapus progres ini ?"
            btnCloseClass="px-4"
            btnCloseCaption="Close"
            bLPrm={btnLoadingHapusProgres}
            bLBtnClass="ml-3"
            bLOnClick={handleHapusProgres}
            bLCaption="Hapus Progres"
        />

        <ModalConfirmation
            showModal={modalPostingProgres}
            hideModal={() => setModalPostingProgres(false)}
            title="Konfirmasi"
            bodyCaption={<React.Fragment><div className="alert alert-info"><span className="fa fa-exclamation-circle" /> Setelah progres di posting, progres tidak dapat di edit atau dihapus.</div></React.Fragment>}
            btnCloseClass="px-4"
            btnCloseCaption="Close"
            bLPrm={btnLoadingPostingProgres}
            bLBtnClass="ml-3"
            bLOnClick={handlePostingProgres}
            bLCaption="Posting Progres"
        />

        <ModalConfirmation
            showModal={modalSubmit}
            hideModal={() => setModalSubmit(false)}
            title="Konfirmasi"
            bodyCaption={<React.Fragment><div className="alert alert-info"><span className="fa fa-exclamation-circle" /> Proses pengerjaan yang telah selesai akan dilanjutkan ke proses Q.C.<br /><br />Lanjut untuk proses Q.C. ?</div></React.Fragment>}
            btnCloseClass="px-4"
            btnCloseCaption="Close"
            bLPrm={btnLoadingFinishProgres}
            bLBtnClass="ml-3"
            bLOnClick={handleFinishProgres}
            bLCaption="Ya, Lanjutkan"
            blVariant="info"
        />

        <Modal
            show={modalCancel}
            backdrop="static"
            keyboard={false}
            animation={false}
            centered
        >
            <Modal.Body>
                <div className="f16 mb-4 fontweight600">
                    <span className="fa fa-exclamation-circle mr-1" /> Konfirmasi
                </div>

                <div className="mb-3">
                    <Form.Control
                        as="textarea"
                        rows={3}
                        size="sm"
                        placeholder="Alasan Cancel"
                        ref={refAlasanCancel}
                        value={alasanCancel}
                        onChange={(e) => setAlasanCancel(e.target.value)}
                    />
                </div>

                <div className="alert alert-danger">
                    Perhatian!. Membatalkan proses ini akan menghapus project transaksi ini sehingga proses tidak akan dapat dilanjutkan.
                    <br /><br />
                    Lanjutkan cancel proses ?
                </div>

                <div className="flexJustifyAlignCenter mt-4">
                    <Button
                        variant="light"
                        size="sm"
                        className="px-3"
                        onClick={() => setModalCancel(false)}
                    >
                        Close
                    </Button>

                    <ButtonLoading
                        prmLoading={btnLoadingCancel}
                        btnClass="ml-3"
                        btnVariant="danger"
                        btnOnClick={handleCancel}
                        caption="Ya, Cancel"
                    />
                </div>
            </Modal.Body>
        </Modal>



        <Modal
            show={modalBerhasilCancel}
            backdrop="static"
            keyboard={false}
            animation={false}
            centered
        >
            <Modal.Body>
                <div className="mt-3 mb-4 text-center color636363 fontweight500">
                    Project berhasil di cancel
                </div>

                <div className="text-center">
                    <span className="fa fa-check-circle colorTheme f50" style={{ color: '#69ceb8' }} />
                </div>

                <div className="flexJustifyAlignCenter mt-4">
                    <Button
                        variant="light"
                        size="sm"
                        className="px-3"
                        onClick={handleBerhasliCancelProject}
                    >
                        Close
                    </Button>
                </div>
            </Modal.Body>
        </Modal>


        <Modal
            show={modalNewProgres}
            onShow={() => refNewProgres.current.focus()}
            backdrop="static"
            keyboard={false}
            animation={false}
            centered
        >
            <Modal.Body>
                <div className="f16 color636363 fontweight600 mb-3">Tambah Progres</div>

                <div>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        size="sm"
                        placeholder="Tambah Progres / Keterangan"
                        value={inputProgres}
                        ref={refNewProgres}
                        onChange={(e) => setInputProgres(e.target.value)}
                    />
                </div>

                <div className="mt-4 flexJustifyAlignCenter">
                    <Button
                        variant="light"
                        size="sm"
                        className="px-3"
                        onClick={() => setModalNewProgres(false)}
                    >
                        Close
                    </Button>

                    <ButtonLoading
                        prmLoading={btnLoadingTambahProgres}
                        btnClass="ml-3 px-3"
                        btnVariant="info"
                        btnOnClick={handleSimpanProgres}
                        caption="Simpan"
                    />
                </div>
            </Modal.Body>
        </Modal>

    </React.Fragment>
    );
}

export default ProsesPengerjaan;
