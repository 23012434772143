export const deko = prm => {
    return window.atob(prm);
}

const twoDigits = prm => {
    const leng = prm.toString().length
    return leng < 2 ? '0' + prm : prm
}

const threeDigits = prm => {
    const leng = prm.toString().length

    if (leng === 1) {
        return '00' + prm
    } else if (leng === 2) {
        return '0' + prm
    } else {
        return prm
    }
}

export const getTime = () => {
    const nD = new Date();

    let year = nD.getFullYear();
    let month = nD.getMonth() + 1;
    let date = nD.getDate();
    let hour = nD.getHours();
    let mnt = nD.getMinutes();
    let sec = nD.getSeconds();
    let ms = nD.getMilliseconds();

    return year + twoDigits(month) + twoDigits(date) + twoDigits(hour) + twoDigits(mnt) + twoDigits(sec) + threeDigits(ms)
}

export const getTimeYmdh = () => {
    const nD = new Date();

    let year = nD.getFullYear();
    let month = nD.getMonth() + 1;
    let date = nD.getDate();
    let hour = nD.getHours();

    return year + twoDigits(month) + twoDigits(date) + twoDigits(hour)
}

export const getDateYm = () => {
    const nD = new Date();

    let year = nD.getFullYear();
    let month = nD.getMonth() + 1;

    return year + '-' + twoDigits(month)
}

export const sliceIsoDateToDateYm = (prmDate) => {
    let year = prmDate.getFullYear();
    let month = prmDate.getMonth() + 1;

    return year + '-' + twoDigits(month)
}

export const isoDateToHumanDate = (prmDate) => {
    let varDate = new Date(Date.parse(prmDate));

    const bulan = new Array(12);
    bulan[0] = "Januari";
    bulan[1] = "Februari";
    bulan[2] = "Maret";
    bulan[3] = "April";
    bulan[4] = "Mei";
    bulan[5] = "Juni";
    bulan[6] = "Juli";
    bulan[7] = "Agustus";
    bulan[8] = "September";
    bulan[9] = "Oktober";
    bulan[10] = "November";
    bulan[11] = "Desember";

    return varDate.getDate() + ' ' + bulan[varDate.getMonth()] + ' ' + varDate.getFullYear()
}

export const isoDateToShortHumanDate = (prmDate) => {
    let varDate = new Date(Date.parse(prmDate));

    const bulan = new Array(12);
    bulan[0] = "Jan";
    bulan[1] = "Feb";
    bulan[2] = "Mar";
    bulan[3] = "Apr";
    bulan[4] = "Mei";
    bulan[5] = "Jun";
    bulan[6] = "Jul";
    bulan[7] = "Agt";
    bulan[8] = "Sep";
    bulan[9] = "Okt";
    bulan[10] = "Nov";
    bulan[11] = "Des";

    return varDate.getDate() + ' ' + bulan[varDate.getMonth()] + ' ' + varDate.getFullYear()
}

export const isoDateToLongHumanDate = (prmDate) => {
    let varDate = new Date(Date.parse(prmDate));

    const weekday = new Array(7);
    weekday[0] = "Minggu";
    weekday[1] = "Senin";
    weekday[2] = "Selasa";
    weekday[3] = "Rabu";
    weekday[4] = "Kamis";
    weekday[5] = "Jumat";
    weekday[6] = "Sabtu";

    const bulan = new Array(12);
    bulan[0] = "Januari";
    bulan[1] = "Februari";
    bulan[2] = "Maret";
    bulan[3] = "April";
    bulan[4] = "Mei";
    bulan[5] = "Juni";
    bulan[6] = "Juli";
    bulan[7] = "Agustus";
    bulan[8] = "September";
    bulan[9] = "Oktober";
    bulan[10] = "November";
    bulan[11] = "Desember";

    return weekday[varDate.getDay()] + ', ' + varDate.getDate() + ' ' + bulan[varDate.getMonth()] + ' ' + varDate.getFullYear()
}

export const isoDateToSqlDate = (prmDate) => {
    let varDate = new Date(Date.parse(prmDate));

    return varDate.getFullYear() + '-' + twoDigits(varDate.getMonth() + 1) + '-' + twoDigits(varDate.getDate())
}

export const sqlDateToYmDate = (prm_tgl) => {
    let split_tgl = prm_tgl.split('-');

    let tahun = split_tgl[0];
    let bulan = twoDigits(split_tgl[1]);

    if (bulan === '01') { bulan = 'Januari'; }
    else if (bulan === '02') { bulan = 'Februari'; }
    else if (bulan === '03') { bulan = 'Maret'; }
    else if (bulan === '04') { bulan = 'April'; }
    else if (bulan === '05') { bulan = 'Mei'; }
    else if (bulan === '06') { bulan = 'Juni'; }
    else if (bulan === '07') { bulan = 'Juli'; }
    else if (bulan === '08') { bulan = 'Agustus'; }
    else if (bulan === '09') { bulan = 'September'; }
    else if (bulan === '10') { bulan = 'Oktober'; }
    else if (bulan === '11') { bulan = 'November'; }
    else if (bulan === '12') { bulan = 'Desember'; }
    else { bulan = 'Bulan'; }

    return bulan + ' ' + tahun;
}

export const sqlDateToSqlYm = (prm_tgl) => {
    let split_tgl = prm_tgl.split('-');

    let tahun = split_tgl[0];
    let bulan = twoDigits(split_tgl[1]);

    return tahun + '-' + bulan;
}

export const sqlDateToLongHumanDate = (prm_tgl) => {
    let split_tgl = prm_tgl.split('-');

    let tahun = split_tgl[0];
    let bulan = twoDigits(split_tgl[1]);
    let tanggal = split_tgl[2];

    if (bulan === '01') { bulan = 'Januari'; }
    else if (bulan === '02') { bulan = 'Februari'; }
    else if (bulan === '03') { bulan = 'Maret'; }
    else if (bulan === '04') { bulan = 'April'; }
    else if (bulan === '05') { bulan = 'Mei'; }
    else if (bulan === '06') { bulan = 'Juni'; }
    else if (bulan === '07') { bulan = 'Juli'; }
    else if (bulan === '08') { bulan = 'Agustus'; }
    else if (bulan === '09') { bulan = 'September'; }
    else if (bulan === '10') { bulan = 'Oktober'; }
    else if (bulan === '11') { bulan = 'November'; }
    else if (bulan === '12') { bulan = 'Desember'; }
    else { bulan = 'Bulan'; }


    let current_datetime = new Date(prm_tgl);
    let formated_date = current_datetime.getDay();
    let formated_date_string = formated_date.toString();
    let hari = '';
    if (formated_date_string === '0') { hari = 'Minggu'; }
    else if (formated_date_string === '1') { hari = 'Senin'; }
    else if (formated_date_string === '2') { hari = 'Selasa'; }
    else if (formated_date_string === '3') { hari = 'Rabu'; }
    else if (formated_date_string === '4') { hari = 'Kamis'; }
    else if (formated_date_string === '5') { hari = 'Jumat'; }
    else if (formated_date_string === '6') { hari = 'Sabtu'; }

    return hari + ', ' + tanggal + ' ' + bulan + ' ' + tahun;
}

export const sqlDateToHumanDate = (prm_tgl) => {
    let split_tgl = prm_tgl.split('-');

    let tahun = split_tgl[0];
    let bulan = twoDigits(split_tgl[1]);
    let tanggal = split_tgl[2];

    if (bulan === '01') { bulan = 'Januari'; }
    else if (bulan === '02') { bulan = 'Februari'; }
    else if (bulan === '03') { bulan = 'Maret'; }
    else if (bulan === '04') { bulan = 'April'; }
    else if (bulan === '05') { bulan = 'Mei'; }
    else if (bulan === '06') { bulan = 'Juni'; }
    else if (bulan === '07') { bulan = 'Juli'; }
    else if (bulan === '08') { bulan = 'Agustus'; }
    else if (bulan === '09') { bulan = 'September'; }
    else if (bulan === '10') { bulan = 'Oktober'; }
    else if (bulan === '11') { bulan = 'November'; }
    else if (bulan === '12') { bulan = 'Desember'; }
    else { bulan = 'Bulan'; }

    return tanggal + ' ' + bulan + ' ' + tahun;
}

export const sqlYmDateToTahunBulan = (yM) => {
    const numBulan = yM.substr(-2);
    let result = ' Month ';

    if (numBulan === '01') { result = 'Januari'; }
    else if (numBulan === '02') { result = 'Februari'; }
    else if (numBulan === '03') { result = 'Maret'; }
    else if (numBulan === '04') { result = 'April'; }
    else if (numBulan === '05') { result = 'Mei'; }
    else if (numBulan === '06') { result = 'Juni'; }
    else if (numBulan === '07') { result = 'Juli'; }
    else if (numBulan === '08') { result = 'Agustus'; }
    else if (numBulan === '09') { result = 'September'; }
    else if (numBulan === '10') { result = 'Oktober'; }
    else if (numBulan === '11') { result = 'November'; }
    else if (numBulan === '12') { result = 'Desember'; }

    return result + ' ' + yM.substr(0, 4);
} // end func

export const dateTimeToHumanDateTime = (tgl) => {
    const split_tgl = tgl.split(' ');
    const split_jam = (split_tgl[1]).split(':');
    return sqlDateToLongHumanDate(split_tgl[0]) + ' ' + split_jam[0] + ':' + split_jam[1];
}

export const idMaker = () => {
    const nD = new Date();

    let year = nD.getFullYear();
    let month = nD.getMonth() + 1;
    let date = nD.getDate();
    let hour = nD.getHours();
    let mnt = nD.getMinutes();
    let sec = nD.getSeconds();
    let ms = nD.getMilliseconds();

    return '8' + twoDigits(sec) + twoDigits(month) + twoDigits(mnt) + twoDigits(date) + twoDigits(hour) + year.toString().slice(-3) + threeDigits(ms)
}

export const VFCannotNull = (prm) => {
    if (!prm.trim()) {
        return true
    } else {
        return false
    }
}

export const VFNotNumber = (prm) => {
    if (isNaN(prm)) {
        return true
    } else {
        return false
    }
}

export const removeStrip = (prm) => {
    return prm.replace(/[\s-]/g, "");
}

export const VFNoHpFormat = (prm) => {
    // let rgx = new RegExp("^08[0-9]{8,11}$");

    let rgx = new RegExp(/^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/g);

    if (prm && !rgx.test(prm)) {
        return true;
    } else {
        return false;
    }
}

export const cutStringWidthWindowThreeDots = (prmReduxWindowWidth, prmBatasFluid, prmString, limitString) => {
    return (prmReduxWindowWidth < prmBatasFluid ? (prmString.length > limitString ? prmString.substring(0, limitString) + '...' : prmString) : prmString)
}

export const formatNumber = (prm) => {
    const n = String(prm);
    const formatCurrency = n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const result = parseInt(formatCurrency.charAt(0)) === 0 ? formatCurrency.replace(/^.{1}/g, '') : formatCurrency;

    return result;
}

export const formatNumberCanZero = (prm) => {
    const n = String(prm);
    const formatCurrency = n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const result = parseInt(formatCurrency.charAt(0)) === 0 ? '0' : formatCurrency;

    return result;
}

export const formatNumberCanZeroMinus = (prm) => {
    const cekMinus = Number(prm) < 0 ? true : false;

    const n = cekMinus ? String(Number(prm) * -1) : String(prm);
    const formatCurrency = n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const result = parseInt(formatCurrency.charAt(0)) === 0 ? '0' : formatCurrency;

    return cekMinus ? '-' + result : result;
}

export const handleCallBack = (otjbt, levelUser, callbackUrl, prefixUrl) => {
    let callbackUrlModify = callbackUrl;

    if (otjbt === levelUser.owner) {
        if (callbackUrl === 'penilaiankaryawan') {
            callbackUrlModify = prefixUrl.owner + '/penilaiankaryawan';
        } else if (callbackUrl === 'pencariancepat') {
            callbackUrlModify = 'pencariancepat';
        } else if (callbackUrl === 'home') {
            callbackUrlModify = '';
        } else if (callbackUrl === 'cekserialnumbern') {
            callbackUrlModify = 'cekserialnumber';
        } else if (callbackUrl === 'cekserialnumbery') {
            callbackUrlModify = prefixUrl.owner + '/cekserialnumber';
        } else if (callbackUrl === 'report') {
            callbackUrlModify = prefixUrl.owner + '/report';
        } else {
            callbackUrlModify = callbackUrl;
        }
    }

    if (otjbt === levelUser.teknisi) {
        if (callbackUrl === 'home') {
            callbackUrlModify = '';
        } else if (callbackUrl === 'pencariancepat') {
            callbackUrlModify = 'pencariancepat';
        } else {
            callbackUrlModify = callbackUrl;
        }
    }

    return callbackUrlModify;
}