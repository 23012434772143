import React from 'react';
import "../../styles/dotMenu.scss";

const DotMenu = ({ bgDot }) => {
    return (
        <div className="containerLuarDotMenu">
            <div className="containerDotMenu">
                <span className={`${bgDot} dotMenu topLeft`} />
                {/* <span className={`${bgDot} dotMenu topRight`} /> */}
                <span className={`${bgDot} dotMenu bottomLeft`} />
                <span className={`${bgDot} dotMenu bottomRight`} />
            </div>
        </div>
    )
}

export default DotMenu