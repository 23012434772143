import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ModalAlertWarning = ({ showWarningModal, hideWarningModal, paramText }) => {
    return (
        <Modal centered size="sm" animation={false} show={showWarningModal} onHide={hideWarningModal}>
            <Modal.Body>
                <div>

                    <div className="mb-3">
                        <div className="f16 bold">Informasi</div>
                    </div>

                    <div className="f14 mb-4">{paramText}</div>

                    <div className="text-center">
                        <Button
                            variant="light"
                            size="sm"
                            onClick={hideWarningModal}
                            className="px-4"
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    );
}

export default ModalAlertWarning