import React from 'react';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { Row, Col, Card, Image, Modal, Button } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import Masonry from 'react-masonry-css';
import { PDFDownloadLink, Page, Text, View, Document, Image as ImagePdf } from '@react-pdf/renderer';
import QrcodeReact from 'jsbarcode';

import { ROOT_BASE_URL, BASE_URL_DUA, batasFluid, levelUser } from "../../utils/globalVar";
import { sqlDateToHumanDate, deko } from "../../utils/globalFunc";
import CustomerAvatar from "../../components/CustomerAvatar";
import soldering from "../../assets/images/system/soldering.png";
import Layout from "../../components/Layout";
import NavbarTopOwner from "../../components/NavbarTop";
import NavbarTopTeknisi from "../../components/teknisi/NavbarTop";
import ModalAlertWarning from "../../components/ModalAlertWarning";
import PlaceHolderElement1 from "../../components/PlaceHolderElement1";

const CetakLabel = () => {

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    const parameter = useParams();
    const idTransaksi = parameter.id;

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [dataCetakLabel, setDataCetakLabel] = React.useState(null);
    const [flagDataCetakLabel, setFlagDataCetakLabel] = React.useState(null);
    const [loadingDataCetakLabel, setLoadingDataCetakLabel] = React.useState(false);
    const [dataLabel, setDataLabel] = React.useState(null);

    const [listFotoTransaksi, setListFotoTransaksi] = React.useState(null);
    const [modalZoomImage, setModalZoomImage] = React.useState(false);
    const [srcFoto, setSrcFoto] = React.useState('');

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [])

    React.useEffect(() => {
        setLoadingDataCetakLabel(true)

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/data_cetak_label',
            data: {
                id_transaksi: idTransaksi,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingDataCetakLabel(false);

            if (response.data.flag_hasil === true) {
                setDataCetakLabel(response.data.data_transaksi);
                setDataLabel(response.data.data_label);
                setListFotoTransaksi(response.data.data_foto);
                setFlagDataCetakLabel(true);
            }

            if (response.data.flag_hasil === false) {
                setFlagDataCetakLabel(false);
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingDataCetakLabel(false)
            handleWarningModal('Transaksi Warning : ' + error.message)
        })
    }, [idTransaksi, reduxLogin.syswebappi])

    const buatQr = (prm) => {
        let canvas;

        canvas = document.createElement('canvas');
        QrcodeReact(canvas, prm, {
            // width: 4,
            height: 40,
            displayValue: false
        });
        const qr = canvas.toDataURL();
        return qr;
    }

    const PdfLabel = () => {
        return (
            <Document>
                <Page size={164}>
                    {
                        dataLabel && dataCetakLabel && Object.keys(dataLabel).map((keyIndukLbl) => {
                            return (
                                <View
                                    key={keyIndukLbl}
                                    style={{
                                        borderBottom: '1px solid #000',
                                        padding: '4px 4px 6px 4px',
                                        fontSize: '11px',
                                        marginBottom: dataLabel.length === (Number(keyIndukLbl) + 1) ? '' : '4px'
                                    }}
                                >
                                    <View>
                                        <View style={{
                                            marginBottom: '4px',
                                        }}>
                                            <Text
                                                style={{
                                                    backgroundColor: parseInt(dataLabel[keyIndukLbl].utama) === 1 ? '#000' : '#fff',
                                                    color: parseInt(dataLabel[keyIndukLbl].utama) === 1 ? '#fff' : '#000',
                                                    padding: parseInt(dataLabel[keyIndukLbl].utama) === 1 ? '2px' : '0px'
                                                }}
                                            >
                                                {dataCetakLabel[0].nama}
                                            </Text>
                                        </View>

                                        <View style={{ marginBottom: '4px' }}>
                                            <Text>- {dataLabel[keyIndukLbl].label} {`(${Number(keyIndukLbl) + 1}/${dataLabel.length})`}</Text>
                                        </View>

                                        {dataLabel.length > 1 && parseInt(dataLabel[keyIndukLbl].utama) === 1 &&
                                            <View style={{ marginBottom: '4px' }}>
                                                <View>
                                                    <Text> Semua : {Object.keys(dataLabel).map((subKeyLbl) => {
                                                        return (
                                                            <Text key={subKeyLbl + 'adjkshdkj78879'}>
                                                                {dataLabel[subKeyLbl].label + '' + (dataLabel.length === (Number(subKeyLbl) + 1) ? '' : ', ')}
                                                            </Text>
                                                        )
                                                    })}
                                                    </Text>
                                                </View>

                                                <View style={{ marginTop: '4px' }}>
                                                    <Text>
                                                        Tanggal Masuk : {dataCetakLabel[0].tanggal_masuk ? sqlDateToHumanDate(dataCetakLabel[0].tanggal_masuk) : ' - '}
                                                    </Text>
                                                </View>
                                            </View>
                                        }

                                        {parseInt(dataLabel[keyIndukLbl].utama) === 1 &&
                                            <View style={{ marginBottom: '4px' }}>
                                                <View>
                                                    <Text>Kerusakan : {dataCetakLabel[0].kerusakan}</Text>
                                                </View>
                                            </View>
                                        }

                                        <View>
                                            <ImagePdf src={buatQr(dataCetakLabel[0].id_transaksi)} />
                                        </View>

                                        <View style={{ textAlign: 'center' }}>
                                            <Text>{dataLabel[keyIndukLbl].id_transaksi}</Text>
                                        </View>
                                    </View>

                                    {dataLabel.length === 1 &&
                                        <View style={{ height: '50px' }}>
                                            <Text>&nbsp;</Text>
                                        </View>
                                    }
                                </View>
                            )
                        })
                    }
                </Page>
            </Document>
        )
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            {deko(reduxLogin.syswebappj) === levelUser.owner &&
                <NavbarTopOwner
                    shadow={true}
                    titleNavTop="Cetak Label"
                    prmBackButton={true}
                />
            }

            {deko(reduxLogin.syswebappj) === levelUser.teknisi &&
                <NavbarTopTeknisi
                    shadow={true}
                    titleNavTop="Cetak Label"
                    prmBackButton={true}
                />
            }

            <Layout titleTab="Cetak Label">
                <Row>
                    <Col xs={12} className="mt-3 px-3">
                        {
                            dataCetakLabel && !loadingDataCetakLabel &&
                            <div>
                                <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3`}>
                                    <div className="flex">
                                        <div>
                                            <CustomerAvatar
                                                prmSlug={dataCetakLabel[0].slug_ck}
                                                prmGender={dataCetakLabel[0].gender}
                                                ukuran={{ width: '40px', height: '40px' }}
                                            />
                                        </div>

                                        <div className="ml-3 color636363">
                                            <div className="bold">{dataCetakLabel[0].nama}</div>
                                            <div className="f13">{dataCetakLabel[0].nama_ktgr_cust}</div>
                                            <div className="f13 mt-2">{dataCetakLabel[0].alamat}</div>
                                            {deko(reduxLogin.syswebappj) === levelUser.owner &&
                                                <div className="f13">{dataCetakLabel[0].no_hp}</div>
                                            }
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="color636363">
                                        <div>
                                            <div className="fontweightBold mb-1">No Nota</div>
                                            <div className="f13">{dataCetakLabel[0].id_transaksi}</div>
                                        </div>

                                        <div className="mt-3">
                                            <div className="fontweightBold mb-1">Barang</div>
                                            <div className="f13">{dataCetakLabel[0].nama_barang}</div>
                                        </div>

                                        <div className="mt-3">
                                            <div className="fontweightBold mb-1">Keluhan</div>
                                            <div className="f13">{dataCetakLabel[0].kerusakan}</div>
                                        </div>

                                        <div className="mt-3">
                                            <div className="fontweightBold">Foto Barang</div>
                                            {listFotoTransaksi ?
                                                <div className="text-center mt-2">
                                                    <Masonry
                                                        breakpointCols={3}
                                                        className="my-masonry-grid"
                                                        columnClassName="my-masonry-grid_column"
                                                    >
                                                        {Object.keys(listFotoTransaksi).map((key) => {
                                                            return (
                                                                <img
                                                                    key={"lfcl987ashd" + key}
                                                                    src={ROOT_BASE_URL + listFotoTransaksi[key].foto}
                                                                    alt={'foto'}
                                                                    style={{ width: '100%' }}
                                                                    onClick={() => {
                                                                        setSrcFoto(ROOT_BASE_URL + listFotoTransaksi[key].foto)
                                                                        setModalZoomImage(true);
                                                                    }}
                                                                />
                                                            )
                                                        })}
                                                    </Masonry>
                                                </div>
                                                :
                                                <div className="color808080">
                                                    Tidak ada data foto
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Card>

                                <Row>
                                    <Col xs={12} className="mb-3 text-center bold color636363">
                                        <div>Kelengkapan Barang</div>
                                    </Col>

                                    {dataLabel ? Object.keys(dataLabel).map((key) => {
                                        return (
                                            <Col xs={12} md={6} key={key}>
                                                <Card
                                                    className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3 f13 color636363 ${parseInt(dataLabel[key].utama) === 1 ? 'lbl-utama' : ''}`}
                                                    style={{ borderRadius: 0 }}
                                                >
                                                    <div className="flexJustifyBeetwenAlignCenter">
                                                        <div className="mr-2">
                                                            <div className="fontweightBold">
                                                                {dataCetakLabel[0].nama}
                                                            </div>

                                                            <div className="mt-1">
                                                                <span>
                                                                    {dataLabel[key].label}
                                                                </span>
                                                            </div>

                                                            {parseInt(dataLabel[key].utama) === 1 &&
                                                                <div className="mt-1">
                                                                    Semua : {Object.keys(dataLabel).map((subKey) => {
                                                                        return (
                                                                            <span key={subKey + 'hdhf987s'}>
                                                                                {dataLabel[subKey].label}{dataLabel.length === (Number(subKey) + 1) ? '' : ', '}
                                                                            </span>
                                                                        )
                                                                    })}
                                                                </div>
                                                            }

                                                            <div className="mt-3">
                                                                <span className="fontweight600 mr-1">Nota :</span>
                                                                {dataLabel[key].id_transaksi}
                                                            </div>

                                                            <div className="">
                                                                <span className="fontweight600 mr-1">Tanggal Masuk :</span>
                                                                {dataCetakLabel[0].tanggal_masuk ? sqlDateToHumanDate(dataCetakLabel[0].tanggal_masuk) : ' - '}
                                                            </div>

                                                            {parseInt(dataLabel[key].utama) === 1 &&
                                                                <div>
                                                                    <div className="mt-1">
                                                                        <span className="fontweight600 mr-1">Barang :</span>
                                                                        {dataCetakLabel[0].nama_barang}
                                                                    </div>
                                                                    <div className="mt-1">
                                                                        <span className="fontweight600 mr-1">Kerusakan :</span>
                                                                        {dataCetakLabel[0].kerusakan}
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                        :
                                        <Col xs={12} className="color808080">
                                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-5' : 'p-4'} shadow-sm brSoftMedium text-center mb-3`}>
                                                <div>Tidak data kelengkapan barang</div>
                                            </Card>
                                        </Col>
                                    }

                                    {dataLabel &&
                                        <Col xs={12}>
                                            <div className="mt-3">
                                                <PDFDownloadLink document={<PdfLabel />} fileName={`label_${idTransaksi}.pdf`}>
                                                    {({ blob, url, loading, error }) =>
                                                        <Button
                                                            variant='info'
                                                            size="sm"
                                                            className="btnXsOverride width100"
                                                        >
                                                            <span>Download Label</span>
                                                        </Button>
                                                    }
                                                </PDFDownloadLink>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </div>
                        }

                        {
                            loadingDataCetakLabel &&
                            <PlaceHolderElement1 prmMT="" prmPX="" />
                        }

                        {
                            !dataCetakLabel && flagDataCetakLabel === false && !loadingDataCetakLabel &&
                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-5' : 'p-4'} shadow-sm brSoftMedium text-center f13`}>
                                <div className="f16 mb-3 color808080">
                                    Tidak ada data
                                </div>

                                <div className="flexJustifyAlignCenter">
                                    <Image
                                        src={soldering}
                                        style={{
                                            width: '75%'
                                        }}
                                    />
                                </div>
                            </Card>
                        }
                    </Col>
                </Row>
            </Layout>

            <Modal
                show={modalZoomImage}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body className="p-0">
                    <div className="flexJustifyAlignCenter">
                        <span
                            onClick={() => setModalZoomImage(false)}
                            className="fa fa-close f20 bgBlackOpacity02 colorWhite flexJustifyAlignCenter"
                            style={{
                                borderRadius: '50%',
                                width: '35px',
                                height: '35px',
                                position: 'absolute',
                                top: '15px',
                                right: '17px'
                            }}
                        />

                        <img
                            src={srcFoto}
                            alt='foto'
                            style={{ width: '100%' }}
                        />
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment >
    );
}

export default CetakLabel;
