import React from 'react';
import { Image } from 'react-bootstrap';
import soldering from "../assets/images/system/soldering.png";
import { useSelector } from "react-redux";
import { widthXS, widthSM, widthMD, widthLG } from "../utils/globalVar";

const NoData = () => {

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);

    return (
        <div className="text-center mt-3">
            <div className="flexJustifyAlignCenter">
                <Image
                    src={soldering}
                    style={{ width: reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? '60%' : '50%') : '40%') : '30%') : '30%' }}
                />
            </div>

            <div className="mt-3 f16 color636363">
                Tidak ada data
            </div>
        </div>
    );
}

export default NoData;
