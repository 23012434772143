import React from 'react';
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import 'font-awesome/css/font-awesome.min.css';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import CustomerAvatar from "../../components/CustomerAvatar";
import { sqlDateToLongHumanDate } from "../../utils/globalFunc";
import { widthLG, widthMD, widthSM, widthXS, BASE_URL_DUA, batasFluid, prefixUrl } from "../../utils/globalVar";

import ModalAlertWarning from '../../components/ModalAlertWarning';
import PlaceHolderElement1 from '../../components/PlaceHolderElement1';
import ButtonLoading from '../../components/ButtonLoading';
import LottiePausAstronot from '../../components/LottiePausAstronot';
import BarcodeScanner from '../../components/BarcodeScanner';

const ProjectCancel = () => {

    const history = useHistory();
    const reduxWindowWidth = useSelector(data => data.lumoswinwid);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [heightNavTop, setHeightNavTop] = React.useState(0);

    const [totalDataCancel, setTotalDataCancel] = React.useState(0);

    const [loadingInit, setLoadingInit] = React.useState(false);
    const [dataProjectCancel, setDataProjectCancel] = React.useState(null);
    const limitData = 10;
    const refOffsetData = React.useRef(0);
    const [inputSearch, setInputSearch] = React.useState('');
    const [hasMore, setHasMore] = React.useState(false);
    const [btnLoadingMore, setBtnLoadingMore] = React.useState(false);
    const [loadingTyping, setLoadingTyping] = React.useState(false);

    const [modalBarcodeScanner, setModalBarcodeScanner] = React.useState(false);
    const handleScanBarcode = (err, result) => {
        if (result) {
            setInputSearch(result.text);
            setModalBarcodeScanner(false);
        }
    }

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";

        document.querySelector(".containerLuarTopNavbar") && mountedRef.current && setHeightNavTop(document.querySelector(".containerLuarTopNavbar").clientHeight);

        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, []);

    const moreData = () => {
        setBtnLoadingMore(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/project_cancel',
            data: {
                inputan: inputSearch,
                limit_data: limitData,
                offset_data: refOffsetData.current
            }
        }).then((response) => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);

            dataProjectCancel && response.data.data_where && [...dataProjectCancel, ...response.data.data_where].length < response.data.data_where_all.length ? setHasMore(true) : setHasMore(false);

            dataProjectCancel && response.data.data_where && setDataProjectCancel([...dataProjectCancel, ...response.data.data_where]);

            if (response.data.data_where) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);
            handleWarningModal('Warning : ' + error.message);
        })
    };

    React.useEffect(() => {
        setInputSearch('');
        setLoadingInit(true);

        refOffsetData.current = 0;

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/project_cancel',
            data: {
                inputan: '',
                limit_data: limitData,
                offset_data: refOffsetData.current
            }
        }).then((response) => {
            if (!mountedRef.current) return null;
            setLoadingInit(false);

            setTotalDataCancel(response.data.total);

            response.data.data_where && response.data.data_where.length < response.data.data_where_all.length ? setHasMore(true) : setHasMore(false);

            setDataProjectCancel(response.data.data_where);

            if (response.data.data_where) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;
            setLoadingInit(false);
            handleWarningModal('Warning : ' + error.message);
        })
    }, []);

    const [prmSetTimeOut, setPrmSetTimeOut] = React.useState(null);
    const [loadingWhereNull, setLoadingWhereNull] = React.useState(false);

    const cari = (prm) => {
        refOffsetData.current = 0;

        if (prm) {
            setLoadingTyping(true);

            prmSetTimeOut && clearTimeout(prmSetTimeOut);

            setPrmSetTimeOut(setTimeout(() => {
                axios({
                    method: 'post',
                    url: BASE_URL_DUA + '/project_cancel',
                    data: {
                        inputan: prm,
                        limit_data: limitData,
                        offset_data: refOffsetData.current
                    }
                }).then((response) => {
                    if (!mountedRef.current) return null;
                    setLoadingTyping(false);

                    response.data.data_where && response.data.data_where.length < response.data.data_where_all.length ? setHasMore(true) : setHasMore(false);

                    setDataProjectCancel(response.data.data_where);

                    if (response.data.data_where) { refOffsetData.current = refOffsetData.current + limitData };
                }).catch(error => {
                    if (!mountedRef.current) return null;
                    setLoadingTyping(false);
                    handleWarningModal('Warning : ' + error.message);
                })
            }, 1000))
        } else {
            prmSetTimeOut && clearTimeout(prmSetTimeOut);
            setLoadingWhereNull(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/project_cancel',
                data: {
                    inputan: '',
                    limit_data: limitData,
                    offset_data: refOffsetData.current
                }
            }).then((response) => {
                if (!mountedRef.current) return null;
                setLoadingWhereNull(false);
                setLoadingTyping(false);

                response.data.data_where && response.data.data_where.length < response.data.data_where_all.length ? setHasMore(true) : setHasMore(false);

                setDataProjectCancel(response.data.data_where);

                if (response.data.data_where) { refOffsetData.current = refOffsetData.current + limitData };
            }).catch(error => {
                if (!mountedRef.current) return null;
                setLoadingWhereNull(false);
                handleWarningModal('Warning : ' + error.message);
            })
        }
    }

    const handleDetil = (prmId) => {
        history.push('/' + prefixUrl.owner + '/projectcanceldetil/' + prmId);
    }

    return (
        <React.Fragment>

            <BarcodeScanner
                modalBarcodeScanner={modalBarcodeScanner}
                onSken={(satu, dua) => handleScanBarcode(satu, dua)}
                closeModalScanBarcode={() => setModalBarcodeScanner(false)}
            />

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop shadow={false} titleNavTop="Project Yang Dicancel" />

            <Layout titleTab="Project Yang Dicancel">

                {loadingInit && <PlaceHolderElement1 prmMT="mt-3" />}

                <Row>
                    {!loadingInit &&
                        <Col
                            xs={12}
                            className="pt-2 pb-3"
                            style={{
                                position: 'sticky',
                                top: `${heightNavTop}px`,
                                zIndex: '3',
                                backgroundColor: '#fff',
                                borderBottom: '1px solid #eee'
                            }}
                        >

                            <InputGroup>
                                <FormControl
                                    size="sm"
                                    type="text"
                                    placeholder="Ketik No Nota / Customer / Nama Barang / dll"
                                    value={inputSearch}
                                    onChange={(e) => {
                                        setInputSearch(e.target.value);
                                        cari(e.target.value);
                                    }}
                                    className="removeShadowOnFocus"
                                    style={{ borderRight: '0 solid #eee' }}
                                />

                                <InputGroup.Append>
                                    <InputGroup.Text id="ad-cbd-9890" className="bgColorForm">
                                        {loadingTyping &&
                                            <Spinner animation="border" size="sm" variant="info" />
                                        }

                                        <span className="fa fa-barcode ml-2" onClick={() => setModalBarcodeScanner(true)} />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    }

                    {loadingWhereNull &&
                        <Col xs={12}>
                            <PlaceHolderElement1 prmMT="mt-3" />
                        </Col>
                    }

                    {!dataProjectCancel && !loadingWhereNull && !loadingTyping && !loadingInit &&
                        <Col xs={12}>
                            <div className="flexRowJustifyAlignCenter">
                                <LottiePausAstronot />
                            </div>
                        </Col>
                    }

                    {!loadingInit && dataProjectCancel &&
                        <Col xs={12} className="mt-3">
                            <div className="alert alert-info f13">Total Data Di Cancel {totalDataCancel}</div>
                        </Col>
                    }

                    {!loadingInit && dataProjectCancel &&
                        <Col xs={12}>
                            <Masonry
                                breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 1 : 2) : 2) : 3) : 3}`}
                                className="my-masonry-grid-12"
                                columnClassName="my-masonry-grid_column-12"
                            >
                                {
                                    Object.keys(dataProjectCancel).map((key) => {
                                        return (
                                            <Card
                                                key={'pc_98798' + key}
                                                className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3 cp`}
                                                onClick={() => handleDetil(dataProjectCancel[key].id_transaksi)}
                                            >
                                                <div style={{ width: '100%' }}>

                                                    <div className="flex">
                                                        <div className="mr-3">
                                                            <CustomerAvatar
                                                                prmSlug={dataProjectCancel[key].slug_ck}
                                                                prmGender={dataProjectCancel[key].gender}
                                                                ukuran={{ width: '40px', height: '40px' }}
                                                            />
                                                        </div>

                                                        <div>
                                                            <div className="bold color636363">
                                                                {dataProjectCancel[key].nama}
                                                            </div>
                                                            <div className="mb-1 color808080">
                                                                {dataProjectCancel[key].nama_cust_ktgr}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div><hr className="mt-2 mb-3" /></div>

                                                    <div className="f13">
                                                        <span className="fontweight500 mr-2 color636363">No Nota</span>
                                                        <span className="color808080">{dataProjectCancel[key].id_transaksi}</span>
                                                    </div>

                                                    <div className="f13">
                                                        <span className="fontweight500 mr-2 color636363">
                                                            Kategori Barang
                                                        </span>
                                                        <span className="color808080">
                                                            {dataProjectCancel[key].nama_barang_kategori ? dataProjectCancel[key].nama_barang_kategori : 'Belum Ada Kategori'}
                                                        </span>
                                                    </div>

                                                    <div className="f13">
                                                        <span className="fontweight500 mr-2 color636363">
                                                            Nama Barang
                                                        </span>
                                                        <span className="color808080">
                                                            {dataProjectCancel[key].nama_barang}
                                                        </span>
                                                    </div>

                                                    <div className="f13 mt-2">
                                                        <span className="fontweight500 mr-2 color636363">
                                                            Tanggal Cancel
                                                        </span>
                                                        <span className="color808080">
                                                            {sqlDateToLongHumanDate(dataProjectCancel[key].tanggal_cancel)}
                                                        </span>
                                                    </div>

                                                    <div className="f13">
                                                        <span className="fontweight500 mr-2 color636363">
                                                            Alasan Cancel
                                                        </span>
                                                        <span className="color808080">
                                                            {dataProjectCancel[key].alasan_cancel}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Card>
                                        )
                                    })
                                }
                            </Masonry>

                            {hasMore ?
                                <div className="text-center mt-3">
                                    <ButtonLoading
                                        prmLoading={btnLoadingMore}
                                        btnClass="btnXsOverride"
                                        btnVariant="info"
                                        btnOnClick={moreData}
                                        caption="Load More"
                                    />
                                </div>
                                :
                                <div className="text-center colorAaa mt-3">
                                    No More Data
                                </div>
                            }
                        </Col>
                    }
                </Row>
            </Layout>

        </React.Fragment >
    );
}

export default ProjectCancel;
