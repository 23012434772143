import React from 'react';
import { Image } from 'react-bootstrap';

import defaultAvatar from "../assets/images/system/defaultAvatar.png";
import manAvatar from "../assets/images/system/manAvatar.png";
import womanAvatar from "../assets/images/system/womanAvatar.png";
import rekananTeknisiAvatar from "../assets/images/system/rekananTeknisiAvatar.png";
import perusahaanAvatar from "../assets/images/system/perusahaanAvatar.jpg";

import { avatar } from "../utils/globalVar";

const CustomerAvatar = ({ prmSlug, prmGender, ukuran }) => {

    const handleAvatarImg = () => {
        let avatarImg = defaultAvatar;

        if (prmSlug === avatar.perorangan.slug) {
            if (prmGender === '1') {
                avatarImg = manAvatar;
            } else {
                avatarImg = womanAvatar;
            }
        } else if (prmSlug === avatar.rekananTeknisi.slug) {
            avatarImg = rekananTeknisiAvatar
        } else if (prmSlug === avatar.perusahaan.slug) {
            avatarImg = perusahaanAvatar
        }

        return avatarImg;
    }

    return (
        <Image
            src={handleAvatarImg()}
            alt='avatar'
            roundedCircle
            style={ukuran}
        />
    )
}

export default CustomerAvatar;