import React from 'react';
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import { prefixUrl, BASE_URL, batasFluid } from "../../utils/globalVar";
import ModalAlertWarning from "../../components/ModalAlertWarning";
import ModalInfoHint from "../../components/ModalInfoHint";
import PlaceHolderElement2 from "../../components/PlaceHolderElement2";

const StokBarang = () => {

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);

    const history = useHistory();
    const [dataStokBarang, setDataStokBarang] = React.useState(null);
    const [dataStokBarangAnak, setDataStokBarangAnak] = React.useState({});
    const [loadingDataStokBarang, setLoadingDataStokBarang] = React.useState(false);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [modalInfoHint, setModalInfoHint] = React.useState(false);

    const mountedRef = React.useRef(true);

    React.useEffect(() => {

        setLoadingDataStokBarang(true);

        axios({
            method: 'post',
            url: BASE_URL + '/get_data_stok_barang',
            data: {
                dummy: 'ajkshdkj87'
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setDataStokBarangAnak(response.data.data_stok_kategori_anak);
            setDataStokBarang(response.data.data_stok_kategori);

            setLoadingDataStokBarang(false);
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingDataStokBarang(false);
            handleWarningModal(error.message)
        })
    }, [])

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, []);

    const InfoHint = () => {
        return (
            <React.Fragment>
                <div className="alert alert-info py-3 px-3">
                    <div className="fontweight500">
                        Pengertian
                        <hr className="my-2" />
                    </div>

                    <div>
                        Stok barang adalah barang-barang / part / item yang digunakan untuk penggantian part barang customer yang rusak atau harus diganti.
                    </div>

                    <div className="mt-3">
                        Stok barang ini digunakan atau dipilih pada saat proses pengerjaan (perbaikan barang).
                    </div>
                </div>

                <div className="alert alert-info py-3 px-3">
                    <div className="fontweight500">
                        Cara Menambahkan Data Stok Barang
                        <hr className="my-2" />
                    </div>

                    <div>
                        <div>
                            1. Klik <span className="fontweight600">Tambah Kategori Stok Barang</span>
                        </div>

                        <div>
                            2. Isi Nama Kategori (Misalkan <span className="fontweight600">Hard Disk</span>) dan Jumlah Stok Minimal
                        </div>

                        <div>
                            3. Setelah selesai menambahkan kategori stok barang, klik pada kategori stok barang tersebut untuk menambahkan stok item / barang sesuai kategori stok barang tersebut.
                        </div>

                        <div>
                            4. Untuk menambahkan stok, klik <span className="fontweight600">Tambah Stok</span>.
                        </div>

                        <div>
                            5. Isi kolom-kolom yang ada, seperti Nama Barang (misalkan HDD 500GB), Merk (misalkan Seagate), Tipe (misalkan SG-N500), Serial Number (opsional), dst.
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop shadow={true} titleNavTop="Stok Barang" />

            <Layout titleTab="Stok Barang">
                <Row className="mt-4 px-2 mb-5">

                    {loadingDataStokBarang && <Col xs={12}><PlaceHolderElement2 prmMT="" prmPX="" /></Col>}

                    {!loadingDataStokBarang &&
                        <Col xs={4} sm={3} md={3} lg={2} className="px-1 mb-3 cp">
                            <div
                                className="shadow-sm px-2 f12 colorWhite bgWhite text-center flexJustifyAlignCenter"
                                style={{ borderRadius: '6px', width: '100%', border: '1px dashed #1DB5D0' }}
                                onClick={() => history.push('/' + prefixUrl.owner + '/tambahstokbarangkategori')}
                            >
                                <div
                                    className="my-2 flexRowJustifyAlignCenter"
                                    style={{
                                        maxHeight: reduxWindowWidth > batasFluid ? '140px' : '120px',
                                        minHeight: reduxWindowWidth > batasFluid ? '140px' : '120px',
                                    }}
                                >
                                    <div>
                                        <span className="fa fa-plus f25" style={{ color: '#1DB5D0' }} />
                                    </div>

                                    <div className="mt-3" style={{ color: '#1DB5D0' }}>
                                        Tambah Kategori Stok Barang
                                    </div>
                                </div>
                            </div>
                        </Col>
                    }

                    {dataStokBarang && !loadingDataStokBarang && Object.keys(dataStokBarang).map((key) => {
                        return (
                            <Col xs={4} sm={3} md={3} lg={2} key={key} className="px-1 mb-3 cp">
                                <div
                                    className="shadow-sm text-center flexRowJustifyAlignCenter brSoftMedium"
                                    style={{ borderRadius: '6px' }}
                                    onClick={() => history.push('/' + prefixUrl.owner + '/tambahstokbarang/' + dataStokBarang[key].id_sk)}
                                >
                                    <div
                                        className="p-1 f12 fontweight600 colorWhite"
                                        style={{
                                            flex: 1,
                                            width: '100%',
                                            borderRadius: '6px 6px 0 0',
                                            backgroundColor: '#1DB5D0'
                                        }}
                                    >
                                        <div
                                            className="flexJustifyAlignCenter"
                                            style={{
                                                maxHeight: reduxWindowWidth > batasFluid ? '105px' : '85px',
                                                minHeight: reduxWindowWidth > batasFluid ? '105px' : '85px',
                                                width: '100%',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            {dataStokBarang[key].nama_sk}
                                        </div>
                                    </div>

                                    <div
                                        className="py-1 px-2 f11 color808080"
                                        style={{ width: '100%', borderRadius: '0 0 6px 6px' }}
                                    >
                                        <div
                                            className="flexJustifyBeetwenAlignCenter"
                                            style={{ maxHeight: '35px', minHeight: '35px', width: '100%', overflow: 'hidden' }}
                                        >
                                            <div>
                                                Stok {dataStokBarang[key].id_sk in dataStokBarangAnak ? dataStokBarangAnak[dataStokBarang[key].id_sk].yu : 0}

                                                {dataStokBarang[key].id_sk in dataStokBarangAnak ? ((parseInt(dataStokBarangAnak[dataStokBarang[key].id_sk].yu) < parseInt(dataStokBarang[key].stok_minimal)) ?
                                                    <span className="fa fa-exclamation-circle ml-1" style={{ color: '#CA183C' }} /> : '')
                                                    :
                                                    <span className="fa fa-exclamation-circle ml-1" style={{ color: '#CA183C' }} />
                                                }
                                            </div>
                                            <div>Min {dataStokBarang[key].stok_minimal}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}

                    {!dataStokBarang && !loadingDataStokBarang &&
                        <Col xs={4} sm={3} md={3} lg={2} className="px-1 mb-3">
                            <div
                                className="px-3 f12 colorWhite bgWhite text-center flexJustifyAlignCenter"
                                style={{ borderRadius: '6px', width: '100%' }}
                            >
                                <div
                                    className="my-2 flexRowJustifyAlignCenter"
                                    style={{
                                        maxHeight: reduxWindowWidth > batasFluid ? '140px' : '120px',
                                        minHeight: reduxWindowWidth > batasFluid ? '140px' : '120px',
                                    }}
                                >
                                    <div className="color808080">
                                        Belum Ada Data Kategori Stok Barang
                                    </div>
                                </div>
                            </div>
                        </Col>
                    }

                </Row>
            </Layout>

            {!loadingDataStokBarang &&
                <span
                    className="alert alert-secondary cp py-2 px-3"
                    style={{ position: 'fixed', bottom: '0', right: '10px' }}
                    onClick={() => setModalInfoHint(true)}
                >
                    <span className="fa fa-info-circle" /> Info
                </span>
            }

            <ModalInfoHint
                showWarningModal={modalInfoHint}
                hideWarningModal={() => setModalInfoHint(false)}
                paramText={InfoHint()}
            />

        </React.Fragment >
    );
}

export default StokBarang;
