import React from 'react';
import axios from "axios";
import Calendar from 'react-calendar';
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Form, Modal, InputGroup, FormControl } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import { BASE_URL, prefixUrl } from "../../utils/globalVar";
import { VFCannotNull, VFNotNumber, isoDateToSqlDate, sqlDateToLongHumanDate, formatNumber, formatNumberCanZero } from "../../utils/globalFunc";
import ButtonLoading from "../../components/ButtonLoading";
import BarcodeScanner from '../../components/BarcodeScanner';

const StokEdit = () => {

    const parameter = useParams();
    const idSK = parameter.idSK;
    const idS = parameter.idS;

    const history = useHistory();

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const mountedRef = React.useRef(true);

    const [prmLoadingInit, setPrmLoadingInit] = React.useState(false);
    const [prmBtnLoading, setPrmBtnLoading] = React.useState(false);
    const [showModalCalendar, setShowModalCalendar] = React.useState(false);
    const [prmDataStokIfNull, setPrmDataStokIfNull] = React.useState(false);

    const [modalBarcodeScanner, setModalBarcodeScanner] = React.useState(false);
    const handleScanBarcode = (err, result) => {
        if (result) {
            setInputan({ ...inputan, sn: result.text });
            setModalBarcodeScanner(false);
        }
    }

    const initDataInputan = {
        nama: '',
        merk: '',
        tipe: '',
        sn: '',
        tglBeli: new Date(),
        harga: '',
        jumlah: '',
        garansi: '',
        supplier: '',
        isOriginal: 0
    };

    const [inputan, setInputan] = React.useState(initDataInputan);

    const refNama = React.useRef('');
    const refMerk = React.useRef('');
    const refTipe = React.useRef('');
    const refSN = React.useRef('');
    const refHarga = React.useRef('');
    const refJumlah = React.useRef('');
    const refMasaGaransi = React.useRef('');
    const refSupplier = React.useRef('');

    React.useEffect(() => {
        return () => {
            mountedRef.current = false;
        }
    }, []);

    React.useEffect(() => {
        setPrmLoadingInit(true);
        axios({
            method: 'post',
            url: BASE_URL + '/get_data_stok',
            data: {
                id_s: idS
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setPrmLoadingInit(false);

            response.data !== null && setPrmDataStokIfNull(true);

            response.data !== null && setInputan({
                nama: response.data[0].nama,
                merk: response.data[0].merk,
                tipe: response.data[0].tipe,
                sn: response.data[0].serial_number,
                tglBeli: new Date(response.data[0].tanggal_beli),
                harga: formatNumber(response.data[0].harga_beli),
                jumlah: formatNumber(response.data[0].jumlah),
                garansi: formatNumberCanZero(response.data[0].masa_garansi),
                supplier: response.data[0].supplier,
                isOriginal: Boolean(Number(response.data[0].is_original))
            });
        }).catch(error => {
            if (!mountedRef.current) return null;

            setPrmLoadingInit(false);
            handleWarningModal(error.message);
        })
    }, [idS])

    const handleSubmitForm = () => {

        const el = document.getElementsByClassName("inptFieldTBS");
        for (var i in el) {
            if (el[i].type === "text" || el[i].type === "number") {
                el[i].style.border = 'unset'
            }
        }

        if (VFCannotNull(inputan.nama)) {
            refNama.current.style.border = '1px solid red';
            refNama.current.focus();
        } else if (VFCannotNull(inputan.merk)) {
            refMerk.current.style.border = '1px solid red';
            refMerk.current.focus();
        } else if (VFCannotNull(inputan.tipe)) {
            refTipe.current.style.border = '1px solid red';
            refTipe.current.focus();
        } else if (VFCannotNull(inputan.harga.replaceAll(',', ''))) {
            refHarga.current.style.border = '1px solid red';
            refHarga.current.focus();
        } else if (VFNotNumber(inputan.harga.replaceAll(',', ''))) {
            refHarga.current.style.border = '1px solid red';
            refHarga.current.focus();
        } else if (VFCannotNull(inputan.jumlah.replaceAll(',', ''))) {
            refJumlah.current.style.border = '1px solid red';
            refJumlah.current.focus();
        } else if (VFNotNumber(inputan.jumlah.replaceAll(',', ''))) {
            refJumlah.current.style.border = '1px solid red';
            refJumlah.current.focus();
        } else if (VFCannotNull(inputan.garansi.replaceAll(',', ''))) {
            refMasaGaransi.current.style.border = '1px solid red';
            refMasaGaransi.current.focus();
        } else if (VFNotNumber(inputan.garansi.replaceAll(',', ''))) {
            refMasaGaransi.current.style.border = '1px solid red';
            refMasaGaransi.current.focus();
        } else if (VFCannotNull(inputan.supplier)) {
            refSupplier.current.style.border = '1px solid red';
            refSupplier.current.focus();
        } else {
            setPrmBtnLoading(true);

            axios({
                method: 'post',
                url: BASE_URL + '/edit_stok',
                data: {
                    id_s: idS,

                    nama: inputan.nama,
                    merk: inputan.merk,
                    tipe: inputan.tipe,
                    is_original: Number(inputan.isOriginal),
                    serial_number: inputan.sn.trim(),
                    tanggal_beli: isoDateToSqlDate(inputan.tglBeli),
                    harga_beli: inputan.harga.replaceAll(',', ''),
                    jumlah: inputan.jumlah.replaceAll(',', ''),
                    masa_garansi: inputan.garansi.replaceAll(',', ''),
                    supplier: inputan.supplier
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setPrmBtnLoading(false);

                response.data.flag_status === true && history.push('/' + prefixUrl.owner + '/tambahstokbarang/' + idSK);

                response.data.flag_status === false && handleWarningModal('Error : ' + response.data.pesan);
            }).catch(error => {
                if (!mountedRef.current) return null;

                setPrmBtnLoading(false);
                handleWarningModal(error.message)
            })
        }
    }

    return (
        <React.Fragment>

            <BarcodeScanner
                modalBarcodeScanner={modalBarcodeScanner}
                onSken={(satu, dua) => handleScanBarcode(satu, dua)}
                closeModalScanBarcode={() => setModalBarcodeScanner(false)}
            />

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop
                shadow={true}
                titleNavTop="Edit Stok"
                prmBackButton={true}
                prmOnBack={`/${prefixUrl.owner}/tambahstokbarang/${idSK}`}
            />

            <Layout titleTab="Edit Stok">
                <Row>
                    {!prmLoadingInit && prmDataStokIfNull &&
                        <Col xs={12} className="mt-4">
                            <Form.Group controlId="inputNamaBrgStok">
                                <Form.Label>
                                    Nama Barang
                                </Form.Label>
                                <Form.Control
                                    className="inptFieldTBS"
                                    ref={refNama}
                                    name="inputNamaBrgStok"
                                    size="sm"
                                    type="text"
                                    placeholder="Nama Barang"
                                    value={inputan.nama}
                                    onChange={(e) => setInputan({ ...inputan, nama: e.target.value })}
                                />
                            </Form.Group>

                            <Form.Group controlId="inputMerkBrgStok">
                                <Form.Label>
                                    Merk
                                </Form.Label>
                                <Form.Control
                                    className="inptFieldTBS"
                                    ref={refMerk}
                                    name="inputMerkBrgStok"
                                    size="sm"
                                    type="text"
                                    placeholder="Merk"
                                    value={inputan.merk}
                                    onChange={(e) => setInputan({ ...inputan, merk: e.target.value })}
                                />
                            </Form.Group>

                            <Form.Group controlId="inputTipeBrgStok">
                                <Form.Label>
                                    Tipe
                                </Form.Label>
                                <Form.Control
                                    className="inptFieldTBS"
                                    ref={refTipe}
                                    name="inputTipeBrgStok"
                                    size="sm"
                                    type="text"
                                    placeholder="Tipe"
                                    value={inputan.tipe}
                                    onChange={(e) => setInputan({ ...inputan, tipe: e.target.value })}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    Serial Number
                                </Form.Label>

                                <InputGroup>
                                    <Form.Control
                                        className="inptFieldTBS removeShadowOnFocus"
                                        style={{ borderRight: '0 solid #eee' }}
                                        ref={refSN}
                                        name="inputSNBrgStok"
                                        size="sm"
                                        type="text"
                                        placeholder="Serial Number"
                                        value={inputan.sn}
                                        onChange={(e) => setInputan({ ...inputan, sn: e.target.value })}
                                    />

                                    <InputGroup.Append>
                                        <InputGroup.Text className="bgColorForm">
                                            <span className="fa fa-barcode ml-2" onClick={() => setModalBarcodeScanner(true)} />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group controlId="inputTglBeliBrgStok">
                                <Form.Label>
                                    Tanggal Beli
                                </Form.Label>
                                <Form.Control
                                    readOnly
                                    className="inptFieldTBS"
                                    name="inputTglBeliBrgStok"
                                    size="sm"
                                    type="text"
                                    placeholder="Tanggal Beli"
                                    value={sqlDateToLongHumanDate(isoDateToSqlDate(inputan.tglBeli))}
                                    // onChange={(e) => setInputan({ ...inputan, tglBeli: e.target.value })}
                                    onClick={() => setShowModalCalendar(true)}
                                />
                            </Form.Group>

                            <Form.Group controlId="inputHargaBrgStok">
                                <Form.Label>
                                    Harga
                                </Form.Label>
                                <Form.Control
                                    className="inptFieldTBS"
                                    ref={refHarga}
                                    name="inputHargaBrgStok"
                                    size="sm"
                                    type="text"
                                    placeholder="Harga"
                                    value={inputan.harga}
                                    onChange={(e) => setInputan({ ...inputan, harga: formatNumber(e.target.value) })}
                                />
                            </Form.Group>

                            <Form.Group controlId="inputJumlahBrgStok">
                                <Form.Label>
                                    Jumlah
                                </Form.Label>
                                <Form.Control
                                    className="inptFieldTBS"
                                    ref={refJumlah}
                                    name="inputJumlahBrgStok"
                                    size="sm"
                                    type="text"
                                    placeholder="Jumlah"
                                    value={inputan.jumlah}
                                    onChange={(e) => setInputan({ ...inputan, jumlah: formatNumber(e.target.value) })}
                                />
                            </Form.Group>

                            <Form.Group controlId="inputMasaGaransiBrgStok">
                                <Form.Label>
                                    Masa Garansi
                                </Form.Label>

                                <InputGroup className="mb-3" size="sm">
                                    <FormControl
                                        className="inptFieldTBS"
                                        ref={refMasaGaransi}
                                        name="inputMasaGaransiBrgStok"
                                        size="sm"
                                        type="text"
                                        value={inputan.garansi}
                                        onChange={(e) => setInputan({ ...inputan, garansi: formatNumberCanZero(e.target.value) })}
                                        placeholder="Masa Garansi"
                                        aria-label="Masa Garansi"
                                        aria-describedby="inputMasaGaransiBrgStok"
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="inputMasaGaransiBrgStok">Bulan</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group controlId="inputSupplierBrgStok">
                                <Form.Label>
                                    Supplier
                                </Form.Label>
                                <Form.Control
                                    className="inptFieldTBS"
                                    ref={refSupplier}
                                    name="inputSupplierBrgStok"
                                    size="sm"
                                    type="text"
                                    placeholder="Supplier"
                                    value={inputan.supplier}
                                    onChange={(e) => setInputan({ ...inputan, supplier: e.target.value })}
                                />
                            </Form.Group>

                            <Form.Group controlId="inputIsOriBrgStok">
                                <Form.Check
                                    custom
                                    type='checkbox'
                                >
                                    <Form.Check.Input
                                        type='checkbox'
                                        className="inptFieldTBS"
                                        checked={inputan.isOriginal}
                                        onChange={(e) => setInputan({ ...inputan, isOriginal: e.target.checked })}
                                    />

                                    <Form.Check.Label
                                        style={{ paddingTop: '1px' }}
                                        className="colorLabel"
                                    >
                                        Original
                                    </Form.Check.Label>
                                </Form.Check>
                            </Form.Group>

                            <div className="flexJustifyAlignCenter">
                                <ButtonLoading
                                    prmLoading={prmBtnLoading}
                                    btnClass="mt-4 px-4"
                                    btnVariant="info"
                                    btnOnClick={handleSubmitForm}
                                    caption="Simpan"
                                />
                            </div>

                        </Col>
                    }

                    {prmLoadingInit &&
                        <Col xs={12} className="mt-3">
                            <div className="text-center alert alert-warning">Loading..</div>
                        </Col>
                    }

                    {!prmLoadingInit && !prmDataStokIfNull &&
                        <Col xs={12} className="mt-3">
                            <div className="text-center alert alert-light">No Data</div>
                        </Col>
                    }
                </Row>
            </Layout>

            <Modal
                show={showModalCalendar}
                onHide={() => setShowModalCalendar(false)}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tanggal Beli</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flexJustifyAlignCenter pt-1 pb-3">
                        <Calendar
                            defaultView="month"
                            onChange={(value) => {
                                setInputan({ ...inputan, tglBeli: value })
                                setShowModalCalendar(false);
                            }}
                            value={new Date(inputan.tglBeli)}
                        />
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment >
    );
}

export default StokEdit;
