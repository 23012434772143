import React from 'react';
import axios from "axios";
import { Row, Col, Modal, Form, Button, Card, Spinner } from 'react-bootstrap';
import { useSelector } from "react-redux";
import 'font-awesome/css/font-awesome.min.css';
import Calendar from 'react-calendar';
import Masonry from 'react-masonry-css';
import { useHistory } from 'react-router-dom';

import CustomerAvatar from "../../components/CustomerAvatar";
import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import { BASE_URL_DUA, batasFluid, kategoriServis, metodeBayar, widthLG, widthMD, widthSM, widthXS, prefixUrl } from "../../utils/globalVar";
import { isoDateToSqlDate, isoDateToHumanDate, formatNumberCanZero, formatNumberCanZeroMinus, sqlDateToHumanDate, deko } from "../../utils/globalFunc";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import LottiePausAstronot from '../../components/LottiePausAstronot';
import PlaceHolderLoading from "../../components/PlaceHolderElement3";
import PlaceHolderLoadingInit from "../../components/PlaceHolderElement2";
import ButtonLoading from '../../components/ButtonLoading';

const Report = () => {

    const history = useHistory();

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);
    const mountedRef = React.useRef(true);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const limitData = 10;
    const refOffsetData = React.useRef(0);
    const [hasMore, setHasMore] = React.useState(false);
    const [btnLoadingMore, setBtnLoadingMore] = React.useState(false);

    const [jData, setJData] = React.useState(0);

    const tglAwalCaption = 'Semua';
    const tglAkhirCaption = 'Semua';

    const filterInit = {
        proses: 'semua',
        kategoriBarang: 'semua',
        kategoriCustomer: 'semua',
        customer: 'Semua Customer',
        idCustomer: 'semua',
        dp: 'semua',
        metodeDp: 'semua',
        pengambilan: 'semua',
        statusDiskon: 'semua',
        pelunasan: 'semua',
        metodePelunasan: 'semua',
        tanggalAwal: tglAwalCaption,
        tanggalAkhir: tglAkhirCaption,
        jenisServis: 'semua',
        reminder: 'semua',
        penggunaanStok: 'semua',
        sortir: 'desc',
        penyelesaian: 'semua',
        periode: ''
    }

    const [dataFilter, setDataFilter] = React.useState(filterInit);

    const refProses = React.useRef();
    const refKategoriBarang = React.useRef();
    const refKategoriCustomer = React.useRef();
    const refCustomer = React.useRef();
    const refDp = React.useRef();
    const refMetodeDp = React.useRef();
    const refPengambilan = React.useRef();
    const refStatusDiskon = React.useRef();
    const refPelunasan = React.useRef();
    const refMetodePelunasan = React.useRef();
    const refTanggalAwal = React.useRef();
    const refTanggalAkhir = React.useRef();
    const refJenisServis = React.useRef();
    const refReminder = React.useRef();
    const refPenggunaanStok = React.useRef();
    const refSortir = React.useRef();
    const refPenyelesaian = React.useRef();
    const refPeriode = React.useRef();

    const [dataInit, setDataInit] = React.useState({
        kategoriCustomer: null,
        kategoriBarang: null,
        customer: null,
        customerFiltered: null
    });

    const [modalTglAwal, setModalTglAwal] = React.useState(false);
    const [modalTglAkhir, setModalTglAkhir] = React.useState(false);

    const [loadingInit, setLoadingInit] = React.useState(false);
    const [loadingData, setLoadingData] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [showIfNoData, setShowIfNoData] = React.useState(false);

    const [inputCariCustomer, setInputCariCustomer] = React.useState('');
    const [modalFilterCustomer, setModalFilterCustomer] = React.useState(false);

    const [rptKategoriBarang, setRptKategoriBarang] = React.useState(null);
    const [rptKategoriCustomer, setRptKategoriCustomer] = React.useState(null);
    const [rptProses, setRptProses] = React.useState(null);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, []);

    React.useEffect(() => {
        setLoadingInit(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/init_pendapatan',
            data: {
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);

            setDataInit(dtInit => ({
                ...dtInit,
                kategoriCustomer: response.data.kategori_customer,
                kategoriBarang: response.data.kategori_barang,
                customer: response.data.customer,
                customerFiltered: response.data.customer
            }));
        }).catch(error => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);
            handleWarningModal('Error : ' + error.message)
        })
    }, [reduxLogin.syswebappi]);

    React.useEffect(() => {
        function filterSatu(element) {
            var terms = inputCariCustomer.trim().toLowerCase().split(' ');

            var res;

            terms.forEach(term => {
                res = inputCariCustomer ? ((element.nama_customer + ' ' + element.alamat + ' ' + element.no_hp).toLowerCase()).match(term) : dataInit.customer;
            });

            return (res);
        }

        dataInit.customer && setDataInit(dtInit => ({ ...dtInit, customerFiltered: dtInit.customer.filter(filterSatu) }));
    }, [inputCariCustomer, dataInit.customer]);

    const moreData = () => {
        setBtnLoadingMore(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/report',
            data: {
                proses: dataFilter.proses,
                kategori_barang: dataFilter.kategoriBarang,
                kategori_customer: dataFilter.kategoriCustomer,
                customer: dataFilter.idCustomer,
                dp: dataFilter.dp,
                metode_dp: dataFilter.metodeDp,
                pengambilan: dataFilter.pengambilan,
                status_diskon: dataFilter.statusDiskon,
                pelunasan: dataFilter.pelunasan,
                metode_pelunasan: dataFilter.metodePelunasan,

                tanggal_awal: dataFilter.tanggalAwal === tglAwalCaption ? 'semua' : isoDateToSqlDate(dataFilter.tanggalAwal),

                tanggal_akhir: dataFilter.tanggalAkhir === tglAkhirCaption ? 'semua' : isoDateToSqlDate(dataFilter.tanggalAkhir),

                jenis_servis: dataFilter.jenisServis,
                reminder: dataFilter.reminder,
                penggunaan_stok: dataFilter.penggunaanStok,
                sortir: dataFilter.sortir,
                penyelesaian: dataFilter.penyelesaian,
                periode: dataFilter.periode,

                limit_data: limitData,
                offset_data: refOffsetData.current,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);

            data && response.data.data && [...data, ...response.data.data].length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

            data && response.data.data && setData([...data, ...response.data.data]);

            if (response.data.data) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);
            handleWarningModal('Warning : ' + error.message);
        })
    };

    const handleTampilkan = () => {
        refPeriode.current.style.border = 'unset';

        if (!dataFilter.periode) {
            handleWarningModal('Pilih periode terlebih dahulu.');
            refPeriode.current.style.border = '1px solid red';
            refPeriode.current.focus();
        } else {
            setLoadingData(true);

            refOffsetData.current = 0;

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/report',
                data: {
                    proses: dataFilter.proses,
                    kategori_barang: dataFilter.kategoriBarang,
                    kategori_customer: dataFilter.kategoriCustomer,
                    customer: dataFilter.idCustomer,
                    dp: dataFilter.dp,
                    metode_dp: dataFilter.metodeDp,
                    pengambilan: dataFilter.pengambilan,
                    status_diskon: dataFilter.statusDiskon,
                    pelunasan: dataFilter.pelunasan,
                    metode_pelunasan: dataFilter.metodePelunasan,

                    tanggal_awal: dataFilter.tanggalAwal === tglAwalCaption ? 'semua' : isoDateToSqlDate(dataFilter.tanggalAwal),

                    tanggal_akhir: dataFilter.tanggalAkhir === tglAkhirCaption ? 'semua' : isoDateToSqlDate(dataFilter.tanggalAkhir),

                    jenis_servis: dataFilter.jenisServis,
                    reminder: dataFilter.reminder,
                    penggunaan_stok: dataFilter.penggunaanStok,
                    sortir: dataFilter.sortir,
                    penyelesaian: dataFilter.penyelesaian,
                    periode: dataFilter.periode,

                    limit_data: limitData,
                    offset_data: refOffsetData.current,
                    id_user: deko(reduxLogin.syswebappi)
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setJData(response.data.data_all ? response.data.data_all.length : 0);

                if (response.data.data_all && dataInit.kategoriBarang) {
                    let arrayKB = [];

                    dataInit.kategoriBarang.forEach((element, key) => {
                        let jPerKB = 0;

                        response.data.data_all.forEach(elementDataAll => {
                            if (elementDataAll.id_barang_kategori === element.id) {
                                jPerKB = jPerKB + 1;
                            }
                        });

                        arrayKB[key] = {
                            'id': element.id,
                            'nama': element.nama,
                            'jumlah': jPerKB
                        };
                    });

                    setRptKategoriBarang(arrayKB === [] ? null : arrayKB);
                }

                if (response.data.data_all && dataInit.kategoriCustomer) {
                    let arrayKC = [];

                    dataInit.kategoriCustomer.forEach((elementKC, keyKC) => {
                        let jPerKC = 0;

                        response.data.data_all.forEach(elementDataAll => {
                            if (elementDataAll.id_kategori_customer === elementKC.id) {
                                jPerKC = jPerKC + 1;
                            }
                        });

                        arrayKC[keyKC] = {
                            'id': elementKC.id,
                            'nama': elementKC.nama,
                            'jumlah': jPerKC
                        };
                    });

                    setRptKategoriCustomer(arrayKC === [] ? null : arrayKC);
                }

                if (response.data.data_all) {
                    let arrayPrs = [];
                    let jmlProses = 5;
                    let namaProses = ['Nota', 'Antri', 'Pengerjaan', 'Q.C.', 'Selesai', 'Sudah Diambil'];

                    for (let index = 0; index <= jmlProses; index++) {
                        let jPerPrs = 0;

                        response.data.data_all.forEach(elementDataAll => {
                            if (Number(elementDataAll.flag_proses) === index) {
                                jPerPrs = jPerPrs + 1;
                            }
                        });

                        arrayPrs[index] = {
                            'nama': namaProses[index],
                            'jumlah': jPerPrs
                        };
                    };

                    setRptProses(arrayPrs === [] ? null : arrayPrs);
                }

                setLoadingData(false);

                response.data.data && response.data.data.length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

                if (response.data.data) {
                    setData(response.data.data);
                    setShowIfNoData(false);
                } else {
                    setData(null);
                    setShowIfNoData(true);
                }

                if (response.data.data) { refOffsetData.current = refOffsetData.current + limitData };
            }).catch(error => {
                if (!mountedRef.current) return null;

                setLoadingData(false);
                handleWarningModal('Error : ' + error.message);
            })
        }
    }

    const resetFilter = () => {
        setDataFilter(filterInit);
    }

    const statusProject = (prm) => {
        let hasil = '';

        if (prm === '0') {
            hasil = 'Nota';
        } else if (prm === '1') {
            hasil = 'Antri';
        } else if (prm === '2') {
            hasil = 'Pengerjaan';
        } else if (prm === '3') {
            hasil = 'Q.C.';
        } else if (prm === '4') {
            hasil = 'Selesai';
        } else if (prm === '5') {
            hasil = 'Sudah Diambil';
        }

        return hasil;
    }

    const handleMetodeUangMuka = (prmMetode) => {
        if (!prmMetode) {
            return '';
        } else {
            let hasil = '';

            Object.keys(metodeBayar).forEach(element => {
                if (element === prmMetode) {
                    hasil = '(Metode ' + metodeBayar[element] + ')';
                    return
                }
            });

            return hasil;
        }
    }

    const handleMetodePelunasan = (prmMetode) => {
        if (!prmMetode) {
            return '';
        } else {
            let hasil = '';

            Object.keys(metodeBayar).forEach(element => {
                if (element === prmMetode) {
                    hasil = '(Metode ' + metodeBayar[element] + ')';
                    return
                }
            });

            return hasil;
        }
    }

    const handleKetDiskon = (arrDiskon) => {
        let res = '';

        if (arrDiskon[0].keterangan) {
            if (arrDiskon[0].flag === 'persen') {
                res = arrDiskon[0].jumlah + '% (' + arrDiskon[0].keterangan + ')';
            } else {
                res = 'Rp ' + formatNumberCanZero(arrDiskon[0].jumlah) + ' (' + arrDiskon[0].keterangan + ')';
            }
        } else {
            res = 'Rp ' + formatNumberCanZero(arrDiskon[0].jumlah);
        }

        return res;
    }

    const getTotal = (elementByr, elementDp, elementDiskon) => {
        let byr = 0;
        let diskon = 0;
        let total = 0;

        byr = sumTotal(elementByr);
        diskon = sumDiskon(elementDiskon, elementByr);
        total = byr - diskon;

        return total;

    }

    const getTotalBayar = (elementByr, elementDp, elementDiskon) => {
        let byr = 0;
        let dp = elementDp ? elementDp : 0;
        let diskon = 0;
        let total = 0;

        byr = sumTotal(elementByr);
        diskon = sumDiskon(elementDiskon, elementByr);
        total = byr - dp - diskon;

        return total;

    }

    const sumTotal = (elementByr) => {
        let res = 0;

        if (elementByr) {
            elementByr.forEach(elST => {
                res = res + Number(elST.total);
            })
        }

        return res;
    }

    const sumDiskon = (elementDiskon, elementByr) => {
        let res = 0;

        if (elementDiskon) {
            elementDiskon.forEach(item => {
                res = res + (item.flag === 'rupiah' ? Number(item.jumlah) : (Number(item.jumlah) / 100) * sumTotal(elementByr));
            })
        }

        return res;
    }

    const handleGo = (flag, prmId) => {
        if (flag === '0') {
            history.push('/transaksi/' + prmId + '/report');
        } else if (flag === '1') {
            history.push('/confirmdoproses/' + prmId + '/report');
        } else if (flag === '2') {
            history.push('/working/' + prmId + '/report');
        } else if (flag === '3') {
            history.push('/' + prefixUrl.owner + '/qc/' + prmId + '/report');
        } else if (flag === '4') {
            history.push('/' + prefixUrl.owner + '/selesai/' + prmId + '/report');
        } else if (flag === '5') {
            history.push('/' + prefixUrl.owner + '/sudahdiambil/' + prmId + '/report');
        }
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop
                shadow={true}
                titleNavTop="Report"
            />

            <Layout titleTab="Report">

                <Row className="">
                    {!loadingInit &&
                        <Col xs={12}>
                            <Card className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} mt-3 shadow-sm brSoftMedium`}>
                                <Row className="px-2">
                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Proses</div>

                                        <Form.Control
                                            custom
                                            ref={refProses}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.proses}
                                            onChange={(e) => setDataFilter({ ...dataFilter, proses: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="semua">Semua Proses</option>
                                            <option value="0">Nota</option>
                                            <option value="1">Antrian</option>
                                            <option value="2">Pengerjaan</option>
                                            <option value="3">Q.C.</option>
                                            <option value="4">Selesai</option>
                                            <option value="5">Sudah Diambil</option>
                                        </Form.Control>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Kategori Barang</div>

                                        <Form.Control
                                            custom
                                            ref={refKategoriBarang}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.kategoriBarang}
                                            onChange={(e) => setDataFilter({ ...dataFilter, kategoriBarang: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="semua">Semua Kategori</option>
                                            {
                                                dataInit.kategoriBarang &&
                                                Object.keys(dataInit.kategoriBarang).map((keyKB) => {
                                                    return (
                                                        <option
                                                            key={'kb_7678098' + keyKB}
                                                            value={dataInit.kategoriBarang[keyKB].id}
                                                        >
                                                            {dataInit.kategoriBarang[keyKB].nama}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </Form.Control>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Kategori Customer</div>

                                        <Form.Control
                                            custom
                                            ref={refKategoriCustomer}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.kategoriCustomer}
                                            onChange={(e) => setDataFilter({ ...dataFilter, kategoriCustomer: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="semua">Semua Kategori</option>
                                            {
                                                dataInit.kategoriCustomer &&
                                                Object.keys(dataInit.kategoriCustomer).map((keyKK) => {
                                                    return (
                                                        <option
                                                            key={'kk_098' + keyKK}
                                                            value={dataInit.kategoriCustomer[keyKK].id}
                                                        >
                                                            {dataInit.kategoriCustomer[keyKK].nama}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </Form.Control>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Customer</div>

                                        <div
                                            className="flexAlignCenter bgInputImportant rounded-sm"
                                        >
                                            <Form.Control
                                                readOnly
                                                ref={refCustomer}
                                                size="sm"
                                                placeholder="Semua Customer"
                                                className="f12 removeShadowOnFocus bgInputImportant"
                                                style={{ borderRight: '0 solid #eee' }}
                                                value={dataFilter.customer}
                                                onChange={(e) => setDataFilter({ ...dataFilter, customer: e.target.value })}
                                                onClick={() => setModalFilterCustomer(true)}
                                            />

                                            {dataFilter.idCustomer !== 'semua' && <span
                                                className="fa fa-close color808080 py-1 pl-1 pr-2"
                                                onClick={() => setDataFilter({
                                                    ...dataFilter,
                                                    customer: 'Semua Customer',
                                                    idCustomer: 'semua'
                                                })}
                                            />
                                            }
                                        </div>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Down Payment</div>

                                        <Form.Control
                                            custom
                                            ref={refDp}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.dp}
                                            onChange={(e) => setDataFilter({ ...dataFilter, dp: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="semua">Semua</option>
                                            <option value="1">Menggunakan DP</option>
                                            <option value="0">Tidak Menggunakan DP</option>
                                        </Form.Control>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Metode DP</div>

                                        <Form.Control
                                            custom
                                            ref={refMetodeDp}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.metodeDp}
                                            onChange={(e) => setDataFilter({ ...dataFilter, metodeDp: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="semua">Semua Metode</option>
                                            {Object.keys(metodeBayar).map((keyMDP) => {
                                                return (
                                                    <option
                                                        key={keyMDP + 'mdb_dfh7'}
                                                        value={keyMDP}
                                                    >
                                                        {metodeBayar[keyMDP]}
                                                    </option>
                                                )
                                            })}
                                        </Form.Control>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Penyelesaian</div>

                                        <Form.Control
                                            custom
                                            ref={refPenyelesaian}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.penyelesaian}
                                            onChange={(e) => setDataFilter({ ...dataFilter, penyelesaian: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="semua">Semua</option>
                                            <option value="1">Sudah Selesai Dikerjakan</option>
                                            <option value="0">Belum Selesai Dikerjakan</option>
                                        </Form.Control>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Pengambilan</div>

                                        <Form.Control
                                            custom
                                            ref={refPengambilan}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.pengambilan}
                                            onChange={(e) => setDataFilter({ ...dataFilter, pengambilan: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="semua">Semua Pengambilan</option>
                                            <option value="1">Sudah Diambil</option>
                                            <option value="0">Belum Diambil</option>
                                        </Form.Control>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Status Diskon</div>

                                        <Form.Control
                                            custom
                                            ref={refStatusDiskon}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.statusDiskon}
                                            onChange={(e) => setDataFilter({ ...dataFilter, statusDiskon: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="semua">Semua Status</option>
                                            <option value="1">Dapat Diskon</option>
                                            <option value="0">Tidak Dapat Diskon</option>
                                        </Form.Control>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Pelunasan</div>

                                        <Form.Control
                                            custom
                                            ref={refPelunasan}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.pelunasan}
                                            onChange={(e) => setDataFilter({ ...dataFilter, pelunasan: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="semua">Semua Pelunasan</option>
                                            <option value="1">Lunas</option>
                                            <option value="0">Belum Lunas</option>
                                        </Form.Control>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Metode Pelunasan</div>

                                        <Form.Control
                                            custom
                                            ref={refMetodePelunasan}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.metodePelunasan}
                                            onChange={(e) => setDataFilter({ ...dataFilter, metodePelunasan: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="semua">Semua Metode</option>
                                            {Object.keys(metodeBayar).map((keyMB) => {
                                                return (
                                                    <option
                                                        key={keyMB + 'mb_auhs78'}
                                                        value={keyMB}
                                                    >
                                                        {metodeBayar[keyMB]}
                                                    </option>
                                                )
                                            })}
                                        </Form.Control>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Periode</div>

                                        <Form.Control
                                            custom
                                            ref={refPeriode}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.periode}
                                            onChange={(e) => setDataFilter({ ...dataFilter, periode: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="">Pilih Periode</option>
                                            <option value="tanggal_masuk">Periode Tanggal Masuk</option>
                                            <option value="tanggal_selesai">Periode Tanggal Selesai</option>
                                            <option value="tanggal_ambil">Periode Tanggal Ambil</option>
                                            <option value="tanggal_pelunasan">Periode Tanggal Pelunasan</option>
                                        </Form.Control>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Periode Awal</div>

                                        <div
                                            className="flexAlignCenter bgInputImportant rounded-sm"
                                        >
                                            <Form.Control
                                                readOnly
                                                ref={refTanggalAwal}
                                                className="textInputPendaPengel removeShadowOnFocus bgInputImportant"
                                                size="sm"
                                                type="text"
                                                placeholder="Periode Awal"
                                                value={dataFilter.tanggalAwal === tglAwalCaption ? tglAwalCaption : isoDateToHumanDate(dataFilter.tanggalAwal)}
                                                onClick={() => setModalTglAwal(true)}
                                            />

                                            {dataFilter.tanggalAwal !== tglAwalCaption && <span
                                                className="fa fa-close color808080 pl-1 pr-2 py-1"
                                                onClick={() => setDataFilter({
                                                    ...dataFilter,
                                                    tanggalAwal: tglAwalCaption
                                                })}
                                            />
                                            }
                                        </div>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Periode Akhir</div>

                                        <div
                                            className="flexAlignCenter bgInputImportant rounded-sm"
                                        >
                                            <Form.Control
                                                readOnly
                                                ref={refTanggalAkhir}
                                                className="textInputPendaPengel removeShadowOnFocus bgInputImportant"
                                                size="sm"
                                                type="text"
                                                placeholder="Periode Akhir"
                                                value={dataFilter.tanggalAkhir === tglAkhirCaption ? tglAkhirCaption : isoDateToHumanDate(dataFilter.tanggalAkhir)}
                                                onClick={() => setModalTglAkhir(true)}
                                            />

                                            {dataFilter.tanggalAkhir !== tglAkhirCaption && <span
                                                className="fa fa-close color808080 pl-1 pr-2 py-1"
                                                onClick={() => setDataFilter({
                                                    ...dataFilter,
                                                    tanggalAkhir: tglAkhirCaption
                                                })}
                                            />
                                            }
                                        </div>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Kategori Servis</div>

                                        <Form.Control
                                            custom
                                            ref={refJenisServis}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.jenisServis}
                                            onChange={(e) => setDataFilter({ ...dataFilter, jenisServis: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="semua">Semua Kategori</option>
                                            {Object.keys(kategoriServis).map((keyKS) => {
                                                return (
                                                    <option
                                                        key={keyKS + 'ks_ggk'}
                                                        value={keyKS}
                                                    >
                                                        {kategoriServis[keyKS]}
                                                    </option>
                                                )
                                            })}
                                        </Form.Control>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Reminder</div>

                                        <Form.Control
                                            custom
                                            ref={refReminder}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.reminder}
                                            onChange={(e) => setDataFilter({ ...dataFilter, reminder: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="semua">Semua</option>
                                            <option value="1">Direminder</option>
                                            <option value="0">Tidak Direminder</option>
                                        </Form.Control>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Penggunaan Stok</div>

                                        <Form.Control
                                            custom
                                            ref={refPenggunaanStok}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.penggunaanStok}
                                            onChange={(e) => setDataFilter({ ...dataFilter, penggunaanStok: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="semua">Semua</option>
                                            <option value="1">Menggunakan Stok</option>
                                            <option value="0">Tidak Menggunakan Stok</option>
                                        </Form.Control>
                                    </Col>

                                    <Col xs={6} md={4} className="px-1 mb-3">
                                        <div className="f12 mb-2">Urutkan (Periode)</div>

                                        <Form.Control
                                            custom
                                            ref={refSortir}
                                            as="select"
                                            size="sm"
                                            value={dataFilter.sortir}
                                            onChange={(e) => setDataFilter({ ...dataFilter, sortir: e.target.value })}
                                            className="f12 removeShadowOnFocus"
                                        >
                                            <option value="asc">Tanggal A-Z</option>
                                            <option value="desc">Tanggal Z-A</option>
                                        </Form.Control>
                                    </Col>

                                    <Col xs={12} className="mt-3">
                                        <div className="text-right">
                                            <Button
                                                variant="light"
                                                size="sm"
                                                className="btnXsOverride rounded-pill px-3 mr-3"
                                                onClick={resetFilter}
                                            >
                                                Reset Filter
                                            </Button>

                                            <Button
                                                variant="info"
                                                size="sm"
                                                className="btnXsOverride rounded-pill px-3"
                                                onClick={handleTampilkan}
                                            >
                                                Tampilkan
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    }

                    {loadingInit &&
                        <Col xs={12} className="mt-3">
                            <PlaceHolderLoadingInit />
                        </Col>
                    }

                    {loadingData &&
                        <Col xs={12} className="mt-3 px-0">
                            <PlaceHolderLoading
                                caption={
                                    <React.Fragment>
                                        Mengambil Data <Spinner
                                            as="span"
                                            className="ml-2"
                                            animation="border"
                                            variant="secondary"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </React.Fragment>
                                }
                            />
                        </Col>
                    }

                    {showIfNoData && !loadingData &&
                        <Col xs={12} className="mt-3">
                            <div className="flexRowJustifyAlignCenter">
                                <LottiePausAstronot />
                            </div>
                        </Col>
                    }

                    {!loadingData && data && !showIfNoData &&
                        <Col xs={12} className="mt-3">

                            <div className="alert alert-info">
                                <div className="fontweight500 text-center">
                                    Hasil Report
                                </div>

                                <div>
                                    <hr className="my-3" />
                                </div>

                                <div className="mb-2">
                                    <span className="fontweight500">Jumlah Data</span> {jData}
                                </div>

                                <div className="mb-2">
                                    <div className="fontweight500">Kategori Barang</div>
                                    <div className="f13">
                                        {rptKategoriBarang && Object.keys(rptKategoriBarang).map((keyRprKB) => {
                                            return (
                                                <div key={keyRprKB + 'keyRprKB'} className="">
                                                    {rptKategoriBarang[keyRprKB].nama + ' : ' + rptKategoriBarang[keyRprKB].jumlah}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className="mb-2">
                                    <div className="fontweight500">Kategori Customer</div>
                                    <div className="f13">
                                        {rptKategoriCustomer && Object.keys(rptKategoriCustomer).map((keyRprKC) => {
                                            return (
                                                <div key={keyRprKC + 'keyRprKC'} className="">
                                                    {rptKategoriCustomer[keyRprKC].nama + ' : ' + rptKategoriCustomer[keyRprKC].jumlah}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className="mb-2">
                                    <div className="fontweight500">Proses</div>
                                    <div className="f13">
                                        {rptProses && Object.keys(rptProses).map((keyRprPrs) => {
                                            return (
                                                <div key={keyRprPrs + 'keyRprPrs'} className="">
                                                    {rptProses[keyRprPrs].nama + ' : ' + rptProses[keyRprPrs].jumlah}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className="mb-2">
                                <Masonry
                                    breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 1 : 2) : 2) : 3) : 3}`}
                                    className="my-masonry-grid-12"
                                    columnClassName="my-masonry-grid_column-12"
                                >
                                    {Object.keys(data).map((key) => {
                                        return (
                                            <Card
                                                key={'prosesSatu' + key}
                                                className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3 cp`}
                                                onClick={() => handleGo(data[key].flag_proses, data[key].id_transaksi)}
                                            >
                                                <div style={{ width: '100%' }}>

                                                    <div className="flex">
                                                        <div className="mr-3">
                                                            <CustomerAvatar
                                                                prmSlug={data[key].slug_ck}
                                                                prmGender={data[key].gender}
                                                                ukuran={{ width: '40px', height: '40px' }}
                                                            />
                                                        </div>

                                                        <div>
                                                            <div className="color636363 fontweight600">{data[key].nama_customer}</div>
                                                            <div className="mb-1 color808080">{data[key].kategori_customer}</div>
                                                        </div>
                                                    </div>

                                                    <div><hr className="mt-2 mb-3" /></div>

                                                    <div style={{ width: '100%' }}>
                                                        <div className="f13 flexJustifyBeetwenAlignCenter">
                                                            <div>
                                                                <span className="fontweight500 mr-2 color636363">
                                                                    Nota
                                                                </span>
                                                                <span className="color808080">
                                                                    {data[key].id_transaksi}
                                                                </span>
                                                            </div>

                                                            <div className="color808080">
                                                                {statusProject(data[key].flag_proses)}
                                                            </div>
                                                        </div>

                                                        <div className="f13">
                                                            <span className="fontweight500 mr-2 color636363">
                                                                Kategori Barang
                                                            </span>
                                                            <span className="color808080">
                                                                {data[key].barang_kategori ? data[key].barang_kategori : 'Belum Ada Kategori'}
                                                            </span>
                                                        </div>

                                                        <div className="f13 mt-2">
                                                            <span className="fontweight500 mr-2 color636363">
                                                                Tanggal Masuk
                                                            </span>
                                                            <span className="color808080">
                                                                {data[key].tanggal_masuk ? sqlDateToHumanDate(data[key].tanggal_masuk) : ' - '}
                                                            </span>
                                                        </div>

                                                        {data[key].tanggal_selesai &&
                                                            <div className="f13">
                                                                <span className="fontweight500 mr-2 color636363">
                                                                    Tanggal Selesai
                                                                </span>
                                                                <span className="color808080">
                                                                    {data[key].tanggal_selesai ? sqlDateToHumanDate(data[key].tanggal_selesai) : ' - '}
                                                                </span>
                                                            </div>
                                                        }

                                                        {data[key].tanggal_ambil &&
                                                            <div className="f13">
                                                                <span className="fontweight500 mr-2 color636363">
                                                                    Tanggal Ambil
                                                                </span>
                                                                <span className="color808080">
                                                                    {data[key].tanggal_ambil ? sqlDateToHumanDate(data[key].tanggal_ambil) : ' - '}
                                                                </span>
                                                            </div>
                                                        }

                                                        {data[key].tanggal_pelunasan &&
                                                            <div className="f13">
                                                                <span className="fontweight500 mr-2 color636363">
                                                                    Tanggal Pelunasan
                                                                </span>
                                                                <span className="color808080">
                                                                    {data[key].tanggal_pelunasan ? sqlDateToHumanDate(data[key].tanggal_pelunasan) : ' - '}
                                                                </span>
                                                            </div>
                                                        }

                                                        {data[key].dp &&
                                                            <div className="f13 mt-2">
                                                                <span className="fontweight500 mr-2 color636363">
                                                                    Down Payment
                                                                </span>
                                                                <span className="color808080">
                                                                    {data[key].dp ? 'Rp ' + formatNumberCanZero(data[key].dp) : ' - '} {data[key].metode_dp ? handleMetodeUangMuka(data[key].metode_dp) : ''}
                                                                </span>
                                                            </div>
                                                        }

                                                        {data[key].data_diskon &&
                                                            <div className="f13">
                                                                <span className="fontweight500 mr-2 color636363">
                                                                    Diskon
                                                                </span>
                                                                <span className="color808080">
                                                                    {handleKetDiskon(data[key].data_diskon)}
                                                                </span>
                                                            </div>
                                                        }

                                                        {data[key].data_pembayaran &&
                                                            <div className="f13">
                                                                <span className="fontweight500 mr-2 color636363">
                                                                    Total
                                                                </span>
                                                                <span className="color808080">
                                                                    {'Rp ' + formatNumberCanZeroMinus(getTotal(data[key].data_pembayaran, data[key].data_diskon))}
                                                                </span>
                                                            </div>
                                                        }

                                                        {data[key].data_pembayaran &&
                                                            <div className="f13">
                                                                <span className="fontweight500 mr-2 color636363">
                                                                    Total Bayar
                                                                </span>
                                                                <span className="color808080">
                                                                    {'Rp ' + formatNumberCanZeroMinus(getTotalBayar(data[key].data_pembayaran, data[key].dp, data[key].data_diskon))} {data[key].metode_pelunasan ? handleMetodePelunasan(data[key].metode_pelunasan) : ''}
                                                                </span>
                                                            </div>
                                                        }

                                                        {data[key].data_pembayaran &&
                                                            <div className="f13">
                                                                <span className="fontweight500 mr-2 color636363">
                                                                    Lunas
                                                                </span>
                                                                <span className="color808080">
                                                                    {data[key].is_lunas === '1' ? 'Lunas' : 'Belum Lunas'}
                                                                </span>
                                                            </div>
                                                        }

                                                        {data[key].tanggal_ambil &&
                                                            <div className="f13 mt-2">
                                                                <span className="fontweight500 mr-2 color636363">
                                                                    Kategori Servis
                                                                </span>
                                                                <span className="color808080">
                                                                    {Object.keys(kategoriServis).includes(data[key].kategori_servis) ? kategoriServis[data[key].kategori_servis] : ' - '}
                                                                </span>
                                                            </div>
                                                        }

                                                        {data[key].reminder_at &&
                                                            <div className="f13">
                                                                <span className="fontweight500 mr-2 color636363">
                                                                    Reminder
                                                                </span>
                                                                <span className="color808080">
                                                                    {data[key].reminder_at} Bulan sejak pengambilan
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </Card>
                                        )
                                    })}
                                </Masonry>
                            </div>

                            <div className="mt-3 pb-3">
                                {hasMore ?
                                    <div className="text-center">
                                        <ButtonLoading
                                            prmLoading={btnLoadingMore}
                                            btnClass="btnXsOverride"
                                            btnVariant="info"
                                            btnOnClick={moreData}
                                            caption="Load More"
                                        />
                                    </div>
                                    :
                                    <div className="text-center colorAaa">
                                        No More Data
                                    </div>
                                }
                            </div>
                        </Col>
                    }
                </Row>
            </Layout>

            <Modal
                show={modalTglAwal}
                onHide={() => setModalTglAwal(false)}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tanggal Awal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flexJustifyAlignCenter pt-1 pb-3">
                        <Calendar
                            defaultView="month"
                            onChange={(value) => {
                                setDataFilter({ ...dataFilter, tanggalAwal: value });
                                setModalTglAwal(false);
                            }}
                            value={dataFilter.tanggalAwal === tglAwalCaption ? new Date() : dataFilter.tanggalAwal}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={modalTglAkhir}
                onHide={() => setModalTglAkhir(false)}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tanggal Akhir</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flexJustifyAlignCenter pt-1 pb-3">
                        <Calendar
                            defaultView="month"
                            onChange={(value) => {
                                setDataFilter({ ...dataFilter, tanggalAkhir: value });
                                setModalTglAkhir(false);
                            }}
                            value={dataFilter.tanggalAkhir === tglAkhirCaption ? new Date() : dataFilter.tanggalAkhir}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={modalFilterCustomer}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body className="bgSoft p-0 pb-3 px-0">
                    <div className="borderBottomEee bgWhite p-3">
                        <Form.Control
                            className=""
                            size="sm"
                            type="text"
                            placeholder="Ketik Nama / Alamat / No Hp"
                            value={inputCariCustomer}
                            onChange={(e) => setInputCariCustomer(e.target.value)}
                        />
                    </div>

                    <div
                        className="p-3"
                        style={{ overflowY: 'scroll', minHeight: '300px', maxHeight: '300px' }}
                    >
                        {dataInit.customerFiltered ? Object.keys(dataInit.customerFiltered).map(keyCF => {
                            return (
                                <Card
                                    key={keyCF + 'cf_73284'}
                                    className="py-2 px-3 mb-2 brSoftMedium shadow-sm"
                                    onClick={() => {
                                        setDataFilter({
                                            ...dataFilter,
                                            customer: dataInit.customerFiltered[keyCF].nama_customer,
                                            idCustomer: dataInit.customerFiltered[keyCF].id_customer
                                        });

                                        setModalFilterCustomer(false);
                                    }}
                                >
                                    <div className="flex">
                                        <div>
                                            <CustomerAvatar
                                                prmSlug={dataInit.customerFiltered[keyCF].slug_ck}
                                                prmGender={dataInit.customerFiltered[keyCF].gender}
                                                ukuran={{ width: '30px', height: '30px' }}
                                            />
                                        </div>

                                        <div className="ml-3 color636363">
                                            <div className="bold">
                                                {dataInit.customerFiltered[keyCF].nama_customer}
                                            </div>

                                            <div className="f13">{dataInit.customerFiltered[keyCF].nama_kategori}</div>
                                            <div className="f13 mt-1">{dataInit.customerFiltered[keyCF].alamat}</div>
                                            <div className="f13">{dataInit.customerFiltered[keyCF].no_hp}</div>
                                        </div>
                                    </div>
                                </Card>
                            )
                        })
                            :
                            <div className="color808080 text-center">Tidak ada data customer</div>
                        }
                    </div>

                    <div className="pt-3 borderTopEee flexJustifyAlignCenter">
                        <Button
                            variant="info"
                            size="sm"
                            className="btnXsOverride px-4"
                            onClick={() => setModalFilterCustomer(false)}
                        >
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment >
    );
}

export default Report;
