import React from 'react';
import axios from "axios";
import { useSelector } from "react-redux";
import { Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

import Layout from "../../components/Layout";
import NavbarTop from "../../components/NavbarTop";
import { BASE_URL_DUA, batasFluid } from "../../utils/globalVar";
import { VFCannotNull, deko } from "../../utils/globalFunc";
import ModalAlertWarning from '../../components/ModalAlertWarning';
import ModalConfirmation from "../../components/ModalConfirmation";
import ButtonLoading from "../../components/ButtonLoading";
import LottiePausAstronot from "../../components/LottiePausAstronot";
import PlaceHolderElement2 from "../../components/PlaceHolderElement2";

const KelolaAkunUser = () => {

    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    const [dataInit, setDatainit] = React.useState(null);
    const [loadingInit, setLoadingInit] = React.useState(false);
    const prmInit = {
        nama: '',
        username: '',
        password: '',
        level: '',
        statusAkun: ''
    }

    const [inputan, setInputan] = React.useState(prmInit);

    const refNama = React.useRef();
    const refUsername = React.useRef();
    const refPassword = React.useRef();
    const refStatusAkun = React.useRef();
    const refJabatan = React.useRef();

    const [dataForAction, setDataForAction] = React.useState({
        id: '',
        nama: ''
    });

    const [modalTambah, setModalTambah] = React.useState(false);
    const [btnLoadingTambah, setBtnLoadingTambah] = React.useState(false);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [modalHapus, setModalHapus] = React.useState(false);
    const [modalReset, setModalReset] = React.useState(false);
    const [btnLoadingHapus, setBtnLoadingHapus] = React.useState(false);
    const [btnLoadingResetPassword, setBtnLoadingResetPassword] = React.useState(false);

    const refResetPassword = React.useRef();
    const [modalEditUN, setModalEditUN] = React.useState(false);
    const [btnLoadingEditUN, setBtnLoadingEditUN] = React.useState(false);
    const refNewNama = React.useRef('');
    const refNewUN = React.useRef('');

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        setLoadingInit(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/get_all_user',
            data: {
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingInit(false);
            setDatainit(response.data.data);
        }).catch(error => {
            setLoadingInit(false);
            handleWarningModal('Warning : ' + error.message)
        });

        document.body.style.backgroundColor = "#FAF9F9";
        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, [reduxLogin.syswebappi])

    const handleSubmit = () => {
        refNama.current.style.border = 'unset';
        refUsername.current.style.border = 'unset';
        refPassword.current.style.border = 'unset';
        refJabatan.current.style.border = 'unset';
        refStatusAkun.current.style.border = 'unset';

        if (VFCannotNull(inputan.nama)) {
            refNama.current.style.border = '1px solid red';
            refNama.current.focus();
        } else if (inputan.nama.length < 3 || inputan.nama.length > 20) {
            refNama.current.style.border = '1px solid red';
            refNama.current.focus();
        } else if (VFCannotNull(inputan.username)) {
            refUsername.current.style.border = '1px solid red';
            refUsername.current.focus();
        } else if (inputan.username.length < 3 || inputan.username.length > 20) {
            refUsername.current.style.border = '1px solid red';
            refUsername.current.focus();
        } else if (VFCannotNull(inputan.password)) {
            refPassword.current.style.border = '1px solid red';
            refPassword.current.focus();
        } else if (inputan.password.length < 3 || inputan.password.length > 8) {
            refPassword.current.style.border = '1px solid red';
            refPassword.current.focus();
        } else if (VFCannotNull(inputan.level)) {
            refJabatan.current.style.border = '1px solid red';
        } else if (VFCannotNull(inputan.statusAkun)) {
            refStatusAkun.current.style.border = '1px solid red';
        } else {
            setBtnLoadingTambah(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/tambah_user',
                data: {
                    nama: inputan.nama,
                    username: inputan.username,
                    password: inputan.password,
                    level: inputan.level,
                    status_akun: inputan.statusAkun,
                    id_user: deko(reduxLogin.syswebappi)
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingTambah(false);
                setModalTambah(false);

                if (response.data.flag_status === true) {
                    setDatainit(response.data.data);
                    setInputan(prmInit);
                }

                if (response.data.flag_status === false) {
                    handleWarningModal('Warning : ' + response.data.pesan)
                }

                if (response.data.flag_status === 'not-su') {
                    handleWarningModal('Anda tidak memiliki akses untuk menambah data !')
                }

                if (response.data.flag_status === 'exist') {
                    handleWarningModal(<React.Fragment><div className="alert alert-danger">Username {inputan.username} sudah ada. Coba gunakan username lain.</div></React.Fragment>)
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingTambah(false);
                handleWarningModal('Warning : ' + error.message)
            });
        }
    }

    const hapusUser = () => {
        setBtnLoadingHapus(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/hapus_user',
            data: {
                id_for_delete: dataForAction.id,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapus(false);
            setModalHapus(false);

            if (response.data.flag_status === true) {
                setDatainit(response.data.data);
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.pesan)
            }

            if (response.data.flag_status === 'not-su') {
                handleWarningModal('Anda tidak memiliki akses untuk menghapus data !')
            }

            if (response.data.flag_status === 'cannot-delete') {
                handleWarningModal(<React.Fragment><div className="alert alert-danger">User {dataForAction.nama} tidak dapat dihapus karena memiliki beberapa project yang telah dikerjakan.</div></React.Fragment>)
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setBtnLoadingHapus(false);
            setModalHapus(false);
            handleWarningModal('Warning : ' + error.message)
        });
    }

    const handleStatusAkun = (val, id, key) => {
        document.querySelector("#loading_sa" + key).classList.remove('d-none');

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/change_status_akun',
            data: {
                id_user: deko(reduxLogin.syswebappi),
                status_akun: val,
                id_target: id
            }
        }).then((response) => {
            if (!mountedRef.current) return null;


            if (response.data.flag_status === true) {
                setDatainit(response.data.data);
                document.querySelector("#loading_sa" + key).classList.add('d-none');
            }

            if (response.data.flag_status === false) {
                handleWarningModal('Error : ' + response.data.pesan)
            }

            if (response.data.flag_status === 'not-su') {
                handleWarningModal('Anda tidak memiliki akses untuk menghapus data !')
            }
        }).catch(error => {
            if (!mountedRef.current) return null;
            document.querySelector("#loading_sa" + key).classList.add('d-none');
            handleWarningModal(error.message);
        })
    }

    const handleReset = () => {
        refResetPassword.current.style.border = 'unset';
        refNewUN.current.style.border = 'unset';

        if (VFCannotNull(refNewUN.current.value)) {
            refNewUN.current.style.border = '1px solid red';
            refNewUN.current.focus();
        } else if (refNewUN.current.value.length < 3 || refNewUN.current.value.length > 20) {
            refNewUN.current.style.border = '1px solid red';
            refNewUN.current.focus();
        } else if (VFCannotNull(refResetPassword.current.value)) {
            refResetPassword.current.style.border = '1px solid red';
            refResetPassword.current.focus();
        } else if (refResetPassword.current.value.length < 3 || refResetPassword.current.value.length > 8) {
            refResetPassword.current.style.border = '1px solid red';
            refResetPassword.current.focus();
        } else {
            setBtnLoadingResetPassword(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/reset_oleh_su',
                data: {
                    un: refNewUN.current.value,
                    password: refResetPassword.current.value,
                    id_user: deko(reduxLogin.syswebappi),
                    id_target: dataForAction.id
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingResetPassword(false);
                setModalReset(false);

                if (response.data.flag_status === true) {
                    handleWarningModal('Reset Username dan Password Berhasil.')
                }

                if (response.data.flag_status === false) {
                    handleWarningModal('Warning : ' + response.data.pesan)
                }

                if (response.data.flag_status === 'not-su') {
                    handleWarningModal('Anda tidak memiliki akses untuk mengubah data !')
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingResetPassword(false);
                handleWarningModal('Warning : ' + error.message)
            });
        }
    }

    const handleEditUser = () => {
        refNewNama.current.style.border = 'unset';

        if (VFCannotNull(refNewNama.current.value)) {
            refNewNama.current.style.border = '1px solid red';
            refNewNama.current.focus();
        } else if (refNewNama.current.value.length < 3 || refNewNama.current.value.length > 20) {
            refNewNama.current.style.border = '1px solid red';
            refNewNama.current.focus();
        } else {
            setBtnLoadingEditUN(true);

            axios({
                method: 'post',
                url: BASE_URL_DUA + '/edit_user_oleh_su',
                data: {
                    nama: refNewNama.current.value,
                    id_user: deko(reduxLogin.syswebappi),
                    id_target: dataForAction.id
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingEditUN(false);
                setModalEditUN(false);

                if (response.data.flag_status === true) {
                    setDatainit(response.data.data);
                    setDataForAction({ id: '', nama: '' });
                    handleWarningModal('Update User Berhasil.')
                }

                if (response.data.flag_status === false) {
                    handleWarningModal('Warning : ' + response.data.pesan)
                }

                if (response.data.flag_status === 'not-su') {
                    handleWarningModal('Anda tidak memiliki akses untuk mengubah data !')
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingEditUN(false);
                handleWarningModal('Warning : ' + error.message)
            });
        }
    }

    return (
        <React.Fragment>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <NavbarTop shadow={true} titleNavTop="Kelola User" />

            <Layout titleTab="Kelola User">

                <Row>
                    {!loadingInit &&
                        <Col xs={12} className="mt-4 mb-2">
                            <div className="text-right">
                                <div>
                                    <Button
                                        variant="info"
                                        size="sm"
                                        className="btnXsOverride"
                                        onClick={() => setModalTambah(true)}
                                    >
                                        <span className="fa fa-plus-circle mr-2" />
                                        <span>Tambah User</span>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    }

                    {dataInit && !loadingInit &&
                        Object.keys(dataInit).map((key) => {
                            return (
                                <Col
                                    key={key + 'dd7348jh'}
                                    xs={12}
                                    sm={6}
                                    lg={4}
                                    className="mt-3"
                                >
                                    <Card
                                        className={`${reduxWindowWidth > batasFluid ? 'p-3' : 'p-3'} shadow-sm brSoftMedium ${dataInit[key].status_akun === '1' ? 'bgWhite' : 'bgDdd'}`}
                                    >
                                        <Row>
                                            <Col xs={12} className="mb-3">
                                                <div className="flexJustifyBeetwenAlignCenter">
                                                    <div className="color636363 fontweight600">
                                                        <span>{dataInit[key].nama}</span>

                                                        <span className="ml-1 d-none fa fa-circle-o-notch fa-spin fa-lg fa-fw" id={`loading_sa${key}`} />
                                                    </div>

                                                    <div className="color808080 ml-2">
                                                        <span
                                                            className="colorTheme"
                                                            onClick={() => {
                                                                setDataForAction({
                                                                    ...dataForAction,
                                                                    id: dataInit[key].id,
                                                                    nama: dataInit[key].nama,
                                                                })
                                                                setModalEditUN(true);
                                                            }}
                                                        >
                                                            Edit
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xs={6} className="pr-1">
                                                <div className="color808080">
                                                    {deko(reduxLogin.syswebappi) !== dataInit[key].id ?
                                                        <div>
                                                            <Form.Control
                                                                custom
                                                                as="select"
                                                                size="sm"
                                                                defaultValue={dataInit[key].level}
                                                            >
                                                                <option value="teknisi">Teknisi</option>
                                                            </Form.Control>
                                                        </div>
                                                        :
                                                        <div>Super User</div>
                                                    }
                                                </div>
                                            </Col>

                                            <Col xs={6} className="pl-1">
                                                <div className="color808080">
                                                    {deko(reduxLogin.syswebappi) !== dataInit[key].id ?
                                                        <div>
                                                            <Form.Control
                                                                custom
                                                                as="select"
                                                                size="sm"
                                                                value={dataInit[key].status_akun}
                                                                onChange={(e) => handleStatusAkun(e.target.value, dataInit[key].id, key)}
                                                            >
                                                                <option value="1">Akun Aktif</option>
                                                                <option value="0">Akun Non Aktif</option>
                                                            </Form.Control>
                                                        </div>
                                                        :
                                                        <div>Akun Aktif</div>
                                                    }
                                                </div>
                                            </Col>


                                            <Col xs={12} className="mt-3">
                                                <div className="flexJustifyBeetwenAlignCenter">
                                                    <div
                                                        className="colorTheme"
                                                        onClick={() => {
                                                            setDataForAction({
                                                                ...dataForAction,
                                                                id: dataInit[key].id,
                                                                nama: dataInit[key].nama
                                                            })
                                                            setModalReset(true);
                                                        }}
                                                    >
                                                        Reset
                                                    </div>

                                                    {deko(reduxLogin.syswebappi) !== dataInit[key].id &&
                                                        <div
                                                            className="colorTheme"
                                                            onClick={() => {
                                                                setDataForAction({
                                                                    ...dataForAction,
                                                                    id: dataInit[key].id,
                                                                    nama: dataInit[key].nama
                                                                })
                                                                setModalHapus(true);
                                                            }}
                                                        >
                                                            Hapus Akun
                                                        </div>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            )
                        })
                    }

                    {loadingInit &&
                        <Col xs={12} className="mt-4">
                            <PlaceHolderElement2 prmMT="" prmPX="" />
                        </Col>
                    }

                    {!loadingInit && !dataInit &&
                        <Col xs={12} className="mt-4">
                            <div className="flexRowJustifyAlignCenter">
                                <LottiePausAstronot caption={`Belum ada data.
                                Klik tombol Tambah User untuk menambahkan user.`} />
                            </div>
                        </Col>
                    }
                </Row>

            </Layout>

            <Modal
                show={modalTambah}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="fontweight600 pb-3 borderBottomEee">
                        Tambah User
                    </div>

                    <div className="mt-3">
                        <Form.Group>
                            <Form.Label>
                                <div>Nama</div>
                                <div className="f12 colorAaa">3 - 20 karakter</div>
                            </Form.Label>
                            <Form.Control
                                ref={refNama}
                                size="sm"
                                type="text"
                                placeholder="Nama"
                                value={inputan.nama}
                                onChange={(e) => setInputan({ ...inputan, nama: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                <div>Username</div>
                                <div className="f12 colorAaa">3 - 20 karakter</div>
                            </Form.Label>
                            <Form.Control
                                ref={refUsername}
                                size="sm"
                                type="text"
                                placeholder="Username"
                                value={inputan.username}
                                onChange={(e) => setInputan({ ...inputan, username: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                <div>Password</div>
                                <div className="f12 colorAaa">3 - 8 karakter</div>
                            </Form.Label>
                            <Form.Control
                                ref={refPassword}
                                size="sm"
                                type="password"
                                placeholder="Password"
                                value={inputan.password}
                                onChange={(e) => setInputan({ ...inputan, password: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Jabatan
                            </Form.Label>

                            <Form.Control
                                custom
                                as="select"
                                size="sm"
                                ref={refJabatan}
                                value={inputan.level}
                                onChange={(e) => {
                                    setInputan({ ...inputan, level: e.target.value, jumlah: '' });
                                }}
                            >
                                <option value="">Pilih Jabatan</option>
                                <option value="teknisi">Teknisi</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Status Akun
                            </Form.Label>

                            <Form.Control
                                custom
                                as="select"
                                size="sm"
                                ref={refStatusAkun}
                                value={inputan.statusAkun}
                                onChange={(e) => {
                                    setInputan({ ...inputan, statusAkun: e.target.value, jumlah: '' });
                                }}
                            >
                                <option value="">Status Akun</option>
                                <option value="1">Aktif</option>
                                <option value="0">Tidak Aktif</option>
                            </Form.Control>
                        </Form.Group>

                        <div className="mt-3 text-center">
                            <Button
                                variant="light"
                                size="sm"
                                className="ml-2"
                                onClick={() => setModalTambah(false)}
                            >
                                Kembali
                            </Button>

                            <ButtonLoading
                                prmLoading={btnLoadingTambah}
                                btnClass="px-3 ml-3"
                                btnVariant="info"
                                btnOnClick={handleSubmit}
                                caption="Simpan"
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={modalReset}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="fontweight600 pb-3 borderBottomEee">
                        Reset Username dan Password Untuk {dataForAction.nama}
                    </div>

                    <div className="mt-3">
                        <Form.Group>
                            <Form.Label>
                                <div>Username Baru</div>
                                <div className="f12 colorAaa">3 - 20 karakter</div>
                            </Form.Label>
                            <Form.Control
                                ref={refNewUN}
                                size="sm"
                                type="text"
                                placeholder="Username Baru"
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                <div>Password Baru</div>
                                <div className="f12 colorAaa">3 - 8 karakter</div>
                            </Form.Label>
                            <Form.Control
                                ref={refResetPassword}
                                size="sm"
                                type="password"
                                placeholder="Password Baru"
                            />
                        </Form.Group>

                        <div className="mt-3 text-center">
                            <Button
                                variant="light"
                                size="sm"
                                className="ml-2"
                                onClick={() => setModalReset(false)}
                            >
                                Kembali
                            </Button>

                            <ButtonLoading
                                prmLoading={btnLoadingResetPassword}
                                btnClass="px-3 ml-3"
                                btnVariant="info"
                                btnOnClick={handleReset}
                                caption="Simpan"
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={modalEditUN}
                backdrop="static"
                keyboard={false}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="mb-3">
                        <div className="fontweight600">Edit User</div>
                    </div>

                    <div>
                        <Form.Group>
                            <Form.Label>
                                <div>Nama</div>
                                <div className="f12 colorAaa">3 - 20 karakter</div>
                            </Form.Label>
                            <Form.Control
                                ref={refNewNama}
                                size="sm"
                                type="text"
                                placeholder="Nama"
                                defaultValue={dataForAction.nama}
                            />
                        </Form.Group>
                    </div>

                    <div className="mt-4 text-center">
                        <Button
                            variant="light"
                            size="sm"
                            className="ml-2"
                            onClick={() => setModalEditUN(false)}
                        >
                            Kembali
                        </Button>

                        <ButtonLoading
                            prmLoading={btnLoadingEditUN}
                            btnClass="px-3 ml-3"
                            btnVariant="info"
                            btnOnClick={handleEditUser}
                            caption="Simpan"
                        />
                    </div>
                </Modal.Body>
            </Modal>


            <ModalConfirmation
                showModal={modalHapus}
                hideModal={() => setModalHapus(false)}
                title="Konfirmasi"
                bodyCaption={`Hapus user '${dataForAction.nama}' ?`}
                btnCloseClass="px-4"
                btnCloseCaption="Close"
                bLPrm={btnLoadingHapus}
                bLBtnClass="ml-3 px-3"
                bLOnClick={hapusUser}
                bLCaption="Hapus"
            />

        </React.Fragment >
    );
}

export default KelolaAkunUser;
