import React from 'react';
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import 'font-awesome/css/font-awesome.min.css';

import Layout from "../components/Layout";
import NavbarTopOwner from "../components/NavbarTop";
import NavbarTopTeknisi from "../components/teknisi/NavbarTop";
import CustomerAvatar from "../components/CustomerAvatar";
import { sqlDateToLongHumanDate, deko } from "../utils/globalFunc";
import { widthLG, widthMD, widthSM, widthXS, BASE_URL_DUA, batasFluid, prefixUrl, levelUser } from "../utils/globalVar";
import PlaceHolderElement4Flex from "../components/PlaceHolderElement4Flex";
import ModalAlertWarning from '../components/ModalAlertWarning';
import ButtonLoading from '../components/ButtonLoading';
import LottiePausAstronot from '../components/LottiePausAstronot';
import BarcodeScanner from '../components/BarcodeScanner';

const PencarianCepat = () => {

    const history = useHistory();
    const reduxLogin = useSelector(data => data.lumoslored);
    const reduxWindowWidth = useSelector(data => data.lumoswinwid);

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const [heightNavTop, setHeightNavTop] = React.useState(0);

    const [data, setData] = React.useState(null);
    const limitData = 3;
    const refOffsetData = React.useRef(0);
    const [inputSearch, setInputSearch] = React.useState('');
    const [hasMore, setHasMore] = React.useState(false);
    const [btnLoadingMore, setBtnLoadingMore] = React.useState(false);
    const [loadingTyping, setLoadingTyping] = React.useState(false);
    const [loadingProses, setLoadingProses] = React.useState(false);
    const [tidakAdaHasil, setTidakAdaHasil] = React.useState(false);

    const [modalBarcodeScanner, setModalBarcodeScanner] = React.useState(false);
    const handleScanBarcode = (err, result) => {
        if (result) {
            setInputSearch(result.text);
            setModalBarcodeScanner(false);
        }
    }

    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#FAF9F9";

        document.querySelector(".containerLuarTopNavbar") && mountedRef.current && setHeightNavTop(document.querySelector(".containerLuarTopNavbar").clientHeight);

        return () => {
            document.body.style.backgroundColor = "#fff";
            mountedRef.current = false;
        }
    }, []);

    const moreData = () => {
        setBtnLoadingMore(true);

        axios({
            method: 'post',
            url: BASE_URL_DUA + '/pencarian_cepat',
            data: {
                inputan: inputSearch,
                limit_data: limitData,
                offset_data: refOffsetData.current,
                id_user: deko(reduxLogin.syswebappi)
            }
        }).then((response) => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);

            data && response.data.data && [...data, ...response.data.data].length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

            data && response.data.data && setData([...data, ...response.data.data]);

            if (response.data.data) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;
            setBtnLoadingMore(false);
            handleWarningModal('Warning : ' + error.message);
        })
    };


    // baas
    const getData = React.useCallback((dt) => {
        axios({
            method: 'post',
            url: BASE_URL_DUA + '/pencarian_cepat',
            data: dt
        }).then((response) => {
            if (!mountedRef.current) return null;

            setLoadingTyping(false);
            setLoadingProses(false);

            response.data.data && response.data.data.length < response.data.data_all.length ? setHasMore(true) : setHasMore(false);

            if (response.data.data) {
                setData(response.data.data);
                setTidakAdaHasil(false);
            } else {
                setData(null);
                setTidakAdaHasil(true);
            }

            if (response.data.data) { refOffsetData.current = refOffsetData.current + limitData };
        }).catch(error => {
            if (!mountedRef.current) return null;
            setLoadingTyping(false);
            setTidakAdaHasil(false);
            setLoadingProses(false);
            handleWarningModal('Warning : ' + error.message);
        })
    }, []);

    React.useEffect(() => {
        !inputSearch && setLoadingProses(true);
        inputSearch && setLoadingTyping(true);

        refOffsetData.current = 0;

        const handler = setTimeout(() => {
            const dt = {
                inputan: inputSearch,
                limit_data: limitData,
                offset_data: refOffsetData.current,
                id_user: deko(reduxLogin.syswebappi)
            }

            getData(dt)
        }, inputSearch ? 1000 : 500);

        return () => clearTimeout(handler);
    }, [inputSearch, getData, reduxLogin.syswebappi]);

    const handleGo = (flag, prmId) => {
        if (flag === '0') {
            history.push('/transaksi/' + prmId + '/pencariancepat');
        } else if (flag === '1') {
            history.push('/confirmdoproses/' + prmId + '/pencariancepat');
        } else if (flag === '2') {
            history.push('/working/' + prmId + '/pencariancepat');
        } else if (flag === '3') {
            deko(reduxLogin.syswebappj) === levelUser.owner && history.push('/' + prefixUrl.owner + '/qc/' + prmId + '/pencariancepat');

            deko(reduxLogin.syswebappj) === levelUser.teknisi && history.push('/' + prefixUrl.teknisi + '/menungguqc/' + prmId + '/pencariancepat');
        } else if (flag === '4') {
            deko(reduxLogin.syswebappj) === levelUser.owner && history.push('/' + prefixUrl.owner + '/selesai/' + prmId + '/pencariancepat');

            deko(reduxLogin.syswebappj) === levelUser.teknisi && handleWarningModal('Project Sudah Selesai dan Menunggu Diambil Oleh Customer.');
        } else if (flag === '5') {
            deko(reduxLogin.syswebappj) === levelUser.owner && history.push('/' + prefixUrl.owner + '/sudahdiambil/' + prmId + '/pencariancepat');

            deko(reduxLogin.syswebappj) === levelUser.teknisi && handleWarningModal('Project Sudah Diambil Oleh Customer.');
        }
    }

    const getProses = (flag) => {
        let proses = '';

        if (flag === '0') {
            proses = 'Nota';
        } else if (flag === '1') {
            proses = 'Antrian';
        } else if (flag === '2') {
            proses = 'Pengerjaan';
        } else if (flag === '3') {
            proses = 'Q.C';
        } else if (flag === '4') {
            proses = 'Selesai';
        } else if (flag === '5') {
            proses = 'Sudah Diambil';
        }

        return proses;
    }

    return (
        <React.Fragment>

            <BarcodeScanner
                modalBarcodeScanner={modalBarcodeScanner}
                onSken={(satu, dua) => handleScanBarcode(satu, dua)}
                closeModalScanBarcode={() => setModalBarcodeScanner(false)}
            />

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            {deko(reduxLogin.syswebappj) === levelUser.owner && <NavbarTopOwner shadow={false} titleNavTop="Pencarian Cepat" />}

            {deko(reduxLogin.syswebappj) === levelUser.teknisi && <NavbarTopTeknisi shadow={false} titleNavTop="Pencarian Cepat" />}

            <Layout titleTab="Pencarian Cepat">
                <Row>
                    <Col
                        xs={12}
                        className="pt-2 pb-3 mb-3"
                        style={{
                            position: 'sticky',
                            top: `${heightNavTop}px`,
                            zIndex: '3',
                            backgroundColor: '#fff',
                            borderBottom: '1px solid #eee'
                        }}
                    >

                        <InputGroup>
                            <FormControl
                                size="sm"
                                type="text"
                                placeholder="Ketik Pencarian"
                                value={inputSearch}
                                onChange={(e) => {
                                    setInputSearch(e.target.value);
                                }}
                                className="removeShadowOnFocus"
                                style={{ borderRight: '0 solid #eee' }}
                            />

                            <InputGroup.Append>
                                <InputGroup.Text id="ad-cbd-9890" className="bgColorForm">
                                    {loadingTyping &&
                                        <Spinner animation="border" size="sm" variant="info" />
                                    }

                                    <span className="fa fa-barcode ml-2" onClick={() => setModalBarcodeScanner(true)} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>

                    {!data && !loadingTyping && tidakAdaHasil &&
                        <Col xs={12}>
                            <div className="flexRowJustifyAlignCenter">
                                <LottiePausAstronot />
                            </div>
                        </Col>
                    }

                    {!loadingTyping && data && !loadingProses &&
                        <Col xs={12} className="">
                            <Masonry
                                breakpointCols={`${reduxWindowWidth < widthLG ? (reduxWindowWidth < widthMD ? (reduxWindowWidth < widthSM ? (reduxWindowWidth < widthXS ? 1 : 2) : 2) : 3) : 3}`}
                                className="my-masonry-grid-12"
                                columnClassName="my-masonry-grid_column-12"
                            >
                                {
                                    Object.keys(data).map((key) => {
                                        return (
                                            <Card
                                                key={'prosesSatu' + key}
                                                className={`${reduxWindowWidth > batasFluid ? 'p-4' : 'p-3'} shadow-sm brSoftMedium mb-3 cp`}

                                                onClick={() => handleGo(data[key].flag_proses, data[key].id_transaksi)}
                                            >
                                                <div style={{ width: '100%' }}>

                                                    <div className="flex">
                                                        <div className="mr-3">
                                                            <CustomerAvatar
                                                                prmSlug={data[key].slug_ck}
                                                                prmGender={data[key].gender}
                                                                ukuran={{ width: '40px', height: '40px' }}
                                                            />
                                                        </div>

                                                        <div>
                                                            <div className="bold color636363">
                                                                {data[key].nama}
                                                            </div>
                                                            <div className="mb-1 color808080">
                                                                {data[key].nama_cust_ktgr}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div><hr className="mt-2 mb-3" /></div>

                                                    <div className="f13">
                                                        <span className="fontweight500 mr-2 color636363">No Nota</span>
                                                        <span className="color808080">{data[key].id_transaksi}</span>
                                                    </div>

                                                    <div className="f13">
                                                        <span className="fontweight500 mr-2 color636363">
                                                            Tanggal Masuk
                                                        </span>
                                                        <span className="color808080">
                                                            {sqlDateToLongHumanDate(data[key].tanggal_masuk)}
                                                        </span>
                                                    </div>

                                                    <div className="f13">
                                                        <span className="fontweight500 mr-2 color636363">
                                                            Proses
                                                        </span>
                                                        <span className="color808080">
                                                            {getProses(data[key].flag_proses)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Card>
                                        )
                                    })
                                }
                            </Masonry>

                            <div className="mt-1">
                                {hasMore ?
                                    <div className="text-center">
                                        <ButtonLoading
                                            prmLoading={btnLoadingMore}
                                            btnClass="btnXsOverride"
                                            btnVariant="info"
                                            btnOnClick={moreData}
                                            caption="Load More"
                                        />
                                    </div>
                                    :
                                    <div className="text-center colorAaa">
                                        No More Data
                                    </div>
                                }
                            </div>
                        </Col>
                    }
                </Row>

                {loadingProses &&
                    <Row>
                        <Col xs={12} className="mt-0 px-0">
                            <PlaceHolderElement4Flex />
                        </Col>
                    </Row>
                }
            </Layout>

        </React.Fragment >
    );
}

export default PencarianCepat;
