import React from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { is_login } from "../redux/reduxLogin/action";

import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { brand, BASE_URL, batasFluid, otspc } from '../utils/globalVar';
import { VFCannotNull } from "../utils/globalFunc";
import ModalAlertWarning from '../components/ModalAlertWarning';
import ButtonLoading from "../components/ButtonLoading";

const Login = () => {

    // info : ModalAlertWarning attribute
    const [warningModal, setWarningModal] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');
    const handleWarningModal = (prm) => { setWarningModal(true); setWarningText(prm); }
    // info : ModalAlertWarning attribute

    const mountedRef = React.useRef(true);

    const dispatch = useDispatch();
    const reduxWindowWidth = useSelector(data => data.lumoswinwid);
    const reduxLogin = useSelector(data => data.lumoslored);

    const history = useHistory();

    const [btnLoadingLogin, setBtnLoadingLogin] = React.useState(false);

    const refUserName = React.useRef();
    const refPassword = React.useRef();

    React.useEffect(() => {
        return () => {
            mountedRef.current = false;
        }
    }, []);

    React.useEffect(() => {
        (reduxLogin && reduxLogin.syswebappj && reduxLogin.syswebappi && reduxLogin.syswebappn && reduxLogin.otspc && (reduxLogin.otspc === otspc)) && history.push('/');

        document.title = `Login \u00B7 ${brand.nama} ${brand.tag}`;
    }, [history, reduxLogin])

    const [inputUsername, setInputUsername] = React.useState('');
    const [inputPassword, setInputPassword] = React.useState('');

    const handleInputUsername = (e) => {
        setInputUsername(e.target.value)
    }

    const handleInputPassword = (e) => {
        setInputPassword(e.target.value)
    }

    const handleSubmit = () => {
        refUserName.current.style.border = 'unset';
        refPassword.current.style.border = 'unset';

        if (VFCannotNull(inputUsername)) {
            refUserName.current.style.border = '1px solid red';
            refUserName.current.focus();
        } else if (VFCannotNull(inputPassword)) {
            refPassword.current.style.border = '1px solid red';
            refPassword.current.focus();
        } else {
            setBtnLoadingLogin(true);

            axios({
                method: 'post',
                url: BASE_URL + '/login_lumos_nhdospekdn',
                data: {
                    username: inputUsername,
                    password: inputPassword
                }
            }).then((response) => {
                if (!mountedRef.current) return null;

                setBtnLoadingLogin(false);

                if (response.data.flag_status === true) {
                    dispatch(is_login(response.data.data));
                    history.push('/');
                }

                if (response.data.flag_status === false) {
                    handleWarningModal('Akun tidak terdaftar.');
                }

                if (response.data.flag_status === 'non-aktif') {
                    handleWarningModal('Akun Anda sudah tidak aktif.');
                }

                if (response.data.flag_status === 'wrong-password') {
                    handleWarningModal('Password yang Anda masukkan salah !');
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setBtnLoadingLogin(false);
                handleWarningModal(error.message)
            })
        }

    }

    return (
        <Container fluid={reduxWindowWidth <= batasFluid ? true : false}>

            <ModalAlertWarning
                showWarningModal={warningModal}
                hideWarningModal={() => { setWarningModal(false); setWarningText(''); }}
                paramText={warningText}
            />

            <Row className="justify-content-center">

                <Col xs={12} sm={8} md={6} lg={5} xl={4}>

                    <div className="mt-5 mb-3 flexJustifyAlignCenter">

                        <Card
                            className="p-3 border-0"
                            style={{ width: '100%' }}
                        >
                            <div className="text-center f16 bold mb-4">
                                Sign In Aplikasi {brand.nama}
                            </div>

                            <div>
                                <Form.Group controlId="inputUsername">
                                    <Form.Label>
                                        Username
                                    </Form.Label>

                                    <Form.Control
                                        size="sm"
                                        ref={refUserName}
                                        type="text"
                                        placeholder="Username"
                                        value={inputUsername}
                                        onChange={handleInputUsername}
                                    />
                                </Form.Group>

                                <Form.Group controlId="inputPassword">
                                    <Form.Label>
                                        Password
                                    </Form.Label>

                                    <Form.Control
                                        size="sm"
                                        ref={refPassword}
                                        type="password"
                                        placeholder="Password"
                                        value={inputPassword}
                                        onChange={handleInputPassword}
                                    />
                                </Form.Group>
                            </div>

                            <div className="flexJustifyAlignCenter mt-4">
                                <ButtonLoading
                                    prmLoading={btnLoadingLogin}
                                    btnClass="px-4 width100"
                                    btnVariant="info"
                                    btnOnClick={handleSubmit}
                                    caption="Sign In"
                                />
                            </div>

                        </Card>

                    </div>

                </Col>
            </Row>

        </Container>
    );
}

export default Login;
